<template>
	<div class="container">
		<div class="edit-wrap flex-column align-center" v-if="editBankModal">
			<div class="edit-title">修改银行卡号</div>
			<div class="edit-line"></div>
			<input type="text" v-model="editBankNum" class="edit-input" placeholder="请输入银行卡号">
			<div class="edit-line"></div>
			<div class="edit-btn" @click="doEdit()">确认修改</div>
		</div>
		<div class="model" v-if="editBankModal" @click.stop="cancelModal"></div>
		<div class="edit-wrap flex-column align-center" v-if="errmodel">
			<div class="edit-title">银行卡账号修改失败：</div>
			<div class="edit-line"></div>
			<div class="edit-msg">{{errorMsg}}</div>
			<div class="edit-line"></div>
			<div class="edit-btn" @click="cancelErrmodel()">确认</div>
		</div>
		<div class="model" v-if="errmodel" @click.stop="cancelModal"></div>
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">提现</div>
			<div class="zd-btn" @click.stop="navBillList()">账单</div>
		</div>
		<!-- <div class="tixian-sm">提现业务需先提交请求，我司会在24小时之内处理请求</div> -->
		<!-- <div class="tixian-sm">提现手续费1%，一个工作日到账</div> -->
		<div class="tixian-sm">根据央行相关规定，资金由第三方持牌机构存管。</div>
		<div class="pay-container">
			<div class="pay-con">
				<div class="bill-type-title">提现至</div>
				<!-- 	<div class="pay-list">
					<div class="flex-row align-center space-between pay-item">
						<div class="flex-row align-center ">
							<img src='../assets/zfb_icon.png' class="pay-icon" />
							<div class="pay-item-name">支付宝支付</div>
						</div>
						<img class="select_icon" src="../assets/select_icon.png" />
					</div>
					<div class="flex-row align-center space-between pay-item">
						<div class="flex-row align-center ">
							<img src='../assets/wechat_icon.png' class="pay-icon" />
							<div class="pay-item-name">微信支付</div>
						</div>
						<div class="no-select"></div>
					</div>
				</div> -->
				<!-- <div class="payinfo-wrap flex-column">
					<div class="flex-row align-center">
						<img src='../assets/zfb_icon.png' class="pay-icon" />
						<div class="pay-item-name">支付宝支付</div>
					</div>
					<input type="text" v-model="payInfo.account" class="payinfo-input" placeholder="支付宝账号" />
					<input type="text" v-model="payInfo.name" class="payinfo-input" placeholder="姓名" />
				</div> -->
				<div class="payinfo-wrap flex-column">
					<div class="flex-row align-center">
						<img src='../assets/ysf.png' class="pay-icon" />
						<div class="pay-item-name">账户一</div>
					</div>
					<div class="flex-row align-center ysf-wrap space-between">
						<div class="ysf-ti">银行卡号</div>
						<div class="flex-row align-center" @click="editBank()">
							<div class="ysf-con">{{info.no}}</div>
							<img src="../assets/edit.png" class="edit-img" alt="">
						</div>
					</div>
					<div class="flex-row align-center ysf-wrap space-between">
						<div class="ysf-ti">持卡人姓名</div>
						<div class="ysf-con">{{info.name}}</div>
					</div>
					<div class="flex-row align-center ysf-wrap space-between">
						<div class="ysf-ti">身份证号</div>
						<div class="ysf-con">{{info.idCard}}</div>
					</div>
				</div>
				<div class="flex-row align-center bill-price-sec space-between">
					<div class="bill-price-title">可支配金额</div>
					<!-- <div class="bill-price-tips">单笔最低提现199，单日最高提现19999</div> -->
				</div>

				<div class="flex-row align-center price-input-sec">
					<div class="fuhao">￥</div>
					<input :placeholder="sr.zfbBalance" @input="inputZfbBalanceMoney" type="number" class="price-input"
						v-model="payInfo.zfbMoney" />
				</div>
				<div class="flex-row align-center space-between dtx-wrap2">
					<div class="dtx-tips"></div>
					<div class="dtx-price">提现手续费{{payInfo.zfbFee?payInfo.zfbFee:0}}元</div>
				</div>
				<div class="echarge-btn2 " @click.stop="doWithdraw(4)">提现</div>
				<!-- 	<div class="flex-row align-center price-input-sec price-input-sec-mt">
					<div class="fuhao">￥</div>
					<input :placeholder="sr.zfbBalance" type="number" class="price-input"
						v-model="payInfo.zZfbMoney" />
				</div> -->
				<div class="echarge-btn2 " @click.stop="doInnerTransfer(4)">转余额</div>
				<div class="linnes"></div>
				<div class="payinfo-wrap flex-column">
					<div class="flex-row align-center">
						<img src='../assets/ysf.png' class="pay-icon" />
						<div class="pay-item-name">账户二</div>
					</div>
					<div class="flex-row align-center ysf-wrap space-between">
						<div class="ysf-ti">银行卡号</div>
						<div class="ysf-con">{{info.no}}</div>
					</div>
					<div class="flex-row align-center ysf-wrap space-between">
						<div class="ysf-ti">持卡人姓名</div>
						<div class="ysf-con">{{info.name}}</div>
					</div>
					<div class="flex-row align-center ysf-wrap space-between">
						<div class="ysf-ti">身份证号</div>
						<div class="ysf-con">{{info.idCard}}</div>
					</div>
				</div>
				<div class="flex-row align-center bill-price-sec space-between">
					<div class="bill-price-title">可提现金额</div>
					<div class="bill-price-tips">单日最高提现19999</div>
				</div>
				<!-- <div class="flex-row align-center space-between tixian-yue-sec">
					<div class="tixian-yue">最多可提现2,3232元</div>
					<div class="tixian-all-btn">提现全部</div>
				</div> -->
				<div class="flex-row align-center price-input-sec">
					<div class="fuhao">￥</div>
					<input :placeholder="sr.avlBalance" @input="inputMoney" type="number" class="price-input"
						v-model="payInfo.money" />
				</div>
				<div class="flex-row align-center space-between dtx-wrap">
					<div class="dtx-tips">待结算金额</div>
					<div class="dtx-price">{{sr.waitSettleAmount}}元</div>
				</div>
				<!-- <div class="tips">单日最高提现10000元</div> -->
				<div class="echarge-btn" @click.stop="doWithdraw(3)">提现</div>
				<!-- <div class="echarge-btn-z " @click.stop="doInnerTransfer(3)">转余额</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				type: 3, //2支付宝 3银行卡
				payInfo: {
					account: '',
					name: '',
					money: '',
					zfbMoney: '',
					zfbFee: '', //手续费
					zfbEndFee: '',
					zZfbMoney: ''
				},
				request: 0,
				info: {},
				sr: {},
				editBankModal: 0,
				editBankNum: '',
				save: 0,
				errmodel: 0,
				errorMsg: '12312'
			}
		},
		mounted() {
			this.getUserPayType();
			this.getUserTotalIncome();
			util.wxConfig();
		},
		methods: {
			cancelErrmodel() {
				this.errmodel = 0;
			},
			doEdit() {
				if (!this.editBankNum) {
					Toast('请输入银行卡号');
					return;
				}
				if (this.save) {
					return;
				}
				this.save = 1;
				Toast.loading({
					message: '保存中...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('account', this.editBankNum);
				params.append('payType', 3);
				params.append('thridSettleCenter', 1);
				params.append('name', this.info.name);
				params.append('idCard', this.info.idCard);
				this.axios.post(config.requestUrl + '/front/user/updateBankPay', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.getUserPayType();
						Toast('修改成功');
						this.save = 0;
						this.editBankModal = 0;
					} else {
						Toast.clear();
						this.errorMsg = response.data.msg
						this.editBankModal = 0;
						this.errmodel = 1;
						this.save = 0
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			editBank() {
				this.editBankModal = 1;
			},
			cancelModal() {
				this.editBankModal = 0;
			},
			navBillList() {
				this.$router.push('/billlist')
			},
			getUserTotalIncome(cb) {
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/financial/getUserTotalIncome', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.sr = response.data.obj;
						this.payInfo.zfbMoney = '';
						this.payInfo.zfbFee = '';
						this.payInfo.zfbEndFee = ''
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getUserPayType() {
				let params = new FormData()
				params.append('payType', this.type)
				this.axios.post(config.requestUrl + '/front/user/getUserPayType', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let info = response.data.obj[0];
						// if (this.type == 2) {
						// 	if (response.data.obj && response.data.obj[0]) {
						// 		this.payInfo.account = response.data.obj[0].userAccount;
						// 		this.payInfo.name = response.data.obj[0].name;
						// 	}
						// } else if (this.type == 3) {
						if (info && info.no) {
							info.no = util.addStar(info.no, 4, 4)
							info.idCard = util.addStar(info.idCard, 4, 4)
							this.info = info;
						} else {
							this.$router.replace('/bank')
						}
						// }
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							this.$store.commit("clearUser");
							this.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/')
							}, 1200)
						}
						Toast(response.data.msg ? response.data.msg : '当前系统繁忙 请稍后再试');
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
				// let params2 = new FormData()
				// params2.append('payType', 3)
				// this.axios.post(config.requestUrl + '/front/user/getUserPayType', params2, {
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data',
				// 		'token': this.$store.state.user.token
				// 	}
				// }).then(response => {
				// 	if (response.data.code == 0) {
				// 		let info = response.data.obj[0];
				// 		if (info && info.no) {
				// 			this.info = info;
				// 		}
				// 		console.log(response)
				// 	} else {
				// 		Toast(response.data.msg);
				// 	}
				// }, response => {
				// 	Toast('获取失败，请稍后重试');
				// })
			},
			inputMoney(e) {
				console.log(e.target.value)
				this.payInfo.money = e.target.value.replace(/[^\d.]/g, '')
			},

			async inputZfbBalanceMoney(e) {
				const that = this
				this.payInfo.zfbMoney = e.target.value.replace(/[^\d.]/g, '')
				if (this.payInfo.zfbMoney > 0) {
					let params = new FormData()
					params.append('extractAmount', that.payInfo.zfbMoney)
					await that.axios.post(config.requestUrl + '/front/financial/getConsignmentFee', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							console.log(response)
							that.payInfo.zfbFee = response.data.obj.newPlatformFee
							that.payInfo.zfbEndFee = response.data.obj.reallyAmount
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								setTimeout(() => {
									this.$router.replace('/')
								}, 1200)
							}
							Toast(response.data.msg);
						}
					}, response => {
						that.request = 0;
						Toast('获取失败，请稍后重试');
					})
				} else {
					that.payInfo.zfbFee = 0
				}

			},
			// getUserPayType() {
			// 	const that = this
			// 	let params = new FormData()
			// 	params.append('payType', 2)
			// 	that.axios.post(config.requestUrl + '/front/user/getUserPayType', params, {
			// 		headers: {
			// 			'Content-Type': 'multipart/form-data',
			// 			'token': that.$store.state.user.token
			// 		}
			// 	}).then(response => {
			// 		that.request = 0;
			// 		if (response.data.code == 0) {
			// 			if (response.data.obj && response.data.obj[0]) {
			// 				this.payInfo.account = response.data.obj[0].userAccount;
			// 				this.payInfo.name = response.data.obj[0].name;
			// 			}
			// 			console.log(1)
			// 		} else {
			// 			let msg = response.data.msg;
			// 			const str = "未获取到用户信息";
			// 			const str2 = "登录已失效"
			// 			if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
			// 				localStorage.removeItem("user");
			// 				that.$store.commit("clearUser");
			// 				that.$store.commit("clearCode");
			// 				setTimeout(() => {
			// 					this.$router.replace('/')
			// 				}, 1200)
			// 			}
			// 			Toast(response.data.msg);
			// 		}
			// 	}, response => {
			// 		that.request = 0;
			// 		Toast('获取失败，请稍后重试');
			// 	})
			// },
			doWithdraw(payType) {
				const that = this;
				if (that.request) return;

				if (payType == 4) {
					if (!that.info.no) {
						Toast('请完善信息');
						setTimeout(() => {
							this.$router.replace('/bank');
						}, 1200)
						return;
					}
					// if (!that.payInfo.account) {
					// 	Toast('请输入支付宝账号');
					// 	return;
					// }
					// if (!that.payInfo.name) {
					// 	Toast('请输入支付宝姓名');
					// 	return;
					// }
					if (!that.payInfo.zfbMoney) {
						Toast('请输入提现金额');
						return;
					}
					if (that.payInfo.zfbMoney <= 2) {
						Toast('单笔最低提现2.01元');
						return;
					}
				} else if (payType == 3) {
					if (!that.info.no) {
						Toast('请完善信息');
						setTimeout(() => {
							this.$router.replace('/bank');
						}, 1200)
						return;
					}
					if (!that.payInfo.money) {
						Toast('请输入提现金额');
						return;
					}
				}

				if (that.payInfo.money > 19999) {
					Toast('单日最高提现19999元');
					return;
				}
				if (payType == 4) {
					that.request = 1;
					let params = new FormData()
					params.append('extractAmount', that.payInfo.zfbMoney)
					that.axios.post(config.requestUrl + '/front/financial/getConsignmentFee', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						that.request = 0
						if (response.data.code == 0) {
							console.log(response)
							that.payInfo.zfbFee = response.data.obj.newPlatformFee;
							that.payInfo.zfbEndFee = response.data.obj.reallyAmount
							Dialog.confirm({
									message: `实际到账金额${that.payInfo.zfbMoney}-${that.payInfo.zfbFee}=${that.payInfo.zfbEndFee}元`,
								})
								.then(() => {
									that.withdraw(payType, that.payInfo.zfbEndFee)
								})
								.catch(() => {
									console.log('取消退出登录')
									// on cancel
								});
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								setTimeout(() => {
									this.$router.replace('/')
								}, 1200)
							}
							Toast(response.data.msg);
						}
					}, response => {
						that.request = 0;
						Toast('获取失败，请稍后重试');
					})
				} else {
					that.withdraw(payType);
				}

			},
			withdraw(payType, money) {
				const that = this;
				Toast.loading({
					message: '',
					forbidClick: true,
					duration: 0,
				});
				that.request = 1;
				let params = new FormData()
				params.append('currencyId', 1)
				params.append('account', that.payInfo.account)
				// params.append('currencyId',that.payInfo.account)
				params.append('extractType', payType)
				if (payType == 4) {
					params.append('quantity', that.payInfo.zfbMoney)
				} else {
					params.append('quantity', that.payInfo.money)
				}
				params.append('realName', that.payInfo.name)
				that.axios.post(config.requestUrl + '/front/financial/apply', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					that.request = 0;
					if (response.data.code == 0) {
						console.log(1)
						Toast.success('提交成功');
						setTimeout(() => {
							that.$router.replace({
								path: '/withdrawsuccess',
								query: {
									money: payType == 4 ? money : that.payInfo
										.money,
									payType
								}
							})
						}, 1200)

					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/')
							}, 1200)
						}
						Toast(response.data.msg ? response.data.msg : '当前系统繁忙 请稍后再试');
					}
				}, response => {
					that.request = 0;
					Toast('获取失败，请稍后重试');
				})
			},
			doInnerTransfer() {
				const that = this;
				if (that.payInfo.zfbMoney <= 0) {
					Toast('请输入转入金额')
					return;
				}
				Dialog.confirm({
						allowHtml: true,
						title: '转余额',
						message: '转余额后可购买藏品，是否确认？',
					}).then(() => {
						Toast.loading({
							message: '提交中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						params.append('currencyId', 1);
						params.append('transferAmount', that.payInfo.zfbMoney);
						params.append('transferType', 1);

						that.axios.post(config.requestUrl + '/front/transfer/doInnerTransfer', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								that.getUserTotalIncome();

								Toast.clear();
								Toast.success('划转成功')
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #6236FF;
		min-height: 100%;
	}

	.pay-container {
		width: 750px;
		/* min-height: 100%; */
		background: #6236FF;
		padding: 40px;
		box-sizing: border-box;
	}

	.tixian-sm {
		width: 750px;
		height: 88px;
		background: #FFEADC;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
		font-size: 24px;
		color: #000000;
		line-height: 88px;
		text-align: center;
	}

	.bill-type-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 86px;
		text-align: center;
		border-bottom: 1px solid #E3E3E3;
	}

	.pay-con {
		width: 670px;
		min-height: 100%;
		background: #FFFFFF;
		border-radius: 10px;
		box-sizing: border-box;
		padding-bottom: 100px;
	}

	.pay-list {
		height: 233px;
		padding: 60px 40px 0 40px;
		box-sizing: border-box;
		border-bottom: 1px solid #E3E3E3;
	}

	.pay-item {
		margin-bottom: 41px;
	}

	.pay-icon {
		width: 28px;
		height: 28px;
	}


	.pay-item-name {
		font-size: 28px;
		color: #000000;
		line-height: 26px;
		margin-left: 20px;
	}

	.pay-item-yue {
		font-size: 28px;
		color: #FF0000;
		line-height: 26px;
	}

	.select_icon {
		width: 22px;
		height: 22px;
		background: #6236FF;
		border-radius: 50%;
	}

	.no-select {
		width: 22px;
		height: 22px;
		background: #D8D8D8;
		border-radius: 50%;
	}

	.bill-price-title {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
	}

	.bill-price-sec {
		margin-top: 46px;
		margin-left: 40px;
	}

	.bill-price-tishi {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-left: 20px;
	}

	.price-input-sec {
		margin-top: 25px;
		padding-left: 40px;
		/* padding-bottom: 52px; */
		/* border-bottom: 1px solid #E3E3E3; */
	}

	.price-input-sec-mt {
		margin-top: 40px;
	}

	.fuhao {
		font-size: 60px;
		font-weight: 600;
		color: #000000;
		line-height: 84px;
	}

	.price-input {
		font-size: 60px;
		font-weight: 600;
		color: #000000;
		line-height: 84px;
		border: none;
		width: 400px;
	}

	.pay-grey-btn {
		width: 590px;
		height: 80px;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		margin: 552px auto 0 auto;
		text-align: center;
	}

	.pay-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		margin: 552px auto 0 auto;
		text-align: center;
	}

	.tixian-yue-sec {
		margin-top: 30px;
		padding: 0 40px;
	}

	.tixian-yue {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
	}

	.tixian-all-btn {
		font-size: 26px;
		color: #6236FF;
		line-height: 30px;
	}

	.payinfo-wrap {
		padding: 30px 40px;
		border-bottom: 1px solid #E3E3E3;
	}

	.payinfo-input {
		margin-top: 30px;
		width: 595px;
		height: 68px;
		padding: 20px 48px;
		box-sizing: border-box;
		background: #F8F8F8;
		border: none;
	}

	.tips {
		margin-top: 32px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
		line-height: 26px;
		margin-left: 40px;
	}

	.echarge-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		/* position: absolute;
		bottom: 290px;
		left: 0;
		right: 0;*/
		margin: 60px auto 0;
		text-align: center;
	}

	.echarge-btn2 {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		/* position: absolute;
		bottom: 290px;
		left: 0;
		right: 0;*/
		margin: 30px auto 0;
		text-align: center;
	}

	.echarge-btn-z {
		width: 590px;
		height: 80px;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		line-height: 80px;
		margin: 30px auto 0;
		text-align: center;
		border: 1px solid #6236FF;
		color: #000000;
	}

	.ysf-wrap {
		margin-top: 50px;
	}

	.ysf-ti {
		font-size: 28px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.25);
	}

	.ysf-con {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.bill-price-tips {
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.85);
		margin-right: 35px;
		max-width: 400px;
		word-break: break-all;
		word-wrap: break-word;
	}

	.dtx-wrap {
		margin-top: 85px;
	}

	.dtx-wrap2 {
		margin-top: 20px;
	}

	.dtx-tips {
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		margin-left: 40px;
	}

	.dtx-price {
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		margin-right: 35px;
	}

	.zd-btn {
		width: 105px;
		height: 49px;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 25px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 49px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 30px;
		bottom: 0;
		margin: auto;
	}

	.linnes {
		margin-top: 40px;
		width: 100%;
		height: 2px;
		background: #E3E3E3;
	}

	.edit-img {
		width: 22px;
		height: 22px;
		margin-left: 30px;
	}

	.edit-wrap {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
	}

	.edit-title {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		margin: 30px 0;
	}

	.edit-line {
		width: 100%;
		height: 2px;
		background: rgba(0, 0, 0, 0.1);
	}

	.edit-input {
		width: 480px;
		height: 70px;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		padding: 17px 20px;
		box-sizing: border-box;
		margin: 35px 0 39px;
		border: none;
	}

	.edit-btn {
		margin-top: 20px;
		padding-bottom: 30px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
	}

	.edit-msg {
		width: 470px;
		margin: 40px 0;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}
</style>
