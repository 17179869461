<template>
	<div class="container">
		<div class="wrap" id="wrap" ref="wrap">

		</div>
		<div class="tips-modal flex-row align-center justify-center" v-if="tips!=''">
			<div class="tips">{{tips}}</div>
		</div>
		<div class="loader-inner line-scale" v-if="load">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				show: 0,
				rds: [],
				tips: '',
				tipsArr: {
					'25d4bc4c-be79-445e-917b-efe170da60af': '丙',
					'2a741ded-e6be-47d2-910d-4951f8dc5f62': '贞',
					'528b7991-076e-417b-9ef7-25b2fbd22bc8': '辰',
					'ad0ee4fd-65a9-4dca-b5b4-11542f974b10': '今',
					'ebb378fa-8d85-4d9e-97f7-860c4c84948a': '卜',
					'63af4c15-d6fe-41e6-95d9-2d36cfe1f38e': '夕',
					'97d3437a-4842-425c-8a0f-5fc4c0213aca': '?',
					'dde8bc00-771d-48cc-8f1e-bafbc3cdad37': '?',
					'e61f1b03-666a-44e7-a322-cdf8184961bc': '妣',
					'887d166d-23fd-4ea3-be16-c5549656bf1c': '辛',
				},
				load: 1
			}
		},
		async mounted() {
			const that = this;
			document.title = '甲骨文一期第十片'
			await this.showXk();
			document.getElementsByTagName('canvas')[0].addEventListener('click', function(e) {
				// console.log(that.rds)
				for (let i in that.rds) {
					let re1 = that.rds[i].isVisible;
					let re2 = e.x >= (parseInt(that.rds[i].location2d.x) - 20);
					let re3 = e.x <= (parseInt(that.rds[i].location2d.x) + 20);
					let re4 = e.y >= (parseInt(that.rds[i].location2d.y) - 20);
					let re5 = e.y <= (parseInt(that.rds[i].location2d.y) + 20);
					if (re1 && re2 && re3 && re4 && re5) {
						let id = that.rds[i].id
						that.tips = that.tipsArr[`${id}`]
						// console.log(that.tipsArr[`${id}`])
						// alert(that.rds[i].id)
					}
				}
			})
			document.getElementsByTagName('canvas')[0].addEventListener('touchend', function(e) {
				let pos = e.changedTouches[0]
				// console.log(that.rds)
				for (let i in that.rds) {
					let re1 = that.rds[i].isVisible;
					let re2 = pos.pageX >= (parseInt(that.rds[i].location2d.x) - 20);
					let re3 = pos.pageX <= (parseInt(that.rds[i].location2d.x) + 20);
					let re4 = pos.pageY >= (parseInt(that.rds[i].location2d.y) - 20);
					let re5 = pos.pageY <= (parseInt(that.rds[i].location2d.y) + 20);
					if (re1 && re2 && re3 && re4 && re5) {
						let id = that.rds[i].id
						that.tips = that.tipsArr[`${id}`]
						// console.log(that.tipsArr[`${id}`])
						// alert(that.rds[i].id)
					}
				}
			})
		},
		methods: {
			async showXk() {
				const that = this;
				const {
					Launcher
				} = window.XK3D;
				const core = await Launcher.launch({
					modelPath: 'https://oss.metacbc.cn/models/ceshi/bc71a1a3-a123-41bb-b107-b6f4f475c741/',
					container: this.$refs.wrap,
					wasmPath: "https://oss.metacbc.cn/wasm/",
					autoRotate: true,
				});
				that.load = 0
				core.hotspot.list.forEach(spot => {
					// 监控热点列表的变化，获取准确的位置
					const {
						dispose
					} = spot.updated.on(() => {
						const {
							id,
							isVisible,
							location2d
						} = spot;
						// 基于3D视图下的2D坐标，及现在是否可见，前端组件可自行完成位置变更及可见性变化
						// console.log(id, isVisible, location2d);
						that.rds[id] = spot;
					});
					// 可以根据需要，如2D组件销毁时，调用dispose()，释放监听，消除回声
				});

			}
		}

	}
</script>

<style scoped="scoped">
	html,
	body,
	#app,
	iframe,
	.container {
		margin: 0;
		display: block;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.wrap {
		width: 100%;
		height: 100%;
		overflow: scroll;
	}

	.tips-modal {
		width: 133px;
		height: 132px;
		position: fixed;
		right: 0;
		bottom: 20%;
		z-index: 9999;
		background-image: url(../assets/3d/bg.png);
		background-size: 133px 132px;
	}

	.tips {
		width: 92px;
		height: 94px;
		line-height: 94px;
		font-size: 34px;
		font-weight: 400;
		text-align: center;
		color: #FFFFFF;
		background-image: url(../assets/3d/font-bg.png);
		background-size: 92px 94px;
	}



	.loader-inner {
		position: fixed;
		top: 50%;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 9999;
		width: 750px;
		height: 100px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	@-webkit-keyframes line-scale {
		0% {
			-webkit-transform: scaley(1);
			transform: scaley(1);
		}

		50% {
			-webkit-transform: scaley(0.4);
			transform: scaley(0.4);
		}

		100% {
			-webkit-transform: scaley(1);
			transform: scaley(1);
		}
	}

	@keyframes line-scale {
		0% {
			-webkit-transform: scaley(1);
			transform: scaley(1);
		}

		50% {
			-webkit-transform: scaley(0.4);
			transform: scaley(0.4);
		}

		100% {
			-webkit-transform: scaley(1);
			transform: scaley(1);
		}
	}

	.line-scale>div:nth-child(1) {
		-webkit-animation: line-scale 1s 0.1s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.1s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div:nth-child(2) {
		-webkit-animation: line-scale 1s 0.2s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.2s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div:nth-child(3) {
		-webkit-animation: line-scale 1s 0.3s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.3s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div:nth-child(4) {
		-webkit-animation: line-scale 1s 0.4s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.4s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div:nth-child(5) {
		-webkit-animation: line-scale 1s 0.5s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.5s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div {
		background-color: #fff;
		width: 8px;
		height: 70px;
		border-radius: 4px;
		margin: 4px;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		display: flex;
	}
</style>
