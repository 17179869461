<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">优先购配额</div>
			<div class="zd-btn" @click.stop="navLog()">记录</div>
		</div>
		<div class="wrap">
			<div class="img-wrap flex-row align-center justify-center">
				<div class="num">{{num}}</div>
				<div class="unit">个</div>
			</div>
			<div class="tips">我的优先购配额</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';

	export default {
		data() {
			return {
				num: ''
			}
		},
		methods: {
			getPreBuyCount() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/destroy/getPreBuyCount', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.num = response.data.obj;
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navLog(){
				this.$router.push('/destroylog')
			}
		},
		mounted() {
			this.getPreBuyCount();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		min-height: 100%;
		background: #6236FF;
	}

	.wrap {
		width: 680px;
		height: max-content;
		margin: 46px auto 0;
		padding: 155px 0;
		background: #FFFFFF;
		box-shadow: inset 0px 0px 87px 0px #FFFFFF;
		border-radius: 20px;
	}

	.img-wrap {
		width: 680px;
		height: 746px;
		background-image: url(../assets/bg-yxg.png);
		background-size: 680px 746px;
	}



	.bg {
		width: 680px;
		height: 746px;
	}

	.num {
		font-size: 150px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 30px;
	}

	.unit {
		margin: 60px 0 30px 10px;
		font-size: 24px;
		font-weight: 400;
		color: #000000;
	}

	.tips {
		width: 330px;
		height: 80px;
		margin: 16px auto;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 40px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
		text-align: center;
		line-height: 80px;
	}

	.zd-btn {
		width: 105px;
		height: 49px;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 25px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 49px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 30px;
		bottom: 0;
		margin: auto;
	}
</style>
