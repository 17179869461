<template>
	<div class="container" ref="getheight">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">账单</div>
			<div class="date" @click="showPicker">{{date}}</div>
		</div>

		<div class="list">
			<van-list v-model="loading" @load="getLog" :finished="finished" finished-text="没有更多了" :error.sync="error"
				error-text="请求失败，点击重新加载">
				<div class="flex-row align-center space-between item" v-for="(item,index) in list" v-bind:key="index">
					<div class="flex-row align-center">
						<!-- <img v-if="item.type==3" src='../assets/chong_icon.png' class="item-icon" />
					<img v-if="item.type==7 || item.type == 6" src='../assets/ti_icon.png' class="item-icon" />
					<img v-if="item.type==8" src='../assets/shou_icon.png' class="item-icon" />
					<img v-if="item.type==1" src='../assets/sys.png' class="item-icon" />
					<img v-if="item.type==2" src='../assets/zn.png' class="item-icon" />
					<img v-if="item.type==9 || item.type==5" src='../assets/buy.png' class="item-icon" /> -->
						<img v-if="item.typeInt == -1" src='../assets/shou_icon.png' class="item-icon" />
						<img v-else-if="item.typeInt == -2" src='../assets/zn.png' class="item-icon" />
						<div v-else-if="item.typeInt == -3" class="rt-img2">奖</div>
						<div v-else-if="item.typeInt == -4" class="rt-img2">赋</div>
						<div v-else-if="item.typeInt == -5" class="rt-img2">店</div>
						<div v-else-if="item.typeInt == -6" class="rt-img2">推</div>
						<div v-else-if="item.typeInt == -7" class="rt-img2">TK</div>
						<img v-else src='../assets/ti_icon.png' class="item-icon" />
						<div class="flex-column">
							<div class="item-name">{{item.type}}</div>
							<div class="item-date">{{item.createTime}}</div>
						</div>
					</div>
					<div class="item-num">
						<!-- {{[4,5,7,9].indexOf(item.type) !== -1 ?'-':''}} -->
						{{item.typeInt == -1 || item.typeInt ==-2  || item.typeInt ==-3 || item.typeInt ==-4 || item.typeInt ==-5  || item.typeInt ==-7  ?'':'-'}}
						{{item.amount}}
					</div>
				</div>
				<!-- <div class="flex-row align-center space-between item">
				<div class="flex-row align-center">
					<img src='../assets/chong_icon.png' class="item-icon" />
					<div class="flex-column">
						<div class="item-name">充值至余额</div>
						<div class="item-date">2021.02.12 24：20</div>
					</div>
				</div>
				<div class="item-num">￥53</div>
			</div>
			<div class="flex-row align-center space-between item">
				<div class="flex-row align-center">
					<img src='../assets/shou_icon.png' class="item-icon" />
					<div class="flex-column">
						<div class="item-name">挂售入账</div>
						<div class="item-date">2021.02.12 24：20</div>
					</div>
				</div>
				<div class="item-num">￥2,3453</div>
			</div> -->
			</van-list>
		</div>
		<van-popup v-model="show" position="bottom">
			<van-datetime-picker @confirm="selectDate" v-model="currentDate" type="date" title="选择年月日"
				:min-date="minDate" :max-date="maxDate" />
		</van-popup>

	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
		DatetimePicker,
		Popup
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/datetime-picker/style';
	import 'vant/lib/popup/style';
	import Vue from 'vue';
	Vue.use(Popup);
	Vue.use(DatetimePicker);

	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				windowHeight: 0,
				offsetHeight: 0,
				date: '',
				paramDate: '',
				show: false,
				minDate: new Date(2020, 0, 1),
				maxDate: new Date(2025, 10, 1),
				currentDate: new Date(2021, 0, 17),
				selectTime: 0,
				loading: false,
				finished: false,
				error: false
			}
		},
		methods: {
			onScroll(e) {
				let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body
					.scrollTop; //页面滚动高度
				if (this.offsetHeight < scroll + this.windowHeight + 50) {
					if (!this.finished && !this.loading) {
						this.getLog();
					}
				}
			},
			showPicker() {
				this.show = true;
			},
			selectDate(e) {
				console.log(e)
				var date = new Date(e);
				let year = date.getFullYear();
				let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)
					.toString())
				let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
				this.date = `${year}年${month}月${day}日`;
				this.paramDate = `${year}-${month}-${day}`;
				this.currentDate = new Date();
				this.pageNum = 1;
				this.list = [];
				this.show = false;
				this.selectTime = 1;
				this.more = true;
				this.getLog();

			},
			getLog() {
				console.log('getLog')

				this.loading = true;
				const that = this;
				let startDate;
				if (that.selectTime == 0) {
					startDate = '2020-01-01 00:00:00';
				} else {
					startDate = this.paramDate + ' 00:00:00';
				}
				let endDate = this.paramDate + ' 23:59:59';
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				params.append('startDate', startDate);
				params.append('endDate', endDate);
				console.log(params)
				this.axios.post(config.requestUrl + '/front/financial/getUserIncomeAmountLog', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = this.list;
						let newList = response.data.obj.list;
						for (let i in newList) {
							let createTime = newList[i].doTime.replace(/-/g, "/");
							newList[i].createTime = util.formatDate(createTime)
							if (newList[i].amount < 0) {
								newList[i].amount = '-￥' + Math.abs(newList[i].amount);
							} else {
								newList[i].amount = '￥' + newList[i].amount
							}
						}
						console.log(newList)
						if (response.data.obj.isLastPage) {
							this.finished = true;
							this.more = false;
						}
						list = list.concat(newList);
						this.pageNum = this.pageNum + 1;
						this.list = list;
						if (this.list.length == 0) {
							this.empty = true;
						}
						this.offsetHeight = this.$refs.getheight.offsetHeight
						Toast.clear()
						this.$forceUpdate();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},

		},
		mounted() {
			util.wxConfig();
			window.addEventListener('scroll', this.onScroll, false);
			this.windowHeight = window.innerHeight; //窗口高度
			this.offsetHeight = this.$refs.getheight.offsetHeight;
			var date = new Date();
			let year = date.getFullYear();
			let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)
				.toString())
			let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
			this.date = `${year}年${month}月${day}日`;
			this.paramDate = `${year}-${month}-${day}`;
			this.currentDate = new Date();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	.nav {
		position: relative;
	}

	.date {
		width: 200px;
		height: 50px;
		background: #6236FF;
		border-radius: 34px;
		font-size: 24px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		position: absolute;
		right: 30px;
		top: 21px;
	}

	.list {
		width: 750px;
	}

	.item {
		width: 750px;
		height: 165px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
		padding: 0 40px;
		box-sizing: border-box;
	}

	.item-icon {
		width: 67px;
		height: 67px;
		margin-right: 30px;
	}

	.item-name {
		font-size: 30px;
		color: #000000;
		line-height: 30px;
	}

	.item-date {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-top: 25px;
	}

	.item-num {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
	}

	.rt-img {
		width: 67px;
		height: 67px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 67px;
		border-radius: 100%;
		background: gray;
		margin-right: 30px;
	}

	.rt-img2 {
		width: 67px;
		height: 67px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 67px;
		border-radius: 100%;
		background: #F7B500;
		margin-right: 30px;
	}
</style>
