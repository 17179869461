<template>
	<div>
		<div class="flex-row align-center justify-end">
			<div class="bill" @click.stop="navBalanceList()">账单</div>
		</div>
		<div class="flex-column align-center">
			<img src="../assets/yue_img.png" class="yue-icon" />
			<div class="mine-yue-title">我的余额</div>
			<div class="mine-yue">￥{{balance}}</div>
		</div>
		<div class="echarge-btn" @click.stop="navBill()">充值</div>
		<!-- <div class="more-btn" @click.stop="returnPrice()">退款</div> -->
		<div class="more-btn" @click.stop="returnPrice()">
			退款{{isNumCanRefundAmount?'￥'+canRefundAmount:'('+canRefundAmount+')'}}</div>
		<div class="more-btn2" @click.stop="doInnerTransfer()">
			转收入{{isNumCanRefundAmount?'￥'+surplusToDoAmount:'('+surplusToDoAmount+')'}}</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				balance: 0,
				typeArr: [],
				type: 0,
				payModel: false,
				payType: 2,
				wxH5Pay: 0, //是否开始了其他浏览器h5支付
				canRefundAmount: '',
				surplusToDoAmount: '',
				isNumCanRefundAmount: false,
				isNumSurplusToDoAmount: false
			}
		},
		methods: {
			changeMoney(type) {
				this.type = type;
			},
			recharge() {
				this.payModel = true;
			},
			navBalanceList() {
				this.$router.push('/balancelist')
			},
			navBill() {
				// Toast('请至首发抢购平台充值')
				this.$router.push('/bill')
			},
			navWithdraw() {
				this.$router.push('/withdraw')
			},
			getWalletByCurrencyId(cb) {
				const that = this;
				let params = new FormData()
				params.append('currencyId', 1)
				that.axios.post(config.requestUrl + '/front/financial/getWalletByCurrencyId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.balance = response.data.obj.using;
						this.canRefundAmount = response.data.obj.canRefundAmount;
						that.isNumCanRefundAmount = util.isRealNum(response.data.obj.canRefundAmount)
						this.surplusToDoAmount = response.data.obj.surplusToDoAmount;
						that.isNumSurplusToDoAmount = util.isRealNum(response.data.obj.surplusToDoAmount)
						if (cb) cb();
						console.log(response)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			returnPrice() {
				const that = this;
				if (!util.isRealNum(that.canRefundAmount)) return;
				Dialog.confirm({
						allowHtml: true,
						title: '申请退款',
						message: '余额退款<span style="font-weight:bolder">三个工作日</span>内到账，原路退回付款账户，是否确认退款？',
					}).then(() => {
						Toast.loading({
							message: '提交中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						params.append('currencyId', 1);
						that.axios.post(config.requestUrl + '/front/financial/balanceRefund', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								Toast.clear();
								this.$router.replace({
									path: '/refundsuccess',
									query: {
										money: response.data.obj
									}
								})
								// Toast.success('退出成功')
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});

			},
			doInnerTransfer() {
				const that = this;
				that.getWalletByCurrencyId(() => {
					if (!util.isRealNum(that.surplusToDoAmount)) return;
					Dialog.confirm({
							allowHtml: true,
							title: '转收入',
							message: '转收入后可提现，是否确认？',
						}).then(() => {
							Toast.loading({
								message: '提交中...',
								forbidClick: true,
								duration: 0,
							});
							let params = new FormData()
							params.append('currencyId', 1);
							params.append('transferAmount', that.surplusToDoAmount);
							params.append('transferType', 2);

							that.axios.post(config.requestUrl + '/front/transfer/doInnerTransfer', params, {
								headers: {
									'Content-Type': 'multipart/form-data',
									'token': this.$store.state.user.token
								}
							}).then(response => {
								if (response.data.code == 0) {
									that.getWalletByCurrencyId();
									Toast.clear();
									Toast.success('划转成功')
								} else {
									let msg = response.data.msg;
									const str = "未获取到用户信息";
									const str2 = "登录已失效"
									if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
										localStorage.removeItem("user");
										that.$store.commit("clearUser");
										that.$store.commit("clearCode");
										setTimeout(() => {
											this.$router.push('/')
										}, 1200)
									}
									Toast(response.data.msg);
								}
								this.loading = 0;
							}, response => {
								this.loading = 0;
								Toast('获取失败，请稍后重试');
							})
						})
						.catch(() => {
							console.log('取消退出登录')
							// on cancel
						});
				})
			}
		},
		mounted() {
			this.getWalletByCurrencyId();
			util.wxConfig();
		}
	}
</script>

<style scoped="scoped">
	.bill {
		width: 105px;
		height: 49px;
		background: rgba(98, 54, 255, 0.07);
		border-radius: 25px;
		margin-top: 20px;
		text-align: center;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 49px;
		margin-right: 30px;
	}

	.yue-icon {
		width: 200px;
		height: 200px;
		margin-top: 69px;
	}

	.mine-yue-title {
		font-size: 30px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
		margin-top: 40px;
	}

	.mine-yue {
		font-size: 60px;
		font-weight: 600;
		color: #000000;
		line-height: 60px;
		margin-top: 101px;
	}

	.echarge-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		position: absolute;
		bottom: 390px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}

	.more-btn {
		width: 590px;
		height: 80px;
		border: 1px solid #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		position: absolute;
		bottom: 270px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}

	.more-btn2 {
		width: 590px;
		height: 80px;
		border: 1px solid #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		position: absolute;
		bottom: 150px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}
</style>
