<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">置换</div>
		</div>
		<div class="empty-text" v-if="isRequest&& list.length == 0">没有可置换的数字藏品</div>
		<van-list v-model="loading" @load="getMergeConfigListByUserId" :finished="finished" finished-text=""
			:error.sync="error" error-text="请求失败，点击重新加载">
			<div class="list flex-row align-center flex-wrap">
				<div class="item flex-column align-center" v-for="(item,index) in list" v-bind:key="index">
					<img :src="item.mianPic" class="item-img" />
					<div class="good-item-name">{{item.name}}</div>
					<div v-if="item.isEnd == 0" class="item-btn" @click.stop="compound(index)">置换</div>
					<div v-else class="item-btn-false" >已结束</div>
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				error: false,
				isRequest: 0
			}
		},
		methods: {
			compound(index) {
				const that = this;
				let item = that.list[index];
				Dialog.confirm({
						title: '确认置换',
						// message: `本次置换会消耗${item.consumeCount}件藏品，是否确定？`,
						message: `将${item.srcNftName}置换为${item.targetNftName}，是否确定？`,
					})
					.then(() => {
						let params = new FormData()
						params.append('id', item.id)
						Toast.loading({
							message: '置换中...',
							forbidClick: true,
							duration: 0,
						});
						that.axios.post(config.requestUrl + '/front/displace/doDisplace', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								Toast.success('置换成功');
								that.getMergeConfigListByUserId();
							} else {
								Toast(response.data.msg);
							}
						}, response => {
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消')
						// on cancel
					});
			},
			getMergeConfigListByUserId() {
				console.log('qwer')
				const that = this;
				let params = new FormData()
				params.append('pageNum', that.pageNum)
				params.append('pageSize', that.pageSize)
				that.axios.post(config.requestUrl + '/front/displace/getDisplaceConfigListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = that.list;
						let newList = response.data.obj.list;
						let resList=[];
						for (let i in newList) {
							let isEnd = 0;
							if (newList[i].surplusQuantity <= 0) {
								isEnd = 1;
							} else {
								let date = newList[i].endTime.replace(/-/g, "/")
								if (util.compareTime(date) == 1) {
									isEnd = 1
								}
							}
							newList[i].isEnd = isEnd
							if(newList[i].obtainQuantity == 1){
								resList.push(newList[i])
							}
						}
						let finished = false;
						if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
							true;
						list = list.concat(resList);
						that.list = list;

						that.finished = finished;
						that.error = false;
						that.pageNum=that.pageNum+1
						this.isRequest = 1;
						that.loading=false;
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			}
		},
		mounted() {
			util.wxConfig();
		},
	}
</script>


<style scoped="scoped">
	.container {
		width: 100%;
		background: #F4F4F4;
		min-height: 100%;
	}


	/* 置换列表部分 */
	.list {
		padding: 10px 30px 0 30px;
	}

	.item {
		width: 335px;
		height: 524px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-top: 20px;
	}

	.item:nth-child(2n) {
		margin-left: 20px;

	}

	.item-img {
		width: 335px;
		/* height: 509px; */
		height: 375px;
	}

	.item-btn {
		width: 180px;
		height: 60px;
		background: #6236FF;
		border-radius: 30px;
		margin-top: 21px;
		text-align: center;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 60px;
	}

	.empty-text {
		font-size: 30px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		text-align: center;
		margin-top: 621px;
	}
	
	
	.item-btn-false {
		width: 180px;
		height: 60px;
		background: #cccccc;
		border-radius: 30px;
		margin-top: 21px;
		text-align: center;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 60px;
	}
</style>
