<template>
	<div class="container">
		<div class="flex-row align-center justify-center support-sec">
			<img src="../assets/support_icon.png" class="support-icon" />
			<div class="support-dian"></div>
			<div class="support-con">Meta链提供技术支持</div>
		</div>
		<div class="empty-text" v-if="list.length == 0 && request">没有赋能实业的藏品</div>
		<van-list v-model="loading" @load="getProductEntryEndueList" :finished="finished" finished-text=""
			:error.sync="error" error-text="请求失败，点击重新加载">
			<div class="card-item flex-row align-center" v-for="(item,index) in list" v-bind:key="index"
				@click.stop="navDetail(index)">
				<img :src="item.pic" alt="" class="card-img">
				<div class="card-con-wrap flex-column">
					<div class="card-name">{{item.name}}</div>
					<div class="card-pos">{{item.entryNo}}</div>
				</div>
				<div class="sr-con flex-column align-center justify-center">
					<div class="sr-ti-wrap flex-row align-center">
						<img src="../assets/entry/icon-sr.png" alt="" class="sr-icon">
						<div>赋能实业收入</div>
					</div>
					<div class="flex-row align-center">
						<div class="sr">￥{{item.income}}</div>
						<img src="../assets/icon-q.png" class="icon-q" alt="" @click.stop="tips()">
					</div>
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 20,
				loading: false,
				finished: false,
				error: false,
				request:0
			}
		},
		mounted() {

		},
		methods: {
			tips(){
				Dialog({ message: '如果买家在七天内申请退款，金额会减少。',confirmButtonColor:"#000000" });
			},
			getProductEntryEndueList() {
				this.loading = true;
				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);

				this.axios.post(config.requestUrl + '/front/endue/getProductEntryEndueList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = that.list;
						let newList = response.data.obj;

						if (response.data.obj.isLastPage) {
							this.finished = true;
							this.more = false;
						}
						list = list.concat(newList);
						this.pageNum = this.pageNum + 1;
						this.list = list;
						if (newList.length == 0) {
							this.finished = true;
						}
						Toast.clear()
						this.$forceUpdate();
						that.request=1;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			navDetail(index) {
				this.$router.push({
					path: '/entrydetail',
					query: {
						productId: this.list[index].productId,
						productEntryId: this.list[index].productEntryId,
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		background: #F4F4F4;
		min-height: 100%;
		width: 100%;
		/* height: 100%; */
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	.card-item {
		margin: 20px auto 0;
		width: 710px;
		height: 160px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.card-img {
		width: 100px;
		height: 100px;
		margin: 0 20px;
	}

	.card-con-wrap {
		width: 300px;
	}

	.card-name {
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.card-pos {
		font-size: 26px;
		font-weight: 400;
		color: #6D7278;
		margin-top: 18px;
	}

	.sr-con {
		width: 270px;
		height: 140px;
		background: #FFFFFF;
		box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 10px 10px 0px;
		border-left: ;
	}

	.sr-icon {
		width: 23px;
		height: 23px;
		margin-right: 10px;
	}

	.sr-ti-wrap {
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}

	.sr {
		width:135px;
		margin-top: 17px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		text-align: center;
	}
	.icon-q{
		margin-top: 17px;
		width: 24px;
		height: 24px;
	}
	.empty-text {
		font-size: 30px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		text-align: center;
		margin-top: 621px;
	}
</style>
