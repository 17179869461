<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">完善银行卡信息</div>
		</div>
		<div class="bank-wrap flex-column align-center justify-center">
			<div class="bank-t1">添加银行卡</div>
			<div class="bank-t2">请填写下列信息</div>
		</div>
		<div class="bank-con">
			<div class="bank-item flex-row align-center">
				<img src="../assets/bank-icon1.png" class="bank-icon">
				<input v-model="name" disabled type="text" placeholder="开户人姓名">
				
			</div>
			<div class="bank-item flex-row align-center">
				<img src="../assets/bank-icon2.png" class="bank-icon">
				<input @input="inputCard" v-model="idCard" type="text" placeholder="开户人身份证">
			</div>
			<div class="bank-item flex-row align-center">
				<img src="../assets/bank-icon3.png" class="bank-icon">
				<input v-model="account" type="text" placeholder="银行卡卡号">
			</div>
		</div>
		<div v-if="name && cardVerify && account.length>8" class="submit" @click.stop="submit">确认</div>
		<div v-else class="submit submit-false">确认</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	export default {
		data() {
			return {
				account: '',
				idCard: '',
				name: '',
				cardVerify: 0,
				isSubmit: 0
			}
		},
		mounted(){
			util.wxConfig();
			this.getMember();
		},
		methods: {
			getMember() {
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.name = response.data.obj.userInfo.otcName;
						console.log(this.name)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
			
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			inputCard(e) {
				const that = this;
				let card = e.target.value;
				console.log(e)
				let cardVerify = 0;
				if ((/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(card))) {
					cardVerify = 1;
				}
				that.cardVerify = cardVerify;
				console.log(`that.cardVerify${that.cardVerify}`)
			},
			submit() {
				const that = this;
				if (that.isSubmit) return;
				Toast.loading({
					message: '保存中...',
					forbidClick: true,
					duration: 0,
				});
				that.isSubmit = 1;
				let params = new FormData()
				params.append('account', that.account);
				params.append('idCard', that.idCard);
				params.append('name', that.name);
				params.append('payType', 3);
				params.append('thridSettleCenter', 1);
				that.axios.post(config.requestUrl + '/front/user/addPay', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.success('保存成功');
						setTimeout(() => {
							that.$router.go(-1);
						}, 1200)
					} else {
						Toast(response.data.msg);
					}
					that.isSubmit = 0;
				}, response => {
					that.isSubmit = 0;
					Toast('保存失败，请稍后重试');
				})
			}
		}
	}
</script>

<style scoped="scoped">
	input{
		background: #FFFFFF;
	}
	.container {
		min-height: 100%;
		background: #FFFFFF;
	}

	.bank-wrap {
		width: 704px;
		height: 335px;
		background-image: url(../assets/bank-bg.png);
		box-sizing: border-box;
		background-size: 704px 335px;
		background-repeat: no-repeat;
		margin: 32px auto 0 auto;
	}

	.bank-t1 {
		font-size: 32px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FFFFFF;
	}

	.bank-t2 {
		margin-top: 30px;
		font-size: 28px;
		font-weight: 400;
		color: #CFC2FF;
	}

	.bank-con {
		width: 670px;
		margin: 26px auto 0;
	}

	.bank-item {
		width: 100%;
		padding: 40px 20px;
		box-sizing: border-box;
		border-bottom: 1px solid #E3E3E3;
	}

	.bank-icon {
		width: 30px;
		height: 30px;
		margin-right: 30px;
	}

	.bank-item input {
		width: 550px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
		border: none;
	}

	.submit {
		margin: 180px auto 0;
		width: 590px;
		height: 80px;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 80px;
		background: #6236FF;

	}

	.submit-false {
		background: #BFBFBF;
	}
	.edit-img{
		width: 22px;
		height: 22px;
		margin-left: 30px;
	}
</style>
