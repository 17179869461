<template>
	<div>
		<div class="container" v-show="!isSetPwd">
			<template v-show="step ==1">
				<div ref="qrCodeDiv" style="position: fixed;top: -9999px;left: -9999px;"></div>
				<!-- <img :src="info.productPic" class="good-img" /> -->
				<div class="for-img" @click.stop="nav3D()">
					<img v-if="info.isOpen=='是'" :src="info.productPic" class="good-img" />
					<img v-if="info.isOpen=='否'" src="../assets/manghe.png" class="good-img2" />
					<img src="../assets/3d.png" class="icon-3d" v-if="info.iconType == 3">
					<img v-if="tkData.id" src="../assets/icon-shop.png" class="icon-3d" @click="navTk()">
					<img src="../assets/fd.png" class="icon-3d" v-if="info.iconType == 1">
					<img src="../assets/goods/play2.png" class="icon-play" alt="" v-if="info.iconType == 2">
					<video id="myVideo" v-show="videoModal" :src="info.link3D" class="video" controls width="100%"
						height="100%"></video>
				</div>
				<!-- <img src="../assets/xian_img.png" class="xian-img flex-row" v-if="showQrcode" /> -->
				<!-- <div class="qrcode-wrap flex-row align-center space-between" v-show="showQrcode">
					<div class="qrcode-wrap-left flex-column">
						<div class="qrcode-goods-name">{{info.productName}}</div>
						<div class="qrcode-goods-fx">发行方:{{info.issuerName}}</div>
						<div class="qrcode-goods-bh" v-if="info.tokenId !='888888'">版号:{{info.position}}</div>
						<div class="qrcode-goods-bh" v-else>编号:{{info.position}}</div>
						<div class="qrcode-goods-tk" v-if="info.tokenId !='888888'">Token ID:{{info.tokenId}}</div>
						<div v-if="info.tokenId !='888888'" :data-clipboard-text="info.tokenId" class="icon-copy" @click="copy" alt="">
							<img src="../assets/points/icon-copy.png">
						</div>
					</div>
					<div class="qrcode-wrap-right flex-column align-center" v-if="info.tokenId !='888888'">
						<img :src="qrcodeImg" class="qrcode-img">
						<div class="qrcode-desc">扫码查主人</div>
					</div>
				</div> -->
				<div class="flex-row align-center good-name-sec">
					<img src="../assets/good_name_bg.png" class="good-name-bg" />
					<div class="flex-row align-center good-name-con space-between">
						<div class="good-name">{{info.isOpen == '是'?info.productName:'盲盒'}}</div>
						<div class="flex-column zc-wrap">
							<div class="num-wrap2 flex-shrink flex-row align-center" v-if="info.quantity>0">
								<div class="num-wrap-ti">发行量</div>
								<div class="num-wrap-con">{{info.quantity}}件</div>
							</div>
							<div class="num-wrap2 flex-shrink flex-row align-center" v-if="info.mergeRecordCount>0">
								<div class="points-wrap-ti">流通量</div>
								<div class="points-wrap-con">{{info.mergeRecordCount}}件</div>
							</div>
						</div>
					</div>
					
				</div>

				<div class="good-detail-1">
					<div class="label-item flex-wrap flex-row align-center">
						<!-- <div class="num-wrap flex-row align-center flex-shrink" v-if="info.mergeRecordCount>0">
							<div class="num-wrap-ti">实际数量</div>
							<div class="num-wrap-con">{{info.mergeRecordCount}}件</div>
						</div> -->

						<div class="ye-wrap flex-row align-center flex-shrink" v-if="info.income !=null">
							<div class="ye-wrap-ti">余额</div>
							<div class="ye-wrap-con">{{info.income }}元</div>
						</div>
						<div class="ye-wrap flex-row align-center flex-shrink"
							v-if="info.tkOperationsWithdrawAmount !=null && info.tkOperationsWithdrawAmount>0">
							<div class="ye-wrap-ti">余额</div>
							<div class="ye-wrap-con">{{info.tkOperationsWithdrawAmount }}元</div>
						</div>
						<div class="points-wrap flex-row align-center flex-shrink" v-if="info.havePoints!=null">
							<div class="points-wrap-ti">积分</div>
							<div class="points-wrap-con">{{info.havePoints}}</div>
						</div>
						<div class="upoints-wrap flex-shrink flex-row align-center" v-if="info.surplusPoints!=null">
							<div class="upoints-wrap-ti">未发放</div>
							<div class="upoints-wrap-con">{{info.surplusPoints}}</div>
						</div>
					</div>
					<div class="flex-row align-center detail-item" v-if="info.tokenId !='888888'">
						<div class="detail-item-title">版号</div>
						<div class="detail-item-con">
							{{info.periodView?info.periodView:info.position}}
						</div>
					</div>
					<div class="flex-row align-center detail-item" v-else>
						<div class="detail-item-title">编号</div>
						<div class="detail-item-con">
							{{info.periodView?info.periodView:info.position}}
						</div>
					</div>
					<div class="flex-row align-center detail-item" v-if="showQrcode">
						<div class="detail-item-title">Token ID</div>
						<div class="detail-item-con">
							{{info.tokenId}}
						</div>
					</div>
				<!-- 	<div class="flex-row align-center detail-item">
						<div class="detail-item-title">发行方</div>
						<div class="detail-item-con">{{info.issuerName}}</div>
					</div> -->
					<!-- 		<div class="flex-row align-center detail-item">
						<div class="detail-item-title">收藏者</div>
						<div class="detail-item-con">{{info.nickName}}</div>
					</div> -->
					<div class="flex-row align-center detail-item" v-if="info.tokenId !='888888'">
						<div class="detail-item-title">权益</div>
						<div class="detail-item-con">{{info.rightsDesc}}</div>
					</div>
					<div class="flex-row align-center detail-item" v-if="show">
						<div class="detail-item-title">作品介绍</div>
						<div class="detail-item-con">{{head.productDesc?head.productDesc:''}}</div>
					</div>
				</div>
				<div class="good-detail-2" v-if="show">
					<div class="deal-info">发行信息</div>
					<div class="flex-row align-center detail-item">
						<div class="detail-item-title">发行方</div>
						<div class="detail-item-con">{{transactionInfo[0].ownerName}}</div>
					</div>
					<div class="flex-row align-center detail-item">
						<div class="detail-item-title">藏品生成</div>
						<div class="detail-item-con">{{transactionInfo[0].time}}</div>
					</div>
					<div class="flex-row align-center detail-item">
						<div class="detail-item-title">交易哈希</div>
						<div class="detail-item-con">{{transactionInfo[0].hash}}</div>
					</div>
					<!-- <div class="flex-row align-center detail-item" v-if="info.tokenId !='888888'">
						<div class="detail-item-title">换手次数</div>
						<div class="detail-item-con">{{info.changehandle?info.changehandle:0}}次</div>
					</div> -->
				</div>
				<div class="good-detail-2">
					<div class="deal-info">交易信息</div>
					<div class="flex-row align-center detail-item">
						<div class="detail-item-title">交易模式</div>
						<div class="detail-item-con">C2C个人交易</div>
					</div>
					<!-- <div class="flex-row align-center detail-item" v-if="info.tokenId !='888888'">
						<div class="detail-item-title">换手次数</div>
						<div class="detail-item-con">{{info.changehandle?info.changehandle:0}}次</div>
					</div> -->
				</div>
				<div class="recommend-title">历史公告</div>
				<div class="bg-grey ">
					<van-list v-model="loading" @load="getPushMessageList" :finished="finished2" finished-text="没有更多了"
						:error.sync="error" error-text="请求失败，点击重新加载">
						<div class="news-list flex-column align-center">
							<div v-for="(item,index) in  message" v-bind:key="index" class=" flex-row align-center item"
								@click.stop="navDetail(index)">
								<div class="item-img-sec">
									<img :src="item.pic" class="item-img" />
								</div>
								<div class="flex-column justify-center">
									<div class="item-title">{{item.title}}</div>
									<div class="item-sen-title">{{item.subTitle}}</div>
								</div>
							</div>
						</div>
					</van-list>
				</div>
				<div class="zhanwei"></div>
				<div class="flex-row align-center space-between good-price-sec">
					<div class="flex-row align-center justify-center cc-wrap">
						<div class="good-price-title">购入价格：</div>
						<div class="good-price">￥{{info.buyAmt}}</div>
					</div>
					<div class="good-by-sec2 flex-row align-center" v-if="info.isOpen == '是' && info.isSelf ==1">
						<div class="btn-left-wrap flex-row align-center">
							<div class="btn-left-btn" @click.stop="showPointsModal()">提积分</div>
							<div class="btn-line"></div>
							<div class="btn-left-btn" @click.stop="showBalanceModal()">提余额</div>
						</div>
						<div class="slae-btn" @click.stop="openSaleModal()">挂出寄售</div>
					</div>
					<!-- <div class="good-by-sec" v-if="info.isOpen == '是' && info.isSelf ==1" @click.stop="openSaleModal">
						<img src="../assets/hang_bg.png" class="good-by-bg" />
						<div class="buy-btn">挂出寄售</div>
					</div> -->
					<div class="btn-wrap flex-row align-center" v-if="info.isOpen=='否' && info.isSelf == 1">
						<div class="btn-left" @click.stop="openBlind()">拆盒</div>
						<div class="btn-right" @click.stop="openSaleModal()">挂出寄售</div>
					</div>
				</div>
				<div class="hang-sec-model" v-if="saleModal" @click.stop="closeSaleModal">
				</div>
				<div class="hang-sec" v-if="saleModal">
					<div class="hang-title">寄售详情</div>
					<div class="hang-item-sec">
						<div class="flex-row align-center space-between price-sec">
							<div class="price-title">请输入寄售价格：</div>
							<input class="price-input" @input="inputSalePrice" v-model="slaeInfo.salePrice"
								type="number" placeholder="点击设置寄售价格" />
						</div>
						<div class="flex-row align-center space-between hang-item">
							<div class="hang-item-title">支付通道费：</div>
							<div class="hang-item-price"
								:class="slaeInfo.channelFee?'hang-item-price-active1':'hang-item-price'">
								-￥{{slaeInfo.channelFee}}</div>
						</div>
						<div class="flex-row align-center space-between hang-item">
							<div class="hang-item-title">技术服务费：</div>
							<div class="hang-item-price"
								:class="slaeInfo.txServiceCharge?'hang-item-price-active1':'hang-item-price'">
								-￥{{slaeInfo.txServiceCharge}}</div>
						</div>

						<div class="flex-row align-center space-between hang-item">
							<div class="hang-item-title">赋能收益：</div>
							<div class="hang-item-price"
								:class="slaeInfo.rights?'hang-item-price-active1':'hang-item-price'">
								+￥{{slaeInfo.rights}}</div>
						</div>
						<div class="flex-row align-center space-between hang-item">
							<div class="hang-item-title color-red">红包抵扣：</div>
							<div class="hang-item-price color-red">
								+￥{{slaeInfo.discountAmount}}</div>
						</div>
						<div class="flex-row align-center space-between hang-item">
							<div class="hang-item-title">商品出售所得：</div>
							<div class="hang-item-price"
								:class="slaeInfo.price?'hang-item-price-active1':'hang-item-price'">
								￥{{slaeInfo.price}}</div>
						</div>
						<div class="hang-grey-btn" v-if="!slaeInfo.salePrice || slaeInfo.salePrice<=0">立即挂售</div>
						<div class="hang-btn" v-else @click.stop="createConsignment()">立即挂售</div>
					</div>
				</div>

				<div class="sale-confirm-modal flex-column align-center" v-if="saleConfirmModal">
					<div class="sale-confirm-modal-title">出售价格确认</div>
					<div class="flex-row align-center sale-confirm-modal-tips-wrap">
						<img src="../assets/icon-tips.png" alt="" class="sale-confirm-modal-tips-img">
						<div class="sale-confirm-modal-tips">价格输入错误，损失自行承担。</div>
					</div>
					<div class="sale-confirm-goods">
						<div class="scg-top flex-row align-center">
							<img v-if="info.isOpen=='是'" :src="info.productPic" class="scg-img" />
							<img v-if="info.isOpen=='否'" src="../assets/manghe.png" class="scg-img2" />
							<div class="flex-column  scg-top-r space-around">
								<div class="scg-name">{{info.isOpen == '是'?info.productName:'盲盒'}}</div>
								<div class="scg-period">
									{{info.periodView?info.periodView:info.position}}
								</div>
							</div>
						</div>
						<div class="scg-price-wrap flex-row align-center space-between">
							<div class="scg-price-name">出售价格</div>
							<div class="scg-price">￥{{slaeInfo.salePrice}}</div>
						</div>
					</div>
					<div class="pw-wrap flex-column align-center">
						<div class="pw flex-row align-center">
							<div class="pw-title">输入密码：</div>
							<input type="password" v-model="jspassword" placeholder="请输入密码" class="pw-input">
						</div>
						<div class="edit-pw" @click.stop="editPassword">设置修改密码</div>
					</div>
					<div class="sale-confirm-btn-wrap flex-row space-between align-center">
						<div class="sale-cancel-btn" @click.stop="cancalSaleConfrimModal()">取消</div>
						<div class="sale-confirm-btn" @click.stop="confirmConsignment()">确认</div>
					</div>
				</div>
				<div class="model" v-if="saleConfirmModal" @click.stop="cancalSaleConfrimModal()"></div>


				<div class="model" v-if="pointsModal"></div>
				<div class="confirm-wrap flex-column align-center" v-if="pointsModal">
					<div class="confirm-title">提积分</div>
					<div class="cw-points">{{info.havePoints?info.havePoints:0}}积分</div>
					<div class="cw-p-tips">是否全部提到”我的积分”？</div>
					<div class="c-btn-wrap flex-row align-center">
						<div class="c-btn-left" @click.stop="transferPointToUser()">确认</div>
						<div class="c-btn-right" @click.stop="cancelPointsModal()">取消</div>
					</div>
				</div>
				<div class="model" v-if="balanceModal"></div>
				<div class="confirm-wrap flex-column align-center" v-if="balanceModal">
					<div class="confirm-title">提余额</div>
					<div class="cw-money">
						{{info.income?info.income:info.tkOperationsWithdrawAmount?info.tkOperationsWithdrawAmount:0}}元
					</div>
					<div class="cw-p-tips">是否全部提现至”收入”账户？</div>
					<div class="c-btn-wrap flex-row align-center">
						<div class="c-btn-left" @click.stop="confirmTx()">确认</div>
						<div class="c-btn-right" @click.stop="cancelBalanceModal()">取消</div>
					</div>
				</div>
			</template>
			<template v-if="step ==2">
				<iframe id="myiframe" name="myiframe" :src="info.link3D" width="" height=""></iframe>
			</template>
		</div>
		<div class="container" v-if="isSetPwd">
			<div ref="qrCodeDiv" style="position: fixed;top: -9999px;left: -9999px;"></div>
			<div class="code-wrap flex-column" v-if="showVerify">
				<div class="code-title">请输入验证码</div>
				<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
				<div class="code-tips">看不清？点击图片换一张</div>
				<div class="code-input-wrap">
					<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
						v-model="verifyCode" placeholder="请输入验证码" />
				</div>
				<div class="send-code-btn" @click="getCode()">确定</div>
			</div>
			<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
			<div class="flex-row align-center justify-center nav">
				<div class="nav-title-bg"></div>
				<div class="nav-title">交易密码设置</div>
			</div>
			<div class="content flex-column align-center" :style="'height:'+conHeight+'px'">
				<div class="title">密码设置/修改</div>
				<div class="in-wrap flex-column align-center">
					<div class="flex-row align-center t">
						<div class="phone">{{user.loginName}}</div>
						<div class="get-code" @click.stop="getVerifyCode()">{{msg}}</div>
					</div>
					<input type="text" class="input-item" placeholder="验证码" v-model="code"
						oninput="if(value.length > 6)value = value.slice(0, 6)" maxlength="6">
					<input type="text" class="input-item" oninput="if(value.length > 8)value = value.slice(0, 8)"
						maxlength="8" placeholder="请输入新密码" v-model="password">
					<input type="text" class="input-item" oninput="if(value.length > 8)value = value.slice(0, 8)"
						maxlength="8" v-model="confirmPassword" placeholder="请再次输入新密码">
					<div class="btn" :class="code && password &&confirmPassword ?'btn-true':''" @click="confrimEdit()">
						确认
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		Toast,
		Dialog,
		ImagePreview
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import 'vant/lib/index.css'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import QRCode from 'qrcodejs2';
	import Clipboard from 'clipboard';
	export default {
		data() {
			return {
				step: 1,
				saleModal: false,
				slaeInfo: {
					salePrice: '',
					serviceCharge: 0, //平台手续费
					rights: 0, //赋能权益：
					price: 0, //商品出售所得
					txServiceCharge: 0,
					channelFee: 0,
					discountAmount: 0
				},
				info: {},
				productEntryId: '',
				qrcodeImg: '',
				showQrcode: 0,
				saleConfirmModal: 0,
				jspassword: '',
				conHeight: 0,
				user: {},
				time: 0,
				msg: '获取验证码',
				sendCode: 0,
				inteval: '',
				code: '',
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				password: '',
				confirmPassword: '',
				isSetPwd: 0,
				showImg: 0,
				videoModal: 0,
				pointsModal: 0,
				balanceModal: 0,
				tkData: {},
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished2: false,
				error: false,
				message: [],
				head: {},
				transactionInfo: [],
				show:0
			}
		},
		methods: {
			getCerify(){
				const that=this;
				let params = new FormData()
				params.append('productId', that.productEntryId)
				this.axios.post(config.requestUrl + '/front/nftProduct/queryCertificate', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'Token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.head = response.data.obj.head;
						let transactionInfo = response.data.obj.transactionInfo
						
						that.transactionInfo = transactionInfo;
						that.show=1
						// Toast.success('查询成功')
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					this.getBox = 0;
					Toast.fail('获取失败，请稍后重试');
				})
			},
			navDetail(index) {
				this.$router.push('/newsDetail?id=' + this.message[index].id)
			},
			getPushMessageList() {
				const that = this
				let params = new FormData()
				params.append('pageNum', that.pageNum);
				params.append('pageSize', that.pageSize);
				params.append('productEntryId', that.productEntryId)
				that.axios.post(config.requestUrl + '/search/getPushMessageList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let newList = response.data.obj.list;
						let list = that.message;
						let finished = false;
						if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
							true;
						list = list.concat(newList);
						that.message = list;
						that.finished2 = finished;
						that.pageNum=that.pageNum+1
						that.error = false;
					} else {
						let msg = response.data.msg;
						if (msg == '') msg = '获取失败，请稍后重试'
						Toast(msg);
						that.error = true;
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					that.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			confirmTx() {
				const that = this;
				if (!that.info.income > 0 && !that.info.tkOperationsWithdrawAmount > 0) {
					Toast('余额不足');
					return;
				}
				Toast.loading({
					message: '',
					forbidClick: true,
					duration: 0,
				});
				if (that.info.income > 0) {
					let params = new FormData()
					params.append('productEntryId', this.productEntryId);
					this.axios.post(config.requestUrl + '/front/endue/transferToIncome', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							that.balanceModal = 0;
							Toast('提现成功');

						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
							}
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				} else {
					let params = new FormData()
					params.append('productEntryId', this.productEntryId);
					this.axios.post(config.requestUrl + '/front/agencyOperations/transferToIncome', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							that.balanceModal = 0;
							Toast('提现成功');
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
							}
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				}
			},
			copy() {
				console.log(111)
				var clipboard = new Clipboard('.icon-copy')
				console.log(clipboard)
				clipboard.on('success', e => {
					console.log(12312)
					//  释放内存
					Toast.success('复制成功');
				})
				clipboard.on('error', e => {
					console.log('rerwe')
					// 不支持复制
					// 释放内存
					Toast.success('复制失败');
				})
			},
			cancelPointsModal() {
				this.pointsModal = 0
			},
			cancelBalanceModal() {
				this.balanceModal = 0;
			},
			showPointsModal() {
				this.pointsModal = 1
			},
			showBalanceModal() {
				this.balanceModal = 1;
			},
			cancalSaleConfrimModal() {
				this.saleConfirmModal = 0;
			},
			transferPointToUser() {
				const that = this;
				if (that.info.havePoints <= 0) {
					Toast('没有可提取的积分')
					return;
				}
				Toast.loading({
					message: '提取中...',
					forbidClick: true,
					duration: 0,
				});
				this.axios.post(config.pointsRequest + '/point-api/rest/pointProductEntry/transferPointToUser', that
					.productEntryId, {
						headers: {
							'Content-Type': 'text/xml',
							'token': this.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						Toast.success('提取成功')
						that.info.havePoints = 0
						this.pointsModal = 0
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			nav3D() {
				const that = this;
				if (this.info.link3D != '' && this.info.link3D != null) {
					if (this.info.isOpen == '是') {
						document.title = this.info.productName;
					} else {
						document.title = '盲盒';
					}
					if (this.info.link3D.indexOf('.jpg') != '-1') {
						this.showImg = 1;
						ImagePreview({
							images: [
								this.info.link3D
							],
							closeable: true,
							showIndex: false,
							overlayStyle: {
								background: '#000000'
							},
							onClose() {
								that.showImg = 0;
							},
						})
					} else if (this.info.link3D.indexOf('.mp4') != '-1') {
						this.videoModal = 1;
						let myVideo = document.getElementById('myVideo');
						myVideo.play();
						myVideo.addEventListener('pause', function() { //暂停
							console.log("暂停");
							that.videoModal = 0;
						});
						myVideo.addEventListener('ended', function() { //结束
							that.videoModal = 0;
						}, false);
					} else if (this.info.link3D == 'https://shop.metacbc.cn/models') {
						this.$router.push('/models')
					} else if (this.info.link3D == 'https://shop.metacbc.cn/models2') {
						this.$router.push('/models2')
					} else {
						this.step = 2;
						this.$forceUpdate()
					}
				}
			},
			editPassword() {
				this.isSetPwd = 1;
				// this.$router.push('/pw')
			},
			openSaleModal() {
				const that = this;
				if (that.info.isUpper == 0) {
					Dialog.confirm({
							message: '数字商品未上架',
							showCancelButton: false
							// confirmButtonText:'完成支付',
							// cancelButtonText:'取消支付'
						})
						.then(() => {
							console.log('1')
						})
						.catch(() => {
							console.log('关闭了')
						});
					return;
				}
				// this.saleModal = true;
				let params = new FormData()
				params.append('payType', 3)
				this.axios.post(config.requestUrl + '/front/user/getUserPayType', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let info = response.data.obj[0];
						if (info && info.no) {
							this.saleModal = true;
						} else {
							this.$router.push('/bank')
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})

			},
			closeSaleModal() {
				this.saleModal = false
			},
			saleBack() {
				Dialog.confirm({
						message: '确认要撤回挂售吗？',
						// confirmButtonText:'完成支付',
						// cancelButtonText:'取消支付'
					})
					.then(() => {
						this.$router.push('/backsuccess')
					})
					.catch(() => {
						console.log('关闭了')
					});
			},
			getProductEntryInfoDetail() {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', that.productEntryId)
				this.axios.post(config.requestUrl + '/front/consignment/getProductEntryInfoDetail', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token ? this.$store.state.user.token : ''
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.info = response.data.obj;
						let iconType = 0;
						if (that.info.link3D != '' && that.info.link3D != null) {
							if (that.info.link3D.indexOf('.jpg') != '-1') {
								iconType = 1;
							} else if (that.info.link3D.indexOf('.mp4') != '-1') {
								iconType = 2;
							} else {
								iconType = 3;
							}
						}
						that.info.iconType = iconType;
						if (this.info.isOpen == '否') {
							that.showQrcode = 0;
						} else {
							let qrcode = new QRCode(that.$refs.qrCodeDiv, {
								text: config.qrcodeHost + 'certify?productId=' + that.info.id,
								width: 200,
								height: 200,
								colorDark: "#000000", //二维码颜色
								colorLight: "#00FA9A", //二维码背景色
								correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
							})
							let canvas = qrcode._el.querySelector(
								"canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
							that.qrcodeImg = canvas.toDataURL("image/png");
							that.showQrcode = 1;
						}
						that.getOperationsInfoByEntryId();
						util.wxOrderConfig(that.productEntryId, that.info.productName, that.info.productPic, that
							.info.isOpen, that.info.nickName);
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			async inputSalePrice(e) {
				if (e.target.value && e.target.value > 0) {
					await this.getConsignmentFee()
				}
			},
			async getConsignmentFee() {
				const that = this;
				let params = new FormData()
				params.append('salePrice', that.slaeInfo.salePrice)
				params.append('consignmentId', 0)
				await this.axios.post(config.requestUrl + '/front/consignment/getConsignmentFee', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.slaeInfo.serviceCharge = response.data.obj.platformFee;
						that.slaeInfo.rights = response.data.obj.rightsFee;
						that.slaeInfo.price = response.data.obj.incomeAmount;
						that.slaeInfo.txServiceCharge = response.data.obj.txServiceCharge;
						that.slaeInfo.channelFee = response.data.obj.channelFee;
						that.slaeInfo.discountAmount = response.data.obj.discountAmount
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getOperationsInfoByEntryId() {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', that.productEntryId)
				this.axios.post(config.requestUrl + '/front/agencyOperations/getOperationsInfoByEntryId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj) {
							that.tkData = response.data.obj;
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navTk() {
				this.$router.push('/tk?productEntryId=' + this.productEntryId + '&price=' + this.info
					.tkOperationsWithdrawAmount)
			},
			createConsignment() {
				const that = this;
				console.log(that)
				if (!that.slaeInfo.salePrice || that.slaeInfo.salePrice <= 0) {
					Toast('请输入寄售价格')
					return;
				}
				if (!(/(^[1-9]\d*$)/.test(that.slaeInfo.salePrice))) {
					Toast('寄售价格必须为整数')
					return;
				}
				let maxPrice = 99999;
				if (that.info.maxPrice) {
					maxPrice = that.info.maxPrice
				}
				if (that.slaeInfo.salePrice > maxPrice) {
					Toast('最大寄售价格为' + maxPrice + '元')
					return;
				}
				let minPrice=0;
				if(that.info.minPrice){
					minPrice=that.info.minPrice;
				}
				if (that.slaeInfo.salePrice < minPrice) {
					Toast('最小寄售价格为' + minPrice + '元')
					return;
				}
				that.saleConfirmModal = 1;
				that.saleModal = 0;
			},
			confirmConsignment() {
				const that = this;
				if (!that.slaeInfo.salePrice || that.slaeInfo.salePrice <= 0) {
					Toast('请输入寄售价格')
					return;
				}
				if (!that.jspassword) {
					Toast('请输入密码')
					return;
				}
				Toast.loading({
					message: '寄售中...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('productEntryId', that.productEntryId)
				params.append('salePrice', that.slaeInfo.salePrice)
				params.append('consignmentType', 1)
				params.append('password', that.jspassword)
				this.axios.post(config.requestUrl + '/front/consignment/createConsignment', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.success('寄售成功')
						this.saleModal = false
						setTimeout(() => {
							this.$router.replace({
								path: '/hangsuccess',
								query: {
									name: that.info.isOpen == '是' ? that.info.productName : '盲盒',
									id: response.data.obj
								}
							})
						}, 1200)
					} else {
						if (response.data.msg == '发布寄售异常,请稍后再试') {
							Toast('藏品未上链，暂时无法寄售。')
						} else {
							Toast(response.data.msg);
						}
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getConsignmentDetail() {
				const that = this
				let params = new FormData()
				params.append('consignmentId', that.productEntryId)
				that.axios.post(config.requestUrl + '/front/consignment/getConsignmentDetail', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					that.request = 0;
					if (response.data.code == 0) {
						that.goods = response.data.obj
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					that.request = 0;
					Toast('获取失败，请稍后重试');
				})
			},
			openBlind() {
				this.$router.push({
					path: 'share',
					query: this.info
				})
			},
			getVerifyCode() {
				const that = this;
				if (that.sendCode || that.time > 0) return;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			getCode() {
				const that = this;
				if (that.sendCode || that.time > 0) return;
				that.sendCode = 1;
				let params = new FormData()
				params.append('phoneEmail', that.user.loginName)
				params.append('smsType', 1)
				params.append('zone', 86)
				params.append('doType', 1)
				params.append('codeValue', that.verifyCode)
				params.append('key', that.verifyKey)

				Toast.loading({
					message: '发送中...',
					forbidClick: true,
					duration: 0,
				});
				that.axios.post(config.requestUrl + '/ajax/sendCode', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					that.sendCode = 0;
					if (response.data.code == 0) {
						that.showVerify = 0;
						Toast.success('发送成功');
						that.time = 60;
						that.msg = `${that.time}s`;
						that.inteval = setInterval(() => {
							if (that.time <= 0) {
								that.msg = '获取验证码'
								clearInterval(this.interval);
							} else {
								that.time = that.time - 1;
								that.msg = `${that.time}s`
							}
						}, 1000)
					} else {
						Toast(response.data.msg);
					}

				}, response => {
					console.log(response)
					this.sendCode = 0;
					Toast('获取失败，请稍后重试');
				})
			},
			confrimEdit() {
				const that = this;
				if (!that.code || !that.password || !that.confirmPassword) {
					return;
				}
				if (!that.code) {
					Toast('请输入验证码');
					return;
				}
				if (!that.password) {
					Toast('请输入密码');
					return;
				}
				if (!that.confirmPassword) {
					Toast('请输入确认密码');
					return;
				}
				if (that.password.length < 6) {
					Toast('密码长度为6-8位');
					return;
				}

				if (that.password != that.confirmPassword) {
					Toast('密码与确认密码不一致');
					return;
				}
				let params = new FormData()
				params.append('code', that.code);
				params.append('newPwd', that.password);
				params.append('newPwdConfirm', that.confirmPassword);
				this.axios.post(config.requestUrl + '/front/user/changePassword', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast('修改成功');
						setTimeout(() => {
							this.isSetPwd = 0;
							this.msg = '获取验证码'
							this.time = 0;
							this.code = '';
							this.password = '';
							this.confirmPassword = ''
						}, 1200)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},
			getMember() {
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.getBox = 0;
					if (response.data.code == 0) {
						this.user = response.data.obj.userInfo;
						this.user.randName = this.$store.state.user.randName;
						if (!this.$store.state.user.ranName) {
							let user = {
								token: this.$store.state.user.token,
								randName: util.randomString(),
								userPic: this.user.pic,
								nickName: this.user.name
							}
							this.$store.commit('setUser', user)
							localStorage.setItem('user', JSON.stringify(user))
						}

						this.productSize = response.data.obj.productSize
						Toast.clear()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
		},
		mounted() {
			if (!this.$route.query.id) {
				this.$route.replace('/')
			}
			this.type = this.$route.query.type
			this.productEntryId = this.$route.query.id;
			if (this.type == 1) {
				this.getProductEntryInfoDetail();
				this.getCerify();
			} else {
				this.getConsignmentDetail();
			}
			this.conHeight = window.screen.height - 375 / window.screen.width * 44
			this.getMember();
		},
		beforeRouteLeave(to, from, next) {
			if (this.isSetPwd == 1) {
				this.isSetPwd = 0;
				next(false)
			} else if (to.path == '/collectlist') {
				if (this.step == 2) {
					this.step = 1;
					document.title = 'C2C电商平台'
					next(false);
				} else if (this.showImg == 1) {
					this.showImg = 0;
					next(false);
				} else {
					next();
				}
			} else {
				next();
			}
		},
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: rgba(203, 203, 203, 0.1);
		min-height: 100%;
	}

	.for-img {
		position: relative;
	}

	.icon-3d {
		width: 100px;
		height: 100px;
		right: 36px;
		bottom: 36px;
		position: absolute;

	}

	.good-img {
		width: 750px;
		height: 840px;
	}

	.good-name-sec {
		width: 750px;
		height: 120px;
		background: rgba(203, 203, 203, 0.1);
		position: relative;
	}

	.good-name-bg {
		width: 750px;
		height: 120px;
	}

	.good-name-con {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.good-name {
		font-size: 32px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 32px;
		margin-left: 40px;
	}

	.good-status-sec {
		width: 248px;
		height: 120px;
		position: relative;
	}

	.good-status-bg {
		width: 248px;
		height: 120px;
	}

	.good-status-con {
		position: absolute;
		width: 226px;
		height: 120px;
		left: 22px;
		top: 0;
	}

	.sale-icon {
		width: 132px;
		height: 40px;
		margin-top: 18px;
	}

	.good-status {
		font-size: 34px;
		font-family: Helvetica;
		color: #FFFFFF;
		line-height: 34px;
		margin-top: 8px;
	}

	.good-detail-1 {
		width: 750px;
		background: #FFFFFF;
		padding: 0 0 40px 40px;
		box-sizing: border-box;
	}

	.detail-item {
		padding-top: 30px;
	}

	.detail-item-title {
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
		width: 105px;
		flex-shrink: 0;
	}

	.detail-item-con {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
		margin-left: 46px;
		word-break: break-all;
		word-wrap: break-word;
	}

	.good-detail-2 {
		width: 750px;
		background: #FFFFFF;
		padding: 30px 0 40px 40px;
		box-sizing: border-box;
		margin-top: 10px;
	}

	.deal-info {
		font-size: 26px;
		color: #000000;
		line-height: 30px;
	}

	.zhanwei {
		height: 120px;
	}

	/* 底部购买部分 */
	.good-price-sec {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 750px;
		height: 98px;
		background: #FFFFFF;
		box-shadow: 0px -8px 19px 0px rgba(0, 0, 0, 0.07);
	}

	.cc-wrap {
		width: 318px;
	}

	.good-price-title {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;

	}

	.good-price {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FF0000;
		line-height: 30px;
	}

	.good-by-sec {
		width: 302px;
		height: 98px;
		position: relative;
	}

	.good-by-sec2 {
		width: 432px;
		height: 98px;
		position: relative;
	}

	.good-by-bg {
		width: 302px;
		height: 98px;
	}

	.buy-btn {
		position: absolute;
		left: 0;
		top: 0;
		width: 302px;
		height: 98px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 98px;
		margin-left: 116px;
	}

	/* 挂出寄售部分 */
	.hang-sec {
		width: 750px;
		height: 900px;
		background: #FFFFFF;
		border-radius: 20px 20px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 102
	}

	.hang-title {
		width: 750px;
		height: 88px;
		background: #6236FF;
		border-radius: 20px 20px 0px 0px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 88px;
		text-align: center;
	}

	.hang-item-sec {
		padding: 0 40px;
	}

	.price-sec {
		width: 670px;
		height: 120px;
		background: #FFFFFF;
		box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.price-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
	}

	.price-input {
		font-size: 30px;
		border: none;
		text-align: right;
		font-weight: 600;
		color: #FF0101;
		line-height: 30px;
	}

	.price-input:focus {
		border: none !important;
	}

	/* 挂出寄售部分 */
	.hang-sec-model {
		width: 100%;
		height: 100%;
		background: #F4F4F4;
		min-height: 100%;
		background: #000000;
		opacity: 0.7;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
	}

	.hang-item {
		width: 670px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.hang-item-title {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 28px;
	}

	.hang-item-price {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 30px;
	}

	.hang-item-price-active {
		font-size: 30px;
		font-weight: 600;
		color: #FF0101;
		line-height: 30px;
	}

	.hang-item-price-active1 {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
	}

	.hang-grey-btn {
		width: 590px;
		height: 80px;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		margin: 134px auto 0 auto;
	}

	.hang-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		margin: 134px auto 0 auto;
	}

	.btn-wrap {
		width: 360px;
		height: 78px;
		background: linear-gradient(138deg, #FFC755 0%, #FF9624 100%);
		border-radius: 49px;
		padding: 24px 0;
		box-sizing: border-box;
		margin-right: 30px;

	}

	.btn-left {
		width: 50%;
		border-right: 3px solid #f2901c;
		text-align: center;
		box-sizing: border-box;
		font-size: 28px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.btn-right {
		width: 50%;
		text-align: center;
		font-size: 28px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.good-img2 {
		width: 750px;
		height: 750px;
		padding: 45px 0;
	}


	/*下面码*/
	.xian-img {
		width: 750px;
		height: 8px;
		vertical-align: bottom;
	}

	.qrcode-wrap {
		width: 750px;
		height: 328px;
		background: #000000;
	}

	.qrcode-wrap-left {
		width: 350px;
		margin-left: 40px;
	}

	.qrcode-goods-name {
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-fx {
		margin-top: 25px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-bh {
		margin-top: 23px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-tk {
		margin-top: 14px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-wrap-right {
		width: 158px;
		margin-right: 48px;
	}

	.qrcode-img {
		width: 156px;
		height: 156px;
	}

	.qrcode-img img {
		width: 156px;
		height: 156px;
	}

	.qrcode-img image {
		width: 156px;
		height: 156px;
	}

	.qrcode-desc {
		margin-top: 20px;
		font-size: 25px;
		font-weight: bold;
		color: #FFFFFF;
	}

	/*确认弹窗*/
	.sale-confirm-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background: rgba(0, 0, 0, 0.7);
		width: 650px;
		height: max-content;
		z-index: 1001;
		background: #F8F8F8;
		border-radius: 20px;
	}

	.sale-confirm-modal-title {
		width: 100%;
		height: 120px;
		border-radius: 20px 20px 0px 0px;

		background: #FFFFFF;
		text-align: center;
		line-height: 120px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.sale-confirm-modal-tips-wrap {
		width: 580px;
		height: 189px;
		background: #FFFFFF;
		border-radius: 20px;
		margin-top: 30px;
	}

	.sale-confirm-modal-tips-img {
		width: 100px;
		height: 100px;
		margin-left: 30px;
	}

	.sale-confirm-modal-tips {
		margin-left: 20px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.sale-confirm-goods {
		margin-top: 30px;
		width: 580px;
		height: 264px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.scg-top {
		width: 580px;
		height: 175px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 30px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.scg-img {
		width: 100px;
		height: 112px;
		border-radius: 4px;
	}

	.scg-img2 {
		width: 100px;
		height: 100px;
		border-radius: 4px;
	}

	.scg-top-r {
		margin-left: 30px;
	}

	.scg-name {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.scg-period {
		margin-top: 16px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.scg-price-wrap {
		width: 100%;
		height: 88px;
		line-height: 88px;
		padding: 0 30px;
		box-sizing: border-box;
	}

	.scg-price-name {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.scg-price {
		font-size: 28px;
		font-weight: 600;
		color: #F90000;
	}

	.pw-wrap {
		width: 580px;
		height: 204px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-top: 30px;
	}

	.pw {
		width: 100%;
		height: 101px;
		padding: 0 30px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.pw-title {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.pw-input {
		border: none;
		margin-left: 10px;
		width: 300px;
		font-size: 28px;

	}

	.edit-pw {
		width: 188px;
		height: 54px;
		margin-top: 24px;
		background: #FFFFFF;
		border-radius: 27px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		font-size: 24px;
		font-weight: 400;
		color: #000000;
		text-align: center;
		line-height: 54px;
	}

	.sale-confirm-btn-wrap {
		width: 100%;
		padding: 70px 50px 100px;
		box-sizing: border-box;
	}

	.sale-cancel-btn {
		width: 260px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #6236FF;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		text-align: center;
		box-sizing: border-box;
	}

	.sale-confirm-btn {
		width: 260px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		box-sizing: border-box;
	}

	.content {
		height: 100%;
		background-image: url(../assets/pw-bg.png);
		background-size: 100%;
		overflow: scroll;
	}

	.title {
		font-size: 38px;
		font-weight: 600;
		color: #FFFFFF;
		letter-spacing: 2px;
		margin-top: 35px;
	}

	.in-wrap {
		width: 706px;
		height: max-content;
		background-image: url(../assets/pw-bg2.png);
		background-size: 706px 1200px;
		padding-bottom: 100px;
	}

	.t {
		margin-top: 158px;
		width: 590px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		overflow: hidden;

	}

	.phone {
		width: 410px;
		margin-left: 30px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.get-code {
		width: 180px;
		height: 100px;
		background: #6236FF;
		font-size: 28px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 100px;
	}

	.input-item {
		margin-top: 40px;
		width: 590px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		padding: 30px;
		box-sizing: border-box;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.btn {
		margin-top: 200px;
		width: 550px;
		height: 80px;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		text-align: center;
		line-height: 80px;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
		letter-spacing: 2px;
	}

	.btn-true {
		background: #6236FF;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}

	.num-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.num-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(#3FBDFF, #1E5EFF);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.num-wrap-con {
		padding: 8px;
		background: #E3EDFF;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #2369CC;
	}

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	.ye-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.ye-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(#AA8AF7, #5850EC);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.ye-wrap-con {
		padding: 8px;
		background: rgba(98, 54, 255, 0.14);
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #523094;
	}

	.icon-play {
		width: 100px;
		height: 100px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	video {
		position: absolute;
		z-index: 10;
		left: 0;
		top: 0;
		width: 750px;
		height: 841px;
		background: rgba(0, 0, 0, 1);
	}

	.points-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.points-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(180deg, #FFB684 0%, #FF7311 100%);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.points-wrap-con {
		padding: 8px;
		background: #FFF0DD;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #FA6400;
	}

	.btn-left-wrap {
		width: 252px;
		height: 98px;
		background: linear-gradient(140deg, #FFF2D8 0%, #FFE5C9 100%);
	}

	.btn-left-btn {
		width: 125px;
		font-size: 24px;
		font-weight: 500;
		color: #DC4E00;
		text-align: center;
	}

	.btn-line {
		width: 2px;
		height: 50px;
		background: #FFFFFF;
	}

	.slae-btn {
		width: 180px;
		height: 98px;
		background: linear-gradient(138deg, #FFC755 0%, #FF9624 100%);
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 98px;
	}

	.confirm-wrap {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 35px 0 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		box-sizing: border-box;
		z-index: 1111;
	}

	.confirm-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 38px;
	}


	.c-btn-wrap {
		width: 100%;
		height: 88px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	.c-btn-left {
		width: 50%;
		height: 88px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.c-btn-right {
		width: 50%;
		height: 88px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.cw-points {
		width: 296px;
		height: 77px;
		background: #F3EEE5;
		border-radius: 39px;
		font-size: 30px;
		font-weight: 600;
		color: #937757;
		text-align: center;
		line-height: 77px;
	}

	.cw-p-tips {
		margin: 29px auto 52px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.cw-money {
		width: 296px;
		height: 77px;
		background: #FFF3EA;
		border-radius: 39px;
		font-size: 30px;
		font-weight: 600;
		color: #FA6400;
		text-align: center;
		line-height: 77px;
	}

	.icon-copy {
		width: 100px;
		height: 36px;
	}

	.icon-copy img {
		width: 100px;
		height: 36px;
	}

	.color-red {
		color: #FF0101;
	}


	.upoints-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.upoints-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(180deg, #CECECE 0%, #8E8E8E 100%);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.upoints-wrap-con {
		padding: 8px;
		background: #E7E7E7;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #767676;
	}


	/* 推荐部分 */
	.recommend-title {
		width: 750px;
		height: 90px;
		background: #F4F4F4;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 90px;
		padding-left: 40px;
		box-sizing: border-box;
		margin-top: 52px;
	}

	.item {
		width: 710px;
		height: 120px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-top: 20px;
		padding: 0 27px;
		box-sizing: border-box;
	}

	.item-img-sec {
		position: relative;
		margin-right: 37px;
	}

	.item-dot {
		position: absolute;
		z-index: 10;
		top: -3px;
		right: -3px;
		width: 16px;
		height: 16px;
		background: #FF0000;
		border-radius: 50%;
	}

	.item-img {
		width: 86px;
		height: 86px;
		border-radius: 10px;
	}

	.item-title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}

	.item-sen-title {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-top: 10px;
	}
	
	.num-wrap2 {
		margin-top: 10px;
		margin-right: 15px;
	}
	
	.num-wrap2:first-child {
		margin-top: 0;
	}
	
	
	.num-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(#3FBDFF, #1E5EFF);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}
	
	.num-wrap-con {
		padding: 8px;
		background: #E3EDFF;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #2369CC;
	}
	
	.ye-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}
	
	.ye-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(#AA8AF7, #5850EC);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}
	
	.ye-wrap-con {
		padding: 8px;
		background: rgba(98, 54, 255, 0.14);
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #523094;
	}
</style>