<template>
	<div class="container" ref="getheight">
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="getGoods(1)">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
		<div class="flex-row align-center justify-center support-sec">
			<img src="../assets/support_icon.png" class="support-icon" />
			<div class="support-dian"></div>
			<div class="support-con">Meta链提供技术支持</div>
			<div class="login-btn" @click="login()" v-if="!islogin">登录/注册</div>
		</div>
		<div class="head-wrap">
			<div class="search-sec" @click.stop="search()">搜索</div>
			<div class="catena-item-wrap">
				<div class="catena-period-wrap flex-row align-center flex-wrap">
					<div class="period-item" v-for="(item,index) in periodList" v-bind:key="index"
						@click.stop="selectPeriod(index)">
						<div class="period-name" :class="periodIndex == index?'period-name-selected':''">
							{{item.periodName}}
						</div>
						<div class="period-line" :class="periodIndex == index?'period-line-selected':''"></div>
					</div>
				</div>
				<div class="catena-grade-wrap flex-row align-center flex-wrap" v-if="periodIndex != -1">
					<div class="grade-item" :class="type == 1 ?'grade-item-selected':''" @click="changeType(1)">整套</div>
					<div @click.stop="changeType(3)" class="grade-item" :class="type == 3 ?'grade-item-selected':''">盲盒
					</div>
					<div @click.stop="changeLevel(index)" class="grade-item "
						:class="type == 2 && levelIndex == index ?'grade-item-selected':''"
						v-for="(item,index) in LevelList" v-bind:key="index">{{item.name}}</div>

				</div>
			</div>
		</div>
		<div class='good-list-wrap'>
			<van-list :immediateCheck="check" v-model="loading" @load="getGoods" :finished="finished"
				finished-text="没有更多了" :error.sync="error" error-text="请求失败，点击重新加载">
				<div class="good-list flex-row align-center flex-wrap space-between">
					<div class="good-item flex-column" v-for="(item,index) in list" v-bind:key="index"
						@click="navDetail(index,item.id,item.currentMinPrice,item.productId)">
						<div class="relative">
							<div v-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period !=8"
								class="goods-item-wrap" :style="'background-image: url('+item.pic+');'">
							</div>
							<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period == 8"
								src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
								class="good-item-img3" />
							<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 1" :src="item.pic"
								class="good-item-img3" />
							<img v-else src="../assets/manghe.png" class="good-item-img2" />
							<div class="goods-nums2">x {{item.quantity}}</div>
							<img src="../assets/icon-ts2.png" v-if="item.quantity<=0" class="icon-ts" alt="">
						</div>
						<div class="good-item-con">
							<div class="good-item-name">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
							<div class="good-item-num2" v-if="item.isOpen =='否'">
								{{item.periodView}}
							</div>
							<div class="good-item-price" v-if="item.currentMinPrice">￥{{item.currentMinPrice}}</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>

	</div>
</template>
<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import LoginWrap from '@/components/Login.vue';
	import dataCenterBus from '../config/dataCenterBus.js';
	import {
		Toast,
		// Sticky,
		List,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import 'vant/lib/sticky/index.css'
	import 'vant/lib/list/index.css';
	import 'vant/lib/dialog/index.css';
	import Vue from 'vue';
	Vue.use(List);
	// Vue.use(Sticky);
	export default {
		data() {
			return {
				tab: 1,
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				windowHeight: 0,
				offsetHeight: 0,
				showLogin: false,
				sortBy: 'create_time',
				order: 'desc',
				finished: false,
				error: false,
				catenaList: [],
				id: '',
				periodList: [],
				periodIndex: -1,
				LevelList: [],
				type: 1,
				levelIndex: -1,
				check: false,
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
			}
		},
		components: {
			LoginWrap,
		},
		computed: {
			islogin() {
				return this.$store.state.user.token ? true : false
			},
			userPic() {
				return this.$store.state.user.userPic
			},
			nickname() {
				return this.$store.state.user.nickName ? this.$store.state.user.nickName : this.$store.state.user.name
			}
		},
		methods: {
			search() {
				const that = this;
				if (!that.islogin) {
					that.login();
					return;
				} else {
					this.$router.push('/search')
				}
			},

			navDetail(index, id, price, productId) {
				this.$router.push({
					path: '/detail',
					query: {
						id,
						price,
						productId
					}
				})
			},

			login() {
				this.showLogin = true;
				this.$forceUpdate()
			},
			closeLogin() {
				this.showLogin = false;
			},
			closeLogin2() {
				console.log(1)
			},

			getPeriodByCatenaId() {
				const that = this;
				that.axios.get(config.requestUrl + '/front/consignment/getPeriodByCatenaId', {
					params: {
						catenaId: that.id
					},
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token ? that.$store.state.user.token : ''
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.periodList = response.data.obj;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							that.showLogin = true;
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			selectPeriod(index) {
				this.periodIndex = index;
				this.list = [];
				this.more = true;
				this.pageNum = 1
				this.finished = false;
				this.error = false;
				this.type = 1;
				this.levelIndex = -1;
				this.getGoods();
			},
			getProductLevelList() {
				const that = this;
				that.axios.get(config.requestUrl + '/front/consignment/getProductLevelList', {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token ? that.$store.state.user.token : ''
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.LevelList = response.data.obj;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							that.showLogin = true;
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			changeType(type) {
				this.type = type;
				this.list = [];
				this.more = true;
				this.pageNum = 1
				this.finished = false;
				this.error = false;
				this.levelIndex = -1;
				this.getGoods();
			},
			changeLevel(index) {
				this.list = [];
				this.more = true;
				this.pageNum = 1
				this.finished = false;
				this.error = false;
				this.type = 2;
				this.levelIndex = index;
				this.getGoods();
			},
			async getGoods(verify) {
				const that = this;
				that.loading = true;
				if (verify) {
					this.pageNum = this.pageNum - 1;
				}
				let params = {
					pageNum: this.pageNum,
					pageSize: this.pageSize
				};
				if (verify) {
					params.codeValue = that.verifyCode;
					params.key = that.verifyKey;
				}
				that.pageNum = that.pageNum + 1;
				if (this.periodIndex == -1) {
					params.catenaId = that.id
					await that.axios.get(config.requestUrl + '/front/consignment/getProCatenaNewListByCatenaId', {
						params,
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token ? that.$store.state.user.token : ''
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (this.periodIndex == -1) {
								Toast.clear();
								let newList = response.data.obj.list ? response.data.obj.list : [];
								for (let i in newList) {
									newList[i].isAll = 0;
									let productId = newList[i].productEntryIdMap;
									if (productId.length > 0) {
										productId = productId.replace(/{/, "")
										productId = productId.replace(/}/, "")
										productId = productId.split(':');
										if (productId.length > 2) {
											newList[i].isAll = 1;
										}
									}
								}
								let list = that.list;
								let finished = false;
								if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
									true;
								list = list.concat(newList);
								that.list = list;

								that.finished = finished;
								that.showVerify = false;
								that.error = false;
							}
						} else if (response.data.code == -7) {
							that.error = true;
							that.getVerifyCode()
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								that.showLogin = true;
							}
							Toast(response.data.msg);
							that.error = true;

						}
						that.loading = false;
					}, response => {
						that.loading = false;
						that.error = true;
						Toast('获取失败，请稍后重试');
					})
				} else {
					params.period = this.periodList[this.periodIndex].id;
					let type = this.type;
					let levelIndex = this.levelIndex
					if (this.type == 1) {
						params.type = 3;
						params.keyword = 2
					} else if (this.type == 2) {
						// params.isQueryBlindBox=2;
						params.type = 2;
						params.keyword = this.LevelList[this.levelIndex].key
					} else if (this.type == 3) {
						params.type = 4;
						// params.isQueryBlindBox = 2;
					}
					await that.axios.get(config.requestUrl + '/front/consignment/getListByFilter', {
						params,
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token ? that.$store.state.user.token : ''
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (type == this.type && levelIndex == this.levelIndex) {
								Toast.clear();
								let newList = response.data.obj.list;
								for (let i in newList) {
									newList[i].isAll = 0;
									let productId = newList[i].productEntryIdMap;
									if (productId.length > 0) {
										productId = productId.replace(/{/, "")
										productId = productId.replace(/}/, "")
										productId = productId.split(':');
										if (productId.length > 2) {
											newList[i].isAll = 1;
										}
									}
								}
								let list = that.list;
								let finished = false;
								if (response.data.obj.isLastPage) finished = true;
								list = list.concat(newList);
								that.list = list;
								that.finished = finished;
								that.showVerify = false;
								that.error = false;
							}
						} else if (response.data.code == -7) {
							that.error = true;
							that.getVerifyCode()
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								that.showLogin = true;
							}
							that.error = true;
							Toast(response.data.msg);
						}
						that.loading = false;
					}, response => {
						that.loading = false;
						that.error = true;
						Toast('获取失败，请稍后重试');
					})
				}
			},
			getVerifyCode() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},
			async refreshData() {
				const that = this;
				that.loading = true;
				let pageNum = this.pageNum > 5 ? 5 : this.pageNum
				let params = {
					pageNum: 1,
					pageSize: this.pageSize * pageNum
				};

				if (this.periodIndex == -1) {
					params.catenaId = that.id
					await that.axios.get(config.requestUrl + '/front/consignment/getProCatenaNewListByCatenaId', {
						params,
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token ? that.$store.state.user.token : ''
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (this.periodIndex == -1) {
								Toast.clear();
								let newList = response.data.obj.list ? response.data.obj.list : [];
								for (let i in newList) {
									newList[i].isAll = 0;
									let productId = newList[i].productEntryIdMap;
									if (productId.length > 0) {
										productId = productId.replace(/{/, "")
										productId = productId.replace(/}/, "")
										productId = productId.split(':');
										if (productId.length > 2) {
											newList[i].isAll = 1;
										}
									}
								}
								let finished = false;
								if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
									true;
								that.list = newList;
							}
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								that.showLogin = true;
							}
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				} else {
					params.period = this.periodList[this.periodIndex].id;
					let type = this.type;
					let levelIndex = this.levelIndex
					if (this.type == 1) {
						params.type = 3;
						params.keyword = 2
					} else if (this.type == 2) {
						// params.isQueryBlindBox=2;
						params.type = 2;
						params.keyword = this.LevelList[this.levelIndex].key
					} else if (this.type == 3) {
						params.type = 4;
						// params.isQueryBlindBox = 2;
					}
					await that.axios.get(config.requestUrl + '/front/consignment/getListByFilter', {
						params,
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token ? that.$store.state.user.token : ''
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (type == this.type && levelIndex == this.levelIndex) {
								Toast.clear();
								let newList = response.data.obj.list;
								for (let i in newList) {
									newList[i].isAll = 0;
									let productId = newList[i].productEntryIdMap;
									if (productId.length > 0) {
										productId = productId.replace(/{/, "")
										productId = productId.replace(/}/, "")
										productId = productId.split(':');
										if (productId.length > 2) {
											newList[i].isAll = 1;
										}
									}
								}
								let finished = false;
								if (response.data.obj.isLastPage) finished = true;
								that.list = newList;
							}
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								that.showLogin = true;
							}
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				}
			}
		},
		mounted() {
			util.wxConfig();
			dataCenterBus.$on('refreshData', () => {
				console.log('asd')
				this.refreshData();
			});
		},
		activated() {
			console.log('activated')
		},
		beforeRouteEnter(to, from, next) {
			if (from.name == 'detail' || from.name == 'paysuccess') {
				next();
			} else {
				next(that => {
					document.title = that.$route.query.name;
					that.id = that.$route.query.id
					that.getPeriodByCatenaId();
					that.getProductLevelList();
					that.periodIndex = -1;
					that.type = 1;
					that.levelIndex = -1;
					that.list = [];
					that.more = true;
					that.pageNum = 1
					that.finished = false;
					that.error = false;
					that.getGoods();
				});
			}
		},
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #F4F4F4;
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	.search-sec {
		width: 690px;
		height: 70px;
		background: #F4F4F4;
		border-radius: 10px;
		font-size: 30px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 70px;
		margin: 0 auto;
		padding-left: 30px;
		box-sizing: border-box;
	}

	.tab-sec {
		margin-left: 30px;
		background: #F4F4F4;
		padding: 24px 0 10px;
	}

	.tab-item {
		margin-right: 30px;
		margin-bottom: 15px;
		padding: 7px 0;
	}

	.tab-item-name {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
	}

	.tab-item-active {
		height: 40px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 7px 13px 7px 10px;
		/* padding: 7px 13px 7px 10px; */
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 26px;
	}

	.tab-item-icon {
		width: 36px;
		height: 19px;
		margin-left: 8px;
	}

	.tab-item-icon-1 {
		width: 22px;
		height: 22px;
		margin-left: 8px;
	}

	.tab-item-icon2 {
		width: 22px;
		height: 22px;
		margin-left: 10px;
	}

	.tab-item-icon3 {
		width: 22px;
		height: 22px;
		margin-left: 10px;
	}

	.login-btn {
		width: 137px;
		height: 50px;
		background: #6236FF;
		border-radius: 34px;
		text-align: center;
		line-height: 50px;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
		position: absolute;
		top: 0;
		right: 30px;
		bottom: 0;
		margin: auto;
	}

	.bot-tab-sec {
		bottom: 50px !important;
	}

	.good-list-wrap {
		margin-top: 50px;
		/* padding-bottom: 150px; */
		background: #F4F4F4;
	}

	.head-wrap {
		background: #FFFFFF;
		padding-top: 20px;
	}

	.catena-item-wrap {}

	.catena-period-wrap {
		box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.1);
	}

	.period-item {
		margin-top: 27px;
		margin-left: 27px;
	}

	.period-name {
		padding-right: 27px;
		height: 40px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
		text-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1);
	}

	.period-item:last-child {
		color: red
	}

	.period-item:last-child .period-name {
		border: none;
	}

	.period-name-selected {
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 26px;
		text-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1);
	}

	.period-line {
		margin-top: 15px;
		width: 55px;
		height: 5px;
		background: #FFFFFF;
	}

	.period-line-selected {
		width: 55px;
		height: 5px;
		background: #6236FF;
	}

	.catena-grade-wrap {
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.grade-item {
		margin: 25px 0 25px 0;
		padding: 0 16px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 25px;
		font-size: 28px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		line-height: 50px;
		text-align: center;
	}

	.grade-item-selected {
		background: rgba(98, 54, 255, 0.06);
		font-weight: 600;
		color: #6236FF;
		line-height: 50px;
		text-align: center;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}
</style>