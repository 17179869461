<template>
	<div class="container">
		<div class="flex-row align-center justify-center support-sec">
			<img src="../assets/support_icon.png" class="support-icon" />
			<div class="support-dian"></div>
			<div class="support-con">Meta链提供技术支持</div>
		</div>
		<div class=" flex-row align-center space-between search-wrap">
			<form action="javascript:return true;">
				<input @input="inputKeywords" v-model="keywords" @keyup.enter='search()' @keyup.13="show()" ref="input1"
					type="search" placeholder="搜索" @compositionstart="onCompositionstart" @compositionend="onCompositionend" >
			</form>
			<div class="cancle-btn" @click="clearKeywords()" v-if="keywords">取消</div>
			
		</div>
		<div class="flex-column xiala-list" v-if='true'>
			<block v-for="(item,index) in list" v-bind:key="index">
				<div class="xiala-item" @click.stop='toSearch(item)'>{{item}}</div>
			</block>
		</div>
		<div class='center'>
			<div class='part'>
				<div class='title-box'>
					<div class='title'>历史搜索</div>
					<div class='clear' @click.stop='clearHistory()'>清除</div>
				</div>
				<div class='content'>
					<div class='text' @click.stop="toSearch(item)" v-for="(item,index) in history" v-bind:key="index">
						{{item}}</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import 'vant/lib/dialog/index.css';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css'
	export default {
		data() {
			return {
				keywords: '',
				list: [],
				history: [],
				flag:true
			}
		},
		mounted() {
			if (!this.$store.state.user.token) {
				this.$router.replace('/')
			}
			util.wxConfig();
			if (localStorage.getItem('history')) {
				this.history = JSON.parse(localStorage.getItem('history'))
			}
		},
		methods: {
			onCompositionstart(){
				this.flag=false;
			},
			onCompositionend(e){
				const that=this
				setTimeout(()=>{
					that.flag=true;
					that.inputKeywords(e)
				},100)
			},
			clearHistory() {
				Dialog.confirm({
						title: '',
						message: '确认要清除历史搜索？',
					})
					.then(() => {
						localStorage.setItem('history', '')
						if (localStorage.getItem('history')) {
							this.history = JSON.parse(localStorage.getItem('history'))
						}else{
							this.history=[]
						}
						Toast.success('清除成功')
					})
					.catch(() => {
						console.log('取消')
						// on cancel
				});

			},
			show() {
				this.$refs.input1.blur();
			},
			search() {
				if (this.keywords != '') {
					this.$router.push({
						path: '/searchResult',
						query: {
							keywords: this.keywords
						}
					})
				}
			},
			toSearch(keywords) {
				this.$router.push({
					path: '/searchResult',
					query: {
						keywords
					}
				})
			},
			clearKeywords() {
				this.keywords = '';
				this.list = [];
			},
			inputKeywords(e) {
				if(!this.flag){
					return;
				}
				//获取候选词
				const that = this;
				if (e.target.value !== '') {
					this.axios.get(config.requestUrl + '/front/consignment/getNameListByKeyName', {
						params: {
							likeName: e.target.value
						},
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							Toast.clear();
							this.list = response.data.obj
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
							}
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #F4F4F4;
		min-height: 100%;
	}

	.search-wrap {
		width: 690px;
		margin: 20px auto auto;
		position: relative
	}

	.search-wrap input {
		width: 600px;
		height: 70px;
		padding: 0 30px;
		box-sizing: border-box;
		background: #FFFFFF;
		border-radius: 10px;
		border: none;
	}

	.cancle-btn {
		font-size: 30px;
		color: #000000;
		line-height: 42px;
		margin-left: 29px;
	}

	.xiala-list {
		width: 750px;
		background: #FFFFFF;
		padding: 0 40px;
		box-sizing: border-box;
		position: fixed;
		top: 208px;
		left: 0;
		z-index: 999
	}

	.xiala-item {
		width: 670px;
		height: 90px;
		background: #FFFFFF;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 90px;
		margin-bottom: 1px;
	}


	.center {
		padding-left: 20px;
		padding-right: 14px;
	}

	.title-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
	}

	.title {
		font-size: 28px;
		color: #000;
		line-height: 40px;
	}

	.clear {
		width: 80px;
		height: 48px;
		background-color: #f7f7f7;
		border-radius: 100px;
		line-height: 48px;
		color: #353535;
		font-size: 24px;
		text-align: center;
	}

	.content {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 20px;
	}

	.text {
		height: 48px;
		padding: 0 20px;
		background-color: #f7f7f7;
		border-radius: 100px;
		text-align: center;
		line-height: 48px;
		color: #888;
		font-size: 24px;
		margin-right: 16px;
		margin-bottom: 16px;
	}

	.part {
		margin-bottom: 48px;
	}
</style>
