<template>
	<div class="container" v-if="param.isOpen =='是'">
		<img :src='base64GoodsImg' ref='goodimg' crossorigin="anonymous" class="good-img" />
		<img src='../assets/xian_img.png' crossorigin="anonymous" ref='xianimg' class="xian-img" />
		<img :src='qrcodeImg' ref='codeimg' crossorigin="anonymous" v-show="false" class="code-img" />
		<div class="share-img-sec" v-show="base64_img">
			<img :src='base64_img' class="share-img" />
			<canvas id='mycanvas' v-show="false" class="mycanvas share-img" />
			<div class="flex-row align-center btn-sec space-between">
				<!-- <a v-if="!isWx" :href="base64_img" download='share.png' class="save-btn">保存卡片</a> -->
				<div class="give-btn" @click="back()">返回</div>
				<div v-if="!isWx" class="save-btn" @click="donwloadImg">保存卡片</div>
				<div v-else class="save-btn" @click="saveImg">保存卡片</div>
			</div>
		</div>
		<div ref="qrCodeDiv" class="qrcode"></div>
	</div>
	<div class="container" v-else>
		<div style='background:#FEFFD3;width:100%;height:100%;position:fixed;' v-show="yichaikai == 1"></div>
		<div class="share-img-sec" v-if="yichaikai == 0">
			<div class="for-img">
				<img src='../assets/share_not_open.png' class="share-img" />
				<img src="../assets/manghe.png" class="blind-img" alt="" :class="isChai?'shaky':''"
					:animation='animationData'>
			</div>
			<div class="flex-row align-center btn-sec space-between">
				<div class="give-btn" @click="back()">返回</div>
				<div class="save-btn" @click="open()">立即拆开</div>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2';
	import config from '../config/config.js';
	import util from '../config/util.js';
	import base64Img from 'base64-img-promise';
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css';

	export default {
		data() {
			return {
				base64_img: '',
				param: '',
				qrcodeImg: '', //生成的分享图
				isWx: util.isWx(),
				base64GoodsImg: '',
				isChai: 0,
				yichaikai: 0,
				animationData: {},
			}
		},
		created() {
			const param = this.$route.query;
			console.log(param)
			this.param = param;
		},
		async mounted() {
			util.wxConfig();
			let that = this
			if (this.param.isOpen == '是') {
				that.makeCanvas();
			}
		},
		methods: {
			// 保存卡片
			deal() {
				this.$router.push({
					path: "/deal",
					query: {
						productId: this.param.id
					}
				});
			},
			saveImg() {
				Toast.success('请长按图片保存')
			},
			open() {
				const that = this;
				that.isChai = true;
				let params = new FormData()
				params.append('productEntryId', this.param.id);
				this.axios.post(config.requestUrl + '/front/order/setOpenBlindBoxByEntryId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					console.log(response)
					if (response.data.code == 0) {
						setTimeout(function() {
							that.yichaikai = 1
							that.isChai = false;
							setTimeout(() => {
								// let goodsList = that.$store.state.goodsList;
								// goodsList[that.param.index].isOpen = '是';
								// that.$store.commit('setGoodsList', goodsList)
								let data = response.data.obj;
								that.param.productPic2 = data.productPic
								that.param.productPic = data.productPic
								that.param.productName = data.productName
								that.param.issuerName = data.issuerName
								that.param.position = data.position
								that.param.tokenId = data.tokenId
								that.param.isOpen = '是';
								setTimeout(() => {
									that.makeCanvas();
								}, 500)
							}, 1200)
						}, 1500)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						console.log(response.data.msg)
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			async makeCanvas() {
				const that = this;
				const data = await base64Img.requestBase64(that.param.productPic)
				if (data.data) {
					console.log('data')
					console.log(data)
					that.base64GoodsImg = data.data;
					let qrcode = new QRCode(that.$refs.qrCodeDiv, {
						text: config.host + 'certify?productId=' + that.param.id,
						width: 200,
						height: 200,
						colorDark: "#000000", //二维码颜色
						colorLight: "#00FA9A", //二维码背景色
						correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
					})

					let canvas = qrcode._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
					var qrcodeImg = canvas.toDataURL("image/png");
					console.log('qrcodeImg')
					console.log(qrcodeImg)
					that.qrcodeImg = qrcodeImg;


					var c = document.getElementById("mycanvas");
					console.log(c)
					var ctx = c.getContext("2d");
					c.width = 750;
					c.height = 1141;
					ctx.fillStyle = '#000000';
					ctx.fillRect(0, 0, 750, 1141);
					var good_img = that.$refs.goodimg;
					var xian_img = that.$refs.xianimg;
					var code_img = that.$refs.codeimg;
					console.log(good_img);
					console.log(xian_img)
					console.log(code_img)
					good_img.onload = function() {
						ctx.drawImage(good_img, 0, 0, 750, 841);
						console.log('画线')
						// xian_img.onload = function() {
						console.log('xian')
						ctx.drawImage(xian_img, 0, 841, 750, 8);
						ctx.fillStyle = '#ffffff';
						ctx.font = "28px Arial";
						ctx.textAlign = "left";
						ctx.textBaseline = "top";
						ctx.fillText(that.param.productName, 39, 884); //左对齐
						ctx.font = "19px Arial";
						ctx.fillText('发行方：' + that.param.issuerName, 39, 938); //左对齐
						ctx.fillText('版号：' + that.param.position, 39, 979); //左对齐

						// 绘制TOKEN ID 开始
						ctx.textBaseline = "middle";
						let lineHeight = 25;
						ctx.fillText('Token ID: ', 39, 1012 + lineHeight / 2); //左对齐
						let t = that.param.tokenId;
						var chr = t.split("");
						var temp = "";
						var row = [];
						let w = 250;
						for (var a = 0; a < chr.length; a++) {
							if (ctx.measureText(temp).width >= w) {
								row.push(temp);
								temp = "";
							}
							temp += chr[a];
						}
						row.push(temp);
						for (var b = 0; b < row.length; b++) {
							ctx.fillText(row[b], 124, 1012 + b * lineHeight + lineHeight / 2); //左对齐
						}
						// 绘制TOKEN ID 结束
						ctx.drawImage(code_img, 546, 888, 156, 156);
						ctx.textBaseline = "top";
						ctx.font = "25px Arial";
						ctx.fillText('扫码查主人', 559, 1064); //左对齐
						setTimeout(function() { //兼容苹果手机，延时函数
							var base64_img = c.toDataURL("image/png");
							that.base64_img = base64_img
						}, 0)
					}
					// }
				}
			},
			donwloadImg() {
				var aLink = document.createElement('a');
				var blob = this.base64ToBlob(this.base64_img); //new Blob([content]);
				var evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
				aLink.download = this.param.tokenId + '.png';
				aLink.href = URL.createObjectURL(blob);
				// aLink.dispatchEvent(evt);
				aLink.click()
			},
			base64ToBlob(code) {
				var parts = code.split(';base64,');
				var contentType = parts[0].split(':')[1];
				var raw = window.atob(parts[1]);
				var rawLength = raw.length;

				var uInt8Array = new Uint8Array(rawLength);

				for (var i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.qrcode {
		position: fixed;
		top: -9999px;
		left: -9999px;
	}

	.container {
		width: 100%;
		height: 100%;
		overflow: scroll;
		position: fixed;
		background: #333333;
	}

	.good-img {
		width: 750px;
		height: 841px;
		display: none;
	}

	.xian-img {
		width: 750px;
		height: 8px;
		display: none;
	}

	.code-img {
		width: 156px;
		height: 156px;
		/* display: none; */
	}

	.share-img {
		width: 610px;
		height: 930px;
	}

	.save-btn {
		width: 270px;
		height: 60px;
		background: #FF5E38;
		border-radius: 30px;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 60px;
		text-align: center;
	}

	a {
		text-decoration: none;

	}

	a:active {
		text-decoration: none;
	}

	.give-btn {
		width: 270px;
		height: 60px;
		background: #6236FF;
		border-radius: 30px;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 60px;
		text-align: center;
	}

	.share-img-sec {
		width: 610px;
		height: 1050px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}

	.for-img {
		position: relative;
	}

	.blind-img {
		position: absolute;
		left: 0;
		right: 0;
		width: 411px;
		height: 411px;
		top: 126px;
		margin: auto;
	}

	.btn-sec {
		margin-top: 60px;
		padding: 0 6rpx;
	}

	/* 拆开时的抖动效果 */
	.shaky {
		display: inline-block;
		padding: 1px;
		font-size: 12px;
		-webkit-transform-origin: center center;
		-ms-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-name: shaky-slow;
		-ms-animation-name: shaky-slow;
		animation-name: shaky-slow;
		-webkit-animation-duration: 2s;
		-ms-animation-duration: 12s;
		animation-duration: 2s;
		-webkit-animation-iteration-count: infinite;
		-ms-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-timing-function: ease-in-out;
		-ms-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		-webkit-animation-delay: 0s;
		-ms-animation-delay: 0s;
		animation-delay: 0s;
		-webkit-animation-play-state: running;
		-ms-animation-play-state: running;
		animation-play-state: running;
	}

	@-webkit-keyframes shaky-slow {
		0% {
			-webkit-transform: translate(0px, 0px) rotate(0deg);
		}

		2% {
			-webkit-transform: translate(-1px, 1.5px) rotate(1.5deg);
		}

		4% {
			-webkit-transform: translate(1.3px, 0px) rotate(-0.5deg);
		}

		6% {
			-webkit-transform: translate(1.4px, 1.4px) rotate(-2deg);
		}

		8% {
			-webkit-transform: translate(-1.3px, -1px) rotate(-1.5deg);
		}

		10% {
			-webkit-transform: translate(1.4px, 0px) rotate(-2deg);
		}

		12% {
			-webkit-transform: translate(-1.3px, -1px) rotate(-2deg);
		}

		14% {
			-webkit-transform: translate(1.5px, 1.3px) rotate(1.5deg);
		}

		16% {
			-webkit-transform: translate(1.5px, -1.5px) rotate(-1.5deg);
		}

		18% {
			-webkit-transform: translate(1.3px, -1.3px) rotate(-2deg);
		}

		20% {
			-webkit-transform: translate(1px, 1px) rotate(-0.5deg);
		}

		22% {
			-webkit-transform: translate(1.3px, 1.5px) rotate(-2deg);
		}

		24% {
			-webkit-transform: translate(-1.4px, -1px) rotate(2deg);
		}

		26% {
			-webkit-transform: translate(1.3px, -1.3px) rotate(0.5deg);
		}

		28% {
			-webkit-transform: translate(1.6px, -1.6px) rotate(-2deg);
		}

		30% {
			-webkit-transform: translate(-1.3px, -1.3px) rotate(-1.5deg);
		}

		32% {
			-webkit-transform: translate(-1px, 0px) rotate(2deg);
		}

		34% {
			-webkit-transform: translate(1.3px, 1.3px) rotate(-0.5deg);
		}

		36% {
			-webkit-transform: translate(1.3px, 1.6px) rotate(1.5deg);
		}

		38% {
			-webkit-transform: translate(1.3px, -1.6px) rotate(1.5deg);
		}

		40% {
			-webkit-transform: translate(-1.4px, -1px) rotate(-0.5deg);
		}

		42% {
			-webkit-transform: translate(-1.4px, 1.3px) rotate(-0.5deg);
		}

		44% {
			-webkit-transform: translate(-1.6px, 1.4px) rotate(0.5deg);
		}

		46% {
			-webkit-transform: translate(-2.1px, -1.3px) rotate(-0.5deg);
		}

		48% {
			-webkit-transform: translate(1px, 1.6px) rotate(1.5deg);
		}

		50% {
			-webkit-transform: translate(1.6px, 1.6px) rotate(1.5deg);
		}

		52% {
			-webkit-transform: translate(-1.4px, 1.6px) rotate(0.5deg);
		}

		54% {
			-webkit-transform: translate(1.6px, -1px) rotate(-2deg);
		}

		56% {
			-webkit-transform: translate(1.3px, -1.6px) rotate(-2deg);
		}

		58% {
			-webkit-transform: translate(-1.3px, -1.6px) rotate(0.5deg);
		}

		60% {
			-webkit-transform: translate(1.3px, 1.6px) rotate(-0.5deg);
		}

		62% {
			-webkit-transform: translate(0px, 0px) rotate(-1.5deg);
		}

		64% {
			-webkit-transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		66% {
			-webkit-transform: translate(1.6px, -1.6px) rotate(0.5deg);
		}

		68% {
			-webkit-transform: translate(0px, -1.6px) rotate(-2deg);
		}

		70% {
			-webkit-transform: translate(-1.6px, 1px) rotate(1.5deg);
		}

		72% {
			-webkit-transform: translate(-1.6px, 1.6px) rotate(2deg);
		}

		74% {
			-webkit-transform: translate(1.3px, -1.6px) rotate(-0.5deg);
		}

		76% {
			-webkit-transform: translate(1.4px, 1px) rotate(-0.5deg);
		}

		78% {
			-webkit-transform: translate(-1px, 1.4px) rotate(2deg);
		}

		80% {
			-webkit-transform: translate(1.4px, 1.6px) rotate(2deg);
		}

		82% {
			-webkit-transform: translate(-1.6px, -1.6px) rotate(-0.5deg);
		}

		84% {
			-webkit-transform: translate(-1.4px, 1.4px) rotate(-2deg);
		}

		86% {
			-webkit-transform: translate(1px, 1.4px) rotate(-2deg);
		}

		88% {
			-webkit-transform: translate(-1.4px, 1.4px) rotate(-1.5deg);
		}

		90% {
			-webkit-transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		92% {
			-webkit-transform: translate(-1.6px, 1.6px) rotate(2deg);
		}

		94% {
			-webkit-transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		96% {
			-webkit-transform: translate(-1.4px, 1.3px) rotate(-2deg);
		}

		98% {
			-webkit-transform: translate(1.3px, 1px) rotate(-0.5deg);
		}
	}

	@keyframes shaky-slow {
		0% {
			transform: translate(0px, 0px) rotate(0deg);
		}

		2% {
			transform: translate(-1px, 1.5px) rotate(1.5deg);
		}

		4% {
			transform: translate(1.3px, 0px) rotate(-0.5deg);
		}

		6% {
			transform: translate(1.4px, 1.4px) rotate(-2deg);
		}

		8% {
			transform: translate(-1.3px, -1px) rotate(-1.5deg);
		}

		10% {
			transform: translate(1.4px, 0px) rotate(-2deg);
		}

		12% {
			transform: translate(-1.3px, -1px) rotate(-2deg);
		}

		14% {
			transform: translate(1.5px, 1.3px) rotate(1.5deg);
		}

		16% {
			transform: translate(1.5px, -1.5px) rotate(-1.5deg);
		}

		18% {
			transform: translate(1.3px, -1.3px) rotate(-2deg);
		}

		20% {
			transform: translate(1px, 1px) rotate(-0.5deg);
		}

		22% {
			transform: translate(1.3px, 1.5px) rotate(-2deg);
		}

		24% {
			transform: translate(-1.4px, -1px) rotate(2deg);
		}

		26% {
			transform: translate(1.3px, -1.3px) rotate(0.5deg);
		}

		28% {
			transform: translate(1.6px, -1.6px) rotate(-1.5deg);
		}

		30% {
			transform: translate(-1.3px, -1.3px) rotate(-1.5deg);
		}

		32% {
			transform: translate(-1px, 0px) rotate(2deg);
		}

		34% {
			transform: translate(1.3px, 1.3px) rotate(-0.5deg);
		}

		36% {
			transform: translate(1.3px, 1.6px) rotate(1.5deg);
		}

		38% {
			transform: translate(1.3px, -1.6px) rotate(1.5deg);
		}

		40% {
			transform: translate(-1.4px, -1px) rotate(-0.5deg);
		}

		42% {
			transform: translate(-1.4px, 1.3px) rotate(-0.5deg);
		}

		44% {
			transform: translate(-1.6px, 1.4px) rotate(0.5deg);
		}

		46% {
			transform: translate(-2.1px, -1.3px) rotate(-0.5deg);
		}

		48% {
			transform: translate(1px, 1.6px) rotate(1.5deg);
		}

		50% {
			transform: translate(1.6px, 1.6px) rotate(1.5deg);
		}

		52% {
			transform: translate(-1.4px, 1.6px) rotate(0.5deg);
		}

		54% {
			transform: translate(1.6px, -1px) rotate(-2deg);
		}

		56% {
			transform: translate(1.3px, -1.6px) rotate(-2deg);
		}

		58% {
			transform: translate(-1.3px, -1.6px) rotate(0.5deg);
		}

		60% {
			transform: translate(1.3px, 1.6px) rotate(-0.5deg);
		}

		62% {
			transform: translate(0px, 0px) rotate(-1.5deg);
		}

		64% {
			transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		66% {
			transform: translate(1.6px, -1.6px) rotate(0.5deg);
		}

		68% {
			transform: translate(0px, -1.6px) rotate(-2deg);
		}

		70% {
			transform: translate(-1.6px, 1px) rotate(1.5deg);
		}

		72% {
			transform: translate(-1.6px, 1.6px) rotate(2deg);
		}

		74% {
			transform: translate(1.3px, -1.6px) rotate(-0.5deg);
		}

		76% {
			transform: translate(1.4px, 1px) rotate(-0.5deg);
		}

		78% {
			transform: translate(-1px, 1.4px) rotate(2deg);
		}

		80% {
			transform: translate(1.4px, 1.6px) rotate(2deg);
		}

		82% {
			transform: translate(-1.6px, -1.6px) rotate(-0.5deg);
		}

		84% {
			transform: translate(-1.4px, 1.4px) rotate(-2deg);
		}

		86% {
			transform: translate(1px, 1.4px) rotate(-2deg);
		}

		88% {
			transform: translate(-1.4px, 1.4px) rotate(-1.5deg);
		}

		90% {
			transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		92% {
			transform: translate(-1.4px, 1.6px) rotate(2deg);
		}

		94% {
			transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		96% {
			transform: translate(-1.4px, 1.3px) rotate(-2deg);
		}

		98% {
			transform: translate(1.3px, 1px) rotate(-0.5deg);
		}
	}
</style>
