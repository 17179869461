<template>
	<div class="container">
		<div class="canvas-sec" v-show="false">
			<img src="../assets/entry/poster2.png" ref='poster2' crossorigin="anonymous" class="entry-img2">
			<img src="../assets/entry/entry_img2.png" ref='entryimg2' crossorigin="anonymous" class="entry-img2">
			<img src="../assets/entry/entry_img.png" ref='entryimg' crossorigin="anonymous" class="entry-img">
			<img src="../assets/good1.png" ref='headimg' crossorigin="anonymous" class="head-img" />
			<img :src="qrcodeImg" ref='qrcodeimg' crossorigin="anonymous" class="qrcode-img" />
			<canvas id='mycanvas' class="mycanvas share-img" />
		</div>
		<div ref="qrCodeDiv" style="position: fixed;top: -9999px;left: -9999px;"></div>
		<img v-if="base64_img !=''" :src="base64_img" class="invite-poster">
		<img src="../assets/entry/entry_btn.png" class="btn" alt="" @click.stop="donwloadImg()">
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	import QRCode from 'qrcodejs2';
	import base64Img from 'base64-img-promise';
	export default {
		data() {
			return {
				// user:{'pic':'','name':'我是三花猫'},
				base64_img: '',
				qrcodeImg: '',
				info: {}
			}
		},
		mounted() {
			this.info = this.$route.query
			console.log(this.info)
			this.getMember()
		},
		methods: {
			async makeCanvas() {
				let that = this;
				var headimg = that.$refs.headimg;
				if (that.user.pic) {
					const data = await base64Img.requestBase64(that.user.pic)
					if (data.data) {
						headimg = data.data;
					}
				}
				console.log(headimg)
				var c = document.getElementById("mycanvas");
				var ctx = c.getContext("2d");
				c.width = 710;
				c.height = 1400;
				var entryimg2 = that.$refs.entryimg2;
				var entryimg = that.$refs.entryimg;
				var poster2 = that.$refs.poster2;
				var qrcodeimg = that.$refs.qrcodeimg;
				// 根据名字计算需要的宽度,确定名字头像部分的xy坐标
				let name = that.user.name;
				let headimgRX = 272 + 26 / 2
				let headimgRY = 1261 + 26 / 2
				let nameX = 306
				let nameY = 1261

				if (entryimg.complete) {
					qrcodeimg.onload = () => {
						ctx.drawImage(entryimg, 0, 0, 710, 1400);
						ctx.drawImage(entryimg2, 283, 40, 144, 260);
						// 敦煌盛典
						ctx.fillStyle = '#FFBA90';
						ctx.font = "34px Arial";
						ctx.textAlign = "left";
						ctx.textBaseline = "middle";
						ctx.fillText(that.info.endueSettingName, 57, 1030 + 48 / 2 + 4);

						// 价格
						ctx.fillStyle = '#FFBA90';
						ctx.font = "66px Arial";
						ctx.textAlign = "center";
						ctx.fillText(that.info.retailPrice, 331 + 130 / 2, 1024 + 92 / 2 + 10);
						let rbase = 34
						let r = rbase / 2

						// 成人票
						console.log('BBBBB')
						ctx.fillStyle = '#FFBA90';
						ctx.font = "26px Arial";
						ctx.textAlign = "center";
						let posX = 60;
						let posY = 1094;
						let tWidth = ctx.measureText(that.info.endueSettingFareTypeStr).width;
						let rectX = posX //编号白色背景左上角X坐标
						let rectY = posY - 2; //编号白色背景左上角Y坐标
						let rectRightX = posX + tWidth + 40; //编号白色背景右上角X坐标
						ctx.beginPath();
						ctx.fillStyle = 'rgba(69,58,43,0.7)';
						ctx.arc(rectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
						ctx.arc(rectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(rectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						ctx.arc(rectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
						ctx.closePath()
						ctx.fill()

						ctx.fillStyle = '#FFBA90';
						ctx.fillText(that.info.endueSettingFareTypeStr, posX + (tWidth + 40) / 2, 1098 + 28 / 2 +
							2);

						// A区
						ctx.fillStyle = '#FFBA90';
						ctx.font = "26px Arial";
						ctx.textAlign = "center";
						let wposX = rectRightX + 10;
						let wposY = 1094;
						let wWidth = ctx.measureText(that.info.endueSettingTicketName).width;
						if (wWidth < 100) {
							wWidth = 100;
						}
						console.log(wWidth)
						let wrectX = wposX //编号白色背景左上角X坐标
						let wrectY = wposY - 2; //编号白色背景左上角Y坐标
						let wrectRightX = wposX + wWidth + 20; //编号白色背景右上角X坐标
						ctx.beginPath();
						ctx.fillStyle = 'rgba(69,58,43,0.7)';
						ctx.arc(wrectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
						ctx.arc(wrectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(wrectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						ctx.arc(wrectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
						ctx.closePath()
						ctx.fill()
						ctx.fillStyle = '#FFBA90';
						ctx.fillText(that.info.endueSettingTicketName, wposX + (wWidth + 20) / 2,
							1098 + 28 /
							2 + 2);

						// 头像背景
						ctx.fillStyle = '#FFFFFF';
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 26 / 2 + 2, 0, 2 * Math.PI);
						ctx.closePath()
						ctx.fill()

						// 头像
						ctx.save()
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 26 / 2, 0, 2 * Math.PI);
						ctx.clip()
						ctx.drawImage(headimg, 272, 1261, 26, 26);
						ctx.restore()

						// 名字
						ctx.fillStyle = '#FFFFFF';
						ctx.font = "26px Arial";
						ctx.textAlign = "left";
						ctx.fillText(name, nameX, nameY + 26 / 2 + 1);

						ctx.drawImage(qrcodeimg, 74, 1152, 181, 181);
						setTimeout(function() { //兼容苹果手机，延时函数
							var base64_img = c.toDataURL("image/png");
							that.base64_img = base64_img
						}, 0)
					}
				} else {
					console.log(123456)
					var intel = setTimeout(() => {
						ctx.drawImage(entryimg, 0, 0, 710, 1400);
						ctx.drawImage(entryimg2, 283, 40, 144, 260);
						// 敦煌盛典
						ctx.fillStyle = '#FFBA90';
						ctx.font = "34px Arial";
						ctx.textAlign = "left";
						ctx.textBaseline = "middle";
						ctx.fillText(that.info.endueSettingName, 57, 1030 + 48 / 2 + 4);

						// 价格
						ctx.fillStyle = '#FFBA90';
						ctx.font = "66px Arial";
						ctx.textAlign = "center";
						ctx.fillText(that.info.retailPrice, 331 + 130 / 2, 1024 + 92 / 2 + 10);

						let rbase = 34
						let r = rbase / 2

						// 成人票
						console.log('BBBBB')
						ctx.fillStyle = '#FFBA90';
						ctx.font = "26px Arial";
						ctx.textAlign = "center";
						let posX = 60;
						let posY = 1094;
						let tWidth = ctx.measureText(that.info.endueSettingFareTypeStr).width;
						let rectX = posX //编号白色背景左上角X坐标
						let rectY = posY - 2; //编号白色背景左上角Y坐标
						let rectRightX = posX + tWidth + 40; //编号白色背景右上角X坐标
						ctx.beginPath();
						ctx.fillStyle = 'rgba(69,58,43,0.7)';
						ctx.arc(rectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
						ctx.arc(rectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(rectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						ctx.arc(rectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
						ctx.closePath()
						ctx.fill()

						ctx.fillStyle = '#FFBA90';
						ctx.fillText(that.info.endueSettingFareTypeStr, posX + (tWidth + 40) / 2, 1098 + 28 /
							2 +
							2);

						// A区
						ctx.fillStyle = '#FFBA90';
						ctx.font = "26px Arial";
						ctx.textAlign = "center";
						let wposX = rectRightX + 10;
						let wposY = 1094;
						let wWidth = ctx.measureText(that.info.endueSettingTicketName).width;
						if (wWidth < 100) {
							wWidth = 100;
						}
						console.log(wWidth)
						let wrectX = wposX //编号白色背景左上角X坐标
						let wrectY = wposY - 2; //编号白色背景左上角Y坐标
						let wrectRightX = wposX + wWidth + 20; //编号白色背景右上角X坐标
						ctx.beginPath();
						ctx.fillStyle = 'rgba(69,58,43,0.7)';
						ctx.arc(wrectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
						ctx.arc(wrectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(wrectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						ctx.arc(wrectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
						ctx.closePath()
						ctx.fill()
						ctx.fillStyle = '#FFBA90';
						ctx.fillText(that.info.endueSettingTicketName, wposX + (wWidth + 20) / 2,
							1098 + 28 /
							2 + 2);


						// 头像背景
						ctx.fillStyle = '#FFFFFF';
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 26 / 2 + 2, 0, 2 * Math.PI);
						ctx.closePath()
						ctx.fill()

						// 头像
						ctx.save()
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 26 / 2, 0, 2 * Math.PI);
						ctx.clip()
						ctx.drawImage(headimg, 272, 1261, 26, 26);
						ctx.restore()

						// 名字
						ctx.fillStyle = '#FFFFFF';
						ctx.font = "26px Arial";
						ctx.textAlign = "left";
						ctx.fillText(name, nameX, nameY + 26 / 2 + 1);

						ctx.drawImage(qrcodeimg, 74, 1152, 181, 181);
						setTimeout(function() { //兼容苹果手机，延时函数
							var base64_img = c.toDataURL("image/png");
							that.base64_img = base64_img
						}, 0)
					}, 1500)
				}
				console.log('entryimg.complete' + entryimg.complete)
				entryimg.onload = () => {
					console.log('onnload')
				}
				entryimg.onload = function() {
					console.log('图片显示')
					clearTimeout(intel);
					console.log('onload')
					ctx.drawImage(entryimg, 0, 0, 710, 1400);
					ctx.drawImage(entryimg2, 283, 40, 144, 260);
					// 敦煌盛典
					ctx.fillStyle = '#FFBA90';
					ctx.font = "34px Arial";
					ctx.textAlign = "left";
					ctx.textBaseline = "middle";
					ctx.fillText(that.info.endueSettingName, 57, 1030 + 48 / 2 + 4);

					// 价格
					ctx.fillStyle = '#FFBA90';
					ctx.font = "66px Arial";
					ctx.textAlign = "center";
					ctx.fillText(that.info.retailPrice, 331 + 130 / 2, 1024 + 92 / 2 + 10);

					let rbase = 34
					let r = rbase / 2

					// 成人票
					console.log('BBBBB')
					ctx.fillStyle = '#FFBA90';
					ctx.font = "26px Arial";
					ctx.textAlign = "center";
					let posX = 60;
					let posY = 1094;
					let tWidth = ctx.measureText(that.info.endueSettingFareTypeStr).width;
					let rectX = posX //编号白色背景左上角X坐标
					let rectY = posY - 2; //编号白色背景左上角Y坐标
					let rectRightX = posX + tWidth + 40; //编号白色背景右上角X坐标
					ctx.beginPath();
					ctx.fillStyle = 'rgba(69,58,43,0.7)';
					ctx.arc(rectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
					ctx.arc(rectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
					ctx.arc(rectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
					ctx.arc(rectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
					ctx.closePath()
					ctx.fill()

					ctx.fillStyle = '#FFBA90';
					ctx.fillText(that.info.endueSettingFareTypeStr, posX + (tWidth + 40) / 2, 1098 + 28 / 2 +
						2);

					// A区
					ctx.fillStyle = '#FFBA90';
					ctx.font = "26px Arial";
					ctx.textAlign = "center";
					let wposX = rectRightX + 10;
					let wposY = 1094;
					let wWidth = ctx.measureText(that.info.endueSettingTicketName).width;
					if (wWidth < 100) {
						wWidth = 100;
					}
					console.log(wWidth)
					let wrectX = wposX //编号白色背景左上角X坐标
					let wrectY = wposY - 2; //编号白色背景左上角Y坐标
					let wrectRightX = wposX + wWidth + 20; //编号白色背景右上角X坐标
					ctx.beginPath();
					ctx.fillStyle = 'rgba(69,58,43,0.7)';
					ctx.arc(wrectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
					ctx.arc(wrectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
					ctx.arc(wrectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
					ctx.arc(wrectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
					ctx.closePath()
					ctx.fill()
					ctx.fillStyle = '#FFBA90';
					ctx.fillText(that.info.endueSettingTicketName, wposX + (wWidth + 20) / 2,
						1098 + 28 /
						2 + 2);
					// 头像背景
					ctx.fillStyle = '#FFFFFF';
					ctx.beginPath();
					ctx.arc(headimgRX, headimgRY, 26 / 2 + 2, 0, 2 * Math.PI);
					ctx.closePath()
					ctx.fill()

					// 头像
					ctx.save()
					ctx.beginPath();
					ctx.arc(headimgRX, headimgRY, 26 / 2, 0, 2 * Math.PI);
					ctx.clip()
					ctx.drawImage(headimg, 272, 1261, 26, 26);
					ctx.restore()

					// 名字
					ctx.fillStyle = '#FFFFFF';
					ctx.font = "26px Arial";
					ctx.textAlign = "left";
					ctx.fillText(name, nameX, nameY + 26 / 2 + 1);

					ctx.drawImage(qrcodeimg, 74, 1152, 181, 181);
					setTimeout(function() { //兼容苹果手机，延时函数
						var base64_img = c.toDataURL("image/png");
						that.base64_img = base64_img
					}, 0)
				}

			},
			async makeCanvas2() {
				let that = this;
				var headimg = that.$refs.headimg;
				if (that.user.pic) {
					const data = await base64Img.requestBase64(that.user.pic)
					if (data.data) {
						headimg = data.data;
					}
				}
				console.log(headimg)
				var c = document.getElementById("mycanvas");
				var ctx = c.getContext("2d");
				c.width = 710;
				c.height = 1400;
				var entryimg2 = that.$refs.entryimg2;
				var entryimg = that.$refs.entryimg;
				var poster2 = that.$refs.poster2;
				var qrcodeimg = that.$refs.qrcodeimg;
				// 根据名字计算需要的宽度,确定名字头像部分的xy坐标
				let name = that.user.name;
				let headimgRX = 272 + 26 / 2
				let headimgRY = 1261 + 26 / 2
				let nameX = 306
				let nameY = 1261

				if (poster2.complete) {
					qrcodeimg.onload = () => {
						ctx.drawImage(poster2, 0, 0, 710, 1400);
						// 敦煌盛典
						ctx.fillStyle = '#FFBA90';
						ctx.font = "34px Arial";
						ctx.textAlign = "left";
						ctx.textBaseline = "middle";
						ctx.fillText(that.info.endueSettingName, 57, 1030 + 48 / 2 + 4);

						// 价格
						ctx.fillStyle = '#FFBA90';
						ctx.font = "66px Arial";
						ctx.textAlign = "center";
						ctx.fillText(that.info.retailPrice, 331 + 130 / 2, 1024 + 92 / 2 + 10);
						let rbase = 34
						let r = rbase / 2

						// 成人票
						console.log('BBBBB')
						ctx.fillStyle = '#FFBA90';
						ctx.font = "26px Arial";
						ctx.textAlign = "center";
						let posX = 60;
						let posY = 1094;
						let tWidth = ctx.measureText(that.info.endueSettingFareTypeStr).width;
						let rectX = posX //编号白色背景左上角X坐标
						let rectY = posY - 2; //编号白色背景左上角Y坐标
						let rectRightX = posX + tWidth + 40; //编号白色背景右上角X坐标
						ctx.beginPath();
						ctx.fillStyle = 'rgba(69,58,43,0.7)';
						ctx.arc(rectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
						ctx.arc(rectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(rectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						ctx.arc(rectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
						ctx.closePath()
						ctx.fill()

						ctx.fillStyle = '#FFBA90';
						ctx.fillText(that.info.endueSettingFareTypeStr, posX + (tWidth + 40) / 2, 1098 + 28 / 2 +
							2);

						// A区
						ctx.fillStyle = '#FFBA90';
						ctx.font = "26px Arial";
						ctx.textAlign = "center";
						let wposX = rectRightX + 10;
						let wposY = 1094;
						let wWidth = ctx.measureText(that.info.endueSettingTicketName).width;
						if (wWidth < 100) {
							wWidth = 100;
						}
						console.log(wWidth)
						let wrectX = wposX //编号白色背景左上角X坐标
						let wrectY = wposY - 2; //编号白色背景左上角Y坐标
						let wrectRightX = wposX + wWidth + 20; //编号白色背景右上角X坐标
						ctx.beginPath();
						ctx.fillStyle = 'rgba(69,58,43,0.7)';
						ctx.arc(wrectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
						ctx.arc(wrectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(wrectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						ctx.arc(wrectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
						ctx.closePath()
						ctx.fill()
						ctx.fillStyle = '#FFBA90';
						ctx.fillText(that.info.endueSettingTicketName, wposX + (wWidth + 20) / 2,
							1098 + 28 /
							2 + 2);

						// 头像背景
						ctx.fillStyle = '#FFFFFF';
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 26 / 2 + 2, 0, 2 * Math.PI);
						ctx.closePath()
						ctx.fill()

						// 头像
						ctx.save()
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 26 / 2, 0, 2 * Math.PI);
						ctx.clip()
						ctx.drawImage(headimg, 272, 1261, 26, 26);
						ctx.restore()

						// 名字
						ctx.fillStyle = '#FFFFFF';
						ctx.font = "26px Arial";
						ctx.textAlign = "left";
						ctx.fillText(name, nameX, nameY + 26 / 2 + 1);

						ctx.drawImage(qrcodeimg, 74, 1152, 181, 181);
						setTimeout(function() { //兼容苹果手机，延时函数
							var base64_img = c.toDataURL("image/png");
							that.base64_img = base64_img
						}, 0)
					}
				} else {
					console.log(123456)
					var intel = setTimeout(() => {
						ctx.drawImage(poster2, 0, 0, 710, 1400);
						// 敦煌盛典
						ctx.fillStyle = '#FFBA90';
						ctx.font = "34px Arial";
						ctx.textAlign = "left";
						ctx.textBaseline = "middle";
						ctx.fillText(that.info.endueSettingName, 57, 1030 + 48 / 2 + 4);

						// 价格
						ctx.fillStyle = '#FFBA90';
						ctx.font = "66px Arial";
						ctx.textAlign = "center";
						ctx.fillText(that.info.retailPrice, 331 + 130 / 2, 1024 + 92 / 2 + 10);

						let rbase = 34
						let r = rbase / 2

						// 成人票
						console.log('BBBBB')
						ctx.fillStyle = '#FFBA90';
						ctx.font = "26px Arial";
						ctx.textAlign = "center";
						let posX = 60;
						let posY = 1094;
						let tWidth = ctx.measureText(that.info.endueSettingFareTypeStr).width;
						let rectX = posX //编号白色背景左上角X坐标
						let rectY = posY - 2; //编号白色背景左上角Y坐标
						let rectRightX = posX + tWidth + 40; //编号白色背景右上角X坐标
						ctx.beginPath();
						ctx.fillStyle = 'rgba(69,58,43,0.7)';
						ctx.arc(rectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
						ctx.arc(rectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(rectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						ctx.arc(rectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
						ctx.closePath()
						ctx.fill()

						ctx.fillStyle = '#FFBA90';
						ctx.fillText(that.info.endueSettingFareTypeStr, posX + (tWidth + 40) / 2, 1098 + 28 /
							2 +
							2);

						// A区
						ctx.fillStyle = '#FFBA90';
						ctx.font = "26px Arial";
						ctx.textAlign = "center";
						let wposX = rectRightX + 10;
						let wposY = 1094;
						let wWidth = ctx.measureText(that.info.endueSettingTicketName).width;
						if (wWidth < 100) {
							wWidth = 100;
						}
						console.log(wWidth)
						let wrectX = wposX //编号白色背景左上角X坐标
						let wrectY = wposY - 2; //编号白色背景左上角Y坐标
						let wrectRightX = wposX + wWidth + 20; //编号白色背景右上角X坐标
						ctx.beginPath();
						ctx.fillStyle = 'rgba(69,58,43,0.7)';
						ctx.arc(wrectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
						ctx.arc(wrectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
						ctx.arc(wrectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
						ctx.arc(wrectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
						ctx.closePath()
						ctx.fill()
						ctx.fillStyle = '#FFBA90';
						ctx.fillText(that.info.endueSettingTicketName, wposX + (wWidth + 20) / 2,
							1098 + 28 /
							2 + 2);


						// 头像背景
						ctx.fillStyle = '#FFFFFF';
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 26 / 2 + 2, 0, 2 * Math.PI);
						ctx.closePath()
						ctx.fill()

						// 头像
						ctx.save()
						ctx.beginPath();
						ctx.arc(headimgRX, headimgRY, 26 / 2, 0, 2 * Math.PI);
						ctx.clip()
						ctx.drawImage(headimg, 272, 1261, 26, 26);
						ctx.restore()

						// 名字
						ctx.fillStyle = '#FFFFFF';
						ctx.font = "26px Arial";
						ctx.textAlign = "left";
						ctx.fillText(name, nameX, nameY + 26 / 2 + 1);

						ctx.drawImage(qrcodeimg, 74, 1152, 181, 181);
						setTimeout(function() { //兼容苹果手机，延时函数
							var base64_img = c.toDataURL("image/png");
							that.base64_img = base64_img
						}, 0)
					}, 1500)
				}

				poster2.onload = function() {
					console.log('图片显示')
					clearTimeout(intel);
					console.log('onload')
					ctx.drawImage(poster2, 0, 0, 710, 1400);
					// 敦煌盛典
					ctx.fillStyle = '#FFBA90';
					ctx.font = "34px Arial";
					ctx.textAlign = "left";
					ctx.textBaseline = "middle";
					ctx.fillText(that.info.endueSettingName, 57, 1030 + 48 / 2 + 4);

					// 价格
					ctx.fillStyle = '#FFBA90';
					ctx.font = "66px Arial";
					ctx.textAlign = "center";
					ctx.fillText(that.info.retailPrice, 331 + 130 / 2, 1024 + 92 / 2 + 10);

					let rbase = 34
					let r = rbase / 2

					// 成人票
					console.log('BBBBB')
					ctx.fillStyle = '#FFBA90';
					ctx.font = "26px Arial";
					ctx.textAlign = "center";
					let posX = 60;
					let posY = 1094;
					let tWidth = ctx.measureText(that.info.endueSettingFareTypeStr).width;
					let rectX = posX //编号白色背景左上角X坐标
					let rectY = posY - 2; //编号白色背景左上角Y坐标
					let rectRightX = posX + tWidth + 40; //编号白色背景右上角X坐标
					ctx.beginPath();
					ctx.fillStyle = 'rgba(69,58,43,0.7)';
					ctx.arc(rectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
					ctx.arc(rectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
					ctx.arc(rectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
					ctx.arc(rectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
					ctx.closePath()
					ctx.fill()

					ctx.fillStyle = '#FFBA90';
					ctx.fillText(that.info.endueSettingFareTypeStr, posX + (tWidth + 40) / 2, 1098 + 28 / 2 +
						2);

					// A区
					ctx.fillStyle = '#FFBA90';
					ctx.font = "26px Arial";
					ctx.textAlign = "center";
					let wposX = rectRightX + 10;
					let wposY = 1094;
					let wWidth = ctx.measureText(that.info.endueSettingTicketName).width;
					if (wWidth < 100) {
						wWidth = 100;
					}
					console.log(wWidth)
					let wrectX = wposX //编号白色背景左上角X坐标
					let wrectY = wposY - 2; //编号白色背景左上角Y坐标
					let wrectRightX = wposX + wWidth + 20; //编号白色背景右上角X坐标
					ctx.beginPath();
					ctx.fillStyle = 'rgba(69,58,43,0.7)';
					ctx.arc(wrectX + r, rectY + r, r, Math.PI * 1, Math.PI * 1.5); //左上角
					ctx.arc(wrectRightX - r, rectY + r, r, Math.PI * 1.5, Math.PI * 2); //右上角
					ctx.arc(wrectRightX - r, rectY + 38 - r, r, Math.PI * 0, Math.PI * 0.5); //右下角
					ctx.arc(wrectX + r, rectY + 38 - r, r, Math.PI * 0.5, Math.PI * 1); //左下角
					ctx.closePath()
					ctx.fill()
					ctx.fillStyle = '#FFBA90';
					ctx.fillText(that.info.endueSettingTicketName, wposX + (wWidth + 20) / 2,
						1098 + 28 /
						2 + 2);
					// 头像背景
					ctx.fillStyle = '#FFFFFF';
					ctx.beginPath();
					ctx.arc(headimgRX, headimgRY, 26 / 2 + 2, 0, 2 * Math.PI);
					ctx.closePath()
					ctx.fill()

					// 头像
					ctx.save()
					ctx.beginPath();
					ctx.arc(headimgRX, headimgRY, 26 / 2, 0, 2 * Math.PI);
					ctx.clip()
					ctx.drawImage(headimg, 272, 1261, 26, 26);
					ctx.restore()

					// 名字
					ctx.fillStyle = '#FFFFFF';
					ctx.font = "26px Arial";
					ctx.textAlign = "left";
					ctx.fillText(name, nameX, nameY + 26 / 2 + 1);

					ctx.drawImage(qrcodeimg, 74, 1152, 181, 181);
					setTimeout(function() { //兼容苹果手机，延时函数
						var base64_img = c.toDataURL("image/png");
						that.base64_img = base64_img
					}, 0)
				}

			},
			getMember() {
				const that = this
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.getBox = 0;
					if (response.data.code == 0) {
						this.user = response.data.obj.userInfo;
						this.user.randName = this.$store.state.user.randName;
						if (!this.$store.state.user.ranName) {
							let user = {
								token: this.$store.state.user.token,
								randName: util.randomString(),
								userPic: this.user.pic,
								nickName: this.user.name
							}
							this.$store.commit('setUser', user)
							localStorage.setItem('user', JSON.stringify(user))
						}
						let qrcode = new QRCode(that.$refs.qrCodeDiv, {
							text: config.ticketUrl + '/?id=' + that.info.id,
							width: 200,
							height: 200,
							colorDark: "#000000", //二维码颜色
							colorLight: "#FFFFFF", //二维码背景色
							correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
						})
						let canvas = qrcode._el.querySelector(
							"canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
						that.qrcodeImg = canvas.toDataURL("image/png");
						if (that.info.endueSettingName == '敦煌盛典') {
							that.makeCanvas();
						} else {
							that.makeCanvas2();
						}
						Toast.clear()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			donwloadImg() {
				if (util.isWx()) {
					Toast('请长按图片保存')
				} else {
					// downloadCanvas.downloadCanvas('mycanvas', {
					// 	name: 'mycanvas',
					// 	type: 'jpg',
					// 	quality: 0.7
					// });
					// let a = document.createElement('a')
					// let event = new MouseEvent('click')
					// a.download = 'default.png'
					// a.href = this.base64_img
					// a.dispatchEvent(event)

					// let base64Str =this.base64_img;
					// let aLink = document.createElement("a");
					// aLink.style.display = "none";
					// aLink.href = base64Str;
					// aLink.download = "test.jpg";
					// // 触发点击-然后移除
					// document.body.appendChild(aLink);
					// aLink.click();
					// document.body.removeChild(aLink);
					var aLink = document.createElement('a');
					var blob = this.base64ToBlob(this.base64_img); //new Blob([content]);
					var evt = document.createEvent("HTMLEvents");
					evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
					aLink.download = 'entry.png';
					aLink.href = URL.createObjectURL(blob);
					// aLink.dispatchEvent(evt);
					aLink.click()
					Dialog({
						message: '若下载失败请长按图片保存'
					})
				}
			},
			base64ToBlob(code) {
				var parts = code.split(';base64,');
				var contentType = parts[0].split(':')[1];
				var raw = window.atob(parts[1]);
				var rawLength = raw.length;

				var uInt8Array = new Uint8Array(rawLength);

				for (var i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		min-height: 100%;
		width: 100%;
		background-image: url(../assets/entry/entry_bg.jpg);
		background-size: 750px 1574px;
		overflow: scroll;
	}

	.head-img {
		width: 26px;
		height: 26px;
		display: none;
	}

	.entry-img {
		width: 710px;
		height: 1400px;
		display: none;
	}

	.entry-img2 {
		width: 144px;
		height: 260px;
		display: none;
	}

	.qrcode-img {
		width: 181px;
		height: 181px;
		display: none;
	}

	.share-img {
		width: 710px;
		height: 1400px;
	}

	.invite-poster {
		width: 710px;
		height: 1400px;
		display: block;
		margin: 24px auto 3px;
	}

	.btn {
		width: 321px;
		height: 128px;
		display: block;
		margin: auto;
	}
</style>
