<template>
	<div class="container">
		<div class="flex-column align-center" v-show="paySucc">
			<img src="../../assets/pay_suc_icon.png" class="pay-suc-icon" />
			<div class="succ-name">您已成功充值￥{{money}}元</div>
			<div class="flex-column align-center yue-sec">
				<div class="yue-title">账户余额：</div>
				<div class="yue-num">￥{{balance}}</div>
			</div>
			<div class="confirm-btn" @click="confirm">确认</div>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import uni from '@dcloudio/uni-webview-js';
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				paySucc: false,
				balance: '',
				money: '',
				count: 0
			}
		},
		methods: {
			getPayRes(redirect) {
				const that = this;
				that.getPayResInteval = setTimeout(() => {
					Toast.loading({
						message: '正在获取支付结果...',
						forbidClick: true,
						duration: 0,
					});
					that.count = that.count + 1;
					let orderid = that.orderInfo.id;
					let params = new FormData()
					params.append('id', that.orderInfo.id);
					params.append('type', 1);
					that.axios.post(config.requestUrl + '/front/financial/detailInfo', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (response.data.obj.status == 2) {
								this.paySucc = 1;
								Toast.success('支付成功')
								this.getWalletByCurrencyId();
								clearInterval(that.getPayResInteval)
								if (redirect) {
									this.$router.replace('/balance')
								}
							} else {
								that.getPayRes(redirect);
							}
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})

				}, 2000)

			},
			getWalletByCurrencyId() {
				const that = this;
				let params = new FormData()
				params.append('currencyId', 1)
				that.axios.post(config.requestUrl + '/front/financial/getWalletByCurrencyId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.balance = response.data.obj.using;
						console.log(response)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			confirm() {
				uni.reLaunch({
					url: '/pages/member/member'
				});
			},
			unloadHandler(e) {
				alert(111)
			}
		},
		beforeRouteLeave(to, from, next) {
			alert('leave')
			uni.reLaunch({
				url: '/pages/member/member'
			})
			next(false)
		},
		destroyed() {
			alert('ddd')
		},
		mounted() {
			window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
			window.addEventListener('unload', e => this.unloadHandler(e))
			util.wxConfig();
			if (this.$route.query.token) {
				this.$store.commit('setToken', this.$route.query.token)
				let user = {}
				if (localStorage.getItem("user")) {
					user = JSON.parse(localStorage.getItem("user"))
					user.token = this.$route.query.token;
				} else {
					user = {
						token: this.$route.query.token
					}
				}
				localStorage.setItem('user', JSON.stringify(user))
			}
			if (this.$route.query.orderId) {
				this.orderInfo = {
					id: this.$route.query.orderId
				}
				this.getPayRes(0);
			} else {
				this.getWalletByCurrencyId();
				this.paySucc = true;
			}
			this.money = this.$route.query.money
		}
	}
</script>

<style scoped="scoped">
	.pay-suc-icon {
		width: 200px;
		height: 200px;
		margin-top: 100px;
	}

	.succ-name {
		font-size: 28px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 40px;
		margin-top: 50px;
	}

	.yue-sec {
		margin-top: 40px;
		width: 499px;
		height: 229px;
		background: #FFFFFF;
		border-radius: 20px;
		border: 1px solid #F7B500;
	}

	.yue-title {
		width: 499px;
		height: 82px;
		background: #FFEADC;
		border-radius: 20px 20px 0px 0px;
		font-size: 26px;
		color: #000000;
		line-height: 73px;
		text-align: center;
	}

	.yue-num {
		line-height: 140px;
		font-size: 60px;
		font-weight: 600;
		color: #000000;
		text-align: center;
	}

	.confirm-btn {
		width: 590px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #6236FF;
		position: absolute;
		bottom: 170px;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		text-align: center;
	}
</style>
