<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">提现</div>
		</div>
		<div class="jindu-sec">
			<div class="flex-row align-center">
				<div class="icon1"></div>
				<div class="con-active">发起提现申请</div>
			</div>
			<div class="xian"></div>
			<div class="flex-row align-center">
				<div class="icon2"></div>
				<div class="con-active">1个工作日内审核</div>
			</div>
			<div class="xian"></div>
			<div class="flex-row align-center">
				<div class="icon3"></div>
				<div class="con">到账成功</div>
			</div>
		</div>
		<div class="flex-row align-center space-between item mt40">
			<div class="item-title">提现金额：</div>
			<div class="item-con">￥{{money}}</div>
		</div>
		<div class="flex-row align-center space-between item mt27">
			<div class="item-title">提现至：</div>
			<!-- <div class="item-con">{{payType == 2?'支付宝':payType==3?'银行卡':''}}</div> -->
			<div class="item-con">银行卡</div>
		</div>
		<div class="confirm-btn" @click="confirm()">确认</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	export default {
		data() {
			return {
				money: '',
				payType: ''
			}
		},
		mounted() {
			this.money = this.$route.query.money;
			this.payType = this.$route.query.payType;
			util.wxConfig();
		},
		methods: {
			confirm() {
				this.$router.back();
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		min-height: 100%;
		background: #FFFFFF;
	}

	.jindu-sec {
		width: 633px;
		height: 435px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
		margin: 0 auto;
		padding: 73px 0 0 30px;
		box-sizing: border-box;
	}

	.icon1 {
		width: 38px;
		height: 38px;
		background: #6DD400;
		border-radius: 50%;
	}

	.icon2 {
		width: 38px;
		height: 38px;
		background: #0091FF;
		border-radius: 50%;
	}

	.icon3 {
		width: 38px;
		height: 38px;
		background: #E7E7E7;
		border-radius: 50%;
	}

	.con-active {
		font-size: 30px;
		color: #000000;
		line-height: 42px;
		margin-left: 45px;
	}

	.con {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 42px;
		margin-left: 45px;
	}

	.xian {
		width: 1px;
		height: 77px;
		border-left: 1px dashed #979797;
		margin-left: 18px;
	}

	.item {
		padding: 0 63px 0 54px;
	}

	.item-title {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 42px;
	}

	.item-con {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
	}

	.mt40 {
		margin-top: 40px;
	}

	.mt27 {
		margin-top: 27px;
	}

	.confirm-btn {
		width: 590px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #6236FF;
		position: absolute;
		bottom: 170px;
		left: 0;
		right: 0;
		margin: 0 auto;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		text-align: center;
	}
</style>
