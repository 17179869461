<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">销毁</div>
		</div>

		<div class="header flex-row align-center">
			<div class="tab flex-column align-center justify-center" @click="changeTab(1)">
				<div class="tab-test">优先购</div>
				<div class="tab-line" v-if="tab == 1"></div>
			</div>
			<div class="tab flex-column align-center justify-center" @click="changeTab(2)">
				<div class="tab-test">推广卡</div>
				<div class="tab-line" v-if="tab == 2"></div>
			</div>
			<div class="tab flex-column align-center justify-center" @click="changeTab(3)">
				<div class="tab-test">实物</div>
				<div class="tab-line" v-if="tab == 3"></div>
			</div>
		</div>
		<div class="empty-text" v-if="tab == 1 && list.length == 0 && request">没有可销毁的藏品</div>
		<div class="empty-text" v-if="tab == 2 && exList.length == 0 && exRequest">没有可销毁的藏品</div>
		<div class="empty-text" v-if="tab == 3 && entryList.length == 0 && entryRequest">没有可销毁的藏品</div>
		<div class="item-wrap flex-row align-center flex-wrap" v-if="tab == 1">
			<div class="item flex-column" v-for="(item,index) in list" v-bind:key="index">
				<div class="goods-num">x {{item.userHaveQuantity}}</div>
				<img :src="item.productPic" class="goods-img" alt="">
				<div class="goods-name">{{item.productName}}</div>
				<div class="flex-row align-center yxg-wrap">
					<div class="yxg-left flex-row align-center">
						<div class="yxg-ti">优先购</div>
						<div class="yxg-num">{{item.perBuyCount}}个</div>
					</div>
					<div class="btn" @click.stop="preDestroy(index)">销毁</div>
				</div>
			</div>
		</div>
		<div class="item-wrap flex-row align-center flex-wrap" v-if="tab == 2">
			<div class="item flex-column" v-for="(item,index) in exList" v-bind:key="index">
				<div class="goods-num">x {{item.userHaveQuantity}}</div>
				<img :src="item.productPic" class="goods-img" alt="">
				<div class="goods-name">{{item.productName}}</div>
				<div class="flex-row align-center yxg-wrap">
					<div class="yxg-left flex-row align-center">
						<div class="yxg-ti">推广卡</div>
						<div class="yxg-num">{{item.rebateQuota}}个</div>
					</div>
					<div class="btn" @click.stop="preDestroyEx(index)">销毁</div>
				</div>
			</div>
		</div>
		<div class="item-wrap flex-row align-center flex-wrap" v-if="tab == 3">
			<div class="item flex-column" v-for="(item,index) in entryList" v-bind:key="index">
				<img :src="item.targetProductPic" class="goods-img" alt="">
				<div class="goods-name">{{item.targetProductName}}</div>
				<div class="flex-row align-center yxg-wrap ">
					<!-- <div class="yxg-left flex-row align-center">
						<div class="yxg-ti2">{{item.targetProductName}}</div>
					</div> -->
					<div class="yxg-ti2"></div>
					<div class="btn" @click.stop="preDestroyEntry(index)">销毁</div>
				</div>
			</div>
		</div>
		<div class="order-btn-wrap flex-column align-center" @click.stop="navOrder()">
			<div class="btn-text">查</div>
			<div class="btn-text">看</div>
			<div class="btn-text">订</div>
			<div class="btn-text">单</div>
		</div>

		<div class="model" v-if="addressModal" @click.stop="closeAddressModal()"></div>
		<div class="address-modal" v-if="addressModal">
			<div class="am-title-wrap flex-row justify-center align-center">
				<div class="am-title">填写地址</div>
				<div class="am-save-btn "
					:class="(!editUserAddress.name || !editUserAddress.address || !editUserAddress.phone || !editUserAddress.desc || !editUserAddress.code)?'am-save-btn-false':''"
					@click.stop="saveAddress()">保存</div>
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">联系人</div>
				<input type="text" v-model="editUserAddress.name" placeholder="联系人" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">手机号码</div>
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="editUserAddress.phone" placeholder="手机号码" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center" @click="editCity()">
				<div class="am-item-ti">地址信息</div>
				<input type="text" v-model="editUserAddress.address" disabled placeholder="地址信息" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">详细地址</div>
				<input type="text" v-model="editUserAddress.desc" placeholder="详细地址" class="am-item-input">
			</div>
			<div class="am-item flex-row align-center">
				<div class="am-item-ti">邮政编码</div>
				<input type="text" v-model="editUserAddress.code" placeholder="邮政编码" class="am-item-input">
			</div>
		</div>
		<div class="model" v-if="chooseAddressModal" @click.stop="closeChooseAddressModal()"></div>
		<div class="choose-address-modal" v-if="chooseAddressModal">
			<div class="am-title-wrap flex-row justify-center align-center">
				<div class="am-title">选择地区</div>
			</div>
			<div class="ca-con-wrap">
				<div class="flex-row align-center choose-con flex-wrap " v-if="areaArr.provinceIndex>=0">
					<div class="choose-con-item" @click.stop="changeAreaStep(1)">
						{{areaArr.provinceIndex>=0?areaArr.province[areaArr.provinceIndex].name:''}}
					</div>
					<div class="choose-con-item" @click.stop="changeAreaStep(2)"
						:class="areaArr.provinceIndex>=0 &&areaArr.cityIndex ==-1?'choose-con-item-false':''">
						{{areaArr.provinceIndex>=0 &&areaArr.cityIndex ==-1?'市':areaArr.city[areaArr.cityIndex].name}}
					</div>
					<div class="choose-con-item" v-if="areaArr.cityIndex>=0" @click.stop="changeAreaStep(3)"
						:class="areaArr.cityIndex>=0 &&areaArr.areaIndex ==-1?'choose-con-item-false':''">
						{{areaArr.cityIndex>=0 &&areaArr.areaIndex ==-1?'区/县':areaArr.area[areaArr.areaIndex].name}}
					</div>
					<div class="choose-con-item" v-if="areaArr.areaIndex>=0" @click.stop="changeAreaStep(4)"
						:class="areaArr.areaIndex>=0 &&areaArr.streetIndex ==-1?'choose-con-item-false':''">
						{{areaArr.areaIndex>=0 &&areaArr.streetIndex ==-1?'街道/镇':areaArr.street[areaArr.streetIndex].name}}
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==1">
					<div class="addr-con-item flex-row align-center space-between"
						v-for="(item,index) in areaArr.province" :key="index" @click.stop="chooseProvice(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.provinceIndex" src="../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==2">
					<div class="addr-con-item flex-row align-center space-between" v-for="(item,index) in areaArr.city"
						:key="index" @click.stop="chooseCity(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.cityIndex" src="../assets/points/icon-choose.png" class="icon-choose"
							alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==3">
					<div class="addr-con-item flex-row align-center space-between" v-for="(item,index) in areaArr.area"
						:key="index" @click.stop="chooseArea(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.areaIndex" src="../assets/points/icon-choose.png" class="icon-choose"
							alt="">
					</div>
				</div>
				<div class="addr-con " :class="areaArr.provinceIndex<0?'addr-con2':''" v-if="areaArr.step==4">
					<div class="addr-con-item flex-row align-center space-between"
						v-for="(item,index) in areaArr.street" :key="index" @click.stop="chooseStreet(index)">
						<div>{{item.name}}</div>
						<img v-if="index== areaArr.streetIndex" src="../assets/points/icon-choose.png"
							class="icon-choose" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="model" v-if="dhModal3"></div>
		<div class="dh-modal3 flex-column" v-if="dhModal3">
			<div class="dh-m-header flex-row align-center justify-center">
				<div class="dh-m-header-ti">我的地址</div>
				<div class="dh-m-header-edit" @click.stop="editAddr()">修改地址</div>
			</div>
			<div class="dh-m-center flex-column">
				<div class="dh-addr-info" v-if="userAddress.addressPhone">
					<div class="ah-a-h flex-row align-center">
						<div class="dh-a-h-ti">{{userAddress.addressName}}</div>
						<div class="dh-a-h-ph">{{userAddress.addressPhone}}</div>
					</div>
					<div class="ah-ad-h-c">
						{{userAddress.province}}{{userAddress.city}}{{userAddress.area}}{{userAddress.street}}{{userAddress.address}}
					</div>
				</div>
				<div class="dh-tips mt0">销毁{{entryList[entryIndex].destProductName}}换取</div>
				<div class="dh-points">《{{entryList[entryIndex].targetProductName}}》</div>
				<div class="dh-line1"></div>
				<div class="dh-btn-wrap flex-row align-center space-between">
					<div class="dh-btn-w flex-row align-center justify-center" @click.stop="closeDhModal3()">
						<div class="dh-btn-t">取消</div>
					</div>
					<div class="dh-btn-w flex-row align-center justify-center" @click.stop="destroyEntry()">
						<div class="dh-btn-t">确认</div>
					</div>
				</div>
			</div>
			<div class="dh-m-footer flex-row"></div>
		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';

	export default {
		data() {
			return {
				list: [],
				request: 0,
				tab: 1,
				exList: [],
				exRequest: 0,
				entryList: [],
				entryRequest: 0,
				addressModal: 0,
				chooseAddressModal: 0,
				resModal2: 0,
				dhModal3: 0,
				userAddress: {

				}, //用户地址
				editUserAddress: {
					name: '',
					phone: '',
					address: '',
					desc: '',
					code: ''
				},
				areaArr: {
					province: [],
					provinceIndex: -1,
					city: [],
					cityIndex: -1,
					area: [],
					areaIndex: -1,
					street: [],
					streetIndex: -1,
					step: 1
				},
				entryIndex: '',
			}
		},
		methods: {
			changeTab(tab) {
				this.tab = tab;
			},
			getExtension() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/destRebate/getDestroyConfigListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.exList = response.data.obj
						that.exRequest = 1;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			preDestroyEx(index) {
				// this.$router.push('/destroysuccess');
				const that = this;
				let params = new FormData()
				params.append('id', that.exList[index].id)
				this.axios.post(config.requestUrl + '/front/destRebate/checkCanDestroy', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.destroyEx(index)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			destroyEx(index) {
				const that = this;
				let id = that.exList[index].id
				Dialog.confirm({
						title: '销毁提示',
						message: `是否确定销毁一张“${that.exList[index].productName}”换取 ${that.exList[index].rebateQuota}个推广卡配额？`,
					})
					.then(() => {
						Toast.loading({
							message: '销毁中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						params.append('id', id)
						that.axios.post(config.requestUrl + '/front/destRebate/doDestroy', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								Toast.clear()
								that.getExtension();
								that.$router.push({
									path: '/destroysuccess',
									query: {
										name: that.exList[index].productName,
										num: that.exList[index].rebateQuota,
										type: 2
									}
								})
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			getDestroyConfigListByUserId() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/destroy/getDestroyConfigListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.list = response.data.obj
						that.request = 1;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			preDestroy(index) {
				const that = this;
				let params = new FormData()
				params.append('id', that.list[index].id)
				this.axios.post(config.requestUrl + '/front/destroy/checkCanDestroy', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.destroy(index)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			destroy(index) {
				const that = this;
				let id = that.list[index].id
				Dialog.confirm({
						title: '销毁提示',
						message: `是否确定销毁一张“${that.list[index].productName}”换取 ${that.list[index].perBuyCount}个优先购配额？`,
					})
					.then(() => {

						Toast.loading({
							message: '销毁中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						params.append('id', id)
						that.axios.post(config.requestUrl + '/front/destroy/doDestroy', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								that.getDestroyConfigListByUserId();
								Toast.clear()
								that.$router.push({
									path: '/destroysuccess',
									query: {
										name: that.list[index].productName,
										num: that.list[index].perBuyCount,
										type: 1
									}
								})
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			getEntry() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/destPhysical/getDestroyConfigListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.entryList = response.data.obj
						that.entryRequest = 1;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			preDestroyEntry(index) {
				const that = this;
				let params = new FormData()
				params.append('id', that.entryList[index].id)
				this.axios.post(config.requestUrl + '/front/destPhysical/checkCanDestroy', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.dhModal3 = 1;
						that.entryIndex = index;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			destroyEntry() {
				const that = this;
				let params = new FormData()
				params.append("id", that.entryList[that.entryIndex].id)
				this.axios.post(config.requestUrl + '/front/destPhysical/doDestroy', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear()
						that.dhModal3 = false;
						that.getEntry();
						that.$router.push({
							path: '/destroysuccess',
							query: {
								name: that.entryList[that.entryIndex].destProductName,
								num: that.entryList[that.entryIndex].targetProductName,
								type: 3
							}
						})
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			saveAddress() {
				const that = this;
				console.log(that.areaArr)
				if (!that.editUserAddress.name) {
					Toast('请输入姓名');
					return;
				}
				if (!that.editUserAddress.phone) {
					Toast('请输入手机号码');
					return;
				}
				if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.editUserAddress.phone))) {
					Toast('请输入正确的手机号')
					return;
				}
				if (!that.editUserAddress.address) {
					Toast('请输入地址信息');
					return;
				}
				if (!that.editUserAddress.desc) {
					Toast('请输入详细地址');
					return;
				}
				if (!that.editUserAddress.code) {
					Toast('请输入邮政编码');
					return;
				}
				let data = {
					addressName: that.editUserAddress.name,
					address: that.editUserAddress.desc,
					addressPhone: that.editUserAddress.phone,
					provinceId: that.areaArr.province[that.areaArr.provinceIndex].id,
					cityId: that.areaArr.city[that.areaArr.cityIndex].id,
					areaId: that.areaArr.area[that.areaArr.areaIndex].id,
					streetId: that.areaArr.street[that.areaArr.streetIndex].id,
					zip: that.editUserAddress.code,
				}
				let url = '/user-api/rest/userAddress/insertUserAddress'
				if (that.userAddress.addressPhone) {
					data.userAddressId = that.userAddress.userAddressId;
					url = '/user-api/rest/userAddress/updateUserAddress'
				}
				that.axios.post(config.pointsRequest +
					url,
					data, {
						headers: {
							'Content-Type': 'application/json',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						Toast('保存成功')
						that.selectUserAddress();
						that.addressModal = 0;
						that.buy();
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			changeAreaStep(step) {
				if (step != this.areaArr) {
					if (step == 2) {
						if (this.areaArr.provinceIndex >= 0) {
							this.areaArr.step = 2;
						}
					} else if (step == 3) {
						if (this.areaArr.cityIndex >= 0) {
							this.areaArr.step = 3;
						}
					} else if (step == 4) {
						if (this.areaArr.areaIndex >= 0) {
							this.areaArr.step = 4;
						}
					} else {
						this.areaArr.step = 1;
					}
				}
			},
			chooseProvice(index) {
				const that = this;
				this.areaList(this.areaArr.province[index].id, 2, (() => {
					if (that.areaArr.provinceIndex != index) {
						that.areaArr.cityIndex = -1;
						that.areaArr.areaIndex = -1;
						that.areaArr.streetIndex = -1
					}
					that.areaArr.provinceIndex = index;
					that.areaArr.step = 2;
				}))
			},
			chooseCity(index) {
				const that = this;
				this.areaList(this.areaArr.city[index].id, 3, (() => {
					if (that.areaArr.cityIndex != index) {
						that.areaArr.areaIndex = -1;
						that.areaArr.streetIndex = -1
					}
					that.areaArr.cityIndex = index;
					that.areaArr.step = 3;
				}))
			},
			chooseArea(index) {
				const that = this;
				this.areaList(this.areaArr.area[index].id, 4, (() => {
					if (that.areaArr.areaIndex != index) {
						that.areaArr.streetIndex = -1
					}
					that.areaArr.areaIndex = index;
					that.areaArr.step = 4;
				}))
			},
			chooseStreet(index) {
				const that = this;
				that.areaArr.streetIndex = index;
				this.chooseAddressModal = 0
				this.addressModal = 1;
				this.editUserAddress.address = '' + that.areaArr.province[that.areaArr.provinceIndex].name +
					that.areaArr.city[that.areaArr.cityIndex].name + that.areaArr.area[that.areaArr.areaIndex].name +
					that.areaArr.street[that.areaArr.streetIndex].name

			},
			closeAddressModal() {
				this.addressModal = 0
			},
			closeChooseAddressModal() {
				this.chooseAddressModal = 0
			},
			editCity() {
				this.addressModal = 0
				this.chooseAddressModal = 1;
				this.step = 1;
				this.areaList(100000, 1)
			},
			areaList(parentId, level, cb) {
				const that = this;
				that.axios.post(config.pointsRequest +
					`/base-api/rest/baseArea/selectList`,
					parentId, {
						headers: {
							'Content-Type': 'text/xml',
							'token': that.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						switch (level) {
							case 1:
								that.areaArr.province = response.data.data;
								break;
							case 2:
								that.areaArr.city = response.data.data;
								break;
							case 3:
								that.areaArr.area = response.data.data;
								break;
							case 4:
								that.areaArr.street = response.data.data;
								break;
							default:
								break;
						}
						if (cb) cb();
					} else {
						that.error = true
						Toast(response.data.msg);
					}
				}, response => {
					that.error = true
					Toast('获取失败，请稍后重试');
				})
			},
			editAddr() {
				const that = this;
				if (this.userAddress.addressPhone && !this.editUserAddress.phone) {
					console.log(0)
					this.editUserAddress = {
						name: this.userAddress.addressName,
						phone: this.userAddress.addressPhone,
						address: this.userAddress.province + this.userAddress.city + this.userAddress.area + this
							.userAddress.street,
						desc: this.userAddress.address,
						code: this.userAddress.zip
					}
					this.areaList(100000, 1, (() => {
						for (let i in that.areaArr.province) {
							if (that.areaArr.province[i].id == that.userAddress.provinceId) {
								that.areaArr.provinceIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.provinceId, 2, (() => {
						for (let i in that.areaArr.city) {
							if (that.areaArr.city[i].id == that.userAddress.cityId) {
								that.areaArr.cityIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.cityId, 3, (() => {
						for (let i in that.areaArr.area) {
							if (that.areaArr.area[i].id == that.userAddress.areaId) {
								that.areaArr.areaIndex = i;
								continue;
							}
						}
					}))
					this.areaList(this.userAddress.areaId, 4, (() => {
						for (let i in that.areaArr.street) {
							if (that.areaArr.street[i].id == that.userAddress.streetId) {
								that.areaArr.streetIndex = i;
								continue;
							}
						}
					}))
					this.dhModal3 = 0;
					this.addressModal = 1;
				} else {
					this.dhModal3 = 0;
					this.addressModal = 1;
				}

			},
			selectUserAddress() {
				const that = this;
				let params = new FormData()
				that.axios.post(config.pointsRequest + '/user-api/rest/userAddress/selectUserAddress', '', {
					headers: {
						'Content-Type': 'application/json',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.data) {
							that.userAddress = response.data.data
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			closeDhModal3() {
				this.dhModal3 = false;
			},
			navOrder() {
				this.$router.push('/destroyorder')
			},
			navLog() {
				this.$router.push('/destroylog')
			}
		},
		beforeRouteEnter(to, from, next) {
			console.log(to);
			console.log(from)
			if (from.name == 'member' || from.name == null) {
				console.log(111)
				next(that => {
					that.tab = 1;
					that.getDestroyConfigListByUserId();
					that.getExtension();
					that.getEntry();
					that.selectUserAddress();
					that.dhModal3 = false;
				})
			} else {
				next();
			}
		},
		activated() {
			console.log('actived')
		},
		mounted() {
			this.selectUserAddress();
		},
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		min-height: 100%;
		background: #F4F4F4;
	}

	.item-wrap {
		width: 710px;
		margin: 30px auto 0;
	}

	.item {
		position: relative;
		width: 335px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 10px;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.14);
		border-radius: 10px;
		margin: 0 10px 20px;
	}

	.goods-num {
		position: absolute;
		left: 0%;
		top: 0;
		padding: 9px 34px 9px 18px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.85);
		background: rgba(0, 0, 0, 0.5);
		border-radius: 20px 0px 48px 0px;
	}

	.goods-img {
		width: 335px;
		height: 440px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.14);
		border-radius: 10px 10px 0px 0px;
	}

	.goods-name {
		margin: 30px 0 20px 30px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.postion {
		margin-left: 30px;
		font-size: 24px;
		font-weight: 400;
		color: #6D7278;
	}

	.yxg-wrap {
		margin: 40px 0 0 30px;
		padding-bottom: 50px;
	}

	.yxg-left {
		width: max-content;
		height: 45px;
		/* background: #F6F2FF; */
		background: rgb(246, 242, 255);
		border: 2px solid rgb(246, 242, 255);
		border-radius: 23px;
		overflow: hidden;
		box-sizing: border-box;
	}

	.yxg-ti {
		width: 93px;
		height: 45px;
		background: rgb(246, 242, 255);
		font-size: 24px;
		font-weight: 400;
		color: #6236FF;
		text-align: center;
		line-height: 45px;
	}

	.yxg-ti2 {
		width:150px;
		height: 45px;
		font-size: 24px;
		font-weight: 400;
		color: #6236FF;
		text-align: center;
		line-height: 45px;
	}

	.yxg-num {
		padding: 0 14px 0 10px;
		height: 43px;
		font-size: 24px;
		font-weight: 400;
		color: #6236FF;
		line-height: 43px;
		background: #FFFFFF;
	}

	.btn {
		width: 110px;
		height: 45px;
		margin-left: 8px;
		background: #6236FF;
		border-radius: 23px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 45px;
		text-align: center;
	}

	.zd-btn {
		width: 105px;
		height: 49px;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 25px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 49px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 30px;
		bottom: 0;
		margin: auto;
	}

	.empty-text {
		font-size: 30px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		text-align: center;
		margin-top: 621px;
	}

	.header {
		width: 750px;
		height: 88px;
		background: #FFFFFF;
		/* background: #000000; */
	}

	.tab {
		width: 375px;
		height: 88px;
		position: relative;
		box-sizing: border-box;
		margin: 10px auto;
		background: #FFFFFF;
	}

	.tab-test {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
		letter-spacing: 2px;
	}

	.tab-line {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 80px;
		height: 6px;
		background: #000000;
		border-radius: 4px;
	}

	.order-btn-wrap {
		position: fixed;
		right: 0;
		/* top: 0; */
		/* bottom: 0; */
		bottom: 250px;
		margin: auto;
		z-index: 10;
		width: 50px;
		height: max-content;
		padding: 11px 0;
		box-sizing: border-box;
		border-radius: 10px 0px 0px 10px;
		background: #000000;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.ch-wrap {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 100;
		width: 750px;
		height: 98px;
		background: #FFFFFF;
		box-shadow: -1px 0px 0px 0px #FFFFFF;
		border-radius: 20px 20px 0 0;
		overflow: hidden;
	}

	.ch-left {
		width: 375px;
		height: 98px;
		font-size: 30px;
		font-weight: 600;
		line-height: 98px;
		text-align: center;
		color: #FFFFFF;
		background: #AF9270;
	}

	.ch-right {

		width: 373px;
		height: 98px;
		background: #AF9270;
	}

	.ch-btn-wrap {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #FFFFFF;
		background: #AF9270;

	}

	.ch-btn {
		width: 190px;
		height: 56px;
		background: #FFFFFF;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #AF9270;
		text-align: center;
		line-height: 59px;
	}

	.dh-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
		width: 610px;
		height: 839px;
		background-image: url(../assets/points/modal-bg.png);
		background-size: 610px 839px;
	}

	.dh-tips {
		margin-top: 94px;
		width: 610px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		text-align: center;
	}

	.dh-points {
		width: 296px;
		height: 77px;
		margin: 19px auto 0;
		background: #F3EEE5;
		border-radius: 39px;
		text-align: center;
		line-height: 77px;
		font-size: 30px;
		font-weight: 600;
		color: #937757;
	}

	.dh-line1 {
		width: 530px;
		height: 1px;
		background: rgba(0, 0, 0, 0.1000);
		margin: 70px auto 51px;
	}

	.dh-tips2 {
		width: 610px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		text-align: center;
	}

	.dh-user-points {
		width: 296px;
		height: 77px;
		margin: 19px auto 0;
		background: rgba(98, 54, 255, 0.0600);
		border-radius: 39px;
		text-align: center;
		line-height: 77px;
		font-size: 30px;
		font-weight: 600;
		color: #6236FF;
	}

	.dh-line2 {
		width: 595px;
		height: 2px;
		background: #B29574;
		margin: 60px auto 30px;
	}

	.dh-xy {
		width: max-content;
		padding: 11px 35px;
		margin: auto;
		background: linear-gradient(90deg, #FFFFFF 0%, #FDE9CC 50%, #FFFFFF 100%);
		border-radius: 25px;
		font-size: 20px;
		font-weight: 400;
		color: #FA6400;
	}

	.dh-btn-wrap {
		width: 450px;
		margin: 56px auto 0
	}

	.dh-btn-w {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #B29574;
	}

	.dh-btn-t {
		width: 190px;
		height: 56px;
		text-align: center;
		line-height: 56px;
		background: #B29574;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.res-modal {
		position: fixed;
		top: 94px;
		left: 0;
		right: 0;
		z-index: 1001;
		margin: auto;
		width: 548px;
		height: max-content;
		max-height: 100%;
		overflow: scroll;
	}

	.res-con {
		width: 548px;
		height: 1010px;
		background-image: url(../assets/points/res-bg.png);
		background-size: 548px 1010px;
		overflow: hidden;
	}

	.res-goods {
		margin-top: 481px;
		margin-bottom: 28px;
		width: 548px;
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		color: #000000;
	}

	.res-tips {
		width: 548px;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
	}

	.res-tips2 {
		width: 452px;
		height: 50px;
		margin: 27px auto 0;
		background: linear-gradient(90deg, #FFFFFF 0%, #FDE9CC 50%, #FFFFFF 100%);
		border-radius: 25px;
		text-align: center;
		line-height: 50px;
	}

	.res-btn-wrap {
		width: 450px;
		margin: 52px auto;
	}

	.res-btn-w {
		width: 210px;
		height: 74px;
		border-radius: 37px;
		border: 2px solid #B29574;
	}

	.res-btn-t {
		width: 190px;
		height: 56px;
		background: #B29574;
		border-radius: 31px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 56px;
	}

	.c-wrap {
		width: 650px;
		padding: 0 20px;
	}

	.c-wrap>>>img {
		max-width: 100% !important;
		height: auto !important;
	}

	.address-modal {
		width: 750px;
		height: 776px;
		background: #ffffff;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1001;
	}

	.am-title-wrap {
		width: 640px;
		height: 88px;
		margin: auto;
		border-bottom: 1px solid #BFA88D;
		position: relative;
	}

	.am-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.am-save-btn {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 10px;
		margin: auto;
		width: 100px;
		height: 50px;
		font-size: 26px;
		font-weight: 500;
		color: #FFFFFF;
		text-align: center;
		line-height: 50px;
		background: #BEA78C;
		color: #ffffff;
		border-radius: 8px;
	}

	.am-save-btn-false {
		background: rgba(0, 0, 0, 0.25);
	}

	.am-item {
		width: 650px;
		height: 110px;
		margin: auto;
		box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.am-item-ti {
		width: 177px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.am-item-input {
		width: 470px;
		font-size: 28px;
		border: none;
		background: initial;
	}


	.res-modal2 {
		position: fixed;
		top: 94px;
		left: 0;
		right: 0;
		z-index: 1001;
		margin: auto;
		width: 548px;
		height: max-content;
		max-height: 100%;
		overflow: scroll;
	}

	.res-con2 {
		width: 548px;
		height: 1010px;
		background-image: url(../assets/points/res-bg2.png);
		background-size: 548px 1010px;
		overflow: hidden;
	}

	.res-goods2 {
		margin-top: 481px;
		margin-bottom: 28px;
		width: 548px;
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		color: #000000;
	}

	.res-goods-img {
		width: 286px;
		height: 315px;
		display: block;
		margin: 34px auto 0;
	}

	.choose-address-modal {
		width: 750px;
		height: 776px;
		background: #ffffff;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1001;
	}

	.ca-con-wrap {
		width: 640px;
		margin: auto;
	}

	.choose-con {
		height: 100px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.choose-con-item {
		/* height: 100px; */
		margin-right: 40px;
		/* line-height: 100px; */
		font-size: 28px;
		font-weight: 600;
		color: #000000;

	}

	.choose-con-item:last-child {
		margin-right: 0;
	}

	.choose-con-item-false {
		color: rgba(0, 0, 0, 0.25);
	}

	.addr-con {
		height: 590px;
		overflow: scroll;
	}

	.addr-con-item {
		margin: auto;
		width: 640px;
		height: 100px;
		box-sizing: border-box;
		padding: 0 10px;
		background: #FFFFFF;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.addr-con2 {
		height: 690px;
	}

	.dh-modal3 {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
		width: 610px;
		height: max-content;
		overflow: scroll;
		max-height: 90%;
	}

	.dh-m-center {
		width: 610px;
		height: max-content;
		background-image: url(../assets/points/modal-bg2.png);
		background-size: 610px 32px;
		background-repeat: repeat-y;

	}

	.dh-m-footer {
		width: 610px;
		height: 90px;
		background-image: url(../assets/points/modal-bg.png);
		background-size: 610px 839px;
		background-position: 0 90px;

	}

	.dh-m-header {
		width: 611px;
		height: 122px;
		background-image: url(../assets/points/modal-bg.png);
		background-size: 610px 839px;
		/* background-position: 0 90px; */

	}

	.dh-m-header-ti {
		font-size: 26px;
		font-weight: 600;
		color: #B29574;
	}

	.dh-m-header-edit {
		padding: 6px 11px;
		background: #F3EEE5;
		border-radius: 18px;
		font-size: 16px;
		font-weight: 400;
		color: #866B4B;
		position: absolute;
		top: 40px;
		right: 57px;
	}

	.dh-addr-info {
		width: 408px;
		padding: 0 0 46px 0;
		margin: auto;
	}

	.dh-a-h-ti {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		margin-right: 20px;
	}

	.dh-a-h-ph {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.ah-ad-h-c {
		margin-top: 20px;
	}

	.mt0 {
		margin-top: 0;
	}

	.am-item-input-false {
		color: #cccccc;
	}

	.icon-choose {
		width: 38px;
		height: 38px;
	}
</style>
