<template>
	<div class="container">
		<div class="model" v-if="confirmModal" @click.stop="cancelConfirmModal()"></div>
		<div class="confirm-wrap flex-column align-center" v-if="confirmModal">
			<div class="confirm-title">风险提示</div>
			<div class="confirm-goods-name">{{goods.name == '盲盒'? goods.name +'('+goods.productEntryNo+')':goods.name}}
			</div>
			<div class="confirm-goods-name">{{orderInfo.payAmount}}元</div>
			<div class="confirm-content" style="margin-bottom: 0;padding-bottom: 0;">
				请再次确认您所购买的商品和价格是否正确？
			</div>
			<div class="confirm-content" style="margin-top: 5px;font-weight: bold;">
				您作为买家，正在向卖家购买商品，付款后不可退货，交易风险全部由买家承担。平台仅提供技术服务，并且单方面向卖家收取服务费，不向买家收费。
			</div>
			<div class="btn-wrap flex-row align-center">
				<div class="btn-left" @click.stop="goBack()">我再看看</div>
				<div class="btn-right" @click.stop="pay()">确认购买</div>
			</div>
		</div>
		<div class="good-sec-relative">
			<img src='../assets/bg1.png' class="bg1" />
			<div class="good-sec flex-row align-center">
				<div class="flex-row align-top">
					<div class="good-img-sec">
						<img v-if="goods.isOpen == '是'" :src='goods.productPic' class="good-img" />
						<img v-else src="../assets/manghe.png" class="good-img2" alt="">
					</div>
					<div class="good-right-sec">
						<div class="good-name">{{goods.name}}</div>
						<div class="good-num">{{goods.productEntryNo}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="item-sec">
			<div class="flex-row align-center space-between item-border item flex-wrap">
				<div class="item-title flex-shrink">购买须知：</div>
				<div class="item-con flex-shrink ">根据《消费者权益保护法》第二十五条规定，数字化商品不适用于七天无理由退货。</div>
			</div>
			<div class="flex-row align-center space-between item">
				<div class="item-title">当前价格：</div>
				<div class="good-price">￥{{goods.salePrice}}</div>
			</div>
		</div>
		<div class="pay-sec flex-row justify-center">
			<img src="../assets/pay_bg.png" class="pay-bg" />
			<div class="pay-con">
				<div class="pay-list">
					<div class="flex-row align-center space-between pay-item" @click.stop="changePayType(1)">
						<div class="flex-row align-center ">
							<img src='../assets/yue_icon.png' class="pay-icon" />
							<div class="flex-row align-center space-between text-wrap">
								<div class="pay-item-name">余额支付￥{{balance}}</div>
								<div class="pay-item-name2" @click.stop="navBill()">充值已开通</div>
							</div>
							<!-- <div class="pay-item-name">余额支付</div>
							<div class="pay-item-yue">（钱包余额：￥{{balance}}）</div> -->
						</div>
						<img v-if="payType== 1" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div>
					<!-- <div class="flex-row align-center space-between pay-item" @click.stop="changePayType(2)">
						<div class="flex-row align-center ">
							<img src='../assets/zfb_icon.png' class="pay-icon" />
							<div class="pay-item-name">支付宝支付</div>
						</div>
						<img v-if="payType== 2" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div> -->
					<!-- <div class="flex-row align-center space-between pay-item" @click.stop="changePayType(4)">
						<div class="flex-row align-center ">
							<img src='../assets/ysf.png' class="pay-icon" />
							<div class="pay-item-name">银联云闪付</div>
						</div>
						<img v-if="payType== 4" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div>
 -->
					<!-- <div class="flex-row align-center space-between pay-item" @click.stop="changePayType(5)">
						<div class="flex-row align-center ">
							<img src='../assets/ysf.png' class="pay-icon" />
							<div class="pay-item-name">银联云闪付H5支付</div>
						</div>
						<img v-if="payType== 5" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div> -->
					<!-- <div class="flex-row align-center space-between pay-item" @click.stop="changePayType(3)">
						<div class="flex-row align-center ">
							<img src='../assets/wechat_icon.png' class="pay-icon" />
							<div class="pay-item-name">微信支付</div>
						</div>
						<img v-if="payType== 3" class="select_icon" src="../assets/select_icon.png"/>
						<div v-else class="no-select"></div>
					</div> -->
					<!-- <div class="flex-row align-center space-between pay-item" @click.stop="changePayType(6)">
						<div class="flex-row align-center ">
							<img src='../assets/zfb_icon.png' class="pay-icon" />
							<div class="pay-item-name">支付宝支付</div>
						</div>
						<img v-if="payType== 6" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div> -->
					<!-- 		<div class="flex-row align-center space-between pay-item" @click.stop="changePayType(7)">
						<div class="flex-row align-center ">
							<img src='../assets/yl.png' class="pay-icon2" />
							<div class="pay-item-name">银联快捷</div>
						</div>
						<img v-if="payType== 7" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div> -->
				<!-- 	<div class="flex-row align-center space-between pay-item" @click.stop="changePayType(9)">
						<div class="flex-row align-center ">
							<img src='../assets/yl.png' class="pay-icon2" />
							<div class="pay-item-name">快捷支付(只需一次绑卡)</div>
						</div>
						<img v-if="payType== 9" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div> -->
					<div class="flex-row align-center space-between pay-item" @click.stop="changePayType(8)">
						<div class="flex-row align-center ">
							<img src='../assets/ysf.png' class="pay-icon" />
							<div class="pay-item-name">银联云闪付</div>
						</div>
						<img v-if="payType== 8" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div>
				<!-- 	<div class="flex-row align-center space-between pay-item" @click.stop="changePayType(11)">
						<div class="flex-row align-center ">
							<img src='../assets/yl.png' class="pay-icon2" />
							<div class="pay-item-name">快捷支付(只需一次绑卡)</div>
						</div>
						<img v-if="payType== 11" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div> -->
					<div class="flex-row align-center space-between pay-item" @click.stop="changePayType(12)">
						<div class="flex-row align-center ">
							<img src='../assets/yl.png' class="pay-icon2" />
							<div class="pay-item-name">快捷收银台</div>
						</div>
						<img v-if="payType== 12" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div>
					<!-- <div class="flex-row align-center space-between pay-item" @click.stop="changePayType(10)">
						<div class="flex-row align-center ">
							<img src='../assets/yb.png' class="pay-icon" />
							<div class="pay-item-name">易宝支付</div>
						</div>
						<img v-if="payType== 10" class="select_icon" src="../assets/select_icon.png" />
						<div v-else class="no-select"></div>
					</div> -->
				</div>
				<div class="flex-row align-center agreement-sec">
					<div class="agreement-pre">点击提交订单则视为同意</div>
					<div class="agreement-con" @click="navAgreement(3)">《用户协议》</div>
				</div>
				<div class="pay-grey-btn" v-if="payType == 1 && balance < goods.salePrice">余额不足</div>
				<div class="pay-btn" @click.stop="buy()" v-else>确认</div>
			</div>
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				payType: 1,
				balance: '',
				goodsPirce: 1211.11,
				paying: 0,
				goods: {
					id: '',
					name: '1',
					productPic: '',
					productEntryNo: '',
					salePrice: 12,
					isOpen: ''
				},
				orderInfo: {},
				confirmModal: 0,
				price: '',
				type:1,
				
			}
		},
		methods: {
			changePayType(payType) {
				this.payType = payType
			},
			goBack() {
				this.confirmModal = 0;
				this.$router.go(-1)
			},
			pay() {
				const that = this;
				if (that.orderInfo.payAmount <= 0) {
					return;
				}
				if (this.payType == 1) {
					that.getYePayInfo();
				} else if (this.payType == 2) {
					Toast.clear();
					this.$router.push({
						path: "/zfb",
						query: {
							orderId: this.orderInfo.id,
							token: this.$store.state.user.token,
							type: 1,
							name: that.goods.name
						}
					});
				} else if (this.payType == 4) {
					Toast.clear();
					this.$router.push({
						path: "/scanpay",
						query: {
							orderId: this.orderInfo.id,
							token: this.$store.state.user.token,
							type: 1,
							name: that.goods.name
						}
					});
				} else if (this.payType == 5) {
					Toast.clear();
					this.$router.push({
						path: "/ysf",
						query: {
							orderId: this.orderInfo.id,
							token: this.$store.state.user.token,
							type: 1,
							name: that.goods.name,
							goodsId: this.goods.id
						}
					});
				} else if (this.payType == 6) {
					Toast.clear();
					this.$router.push({
						path: "/sdpay",
						query: {
							orderId: this.orderInfo.id,
							token: this.$store.state.user.token,
							type: 1,
							name: that.goods.name
						}
					});
				} else if (this.payType == 7) {
					Toast.clear();
					this.$router.push({
						path: "/scansdpay",
						query: {
							orderId: this.orderInfo.id,
							token: this.$store.state.user.token,
							type: 1,
							name: that.goods.name
						}
					});
				} else if (this.payType == 8) {
					Toast.clear();
					this.$router.push({
						path: "/codesdpay",
						query: {
							orderId: this.orderInfo.id,
							token: this.$store.state.user.token,
							type: 1,
							name: that.goods.name
						}
					});
				} else
				if (this.payType == 9) {
					Toast.clear();
					this.$router.push({
						path: "/open",
						query: {
							orderId: this.orderInfo.id,
							token: this.$store.state.user.token,
							type: 1,
							name: that.goods.name
						}
					});
				} else if (this.payType == 10) {
					let params = new FormData()
					params.append('orderId', this.orderInfo.id);
					params.append('payType', 6);
					params.append('callback_url', config.host + 'paysuccess2?orderIds=' + that.orderInfo.id +
						'&token=' + that.$store.state.user.token + '&name=' + that.goods.name)
					that.axios.post(config.requestUrl + '/front/ybPay', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							window.location.href = response.data.obj
							console.log(response)
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				} else if (this.payType == 11) {
					const that = this;
					let params = new FormData()
					params.append('orderId', this.orderInfo.id);
					params.append('payType', 6)
					params.append('returnUrl', config.host + 'paysuccess?orderId=' + this.orderInfo.id +
						'&token=' + this
						.$store.state.user.token + '&name=' + that.goods.name + '&payType=11')
					params.append('callback_url', config.host + 'paysuccess?orderId=' + this.orderInfo.id +
						'&token=' +
						this
						.$store.state.user.token + '&name=' + that.goods.name + '&payType=11')
					this.axios.post(config.requestUrl + '/front/sandPay', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							Toast.clear();
							console.log(response)
							window.location.href = response.data.obj
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
							}
							Toast.fail(response.data.msg);
						}
					}, response => {
						Toast.fail('获取失败，请稍后重试');
					})
				} else if (this.payType == 12) {
					const that = this;
					let params = new FormData()
					params.append('orderId', this.orderInfo.id);
					params.append('payType', 7)
					params.append('returnUrl', config.host + 'paysuccess?orderId=' + this.orderInfo.id +
						'&token=' + this
						.$store.state.user.token + '&name=' + that.goods.name + '&payType=11')
					params.append('callback_url', config.host + 'paysuccess?orderId=' + this.orderInfo.id +
						'&token=' +
						this
						.$store.state.user.token + '&name=' + that.goods.name + '&payType=11')
					this.axios.post(config.requestUrl + '/front/sandPay', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							Toast.clear();
							console.log(response)
							window.location.href = response.data.obj
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
							}
							Toast.fail(response.data.msg);
						}
					}, response => {
						Toast.fail('获取失败，请稍后重试');
					})
				}
			},
			cancelConfirmModal() {
				this.confirmModal = 0;
			},
			buy() {
				const that = this;
				
				if(that.type==2){
					this.confirmModal=1;
					return;
				}
				if (that.paying) return;
				that.paying = 1;
				Toast.loading({
					message: '正在下单...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('consignmentId', that.goods.id);
				params.append('price', that.goods.salePrice);
				params.append('payType', that.payType == 1 ? 1 : 2);
				that.axios.post(config.requestUrl + '/front/order/createOrderByConsignment', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					console.log(response)
					if (response.data.code == 0) {
						Toast.clear()
						that.orderInfo = response.data.obj;
						that.confirmModal = 1;
						// else if (this.payType == 3) {

						// }
					} else if (response.data.code == -2001) {
						Toast.clear()
						this.$router.replace('/orderlist')
					} else {
						Toast(response.data.msg);
					}
					that.paying = 0
				}, response => {
					that.paying = 0
					Toast('获取失败，请稍后重试');
				})
			},
			getWalletByCurrencyId() {
				const that = this;
				let params = new FormData()
				params.append('currencyId', 1)
				that.axios.post(config.requestUrl + '/front/financial/getWalletByCurrencyId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.balance = response.data.obj.using;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getYePayInfo() {
				const that = this;
				let params = new FormData()
				params.append('orderId', this.orderInfo.id);
				params.append('bizType', 1)
				params.append('currencyId', 1)
				this.axios.post(config.requestUrl + '/front/accountBalancePay', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.payModel = false;
						Toast.success('支付成功')
						setTimeout(() => {
							this.$router.push({
								path: '/paysuccess',
								query: {
									name: that.goods.name,
									payType: 1
								}
							})
						}, 1200)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navAgreement(type) {
				this.$router.push({
					path: "/webview",
					query: {
						type
					}
				});
			},
			navBill() {
				this.$router.push({
					path: "/bill",

				});
				// window.location.href=config.balanceUrl
			}
		},
		mounted() {
			this.getWalletByCurrencyId();
			this.goods.id = this.$route.query.id;
			this.goods.name = this.$route.query.name;
			this.goods.productPic = this.$route.query.productPic;
			this.goods.productEntryNo = this.$route.query.productEntryNo;
			this.goods.salePrice = this.$route.query.salePrice;
			this.goods.isOpen = this.$route.query.isOpen
			this.orderInfo.id=this.$route.query.order_id;
			this.orderInfo.payAmount=this.$route.query.payAmount;
			this.type=this.$route.query.type
			util.wxConfig();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #F4F4F4;
		min-height: 100%;
	}

	.good-sec-relative {
		position: relative;
	}

	.bg1 {
		width: 750px;
		height: 194px;
	}

	.good-sec {
		position: absolute;
		top: 0;
		left: 0;
		width: 750px;
		height: 194px;
	}

	.good-img-sec {
		width: 134px;
		height: 134px;
		background: #000000;
		border-radius: 4px;
		border: 1px solid rgba(255, 255, 255, 0.33);
		text-align: center;
		margin-left: 40px;
	}

	.good-img {
		width: 88px;
		height: 134px;
	}

	.good-img2 {
		width: 88px;
		height: 88px;
		padding: 27px 0;
	}

	.good-right-sec {
		margin-left: 20px;
	}

	.good-name {
		font-size: 28px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 40px;
	}

	.good-num {
		font-size: 26px;
		color: rgba(255, 255, 255, 0.5);
		line-height: 30px;
		margin-top: 16px;
		word-wrap: break-word;
		word-break: break-all;
	}

	.item-sec {
		width: 750px;
		/* height: 181px; */
		background: #FFFFFF;
		padding: 20px 40px;
		box-sizing: border-box;
	}

	.item {
		width: 670px;
		/* height: 90px; */
		padding: 10px 0;
		background: #FFFFFF;
	}

	.item-border {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		margin-bottom: 1px;
	}

	.item-title {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 28px;
	}

	.item-con {
		max-width: 500px;
		font-size: 28px;
		color: #000000;
		line-height: 32px;
		word-wrap: break-word;
		word-break: break-all;
	}

	.good-price {
		font-size: 28px;
		font-weight: 600;
		color: #FF0000;
		line-height: 28px;
	}

	/* 支付列表 */
	.pay-sec {
		width: 750px;
		background: #FFFFFF;
		position: relative;
	}

	.pay-bg {
		width: 678px;
		height: 639px;
	}

	.pay-con {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 678px;
		height: 639px;
		margin: 0 auto;
	}

	.pay-list {
		width: 678px;
		/* height: 293px; */
		padding: 29px 44px 0 44px;
		box-sizing: border-box;
		border-bottom: 1px solid #E3E3E3;
	}

	.pay-item {
		margin-bottom: 41px;
	}

	.pay-icon {
		width: 28px;
		height: 28px;
	}

	.pay-icon2 {
		width: 32px;
		height: 32px;
	}

	.text-wrap {
		width: 500px;
	}

	.pay-item-name {
		font-size: 28px;
		color: #000000;
		line-height: 26px;
		margin-left: 20px;
	}

	.pay-item-yue {
		font-size: 28px;
		color: #FF0000;
		line-height: 26px;
	}

	.pay-item-name2 {
		font-size: 26px;
		color: #FF0000;
		line-height: 26px;
		padding-bottom: 5px;
		border-bottom: 1px solid #FF0000;
	}

	.select_icon {
		width: 22px;
		height: 22px;
		background: #6236FF;
		border-radius: 50%;
	}

	.no-select {
		width: 22px;
		height: 22px;
		background: #D8D8D8;
		border-radius: 50%;
	}

	.agreement-sec {
		margin-top: 30px;
		margin-left: 44px;
	}

	.agreement-pre {
		font-size: 26px;
		color: #000000;
		line-height: 37px;
	}

	.agreement-con {
		font-size: 26px;
		color: #6236FF;
		line-height: 26px;
		border-bottom: 1px solid #6236FF;
	}

	.pay-grey-btn {
		width: 590px;
		height: 80px;
		background: #D8D8D8;
		border-radius: 10px;
		font-size: 26px;
		color: #000000;
		line-height: 80px;
		text-align: center;
		margin: 38px auto 0 auto;
	}

	.pay-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		margin: 38px auto 0 auto;
	}

	.confirm-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 38px;
	}

	.confirm-wrap {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 35px 0 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		box-sizing: border-box;
		z-index: 1111;
	}

	.confirm-goods-name {
		font-size: 26px;
		font-weight: 400;
		margin-bottom: 5px;
	}

	.confirm-content {
		width: 520px;
		font-size: 26px;
		font-weight: 400;
		padding-bottom: 50px;
	}

	.confirm-tips {
		width: 520px;
		height: 60px;
		background: #FEF9EA;
		border-radius: 30px;
		margin-top: 24rpxk
	}

	.btn-wrap {
		width: 100%;
		height: 88px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	.btn-left {
		width: 50%;
		height: 88px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.btn-right {
		width: 50%;
		height: 88px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 400;
		color: #6236FF;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}
</style>