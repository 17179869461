<template>
	<div class="container">
		<iframe src="http://test.metacbc.cn/demo/home.html" style="iframe-wrap"></iframe>
	</div>
</template>

<script>
	export default{
		data(){
			return {}
		},
		methods:{
			
		}
	}
</script>

<style scoped="scoped">
	.container{
		height: 100%;
	}
	iframe{
		width: 100%;
		height: 100%;
		border: none;
	}

</style>
