<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">已销毁</div>
		</div>
		<div class="flex-column align-center">
			<img src="../assets/pay_suc_icon.png" class="pay-suc-icon" />
			<div class="good-name">「{{name}}」</div>
			<div class="good-name" v-if="type ==3">数字藏品已销毁换取{{num}}！</div>
			<div class="good-name" v-else>数字藏品已销毁换取{{num}}个{{type==1?'优先购':'推广卡'}}配额！</div>
			<div class="good-name" v-if="type ==3">物流信息可在“我的-销毁-查看订单”中查看</div>
			<div @click.stop="navDetail" class="pay-detail">继续销毁</div>
			<div @click.stop="navHome" class="go-home">回到首页</div>
		</div>
	</div>
</template>


<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import LoginWrap from '@/components/Login.vue';
	import {
		Toast,
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import Vue from 'vue';

	export default {
		data() {
			return {
				name: '',
				num: '',
				id: '',
				type: ''
			}
		},
		methods: {
			navHome() {
				this.$router.replace('/')
			},
			navDetail() {
				this.$router.go(-1);
			}
		},
		mounted() {
			this.name = this.$route.query.name;
			this.num = this.$route.query.num
			this.type = this.$route.query.type
			util.wxConfig();
		}
	}
</script>

<style scoped="scoped">
	.pay-suc-icon {
		width: 200px;
		height: 200px;
		margin-top: 100px;
		margin-bottom: 50px;
	}

	.good-name {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		line-height: 40px;
	}

	.sm-sec {
		margin-top: 90px;
	}

	.shuoming {
		font-size: 24px;
		color: #000000;
		line-height: 30px;
		margin: 0 20px;
	}

	.xian {
		width: 76px;
		height: 1px;
		background: #B9B9B9;
	}

	.pay-detail {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		position: absolute;
		bottom: 290px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}

	.go-home {
		width: 590px;
		height: 80px;
		border: 1px solid #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		position: absolute;
		bottom: 170px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}
</style>
