<template>
	<div id="app">
		<keep-alive>
			<router-view :key="key" v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view :key="key" v-if="!$route.meta.keepAlive" />
	</div>
</template>
<script>
	export default {
		name: 'myApp',
		computed: {
			key() {
				return this.$route.fullPath
			}
		}
	}
</script>
<style>
	html {
		margin: 0 !important;
		padding: 0 !important;
	}

	body {
		margin: 0 !important;
		padding: 0 !important;
		-webkit-text-size-adjust: 100% !important;
		text-size-adjust: 100% !important;
		-moz-text-size-adjust: 100% !important;
	}

	html,
	body,
	#app {
		height: 100%;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.align-center {
		align-items: center;
	}

	.align-top {
		align-items: flex-start;
	}

	.align-end {
		align-items: flex-end;
	}

	.space-between {
		justify-content: space-between;
	}

	.space-around {
		justify-content: space-around;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.relative {
		position: relative;
	}

	.flex-end {
		justify-content: flex-end;
	}

	/*按钮样式开始  */

	button {
		margin: 0;
		padding: 0;
		border: none;
		background: none;
		border-radius: none;
	}

	button::after {
		content: none;
	}

	button[plain] {
		border: none;
	}

	/*按钮样式结束  */

	img {
		vertical-align: middle;
	}

	/* 多行溢出 */

	.more-ellipsis {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	/* 蒙层 */
	.model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1000;
	}

	/* 底部导航开始*/
	.tab-footer-sec {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 20;
		background: #FFFFFF;
		/* border-top: 1px solid rgba(0, 0, 0, 0.25); */
	}

	.tab-footer-btn {
		width: 375px;
		height: 88px;
		background: #FFFFFF;
		font-size: 20px;
		font-weight: bold;
		color: #000000;
		line-height: 20px;
		box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.25);
	}

	.tab-footer-btn-title {
		margin-top: 58px;
	}

	.tab-no-active-img {
		width: 48px;
		height: 48px;
		margin: 5px 0;
	}

	.tab-active-img {
		width: 93px;
		height: 93px;
		position: absolute;
		top: -29px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}


	/* 底部导航结束*/

	.bianhao {
		font-size: 20px;
		font-weight: bold;
		color: rgba(255, 255, 255);
		line-height: 28px;
		position: absolute;
		top: 109px;
		right: -70px;
		transform: rotate(90deg);
	}

	.bianhao2 {
		font-size: 20px;
		font-weight: bold;
		color: rgba(255, 255, 255);
		line-height: 28px;
		position: absolute;
		top: 109px;
		right: -75px;
		transform: rotate(90deg);
		white-space: nowrap;
	}

	/* 产品列表开始 */
	.good-list {
		/* margin-top: 30px; */
		padding: 10px 30px 0 30px;
	}

	.good-item {
		width: 335px;
		height: 530px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-bottom: 20px;
		position: relative;
		/* margin: 0 10px 20px 10px; */
	}
	
	.good-item-new {
		width: 335px;
		height: 660px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-bottom: 20px;
		position: relative;
		/* margin: 0 10px 20px 10px; */
	}

	.good-item2 {
		width: 335px;
		height: 530px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-bottom: 20px;
		/* margin: 0 10px 20px 10px; */
	}

	.good-item3 {
		width: 335px;
		height: 470px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-bottom: 10px;
		position: relative;
		/* margin: 0 10px 20px 10px; */
	}

	.goods-nums {
		position: absolute;
		top: 349px;
		right: 0;
		min-width: 60px;
		height: 60px;
		padding-left: 15px;
		padding-right: 10px;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 50px 0 0 0;
		text-align: center;
		line-height: 60px;
		font-size: 30px;
		color: #FFFFFF;
	}
	
	.icon-ts{
		position: absolute;
		top: 159px;
		left: 117px;
		width: 100px;
		height: 57px;
	}

	.goods-nums2 {
		position: absolute;
		top: 315px;
		right: 0;
		min-width: 60px;
		height: 60px;
		padding-left: 15px;
		padding-right: 10px;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 50px 0 0 0;
		text-align: center;
		line-height: 60px;
		font-size: 30px;
		color: #FFFFFF;
	}

	.goods-item-wrap {
		width: 335px;
		height: 409px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center -80px;
	}

	.good-item-img {
		width: 335px;
		height: 375px;
		overflow: hidden;
	}

	.good-item-img3 {
		width: 335px;
		/* height: auto; */
		height: 375px;
	}

	.good-item-img2 {
		width: 335px;
		height: 335px;
		padding: 20px 0;
	}

	.good-item-con {
		margin-left: 30px;
	}

	.good-item-name {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
		margin-top: 20px;

	}

	.good-item-num {
		font-size: 24px;
		color: #6D7278;
		line-height: 24px;
		margin-top: 30px;
	}

	.good-item-num2 {
		margin-top: 5px;
		font-size: 24px;
		color: #6D7278;
		/* line-height: 24px; */
		flex-shrink: 0;
	}

	.compound-num-wrap {
		border-radius: 8px;
		overflow: hidden;
		/* transform: scale(0.8); */
		transform-origin: center right;
		flex-shrink: 0;
	}

	.compound-num-wrap-ti {
		background: linear-gradient(180deg, #3FBDFF 0%, #1E5EFF 100%);
		text-align: center;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		padding: 8px;
	}

	.compound-num-wrap-con {
		font-size: 18px;
		font-weight: 400;
		color: #003ABD;
		padding: 8px 10px;
		background: #E3EDFF;
	}

	.good-item-price {
		font-size: 20px;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
		margin-top: 25px;
	}

	.good-item-price2 {
		font-size: 20px;
		font-weight: 600;
		color: #000000;
		/* line-height: 30px; */
	}

	.goods-balance-wrap {
		border-radius: 8px;
		overflow: hidden;
		/* transform: scale(0.8); */
		transform-origin: center right;
	}

	.goods-balance-wrap-ti {
		background: linear-gradient(180deg, #AA8AF7 0%, #5850EC 100%);
		text-align: center;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		padding: 8px;
	}

	.goods-balance-wrap-con {
		background: rgba(98, 54, 255, 0.14);
		font-size: 18px;
		font-weight: 400;
		color: #592B99;
		padding: 8px 10px;

	}

	.goods-mt {
		margin-top: 17px;
	}

	/* 产品列表结束 */
	/* 技术支持开始 */
	.support-sec {
		width: 750px;
		height: 88px;
		background: #000000;
		position: relative;
	}

	.support-icon {
		width: 31px;
		height: 31px;
	}

	.support-dian {
		width: 3px;
		height: 3px;
		background: #FFFFFF;
		margin: 0 10px;
	}

	.support-con {
		font-size: 28px;
		color: #FFFFFF;
		line-height: 28px;
	}

	/* 技术支持结束 */
	/*底部tab*/
	.bot-tab-sec {
		position: fixed;
		z-index: 100;
		bottom: 50px;
		left: 0;
		width: 100%;
		height: 98px;
		background: #FFFFFF;
		box-shadow: 0px -1px 24px 0px rgba(0, 0, 0, 0.1);
	}

	.bot-tab-item {
		width: 33.33%;
	}

	.bot-tab-item-icon {
		width: 36px;
		height: 36px;
		margin-bottom: 10px;
	}

	.bot-tab-item .bot-tab-item-name {
		font-size: 22px;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.5);
		line-height: 22px;
	}

	.bot-tab-item-active .bot-tab-item-name {
		font-size: 22px;
		font-weight: 600;
		color: #000000;
		line-height: 22px;
	}

	/*底部tab结束*/
	/*顶部标题*/
	.page-title-wrap {
		width: 750px;
		height: 88px;
		background: #000000;
		position: relative;
	}

	.page-title-wrap .page-title {
		width: 750px;
		height: 88px;
		position: absolute;
		left: 0;
		font-size: 30px;
		color: #FFFFFF;
		line-height: 88px;
		text-align: center;
		z-index: 3;
	}

	.page-title-wrap .page-title-bg {
		width: 145px;
		height: 20px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 22px;
		margin: auto;
		z-index: 2;
	}

	/*顶部结束*/
	/* 导航栏标题开始 */
	.nav {
		height: 88px;
		background: #000000;
		position: relative;
	}

	.nav-title-bg {
		width: 222px;
		height: 20px;
		background: linear-gradient(270deg, rgba(71, 19, 234, 0) 0%, #3E00FF 50%, rgba(60, 1, 243, 0) 100%);
		margin-top: 12px;
	}

	.nav-title {
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 88px;
		position: absolute;
		top: 0;
		left: 0;
		width: 750px;
		height: 88px;
		text-align: center;
	}

	/* 导航栏标题结束 */

	.icp {
		font-size: 22px;
		/* color: #000000; */
		color: #6D7278;
		background: #F2F2F2;
		text-align: center;
		position: fixed;
		/* bottom: 88px; */
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50px;
		z-index: 10
	}

	.beiab-img {
		margin-right: 6px;
	}

	.van-image-preview__close-icon {
		position: absolute !important;
		z-index: 1 !important;
		font-size: 48px !important;
	}
	.goods-name-wrap{
		margin-top: 20px;
		padding-right: 20px;
	}
	.collect-wrap{
		
	}
	.icon-collect{
		width: 20px;
		height: 20px;
		margin-right: 3px;
	}
	.collect-num{
		font-size: 22px;
		font-weight: 400;
		color: #000000;
	}
	
	.good-item-name2 {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
	
	}
	.pt-wrap{
		margin-top: 15px;
	}
	.goods-pt-wrap {
		margin-left: 10px;
		border-radius: 8px;
		overflow: hidden;
		/* transform: scale(0.8); */
		transform-origin: center right;
	}
	
	.goods-pt-wrap-ti {
		background: linear-gradient(180deg, #FFB684 0%, #FF7311 100%);
		text-align: center;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		padding: 8px;
	}
	
	.goods-pt-wrap-con {
		background: #FFF0DD;
		font-size: 18px;
		font-weight: 400;
		color: #FA6400;
		padding: 8px 10px;
	
	}
	
	.goods-upt-wrap {
		border-radius: 8px;
		overflow: hidden;
		/* transform: scale(0.8); */
		transform-origin: center right;
	}
	
	.goods-upt-wrap-ti {
		background: linear-gradient(180deg, #CECECE 0%, #8E8E8E 100%);
		text-align: center;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		padding: 8px;
	}
	
	.goods-upt-wrap-con {
		background: #E7E7E7;
		font-size: 18px;
		font-weight: 400;
		color: #767676;
		padding: 8px 10px;
	
	}
	.flex-shrink{
		flex-shrink: 0;
	}
	
</style>
