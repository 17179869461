import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue';
import detail from '../views/detail.vue';
import search from '../views/search.vue';
import searchResult from '../views/searchResult.vue';
import collect from '../views/collect.vue';
import member from '../views/member.vue';
import pay from '../views/pay.vue';
import paysuccess from '../views/paysuccess.vue';
import orderdetail from '../views/orderdetail.vue';
import hangsuccess from '../views/hangsuccess.vue';
import backsuccess from '../views/backsuccess.vue';
import compose from '../views/compose.vue';
import balance from '../views/balance.vue';
import bill from '../views/bill.vue';
import billsuccess from '../views/billsuccess.vue';
import withdraw from '../views/withdraw.vue';
import withdrawsuccess from '../views/withdrawsuccess.vue';
import billlist from '../views/billlist.vue';
import zfb from '../views/zfb.vue';
import consignmentdetail from '../views/consignmentdetail.vue'
import share from '../views/share.vue'
import webview from '../views/webview.vue'
import scanpay from '../views/scanpay.vue'
import bank from '../views/bank.vue'
import sr from '../views/sr.vue'
import payfail from '../views/payfail.vue'
import ysf from '../views/ysf.vue'
import resetFont from 'wx-font-reset';
import collectlist from '../views/collectlist.vue';

import refundsuccess from '../views/refundsuccess.vue';
import pattern from '../views/pattern.vue';
import models from '../views/models.vue';
import models2 from '../views/models2.vue';
import balancelist from '../views/balancelist.vue';
import sdpay from '../views/sdpay.vue';
import scansdpay from '../views/scansdpay.vue';
import codesdpay from '../views/codesdpay.vue';
import orderlist from '../views/orderlist.vue';
import catena from '../views/catena.vue';
import pw from '../views/pw.vue';
import entry from '../views/entry.vue';
import entrydetail from '../views/entrydetail.vue';
import entryshare from '../views/entryshare.vue';
import destroy from '../views/destroy.vue';
import destroysuccess from '../views/destroysuccess.vue';
import prior from '../views/prior.vue';
import destroylog from '../views/destroylog.vue';
import open from '../views/open.vue';
import entrylog from '../views/entrylog.vue';
import unibill from '../views/uni/unibill.vue';
import unipay from '../views/uni/unipay.vue';
import unires from '../views/uni/unires.vue';
import download from '../views/download.vue';
import tk from '../views/tk.vue';
import tkdata from '../views/tkdata.vue';
import decompose from '../views/decompose.vue';
import substitution from '../views/substitution.vue';
resetFont();
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		name: 'home',
		component: home,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/detail',
		name: 'detail',
		component: detail,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/search',
		name: 'search',
		component: search,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/searchResult',
		name: 'searchResult',
		component: searchResult,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/pay',
		name: 'pay',
		component: pay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/paysuccess',
		name: 'paysuccess',
		component: paysuccess,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/collect',
		name: 'collect',
		component: collect,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/member',
		name: 'member',
		component: member,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/orderdetail',
		name: 'orderdetail',
		component: orderdetail,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/hangsuccess',
		name: 'hangsuccess',
		component: hangsuccess,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/backsuccess',
		name: 'backsuccess',
		component: backsuccess,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/compose',
		name: 'compose',
		component: compose,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/balance',
		name: 'balance',
		component: balance,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/bill',
		name: 'bill',
		component: bill,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/billsuccess',
		name: 'billsuccess',
		component: billsuccess,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/withdraw',
		name: 'withdraw',
		component: withdraw,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/withdrawsuccess',
		name: 'withdrawsuccess',
		component: withdrawsuccess,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/billlist',
		name: 'billlist',
		component: billlist,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/zfb',
		name: 'zfb',
		component: zfb,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/consignmentdetail',
		name: 'consignmentdetail',
		component: consignmentdetail,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/tk',
		name: 'tk',
		component: tk,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/tkdata',
		name: 'tkdata',
		component: tkdata,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/share',
		name: 'share',
		component: share,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/webview',
		name: 'webview',
		component: webview,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/scanpay',
		name: 'scanpay',
		component: scanpay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/bank',
		name: 'bank',
		component: bank,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/sr',
		name: 'sr',
		component: sr,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/ysf',
		name: 'ysf',
		component: ysf,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/payfail',
		name: 'payfail',
		component: payfail,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/collectlist',
		name: 'collectlist',
		component: collectlist,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/invite',
		name: 'invite',
		component: () => import('../views/invite'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/invite3',
		name: 'invite3',
		component: () => import('../views/invite3'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/invite2',
		name: 'invite2',
		component: () => import('../views/invite2'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/refundsuccess',
		name: 'refundsuccess',
		component: refundsuccess,
		meta: {
			keepAlive: false
		}
	}, {
		path: '/pattern',
		name: 'pattern',
		component: pattern,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/models',
		name: 'models',
		component: models,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/models2',
		name: 'models2',
		component: models2,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/balancelist',
		name: 'balancelist',
		component: balancelist,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/sdpay',
		name: 'sdpay',
		component: sdpay,
		meta: {
			keepAlive: false
		}
	}, {
		path: '/scansdpay',
		name: 'scansdpay',
		component: scansdpay,
		meta: {
			keepAlive: false
		}
	}, {
		path: '/codesdpay',
		name: 'codesdpay',
		component: codesdpay,
		meta: {
			keepAlive: false
		}
	}, {
		path: '/orderlist',
		name: 'orderlist',
		component: orderlist,
		meta: {
			keepAlive: false
		}
	}, {
		path: '/catena',
		name: 'catena',
		component: catena,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/pw',
		name: 'pw',
		component: pw,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/entry',
		name: 'entry',
		component: entry,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/entrydetail',
		name: 'entrydetail',
		component: entrydetail,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/entryshare',
		name: 'entryshare',
		component: entryshare,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/destroy',
		name: 'destroy',
		component: destroy,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/destroysuccess',
		name: 'destroysuccess',
		component: destroysuccess,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/prior',
		name: 'prior',
		component: prior,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/destroylog',
		name: 'destroylog',
		component: destroylog,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/open',
		name: 'open',
		component: open,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/entrylog',
		name: 'entrylog',
		component: entrylog,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unibill',
		name: 'unibill',
		component: unibill,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/unipay',
		name: 'unipay',
		component: unipay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/unires',
		name: 'unires',
		component: unires,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/download',
		name: 'download',
		component: download,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/shop/list',
		name: 'shoplist',
		component: () => import('../views/shop/list'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/shop/collect',
		name: 'shopcollect',
		component: () => import('../views/shop/collect'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/shop/share',
		name: 'shopshare',
		component: () => import('../views/shop/share'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/points/box',
		name: 'pointsbox',
		component: () => import('../views/points/box'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/points/boxdetail',
		name: 'pointsboxdetail',
		component: () => import('../views/points/boxdetail'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/points/card',
		name: 'pointscard',
		component: () => import('../views/points/card'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/points/log',
		name: 'pointslog',
		component: () => import('../views/points/log'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/paysuccess2',
		name: 'paysuccess2',
		component: () => import('../views/paysuccess2'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/extension',
		name: 'extension',
		component: () => import('../views/extension'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/extensionlog',
		name: 'extensionlog',
		component: () => import('../views/extensionlog'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/historyorder',
		name: 'historyorder',
		component: () => import('../views/historyorder'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/points/entryorder',
		name: 'entryorder',
		component: () => import('../views/points/entryorder'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/destroyorder',
		name: 'destroyorder',
		component: () => import('../views/destroyorder'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/decompose',
		name: 'decompose',
		component: () => import('../views/decompose'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/substitution',
		name: 'substitution',
		component: () => import('../views/substitution'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/newsDetail',
		name: 'newsDetail',
		component: () => import('../views/news/detail'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/newsList',
		name: 'newsList',
		component: () => import('../views/news/list'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/proList',
		name: 'proList',
		component: () => import('../views/proList'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/box/card',
		name: 'boxcard',
		component: () => import('../views/box/card'),
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/box/entryorder',
		name: 'boxentryorder',
		component: () => import('../views/box/entryorder'),
		meta: {
			keepAlive: false
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router