<template>
	<div class="container">
		<div class="wrap" id="wrap" ref="wrap">

		</div>
		<div class="tips-modal flex-row align-center justify-center" v-if="tips!=''">
			<div class="tips">{{tips}}</div>
		</div>
		<div class="loader-inner line-scale" v-if="load">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				show: 0,
				rds: [],
				tips: '',
				tipsArr: {
					'8212079b-64c7-4f2b-bcec-3e992d843c54': '我',
					'066a8816-7ce5-4422-a513-f4756ce4ba98': '禾',
					'f42b06f8-bcef-4b45-a04a-29e60b80d155': '專',
					'351b613f-ed22-4c44-a144-3844491f660e': '酉',
					'9129161e-bfb9-41e9-975b-ae67a9e60062': '卜',
					'4577e1b4-f683-4abd-a16d-2154d011b606': '殻',
					'ce4ed33e-fd58-4e5d-9f8e-395fa359c331': '土',
					'3c463c1c-18d2-4ab0-a27c-4c3849941171': '?',
				},
				load: 1
			}
		},
		async mounted() {
			const that = this;
			document.title = ''
			await this.showXk();
			document.getElementsByTagName('canvas')[0].addEventListener('click', function(e) {
				// console.log(that.rds)
				for (let i in that.rds) {
					let re1 = that.rds[i].isVisible;
					let re2 = e.x >= (parseInt(that.rds[i].location2d.x) - 20);
					let re3 = e.x <= (parseInt(that.rds[i].location2d.x) + 20);
					let re4 = e.y >= (parseInt(that.rds[i].location2d.y) - 20);
					let re5 = e.y <= (parseInt(that.rds[i].location2d.y) + 20);
					if (re1 && re2 && re3 && re4 && re5) {
						let id = that.rds[i].id
						that.tips = that.tipsArr[`${id}`]
						// console.log(that.tipsArr[`${id}`])
						// alert(that.rds[i].id)
					}
				}
			})
			document.getElementsByTagName('canvas')[0].addEventListener('touchend', function(e) {
				let pos = e.changedTouches[0]
				// console.log(that.rds)
				for (let i in that.rds) {
					let re1 = that.rds[i].isVisible;
					let re2 = pos.pageX >= (parseInt(that.rds[i].location2d.x) - 20);
					let re3 = pos.pageX <= (parseInt(that.rds[i].location2d.x) + 20);
					let re4 = pos.pageY >= (parseInt(that.rds[i].location2d.y) - 20);
					let re5 = pos.pageY <= (parseInt(that.rds[i].location2d.y) + 20);
					if (re1 && re2 && re3 && re4 && re5) {
						let id = that.rds[i].id
						that.tips = that.tipsArr[`${id}`]
						// console.log(that.tipsArr[`${id}`])
						// alert(that.rds[i].id)
					}
				}
			})
		},
		methods: {
			async showXk() {
				const that = this;
				const {
					Launcher
				} = window.XK3D;
				const core = await Launcher.launch({
					modelPath: 'https://oss.metacbc.cn/models/yjmsg/7421ecb3-8b0e-46d2-a02a-0e036881726/',
					container: this.$refs.wrap,
					wasmPath: "https://oss.metacbc.cn/wasm/",
					autoRotate: true,
				});
				that.load = 0
				core.hotspot.list.forEach(spot => {
					// 监控热点列表的变化，获取准确的位置
					const {
						dispose
					} = spot.updated.on(() => {
						const {
							id,
							isVisible,
							location2d
						} = spot;
						// 基于3D视图下的2D坐标，及现在是否可见，前端组件可自行完成位置变更及可见性变化
						// console.log(id, isVisible, location2d);
						that.rds[id] = spot;
					});
					// 可以根据需要，如2D组件销毁时，调用dispose()，释放监听，消除回声
				});

			}
		}

	}
</script>

<style scoped="scoped">
	html,
	body,
	#app,
	iframe,
	.container {
		margin: 0;
		display: block;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.wrap {
		width: 100%;
		height: 100%;
		overflow: scroll;
	}

	.tips-modal {
		width: 133px;
		height: 132px;
		position: fixed;
		right: 0;
		bottom: 20%;
		z-index: 9999;
		background-image: url(../assets/3d/bg.png);
		background-size: 133px 132px;
	}

	.tips {
		width: 92px;
		height: 94px;
		line-height: 94px;
		font-size: 34px;
		font-weight: 400;
		text-align: center;
		color: #FFFFFF;
		background-image: url(../assets/3d/font-bg.png);
		background-size: 92px 94px;
	}



	.loader-inner {
		position: fixed;
		top: 50%;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 9999;
		width: 750px;
		height: 100px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	@-webkit-keyframes line-scale {
		0% {
			-webkit-transform: scaley(1);
			transform: scaley(1);
		}

		50% {
			-webkit-transform: scaley(0.4);
			transform: scaley(0.4);
		}

		100% {
			-webkit-transform: scaley(1);
			transform: scaley(1);
		}
	}

	@keyframes line-scale {
		0% {
			-webkit-transform: scaley(1);
			transform: scaley(1);
		}

		50% {
			-webkit-transform: scaley(0.4);
			transform: scaley(0.4);
		}

		100% {
			-webkit-transform: scaley(1);
			transform: scaley(1);
		}
	}

	.line-scale>div:nth-child(1) {
		-webkit-animation: line-scale 1s 0.1s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.1s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div:nth-child(2) {
		-webkit-animation: line-scale 1s 0.2s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.2s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div:nth-child(3) {
		-webkit-animation: line-scale 1s 0.3s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.3s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div:nth-child(4) {
		-webkit-animation: line-scale 1s 0.4s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.4s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div:nth-child(5) {
		-webkit-animation: line-scale 1s 0.5s infinite cubic-bezier(.2, .68, .18, 1.08);
		animation: line-scale 1s 0.5s infinite cubic-bezier(.2, .68, .18, 1.08);
	}

	.line-scale>div {
		background-color: #fff;
		width: 8px;
		height: 70px;
		border-radius: 4px;
		margin: 4px;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		display: flex;
	}
</style>
