const sha1 = require('sha1');

function getSign(jsapi_ticket) {
	let timestamp = Date.parse(new Date()) / 1000;
	let noncestr = randomString(10);
	let url = (window.location.href.split('#')[0]);
	var data = {
		jsapi_ticket,
		noncestr,
		timestamp,
		url
	};
	let param = ''
	Object.keys(data).forEach(function(key) {
		param = param + key + '=' + data[key] + '&';
	});
	let str = param.slice(0, -1)
	//计算签名
	let signature = sha1(str);
	return {
		timestamp,
		noncestr,
		jsapi_ticket,
		url,
		signature,
		appId: 'wx23406d34d48c253c'
	}
}

function randomString(len) {
	len = len || 10;
	var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
	var maxPos = chars.length;
	var pwd = '';
	for (let i = 0; i < len; i++) {
		pwd += chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return pwd;
}

export default getSign;
