<template>
	<div class="container" ref="getheight" v-if="!isSetPwd">

		<div class="sale-confirm-modal flex-column align-center" v-if="saleConfirmModal">
			<div class="sale-confirm-modal-title">出售价格确认</div>
			<div class="flex-row align-center sale-confirm-modal-tips-wrap">
				<img src="../assets/icon-tips.png" alt="" class="sale-confirm-modal-tips-img">
				<div class="sale-confirm-modal-tips">价格输入错误，损失自行承担。</div>
			</div>
			<div class="sale-confirm-goods">
				<div class="scg-top flex-row align-center">
					<img :src="slaeInfo.pic" class="scg-img" />
					<div class="flex-column  scg-top-r space-around">
						<div class="scg-name">{{slaeInfo.name}}</div>
						<div class="scg-period">
							整套
						</div>
					</div>
				</div>
				<div class="scg-price-wrap flex-row align-center space-between">
					<div class="scg-price-name">出售价格</div>
					<div class="scg-price">￥{{slaeInfo.salePrice}}</div>
				</div>
			</div>
			<div class="pw-wrap flex-column align-center">
				<div class="pw flex-row align-center">
					<div class="pw-title">输入密码：</div>
					<input type="password" v-model="password" placeholder="请输入密码" class="pw-input">
				</div>
				<div class="edit-pw" @click.stop="editPassword">设置修改密码</div>
			</div>
			<div class="sale-confirm-btn-wrap flex-row space-between align-center">
				<div class="sale-cancel-btn" @click.stop="cancalSaleConfrimModal()">取消</div>
				<div class="sale-confirm-btn" @click.stop="confirmConsignment()">确认</div>
			</div>
		</div>
		<div class="model" v-if="saleConfirmModal" @click.stop="cancalSaleConfrimModal()"></div>
		<div class="flex-row align-center justify-center support-sec">
			<img src="../assets/support_icon.png" class="support-icon" />
			<div class="support-dian"></div>
			<div class="support-con">Meta链提供技术支持</div>
		</div>
		<div class="mem-wrap flex-column justify-center">
			<div class="mem-ti">我的数字藏品({{productSize}})</div>
			<div class="mem-addr" :data-clipboard-text="user.address" @click="copy">区块链地址：{{user.address}}<img
					src="../assets/copy.png" class="copy"></div>
		</div>
		<van-sticky :z-index='99'>
			<div style="background: #F4F4F4;">
				<div class="tab-sec flex-row align-center">
					<div class="tab-item flex-row align-center space-between" @click.stop="changeTab(1)"
						:class="tab==1?'tab-item-active':''">
						<div class="tab-item-name">收藏</div>
						<img src="../assets/icon-collect.png" class="tab-item-icon" v-show="tab == 1" />
					</div>
					<div class="tab-item flex-row align-center space-between" @click.stop="changeTab(2)"
						:class="tab==2?'tab-item-active':''">
						<div class="tab-item-name">售卖中</div>
						<img src="../assets/icon-sale.png" class="tab-item-icon2" v-show="tab == 2" />
					</div>
					<div class="tab-item flex-row align-center space-between" @click.stop="changeTab(3)"
						:class="tab==3?'tab-item-active':''">
						<div class="tab-item-name">已卖</div>
						<img src="../assets/icon-sale2.png" class="tab-item-icon3" v-show="tab == 3" />
					</div>
					<div class="tab-item flex-row align-center space-between" @click.stop="changeTab(4)"
						:class="tab==4?'tab-item-active':''">
						<div class="tab-item-name">买入</div>
						<img src="../assets/icon-sale2.png" class="tab-item-icon3" v-show="tab ==4" />
					</div>
					<div class="tab-item flex-row align-center space-between" @click.stop="changeTab(5)"
						:class="tab==5?'tab-item-active':''">
						<div class="tab-item-name">古玩店</div>
					</div>
					<div class="whole" @click="checkAccount()" v-if="!whole && tab == 1 && collectType==1">整套卖</div>
					<div class="root-btn" :class="root==1?'root-btn-false':''" v-if="tab ==2" @click.stop="doRoot()">授权
					</div>
				</div>
				<div class="header flex-row align-center" v-if="tab == 1">
					<div class="tab flex-column align-center justify-center" @click="changeCollectType(1)">
						<div class="tab-test">已开盒</div>
						<div class="tab-line" v-if="collectType == 1"></div>
					</div>
					<div class="tab flex-column align-center justify-center" @click="changeCollectType(2)">
						<div class="tab-test">未开盒</div>
						<div class="tab-line" v-if="collectType == 2"></div>
					</div>
				</div>
				<div class="catena-wrap flex-row align-center flex-wrap" v-if="tab == 1">
					<div class="catena-item flex-column align-center justify-center" v-for="(item,index) in catenaList"
						v-bind:key="index" @click.stop="changeCatena(index)">
						<div class="catena-name" :class="index==catenaIndex ?'catena-name-selected':''"> {{item.name}}
						</div>
						<div class="catena-line" :class="index==catenaIndex ?'catena-line-selected':''"></div>
					</div>
				</div>
				<div class="period-wrap flex-row align-center flex-wrap" v-if="tab == 1">
					<div class="period-item" :class="index == periodIndex?'period-item-selected':''"
						v-for="(item,index) in periodList" v-bind:key="index" @click.stop="changePeriod(index)">
						{{item.periodName}}
					</div>
				</div>
				<div class="gwd-header flex-row align-center" v-if="tab ==5">
					<div class="gwd-btn" :class="floorIndex==index?'gwd-btn-false':''" v-for="(item,index) in floor"
						:key="index" @click.stop="changeFloor(index)">
						{{item.name}}({{item.currentHaveQuantity}}/{{item.quantity}})
					</div>
				</div>
			</div>
			<input v-if="tab == 1 && collectType == 1" @input="inputKeywords" v-model="keywords" @keyup.enter='search()'
				@keyup.13="show()" ref="input1" type="search" placeholder="搜索" @compositionstart="onCompositionstart"
				@compositionend="onCompositionend" class="search-input">
		</van-sticky>
		<van-list v-model="loading" @load="nextPage" :finished="finished" finished-text="没有更多了" v-if="tab == 1"
			:error.sync="error" error-text="请求失败，点击重新加载" :immediate-check="imCheck">
			<div class="good-list flex-row align-center flex-wrap space-between" v-if="collectType == 1">
				<div class="good-item3 flex-column" @click="navList(index,item.id,1)" v-for="(item,index) in list"
					v-bind:key="index">
					<div class="select-item flex-row align-center" v-if="whole"
						@click.stop="selectGoods(index,!item.select)">
						<div class="select-false" v-if="!item.select"></div>
						<div class="select-true" v-else></div>
					</div>
					<img :src="item.mianPic" class="good-item-img" />
					<div class="goods-nums2">x {{item.count}}</div>
					<div class="good-item-con">
						<div class="good-item-name">{{item.name}}</div>
					</div>
				</div>
			</div>
			<div class="good-list flex-row align-center flex-wrap space-between" v-else>
				<div class="good-item2 flex-column" @click="navDetail(index,item.id,1)" v-for="(item,index) in list"
					v-bind:key="index">
					<div class="select-item flex-row align-center" v-if="whole"
						@click.stop="selectGoods(index,!item.select)">
						<div class="select-false" v-if="!item.select"></div>
						<div class="select-true" v-else></div>
					</div>
					<img src="../assets/manghe.png" class="good-item-img2" />
					<div class="good-item-con">
						<div class="good-item-name">{{item.name}}</div>
						<div class="good-item-num">
							{{item.periodView}}
						</div>
						<div class="good-item-price">￥{{item.buyAmt}}</div>
					</div>
				</div>
			</div>
			<div class="do-whole" v-if="whole && tab == 1 && collectType == 1" @click="doWhole()">整套卖(确认)</div>
		</van-list>
		<van-list v-model="loading" @load="consignmentList" :finished="finished" finished-text="没有更多了" v-if="tab == 2"
			:error.sync="error" error-text="请求失败，点击重新加载">
			<div class="good-list flex-row align-center flex-wrap space-between" v-if="tab != 3"
				style="margin-top: 10px;">
				<div class="good-item flex-column" @click="navDetail(index,item.id,2)" v-for="(item,index) in list"
					v-bind:key="index">
					<div class="root-wrap" v-if="root" @click.stop="nBtn()">
						<img v-if="item.authorizeAntiqueShopId>0" src="../assets/shop/icon-root.png" class="root-icon"
							alt="">
						<div v-if="item.authorizeAntiqueShopId>0" class="cancel-root-btn"
							@click.stop="cancelAuth(index)">
							撤销
						</div>
						<div v-else class="do-root-btn" @click.stop="doAuth(index)">授权</div>
					</div>
					<div v-if="item.isOpen == '是' &&  item.isAll == 1 && item.period !=8" class="goods-item-wrap"
						:style="'background-image: url('+item.pic+');'">
					</div>
					<img v-else-if="item.isOpen == '是' &&  item.isAll == 1 && item.period ==8"
						src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
						class="good-item-img" />
					<img v-else-if="item.isOpen == '是' &&  item.isAll == 0 " :src="item.pic" class="good-item-img" />
					<img v-else-if="item.isOpen == '是' &&  item.isAll == 0" :src="item.pic" class="good-item-img" />
					<img v-else src="../assets/manghe.png" class="good-item-img2" />
					<div class="good-item-con">
						<div class="good-item-name">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
						<div class="good-item-num">
							{{item.periodView}}
						</div>
						<div class="good-item-price">￥{{item.salePrice}}</div>
					</div>
				</div>
			</div>

		</van-list>
		<van-list v-model="loading" @load="consignmentList" :finished="finished" finished-text="没有更多了" v-if="tab ==3 "
			:error.sync="error" error-text="请求失败，点击重新加载">
			<div class="sale-list flex-column">
				<div class="sale-item flex-column" v-for="(item,index) in list" v-bind:key="index">
					<div class="sale-goods-name">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
					<div class="sale-goods-ver">
						{{item.periodView}}
					</div>
					<div class="sale-footer flex-row align-center space-between">
						<div class="sale-time">卖出时间：{{item.updateTime}}</div>
						<div class="sale-price">售卖价：￥{{item.salePrice}}</div>
					</div>
				</div>
			</div>

		</van-list>
		<van-list v-model="loading" @load="getBuyConsignmentList" :finished="finished" finished-text="没有更多了"
			v-if="tab == 4" :error.sync="error" error-text="请求失败，点击重新加载">
			<div class="sale-list flex-column">
				<div class="sale-item flex-column" v-for="(item,index) in list" v-bind:key="index">
					<div class="sale-goods-name">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
					<div class="sale-goods-ver">
						{{item.periodView}}
					</div>
					<div class="sale-footer flex-row align-center space-between">
						<div class="sale-time">买入时间：{{item.payTime}}</div>
						<div class="sale-price">买入价：￥{{item.payAmount}}</div>
					</div>
				</div>
			</div>
		</van-list>

		<van-list v-model="loading" :immediate-check="immediateCheck" @load="getShopFloorProductList"
			:finished="finished" finished-text="没有更多了" v-if="tab == 5" :error.sync="error" error-text="请求失败，点击重新加载">
			<div class="good-list flex-row align-center flex-wrap space-between" style="margin-top: 10px;">
				<div class="good-item flex-column" @click="navDetail2(index,item.consignmentId,2)"
					v-for="(item,index) in list" v-bind:key="index">
					<div class="root-wrap" v-if="floorIndex!=-1" @click.stop="rejAct()">
						<div class="sj-icon" v-if="item.floorId == floor[floorIndex].id && item.shelvesStatus==1">已上架
						</div>
						<div class="cancel-root-btn2"
							v-if="item.floorId == floor[floorIndex].id && item.shelvesStatus==1"
							@click.stop="offShop(index)">下架</div>
						<div class="do-root-btn" v-else @click.stop="pushShop(index)">上架</div>

					</div>
					<div v-if="item.isOpen == '是' &&  item.productLevel == 0 && item.period !=8" class="goods-item-wrap"
						:style="'background-image: url('+item.consignmentPic+');'">
					</div>
					<img v-else-if="item.isOpen == '是' &&  item.productLevel == 0 && item.period ==8"
						src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
						class="good-item-img" />
					<img v-else-if="item.isOpen == '是' &&  item.productLevel != 0 " :src="item.consignmentPic"
						class="good-item-img" />
					<img v-else-if="item.isOpen == '是' &&  item.productLevel != 0" :src="item.consignmentPic"
						class="good-item-img" />
					<img v-else src="../assets/manghe.png" class="good-item-img2" />
					<div class="good-item-con">
						<div class="good-item-name">{{item.consignmentName}}</div>
						<div class="good-item-num">
							{{item.periodDesc}}
						</div>
						<div class="sc-f-price flex-row align-center">
							<div class="good-item-price2">￥{{item.currentPrice}}</div>
							<div class="sc-price">底价:￥{{item.minPrice}}</div>
						</div>
					</div>
				</div>
			</div>
		</van-list>
		<div class="hang-sec-model" v-if="saleModal" @click.stop="closeSaleModal">
		</div>
		<div class="hang-sec" v-if="saleModal">
			<div class="hang-title">寄售详情</div>
			<div class="hang-item-sec">
				<div class="flex-row align-center space-between price-sec">
					<div class="price-title">请输入寄售价格：</div>
					<input class="price-input" @input="inputSalePrice" v-model="slaeInfo.salePrice" type="number"
						placeholder="点击设置寄售价格" />
				</div>
				<div class="flex-row align-center space-between hang-item">
					<div class="hang-item-title">支付通道费：</div>
					<div class="hang-item-price"
						:class="slaeInfo.channelFee?'hang-item-price-active1':'hang-item-price'">
						-￥{{slaeInfo.channelFee}}</div>
				</div>
				<div class="flex-row align-center space-between hang-item">
					<div class="hang-item-title">技术服务费：</div>
					<div class="hang-item-price"
						:class="slaeInfo.txServiceCharge?'hang-item-price-active1':'hang-item-price'">
						-￥{{slaeInfo.txServiceCharge}}</div>
				</div>

				<!-- 	<div class="flex-row align-center space-between hang-item">
					<div class="hang-item-title">提现至银行卡手续费：</div>
					<div class="hang-item-price"
						:class="slaeInfo.serviceCharge?'hang-item-price-active1':'hang-item-price'">
						-￥{{slaeInfo.serviceCharge}}</div>
				</div> -->
				<div class="flex-row align-center space-between hang-item">
					<div class="hang-item-title">赋能收益：</div>
					<div class="hang-item-price" :class="slaeInfo.rights?'hang-item-price-active1':'hang-item-price'">
						+￥{{slaeInfo.rights}}</div>
				</div>
				<div class="flex-row align-center space-between hang-item">
					<div class="hang-item-title color-red">红包抵扣：</div>
					<div class="hang-item-price color-red">
						+￥{{slaeInfo.discountAmount}}</div>
				</div>
				<div class="flex-row align-center space-between hang-item">
					<div class="hang-item-title">商品出售所得：</div>
					<div class="hang-item-price" :class="slaeInfo.price?'hang-item-price-active1':'hang-item-price'">
						￥{{slaeInfo.price}}</div>
				</div>
				<div class="hang-grey-btn" v-if="!slaeInfo.salePrice || slaeInfo.salePrice<=0">立即挂售</div>
				<div class="hang-btn" v-else @click.stop="createConsignment()">立即挂售</div>
			</div>
		</div>
		<div class="model" v-if="perialModal" @click.stop="closePerialModal"></div>
		<div class="perial-wrap" v-if="perialModal">
			<div class="perial-con flex-column">
				<div class="perial-title">整套卖</div>
				<div class="perial-item-wrap" :style="'max-height:'+maxheight+'px'">
					<div class="perial-item flex-row align-center space-between" v-for="(item,index) in ztList"
						v-bind:key="index" @click.stop="selectZt(index)">
						<div class="perial-name">{{item.name}}</div>
						<div class="perial-option" :class="index == ztIndex ?'perial-option-sel':''"></div>
					</div>
				</div>
			</div>
			<div class="perial-btn-con flex-row align-center space-between">
				<div class="perial-btn-cancel" @click.stop="closePerialModal">取消</div>
				<div class="perial-btn-confirm" @click.stop="doWhole">确认</div>
			</div>
		</div>
		<div class="model" v-if="rootConfirmModal" @click.stop="closeRootConfirmModal"></div>
		<div class="root-cf-modal flex-column  align-center" v-if="rootConfirmModal">
			<div class="rcm-item flex-row align-center">
				<div class="rcm-ti">现价</div>
				<div class="rcm-price">{{authCon.price}}</div>
				<div class="rcm-p-icon">元</div>
			</div>
			<div class="rcm-item flex-row align-center">
				<div class="rcm-ti">底价</div>
				<input type="number" class="rcm-price" v-model="authCon.minPrice" placeholder="能接受的最低价">
				<div class="rcm-p-icon">元</div>
			</div>
			<div class="rcm-item flex-row align-center">
				<div class="rcm-ti">代理店</div>
				<input type="text" v-model="authCon.shopId" class="rcm-price" placeholder="Token ID">
			</div>
			<img src="../assets/shop/root-con-tips.png" class="root-con-tips" alt="">
			<div class="rcm-cf-btn" @click.stop="confirmAuth()">确认</div>
		</div>
		<div v-show="tab ==2" class="cancel-sale" @click.stop="cancelSale()">一键撤销</div>
		<div class="icp flex-row align-center space-around">
			<div @click.stop="navBa()">沪ICP备17003041号-4</div>
			<div class="flex-row align-center">
				<!-- <img src="../assets/beiab.png" class="beiab-img"> -->
				<div @click.stop="navBa2()">沪公网安备 31011402009626号</div>
			</div>
		</div>
		<div class="bot-tab-sec flex-row align-center">
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navHome()">
				<img src="../assets/home.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">C2C</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navMsg()">
				<img src="../assets/tab/msg2.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">公告</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center bot-tab-item-active">
				<img src="../assets/collect_tab_select.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的藏品</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navMember()">
				<img src="../assets/mine.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的</div>
			</div>
		</div>
	</div>
	<div class="container" v-else>
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="getCode()">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">交易密码设置</div>
		</div>
		<div class="content flex-column align-center" :style="'height:'+conHeight+'px'">
			<div class="title">密码设置/修改</div>
			<div class="in-wrap flex-column align-center">
				<div class="flex-row align-center t">
					<div class="phone">{{user.loginName}}</div>
					<div class="get-code" @click.stop="getVerifyCode()">{{msg}}</div>
				</div>
				<input type="text" class="input-item" placeholder="验证码" v-model="code"
					oninput="if(value.length > 6)value = value.slice(0, 6)" maxlength="6">
				<input type="text" class="input-item" oninput="if(value.length > 8)value = value.slice(0, 8)"
					maxlength="8" placeholder="请输入新密码" v-model="password">
				<input type="text" class="input-item" oninput="if(value.length > 8)value = value.slice(0, 8)"
					maxlength="8" v-model="confirmPassword" placeholder="请再次输入新密码">
				<div class="btn" :class="code && password &&confirmPassword ?'btn-true':''" @click="confrimEdit()">确认
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast,
		Sticky,
		List,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/sticky/index.css';
	import 'vant/lib/list/index.css';
	import 'vant/lib/dialog/index.css';
	import Clipboard from 'clipboard';
	import Vue from 'vue';
	Vue.use(Sticky);
	Vue.use(List);

	export default {
		data() {
			return {
				tab: 1,
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				windowHeight: 0,
				offsetHeight: 0,
				user: {},
				collectType: 1,
				finished: false,
				status: 0,
				whole: false,
				saleModal: false,
				slaeInfo: {
					salePrice: '',
					serviceCharge: 0, //平台手续费
					rights: 0, //赋能权益：
					price: 0, //商品出售所得
					txServiceCharge: 0,
					name: '',
					channelFee: 0,
					discountAmount: 0
				},
				error: false,
				productSize: '',
				perial: [],
				perialIndex: 0,
				perialModal: 0,
				maxheight: 0,
				catenaList: [],
				catenaIndex: -1,
				periodList: [],
				periodIndex: -1,
				saleConfirmModal: 0,
				jspassword: '',
				conHeight: 0,
				time: 0,
				msg: '获取验证码',
				sendCode: 0,
				inteval: '',
				code: '',
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				password: '',
				confirmPassword: '',
				isSetPwd: 0,
				imCheck: false,
				root: 0,
				rootConfirmModal: 0,
				isRoot: 0,
				authCon: {
					index: -1,
					price: 0,
					minPrice: '',
					shopId: '',
				},
				floor: [],
				shop: {},
				floorIndex: -1,
				immediateCheck: false,
				ztList: [],
				ztIndex: 0,
				flag: true,
				keywords: ''
			}
		},
		mounted() {

		},
		activated() {

		},
		beforeRouteEnter(to, from, next) {
			console.log(from.path)
			if (from.path == '/pw') {
				next()
			} else {
				next(that => {
					that.conHeight = window.screen.height - 375 / window.screen.width * 44
					that.maxheight = window.innerHeight * 0.5
					if (that.$route.query.name) {
						that.keywords = that.$route.query.name
					}
					// this.getProListByOpenStatusIsOk();
					if (that.$route.query.token) {
						const token = that.$route.query.token;
						if (!token) {
							that.$router.push('/')
						}
						const type = that.$route.query.type;
						if (!type) {
							that.$router.push('/')
						}
						that.token = token;
						that.$store.commit('setToken', token);
						let user = {}
						if (localStorage.getItem("user")) {
							user = JSON.parse(localStorage.getItem("user"))
							user.token = token;
						} else {
							user = {
								token
							}
						}
						localStorage.setItem('user', JSON.stringify(user))
					}
					that.tab = 1;
					that.collectType = 1;
					that.list = [];
					that.more = true;
					that.pageNum = 1;
					that.loading = false;
					that.user = {};
					that.periodIndex = -1;
					that.catenaIndex = -1;
					that.periodList = [];
					that.catenaList = [];
					that.password = '';
					that.jspassword = '';
					that.slaeInfo = {
						salePrice: '',
						serviceCharge: 0, //平台手续费
						rights: 0, //赋能权益：
						price: 0, //商品出售所得
						txServiceCharge: 0,
						name: '',
						channelFee: 0,
						discountAmount: 0
					}
					that.finished = false;
					that.root = 0;
					that.rootConfirmModal = 0;
					that.floor = [];
					that.$forceUpdate();
					console.log(that.perialIndex)
					that.getMember();
					that.getPeriodList();
					that.getCompleteSetList()
					that.getProductCatenaList();
					that.getProListByOpenStatusIsOk();
					util.wxConfig();
				});
			}
		},
		methods: {
			onCompositionstart() {
				this.flag = false;
			},
			onCompositionend(e) {
				const that = this
				setTimeout(() => {
					that.flag = true;
					that.inputKeywords(e)
				}, 100)
			},
			search() {
				this.list = [];
				this.more = true;
				this.pageNum = 1;
				this.empty = false;
				this.finished = false;
				this.loading = true;
				this.error = false;
				this.getProListByOpenStatusIsOk()
			},
			inputKeywords(e) {
				if (!this.flag) {
					return;
				}
				//获取候选词
				const that = this;
				if (e.target.value !== '') {
					that.keywords = e.target.value
					that.list = [];
					that.more = true;
					that.pageNum = 1;
					that.empty = false;
					that.finished = false;
					that.loading = true;
					that.error = false;
					that.getProListByOpenStatusIsOk()
				}
			},
			navMsg() {
				this.$router.push('/newsList')
			},
			cancelSale() {
				const that = this;
				Dialog.confirm({
					message: '确认要撤销全部寄售藏品'
				}).then(() => {
					Toast.loading({
						message: '撤销中...',
						forbidClick: true,
						duration: 0,
					});
					that.axios.get(config.requestUrl + '/front/consignment/oneKeyCleanAll', {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token ? that.$store.state.user.token : ''
						}
					}).then(response => {
						if (response.data.code == 0) {
							that.list = [];
							that.more = true;
							that.pageNum = 1;
							that.empty = false;
							that.finished = false;
							that.loading = true;
							that.error = false;
							that.consignmentList();
							Toast('撤销成功')
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								that.$store.commit("clearUser");
								that.$store.commit("clearCode");
								that.showLogin = true;
							}
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				})
			},
			pushShop(index) {
				const that = this;
				let params = new FormData()
				params.append('doType', 1)
				params.append('shopFloorDetailsId', that.list[index].id)
				params.append('floorId', that.floor[that.floorIndex].id)
				that.axios.post(config.requestUrl + '/front/antiqueShop/onTheShelves', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.list[index].floorId = that.floor[that.floorIndex].id;
						that.list[index].shelvesStatus = 1;
						that.getAntiqueShopFloorList();
						Toast.success('上架成功')
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			offShop(index) {
				const that = this;
				let params = new FormData()
				params.append('doType', 2)
				params.append('shopFloorDetailsId', that.list[index].id)
				params.append('floorId', that.floor[that.floorIndex].id)
				that.axios.post(config.requestUrl + '/front/antiqueShop/onTheShelves', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.list[index].floorId = '';
						that.list[index].shelvesStatus = 2;
						that.getAntiqueShopFloorList();
						Toast.success('下架成功')
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			changeFloor(index) {
				if (index == this.floorIndex) this.floorIndex = -1
				else this.floorIndex = index
			},
			closeRootConfirmModal() {
				this.rootConfirmModal = 0;
			},
			nBtn() {
				console.log(nBtn)
			},
			getShopFloorProductList() {
				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				// params.append('floorId', this.floor[this.floorIndex]);
				params.append('shopId', this.shop.id);
				params.append('status', 0);
				this.axios.post(config.requestUrl + '/front/antiqueShop/getShopFloorProductList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let newList = response.data.obj.list
						let list = this.list;
						let finished = false;
						if (response.data.obj.isLastPage) finished = true;
						list = list.concat(newList);
						this.list = list;
						this.pageNum = this.pageNum + 1;
						that.finished = finished;
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						that.error = true;
					}
					this.loading = false;
				}, response => {
					Toast('获取失败，请稍后重试');
					this.loading = false;
					that.error = true;
				})
			},
			cancelAuth(index) {
				const that = this;
				Dialog.confirm({
					title: "提示",
					message: '是否取消授权到古玩店？'
				}).then(() => {
					let params = new FormData()
					params.append('consignmentId', that.list[index].id)
					that.axios.post(config.requestUrl + '/front/antiqueShop/cancelAuthorization', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							that.list[index].authorizeAntiqueShopId = false;
							Toast.success('撤销成功')
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				})
			},
			confirmAuth() {
				const that = this;
				console.log(that.authCon)
				if (!that.authCon.minPrice || that.authCon.minPrice <= 0) {
					Toast('请输入最低价')
					return
				}
				if (that.authCon.minPrice > that.authCon.price) {
					Toast('最低价不能高于现价')
					return
				}
				if (!that.authCon.shopId) {
					Toast('请输入代理店Token ID')
					return;
				}
				let params = new FormData()
				params.append('consignmentId', that.list[that.authCon.index].id)
				params.append('minPrice', that.authCon.minPrice)
				params.append('tokenId', that.authCon.shopId)
				that.axios.post(config.requestUrl + '/front/antiqueShop/authorizeJoinAntiqueShop', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.list[that.authCon.index].authorizeAntiqueShopId = true;
						that.rootConfirmModal = 0
						Toast.success('授权成功')
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			doAuth(index) {
				console.log(this.list[index].salePrice)
				this.authCon = {
					index: index,
					price: this.list[index].salePrice,
					minPrice: '',
					shopId: '',
				}
				console.log(this.authCon)
				this.rootConfirmModal = 1;
			},
			doRoot() {

				this.root = !this.root
			},
			editPassword() {
				this.$router.push('pw')
			},
			getPeriodByCatenaId(id) {
				const that = this;
				that.axios.get(config.requestUrl + '/front/consignment/getPeriodByCatenaId', {
					params: {
						catenaId: id
					},
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token ? that.$store.state.user.token : ''
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.periodList = response.data.obj;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							that.showLogin = true;
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getProductCatenaList() {
				const that = this;
				that.axios.get(config.requestUrl + '/front/consignment/getProductCatenaList', {
					headers: {
						'Content-Type': 'multipart/form-data',
						// 'token': that.$store.state.user.token?that.$store.state.user.token:''
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.catenaList = response.data.obj.productCatenaList;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							that.showLogin = true;
						}
						Toast(response.data.msg);
						that.error = true;
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					that.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			changeCatena(index) {
				if (this.catenaIndex == index) return;
				this.catenaIndex = index;
				this.getPeriodByCatenaId(this.catenaList[index].id)
				this.periodIndex = -1;
				this.list = [];
				this.more = true;
				this.pageNum = 1;
				this.empty = false;
				this.finished = false;
				this.loading = true;
				this.error = false;
				if (this.collectType == 1) {
					this.getProListByOpenStatusIsOk()
				} else {
					this.getProListByOpenStatusIsNo()
				}
			},
			changePeriod(index) {
				if (this.periodIndex == index) return;
				this.periodIndex = index;
				this.list = [];
				this.more = true;
				this.pageNum = 1;
				this.empty = false;
				this.finished = false;
				this.loading = true;
				this.error = false;
				if (this.collectType == 1) {
					this.getProListByOpenStatusIsOk()
				} else {
					this.getProListByOpenStatusIsNo()
				}
			},
			getPeriodList() {
				const that = this;
				let params = new FormData()

				this.axios.get(config.requestUrl + '/front/consignment/getProductPeriodList', {}, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.perial = response.data.obj;
						// that.perial = response.data.obj.concat(response.data.obj);
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getCompleteSetList() {
				const that = this;
				let params = new FormData()

				this.axios.get(config.requestUrl + '/front/consignment/getCompleteSetList ', {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.ztList = response.data.obj;
						// that.perial = response.data.obj.concat(response.data.obj);
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			openSaleModal() {
				this.saleModal = true;
			},
			closeSaleModal() {
				this.saleModal = false
			},
			async inputSalePrice(e) {
				if (e.target.value && e.target.value > 0) {
					await this.getConsignmentFee()
				}
			},
			async getConsignmentFee() {
				const that = this;
				let params = new FormData()
				params.append('salePrice', that.slaeInfo.salePrice)
				params.append('consignmentId', 0)
				await this.axios.post(config.requestUrl + '/front/consignment/getConsignmentFee', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.slaeInfo.serviceCharge = response.data.obj.platformFee;
						that.slaeInfo.rights = response.data.obj.rightsFee;
						that.slaeInfo.price = response.data.obj.incomeAmount;
						that.slaeInfo.txServiceCharge = response.data.obj.txServiceCharge;
						that.slaeInfo.channelFee = response.data.obj.channelFee;
						that.slaeInfo.discountAmount = response.data.obj.discountAmount

					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			createConsignment() {
				const that = this;
				if (!that.slaeInfo.salePrice || that.slaeInfo.salePrice <= 0) {
					Toast('请输入寄售价格')
					return;
				}
				if (that.slaeInfo.salePrice > 100000) {
					Toast('寄售价格最大100000')
					return;
				}
				that.saleConfirmModal = 1;
				that.saleModal = 0;

				that.slaeInfo.name =
					`${that.ztList[that.ztIndex].name}`;
				that.slaeInfo.pic = that.ztList[that.ztIndex].mianPic
			},
			cancalSaleConfrimModal() {
				this.saleConfirmModal = 0;
			},
			confirmConsignment() {
				const that = this;
				let selectArr = [];

				if (!that.slaeInfo.salePrice || that.slaeInfo.salePrice <= 0) {
					Toast('请输入寄售价格')
					return;
				}
				if (that.slaeInfo.salePrice > 100000) {
					Toast('寄售价格最大100000')
					return;
				}
				if (!that.password) {
					Toast('请输入密码');
					return;
				}
				Toast.loading({
					message: '寄售中...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('consignmentType', 2)

				// params.append('productEntryId', selectArr.join(','))
				params.append('salePrice', that.slaeInfo.salePrice)
				params.append('completeSetId', that.ztList[that.ztIndex].id)
				params.append('password', that.password)
				params.append('period', 1)
				this.axios.post(config.requestUrl + '/front/consignment/createConsignment', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.success('寄售成功')
						this.saleModal = false
						this.list = [];
						this.more = true;
						this.pageNum = 1;
						this.empty = false;
						this.finished = false;
						this.loading = true;
						this.getProListByOpenStatusIsOk();
						this.saleConfirmModal = 0;
					} else {
						if (response.data.msg == '发布寄售异常,请稍后再试') {
							Toast('藏品未上链，暂时无法寄售。')
						} else {
							Toast(response.data.msg);
						}
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			changeWhole(whole) {
				this.whole = whole;
			},
			selectGoods(index, select) {
				if (!select) {
					this.list[index].select = false
				} else {
					let num = 0;
					this.list.forEach((item, index, arr) => {
						if (item.select) {
							num += 1
						}
					})
					if (num >= 9) {
						Toast('最多选择9个藏品')
					} else {
						this.list[index].select = true
					}
				}
			},
			checkAccount() {
				// this.openPerialModal();
				let params = new FormData()
				params.append('payType', 3)
				this.axios.post(config.requestUrl + '/front/user/getUserPayType', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let info = response.data.obj[0];
						if (info && info.no) {
							this.openPerialModal();
						} else {
							this.$router.push('/bank')
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			doWhole() {
				const that = this;
				let num = 0;
				let selectArr = [];
				that.axios.get(config.requestUrl + '/front/consignment/isCompleteSet', {
					params: {
						// productEntryId: selectArr.join(',')
						completeSetId: that.ztList[that.ztIndex].id
					},
					headers: {
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.perialModal = 0;
						that.openSaleModal();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					Toast('获取失败，请稍后重试');
				})
			},
			nextPage() {
				if (!this.finished) {
					if (this.tab == 1) {
						if (this.collectType == 1) {
							this.getProListByOpenStatusIsOk();
						} else {
							this.getProListByOpenStatusIsNo();
						}
					} else {
						this.consignmentList();
					}
				}
			},
			changeTab(tab) {
				if (this.tab != tab) {
					this.tab = tab;
					this.list = [];
					this.more = true;
					this.pageNum = 1;
					this.empty = false;
					this.finished = false;
					this.loading = true;
					this.error = false;
					if (this.tab == 2) {
						this.status = 0
					} else {
						this.status = 3
					}
					if (this.tab == 1) {
						if (this.collectType == 1) {
							this.getProListByOpenStatusIsOk();
						} else {
							this.getProListByOpenStatusIsNo();
						}
					} else if (tab == 4) {
						this.getBuyConsignmentList();
					} else if (tab == 5) {
						this.floorIndex = -1;
						this.root = 0;
						this.getAntiqueShopListByUserId();
					} else {
						this.consignmentList();
					}


				}
			},
			getAntiqueShopListByUserId() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/antiqueShop/getAntiqueShopListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = response.data.obj
						if (list.length <= 0) {
							that.finished = true;
							that.loading = false;
							return;
						}
						let shop = {};

						for (let i in list) {
							if (list[i].isDefault == 1) {
								shop = list[i];
								break;
							}
						}
						console.log(shop)
						if (shop && shop.id) {
							that.shop = shop;
							that.getAntiqueShopFloorList(shop.id);
							that.getShopFloorProductList();
						} else {
							that.finished = true;
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getAntiqueShopFloorList() {
				const that = this
				let params = new FormData()
				params.append('shopId', this.shop.id)
				that.axios.post(config.requestUrl + '/front/antiqueShop/getAntiqueShopFloorList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.floor = response.data.obj
						console.log(response.data.obj)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			consignmentList() {
				const that = this;
				const status = that.status;
				const tab = that.tab;
				that.axios.get(config.requestUrl + '/front/user/consignment/list', {
					params: {
						status: that.status,
						pageNum: that.pageNum,
						pageSize: that.pageSize
					},
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (that.tab == tab) {
							let newList = response.data.obj.list
							for (let i in newList) {
								newList[i].isAll = 0;
								let productId = newList[i].productEntryIdMap;
								if (productId.length > 0) {
									productId = productId.replace(/{/, "")
									productId = productId.replace(/}/, "")
									productId = productId.split(':');
									if (productId.length > 2) {
										newList[i].isAll = 1;
									}
								}
							}
							let list = that.list;
							let finished = false;
							if (response.data.obj.isLastPage) finished = true;
							list = list.concat(newList);
							that.list = list;
							that.pageNum = that.pageNum + 1;
							that.finished = finished;
						}
					} else {
						let msg = response.data.msg;
						console.log(msg)
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.$router.push('/')
							}, 1200)
						}
						that.error = true;
						Toast(response.data.msg);
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					that.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			getBuyConsignmentList() {
				const that = this;
				const tab = that.tab;
				that.axios.get(config.requestUrl + '/front/user/getBuyConsignmentList', {
					params: {
						pageNum: that.pageNum,
						pageSize: that.pageSize
					},
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (that.tab == tab) {
							Toast.clear();
							let newList = response.data.obj.list
							// for (let i in newList) {
							// 	newList[i].isAll=0;
							// 	let productId = newList[i].productEntryIdMap;
							// 	if (productId.length > 0) {
							// 		productId = productId.replace(/{/, "")
							// 		productId = productId.replace(/}/, "")
							// 		productId = productId.split(':');
							// 		if(productId.length > 2){
							// 			newList[i].isAll=1;
							// 		}
							// 	}
							// }
							let list = that.list;
							let finished = false;
							if (response.data.obj.isLastPage) finished = true;
							list = list.concat(newList);
							that.list = list;
							that.pageNum = that.pageNum + 1;
							that.finished = finished;
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								that.$router.push('/')
							}, 1200)
						}
						that.error = true;
						Toast(response.data.msg);
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					that.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			async getProListByOpenStatusIsNo() {
				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				this.pageNum = this.pageNum + 1;

				if (this.periodIndex != -1) {
					params.append('period', this.periodList[this.periodIndex].id);
				} else if (this.catenaIndex != -1) {
					params.append('catenaId', this.catenaList[this.catenaIndex].id);
					// params.append('catenaId', 5);
				}
				let catenaIndex = this.catenaIndex;
				let periodIndex = this.periodIndex;
				await this.axios.post(config.requestUrl + '/front/user/getProListByOpenStatusIsNo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (this.collectType == 2 && this.tab == 1 && this.catenaIndex == catenaIndex &&
							periodIndex == this.periodIndex) {
							let list = this.list;
							let newList = response.data.obj.list;
							for (let i in newList) {
								let arr = newList[i].productPic.split('/');
								let str = arr.pop();
								newList[i].productPic2 = config.imgUrl + str;
							}
							if (response.data.obj.isLastPage) {
								this.finished = true;
							}
							list = list.concat(newList);
							this.list = list;
							if (this.list.length == 0) {
								this.empty = true;
							}
							Toast.clear()
							this.$forceUpdate();
						}
						this.loading = false;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						that.error = true;
						Toast(response.data.msg);
						this.loading = false;
					}

				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			async getProListByOpenStatusIsOk() {
				const that = this;
				that.loading = true;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				params.append('likeName', this.keywords)
				this.pageNum = this.pageNum + 1;
				if (this.periodIndex != -1) {
					params.append('period', this.periodList[this.periodIndex].id);
				} else if (this.catenaIndex != -1) {
					params.append('catenaId', this.catenaList[this.catenaIndex].id);
				}
				let catenaIndex = this.catenaIndex;
				let periodIndex = this.periodIndex;
				await this.axios.post(config.requestUrl + '/front/user/getProListByOpenStatusIsOk', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (this.collectType == 1 && this.tab == 1 && this.catenaIndex == catenaIndex &&
							periodIndex == this.periodIndex) {
							let list = this.list;
							let newList = response.data.obj.list;
							if (response.data.obj.isLastPage) this.finished = true;
							list = list.concat(newList);

							this.list = list;
							if (this.list.length == 0) {
								this.empty = true;
							}
							Toast.clear()
							this.$forceUpdate();
							this.loading = false;
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						this.loading = false;
						that.error = true;
						Toast(response.data.msg);
					}
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			navDetail(index, id, type) {
				if (type == 1) {
					this.$router.push({
						path: '/orderdetail',
						query: {
							id,
							type
						}
					})
				} else {
					this.$router.push({
						path: '/consignmentdetail',
						query: {
							id,
							type
						}
					})
				}
			},
			navDetail2(index, id, type) {
				this.$router.push({
					path: '/detail',
					query: {
						id,
						type
					}
				})
			},
			navList(index, id, type) {
				this.$router.push({
					path: '/collectlist',
					query: {
						id,
						type
					}
				})
			},
			navHome() {
				this.$router.replace('/')
			},
			navMember() {
				this.$router.replace('/member')
			},
			getMember() {
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.getBox = 0;
					if (response.data.code == 0) {
						this.user = response.data.obj.userInfo;
						this.user.randName = this.$store.state.user.randName;
						if (!this.$store.state.user.ranName) {
							let user = {
								token: this.$store.state.user.token,
								randName: util.randomString(),
								userPic: this.user.pic,
								nickName: this.user.name
							}
							this.$store.commit('setUser', user)
							localStorage.setItem('user', JSON.stringify(user))
						}

						this.productSize = response.data.obj.productSize
						Toast.clear()
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},

			changeCollectType(collectType) {
				const that = this;
				if (collectType == that.collectType) return;
				that.collectType = collectType;
				that.list = [];
				that.more = true;
				that.pageNum = 1;
				that.empty = false;
				that.finished = false;
				that.error = false;
				this.loading = true;
				this.periodIndex = -1;
				this.catenaIndex = -1;
				this.periodList = [];
				if (collectType == 1) {
					that.getProListByOpenStatusIsOk();
				} else {
					that.getProListByOpenStatusIsNo();
				}
			},
			copy() {
				var clipboard = new Clipboard('.mem-addr')
				clipboard.on('success', e => {
					//  释放内存
					Toast.success('复制成功');
				})
				clipboard.on('error', e => {
					// 不支持复制
					// 释放内存
					Toast.success('复制失败');
				})
			},
			selectPerial(index) {
				this.perialIndex = index
			},
			selectZt(index) {
				this.ztIndex = index
			},
			closePerialModal() {
				this.perialModal = false
			},
			openPerialModal() {
				this.perialModal = true
			},
			navBa2() {
				window.location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009626"
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			},
			getVerifyCode() {
				const that = this;
				if (that.sendCode || that.time > 0) return;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			getCode() {
				const that = this;
				if (that.sendCode || that.time > 0) return;
				that.sendCode = 1;
				let params = new FormData()
				params.append('phoneEmail', that.user.loginName)
				params.append('smsType', 1)
				params.append('zone', 86)
				params.append('doType', 1)
				params.append('codeValue', that.verifyCode)
				params.append('key', that.verifyKey)

				Toast.loading({
					message: '发送中...',
					forbidClick: true,
					duration: 0,
				});
				that.axios.post(config.requestUrl + '/ajax/sendCode', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					that.sendCode = 0;
					if (response.data.code == 0) {
						that.showVerify = 0;
						Toast.success('发送成功');
						that.time = 60;
						that.msg = `${that.time}s`;
						that.inteval = setInterval(() => {
							if (that.time <= 0) {
								that.msg = '获取验证码'
								clearInterval(this.interval);
							} else {
								that.time = that.time - 1;
								that.msg = `${that.time}s`
							}
						}, 1000)
					} else {
						Toast(response.data.msg);
					}

				}, response => {
					this.sendCode = 0;
					Toast('获取失败，请稍后重试');
				})
			},
			confrimEdit() {
				const that = this;
				if (!that.code || !that.password || !that.confirmPassword) {
					return;
				}
				if (!that.code) {
					Toast('请输入验证码');
					return;
				}
				if (!that.password) {
					Toast('请输入密码');
					return;
				}
				if (!that.confirmPassword) {
					Toast('请输入确认密码');
					return;
				}
				if (that.password.length < 6) {
					Toast('密码长度为6-8位');
					return;
				}

				if (that.password != that.confirmPassword) {
					Toast('密码与确认密码不一致');
					return;
				}
				let params = new FormData()
				params.append('code', that.code);
				params.append('newPwd', that.password);
				params.append('newPwdConfirm', that.confirmPassword);
				this.axios.post(config.requestUrl + '/front/user/changePassword', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast('修改成功');
						setTimeout(() => {
							this.$router.go(-1)
						}, 1200)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},

		},

	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #F4F4F4;
		min-height: 100%;
		overflow: scroll;
		padding-bottom: 120px;
	}

	.mem-wrap {
		background-image: url(../assets/collect-bg.png);
		width: 750px;
		height: 200px;
		padding: 0 30px;
		box-sizing: border-box;
		background-size: 750px 200px;
		background-repeat: no-repeat;

	}

	.mem-ti {
		font-size: 40px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.mem-addr {
		margin-top: 20px;
		font-size: 26px;
		font-weight: 400;
		color: #FFFFFF;
		word-wrap: break-word;
		word-break: break-all;
	}

	.tab-sec {
		padding-top: 24px;
		padding-left: 30px;
		/* background: #F4F4F4; */
		position: relative;
		background: #FFFFFF;
	}

	.tab-item {
		margin-right: 30px;
	}

	.tab-item-name {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
	}

	.tab-item-active {
		height: 40px;
		background: #F4F4F4;
		border-radius: 10px;
		padding: 7px 13px 7px 10px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 26px;
	}

	.tab-item-icon {
		width: 23px;
		height: 22px;
		margin-left: 8px;
	}

	.tab-item-icon-1 {
		width: 22px;
		height: 22px;
		margin-left: 8px;
	}

	.tab-item-icon2 {
		width: 39px;
		height: 17px;
		margin-left: 10px;
	}

	.tab-item-icon3 {
		width: 22px;
		height: 22px;
		margin-left: 10px;
	}

	.sale-list {
		width: 750px;
		margin-top: 30px;
		/* padding-bottom: 120px; */
	}

	.sale-item {
		width: 750px;
		height: 225px;
		background: #FFFFFF;
		padding: 40px;
		box-sizing: border-box;
		margin-bottom: 10px;
	}

	.sale-goods-name {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.sale-goods-ver {
		margin-top: 20px;
		font-size: 26px;
		font-weight: 400;
		color: #6D7278;
	}

	.sale-footer {
		margin-top: 34px;
	}

	.sale-time {
		font-size: 24px;
		font-weight: 400;
		color: #6D7278;
	}

	.sale-price {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.header {
		width: 750px;
		height: 88px;
		background: #FFFFFF;
		/* background: #000000; */
	}

	.tab {
		width: 375px;
		height: 88px;
		position: relative;
		box-sizing: border-box;
		margin: 10px auto;
		background: #FFFFFF;
	}

	.tab-test {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
		letter-spacing: 2px;
	}

	.tab-line {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 80px;
		height: 6px;
		background: #000000;
		border-radius: 4px;
	}

	.whole {
		position: absolute;
		right: 30px;
		top: 0;
		bottom: 0;
		margin: auto;
		padding: 12px 20px;
		background: #6236FF;
		border-radius: 30px;
		font-size: 26px;
		font-weight: 400;
		color: #FFFFFF;
		height: fit-content;
	}

	.select-item {
		width: 100%;
		height: 40px;
		background: #E6E6E6;
		border-radius: 10px 10px 0px 0px;

	}

	.select-false {
		margin-left: 16px;
		width: 16px;
		height: 16px;
		background: #FFFFFF;
		border-radius: 100px;
	}

	.select-true {
		margin-left: 16px;
		width: 16px;
		height: 16px;
		background: #6236FF;
		border-radius: 100px;
	}

	.do-whole {
		position: fixed;
		bottom: 123px;
		left: 0;
		right: 0;
		margin: auto;
		width: 424px;
		height: 90px;
		background: #6236FF;
		border-radius: 45px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 90px;
		text-align: center;
	}

	/* 挂出寄售部分 */
	.hang-sec {
		width: 750px;
		height: 900px;
		background: #FFFFFF;
		border-radius: 20px 20px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 102;
	}

	.hang-title {
		width: 750px;
		height: 88px;
		background: #6236FF;
		border-radius: 20px 20px 0px 0px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 88px;
		text-align: center;
	}

	.hang-item-sec {
		padding: 0 40px;
	}

	.price-sec {
		width: 670px;
		height: 120px;
		background: #FFFFFF;
		box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.price-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
	}

	.price-input {
		font-size: 30px;
		border: none;
		text-align: right;
		font-weight: 600;
		color: #FF0101;
		line-height: 30px;
	}

	.price-input:focus {
		border: none !important;
	}

	/* 挂出寄售部分 */
	.hang-sec-model {
		width: 100%;
		height: 100%;
		background: #F4F4F4;
		min-height: 100%;
		background: #000000;
		opacity: 0.7;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
	}

	.hang-item {
		width: 670px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.hang-item-title {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 28px;
	}

	.hang-item-price {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 30px;
	}

	.hang-item-price-active {
		font-size: 30px;
		font-weight: 600;
		color: #FF0101;
		line-height: 30px;
	}

	.hang-item-price-active1 {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
	}

	.hang-grey-btn {
		width: 590px;
		height: 80px;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		margin: 134px auto 0 auto;
	}

	.hang-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		margin: 134px auto 0 auto;
	}

	.nav2 {
		position: fixed;
		top: 0;
		left: 0;
	}

	.copy {
		width: 35px;
		height: 35px;
		margin-left: 5px;
		vertical-align: bottom;
	}

	.perial-wrap {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		background: #FFFFFF;
		z-index: 1001;
		height: max-content;
		max-height: 80%;
		width: 600px;
		padding: 10px 10px 43px;
		box-sizing: border-box;
		border-radius: 20px;
	}

	.perial-con {
		position: relative;
		width: 100%;
		padding: 84px 64px;
		box-sizing: border-box;
		background: #6236FF;
		border-radius: 18px;
	}

	.perial-title {
		width: 238px;
		height: 72px;
		position: absolute;
		top: -46px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
		line-height: 72px;
		font-size: 34px;
		font-weight: 400;
		color: #FFFFFF;
		background: #6236FF;
		border-radius: 20px;
		border: 10px solid #FFFFFF;
	}

	.perial-item-wrap {
		max-height: 80%;
		overflow: scroll;
	}

	.perial-item {
		width: 445px;
		padding: 20px 27px;
		box-sizing: border-box;
		background: #FFFFFF;
		background: #FFFFFF;
		border-radius: 10px;
		margin-bottom: 40px;
	}

	.perial-name {
		width: 360px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
		word-break: break-word;
		word-wrap: break-word;
	}

	.perial-option {
		width: 20px;
		height: 20px;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 100%;
	}

	.perial-option-sel {
		background: #6236FF;
	}

	.perial-btn-con {
		width: 520px;
		margin: 99px auto 0;
	}

	.perial-btn-cancel {
		width: 230px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #6236FF;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		text-align: center;
		line-height: 80px;
	}

	.perial-btn-confirm {
		width: 230px;
		height: 80px;

		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 80px;
	}

	.perial-item:last-child {
		margin-bottom: 0;
	}

	.catena-wrap {
		width: 100%;
		background: #F4F4F4;
		/* box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.1); */
		border-radius: 0px 0px 20px 20px;
		box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.1);
	}

	.catena-item {
		margin-top: 20px;
	}

	.catena-name {
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
		padding-right: 50px;
		padding-left: 50px;
		height: 40px;
		line-height: 40px;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
	}

	.catena-item:last-child .catena-name {
		border: none;
	}

	.catena-name-selected {
		font-weight: 600;
		color: #000000;
	}

	.catena-line {
		margin-top: 15px;
		width: 70px;
		height: 5px;
		background: #F4F4F4;
	}

	.catena-line-selected {
		background: #6236FF;
	}

	.period-wrap {
		margin-top: 20px;
		/* padding-bottom: 20px; */
		padding: 0 20px 20px;
		width: 100%;
		box-sizing: border-box;
		background: #F4F4F4;
	}

	.period-item {
		margin-right: 15px;
		padding: 12px 24px;
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		border-radius: 25px;
	}

	.period-item-selected {
		background: rgba(98, 54, 255, 0.06);
		font-weight: 600;
		color: #6236FF;
	}

	/*确认弹窗*/
	.sale-confirm-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background: rgba(0, 0, 0, 0.7);
		width: 650px;
		height: max-content;
		z-index: 1001;
		background: #F8F8F8;
		border-radius: 20px;
	}

	.sale-confirm-modal-title {
		width: 100%;
		height: 120px;
		border-radius: 20px 20px 0px 0px;

		background: #FFFFFF;
		text-align: center;
		line-height: 120px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.sale-confirm-modal-tips-wrap {
		width: 580px;
		height: 189px;
		background: #FFFFFF;
		border-radius: 20px;
		margin-top: 30px;
	}

	.sale-confirm-modal-tips-img {
		width: 100px;
		height: 100px;
		margin-left: 30px;
	}

	.sale-confirm-modal-tips {
		margin-left: 20px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.sale-confirm-goods {
		margin-top: 30px;
		width: 580px;
		height: 264px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.scg-top {
		width: 580px;
		height: 175px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 30px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.scg-img {
		width: 100px;
		height: 112px;
		border-radius: 4px;
	}

	.scg-top-r {
		margin-left: 30px;
	}

	.scg-name {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.scg-period {
		margin-top: 16px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.scg-price-wrap {
		width: 100%;
		height: 88px;
		line-height: 88px;
		padding: 0 30px;
		box-sizing: border-box;
	}

	.scg-price-name {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.scg-price {

		font-size: 28px;
		font-weight: 600;
		color: #F90000;
	}

	.pw-wrap {
		width: 580px;
		height: 204px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-top: 30px;
	}

	.pw {
		width: 100%;
		height: 101px;
		padding: 0 30px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.pw-title {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.pw-input {
		border: none;
		margin-left: 10px;
		width: 300px;
		font-size: 28px;

	}

	.edit-pw {
		width: 188px;
		height: 54px;
		margin-top: 24px;
		background: #FFFFFF;
		border-radius: 27px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		font-size: 24px;
		font-weight: 400;
		color: #000000;
		text-align: center;
		line-height: 54px;
	}

	.sale-confirm-btn-wrap {
		width: 100%;
		padding: 70px 50px 100px;
		box-sizing: border-box;
	}

	.sale-cancel-btn {
		width: 260px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #6236FF;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		text-align: center;
		box-sizing: border-box;
	}

	.sale-confirm-btn {
		width: 260px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		box-sizing: border-box;
	}

	.content {
		height: 100%;
		background-image: url(../assets/pw-bg.png);
		background-size: 100%;
		overflow: scroll;
	}

	.title {
		font-size: 38px;
		font-weight: 600;
		color: #FFFFFF;
		letter-spacing: 2px;
		margin-top: 35px;
	}

	.in-wrap {
		width: 706px;
		height: max-content;
		background-image: url(../assets/pw-bg2.png);
		background-size: 706px 1200px;
		padding-bottom: 100px;
	}

	.t {
		margin-top: 158px;
		width: 590px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		overflow: hidden;

	}

	.phone {
		width: 410px;
		margin-left: 30px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.get-code {
		width: 180px;
		height: 100px;
		background: #6236FF;
		font-size: 28px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 100px;
	}

	.input-item {
		margin-top: 40px;
		width: 590px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		padding: 30px;
		box-sizing: border-box;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.btn {
		margin-top: 200px;
		width: 550px;
		height: 80px;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		text-align: center;
		line-height: 80px;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
		letter-spacing: 2px;
	}

	.btn-true {
		background: #6236FF;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}

	.root-btn {
		position: absolute;
		right: 30px;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 103px;
		height: 50px;
		line-height: 50px;
		background: linear-gradient(180deg, #9B66FF 0%, #6236FF 100%);
		border-radius: 30px;
		font-size: 26px;
		font-weight: 400;
		color: #FFFFFF;
		height: fit-content;
		text-align: center;
	}

	.root-btn-false {
		background: rgba(0, 0, 0, 0.25);
	}

	.root-wrap {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 335px;
		height: 375px;
		background: rgba(0, 0, 0, 0.7);
	}

	.do-root-btn {
		position: absolute;
		left: 112px;
		top: 169px;
		z-index: 10;
		width: 111px;
		height: 64px;
		border-radius: 32px;
		border: 4px solid #FFFFFF;
		font-size: 26px;
		font-weight: 500;
		color: #FFFFFF;
		text-align: center;
		line-height: 58px;
		box-sizing: border-box;
	}

	.cancel-root-btn {
		position: absolute;
		left: 112px;
		top: 169px;
		z-index: 10;
		width: 111px;
		height: 64px;
		background: linear-gradient(144deg, #FCC554 0%, #FFA544 100%);
		border-radius: 32px;
		font-size: 26px;
		font-weight: 500;
		color: #000000;
		text-align: center;
		line-height: 64px;
	}

	/*古玩店内容*/
	.root-icon {
		width: 120px;
		height: 47px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}

	.root-cf-modal {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1001;
		width: 750px;
		height: 1182px;
		background: url(../assets/shop/root-con-bg.png);
		background-size: 750px 1192px;
	}

	.rcm-item:first-child {
		margin-top: 315px;
	}

	.rcm-item {
		width: 540px;
		height: 88px;
		margin-top: 30px;
		background: rgba(0, 0, 0, 0.05);
		border-radius: 44px;
	}

	.rcm-ti {
		padding: 0 14px 0 40px;
		height: 34px;
		border-right: 1px solid #D8D8D8;
		text-align: center;
		font-size: 28px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.85);
		line-height: 34px;
	}

	.rcm-price {
		width: 328px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		margin-left: 30px;
		border: none;
		background: none;
	}

	.rcm-p-icon {
		font-size: 28px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.85);
	}

	.root-con-tips {
		width: 540px;
		height: 94px;
		margin-top: 130px;
	}

	.rcm-cf-btn {
		width: 540px;
		height: 88px;
		background: #6236FF;
		border-radius: 44px;
		text-align: center;
		line-height: 88px;
		font-size: 26px;
		font-weight: 500;
		color: #FFFFFF;
		margin-top: 70px;
	}

	.gwd-header {
		width: 750px;
		height: 88px;
		background: #FFFFFF;
		padding-left: 40px;
		box-sizing: border-box;
	}

	.gwd-btn {
		padding: 12px;
		margin-right: 20px;
		background: linear-gradient(180deg, #9B66FF 0%, #6236FF 100%);
		border-radius: 30px;
		font-size: 20px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.gwd-btn-false {
		background: rgba(0, 0, 0, 0.25);
	}

	.cancel-root-btn2 {
		position: absolute;
		left: 112px;
		top: 169px;
		z-index: 10;
		width: 111px;
		height: 64px;
		background: #FFFFFF;
		border-radius: 32px;
		font-size: 26px;
		font-weight: 500;
		color: #000000;
		text-align: center;
		line-height: 64px;
	}

	.sj-icon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		width: 120px;
		height: 47px;
		background: #6236FF;
		border-radius: 10px 0px 50px 0px;
		font-size: 22px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 47px;
	}

	.sc-price {
		margin-left: 20px;
		font-size: 26px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}

	.sc-f-price {
		margin-top: 30px;
	}

	.good-item {
		border-radius: 10px;
		overflow: hidden;
	}

	.color-red {
		color: #FF0101;
	}

	.cancel-sale {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 158px;
		margin: auto;
		z-index: 199;
		width: 400px;
		height: 70px;
		background: rgba(0, 0, 0, 0.7200);
		border-radius: 35px;
		text-align: center;
		line-height: 70px;
		font-size: 28px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.search-input {
		width: 600px;
		height: 70px;
		padding: 0 30px;
		box-sizing: border-box;
		background: #FFFFFF;
		border-radius: 10px;
		border: none;
		display: block;
		margin: 0 auto 20px;
	}
</style>