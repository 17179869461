<template>
	<div class="container" ref="getheight">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">账单</div>
		</div>

		<div class="list">
			<van-list v-model="loading" @load="getLog" :finished="finished" finished-text="没有更多了" :error.sync="error"
				error-text="请求失败，点击重新加载">
				<div class="flex-row align-center space-between item" v-for="(item,index) in list" v-bind:key="index">
					<div class="flex-row align-center">
						<div  class="rt-img2">赋</div>
						<div class="flex-column">
							<div class="item-name">赋能实业收入</div>
							<div class="item-date">{{item.createTime}}</div>
						</div>
					</div>
					<div class="item-num">
						{{item.income>=0?'+':''}}{{item.income}}元
					</div>
				</div>

			</van-list>
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import Vue from 'vue';

	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				windowHeight: 0,
				offsetHeight: 0,
				date: '',
				paramDate: '',
				show: false,
				loading: false,
				finished: false,
				error: false,
				productEntryId: ''
			}
		},
		methods: {
			getLog() {
				this.loading = true;
				const that = this;

				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				params.append('productEntryId', this.productEntryId);
				this.axios.post(config.requestUrl + '/front/endue/getIncomeRecordListByProductId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = this.list;
						let newList = response.data.obj.list;
						for (let i in newList) {
							let createTime = newList[i].createTime.replace(/-/g, "/");
							newList[i].createTime = util.formatDate(createTime)
						}
						if (response.data.obj.isLastPage) {
							this.finished = true;
							this.more = false;
						}
						list = list.concat(newList);
						this.pageNum = this.pageNum + 1;
						this.list = list;
						if (this.list.length == 0) {
							this.empty = true;
						}
						Toast.clear()
						this.$forceUpdate();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},

		},
		mounted() {
			util.wxConfig();
			this.productEntryId = this.$route.query.productEntryId
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	.nav {
		position: relative;
	}

	.date {
		width: 200px;
		height: 50px;
		background: #6236FF;
		border-radius: 34px;
		font-size: 24px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		position: absolute;
		right: 30px;
		top: 21px;
	}

	.list {
		width: 750px;
	}

	.item {
		width: 750px;
		height: 165px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
		padding: 0 40px;
		box-sizing: border-box;
	}

	.item-icon {
		width: 67px;
		height: 67px;
		margin-right: 30px;
	}

	.item-name {
		font-size: 30px;
		color: #000000;
		line-height: 30px;
	}

	.item-date {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-top: 25px;
	}

	.item-num {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
	}

	.rt-img {
		width: 67px;
		height: 67px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 67px;
		border-radius: 100%;
		background: gray;
		margin-right: 30px;
	}

	.rt-img2 {
		width: 67px;
		height: 67px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 67px;
		border-radius: 100%;
		background: #F7B500;
		margin-right: 30px;
	}
</style>
