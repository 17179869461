<template>
	<div class="container" ref="getheight">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">待支付</div>
		</div>
		<div class="empty-text" v-if="list.length == 0 && request">没有待支付订单</div>
		<div class="list">
			<div class="item" v-for="(item,index) in list" v-bind:key="index">
				<div class="flex-row align-center space-between item-header">
					<div class="flex-row align-center">
						<img src="../assets/icon-time.png" class="icon-time">
						<div class="stitle">下单时间</div>
					</div>
					<div class="time">{{item.createTime}}</div>
				</div>
				<div class="flex-row item-con">
					<div class="img-con flex-row align-center space-around">
						<img v-if="item.isOpen =='否'" src="../assets/manghe.png" class="goods-img">
						<img v-if="item.isOpen=='是' && item.consignmentType == 1" :src="item.productPic"
							class="goods-img2">
						<div v-if="item.isOpen=='是' && item.consignmentType == 2" class="goods-img3"
							:style="'background-image: url('+item.productPic+');'">
						</div>
					</div>
					<div class="item-con-ri flex-column">
						<div class="goods-name">{{item.isOpen =='否' ?'盲盒':item.productName}}</div>
						<div class="goods-position">{{item.productEntryNo}}</div>
						<div class="goods-price">售卖价：￥{{item.payAmount}}</div>
					</div>
				</div>
				<div class="flex-row space-between">
					<div></div>
					<div class="flex-row align-center">
						<div class="pay-btn-false" @click="cancelOrder(index)">取消订单</div>
						<div class="pay-btn" @click="pay(index)">支付</div>
					</div>
				</div>
			</div>
		</div>

		<div v-html="form"></div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				windowHeight: 0,
				offsetHeight: 0,
				date: '',
				show: false,
				loading: false,
				finished: false,
				error: false,
				form: '',
				timeout: '',
				request: 0
			}
		},
		methods: {
			cancelOrder(index) {
				Dialog.confirm({
					message: '确认要取消订单？'
				}).then(() => {
					Toast.loading({
						message: '',
						forbidClick: true,
						duration: 0,
					});
					const that = this;
					let params = new FormData()
					params.append('orderId', that.list[index].id);

					this.axios.post(config.requestUrl + '/front/order/cleanOrder', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							Toast('取消成功');
							this.getWaitPayOrderList();
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
							}
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})
				})
			},
			getWaitPayOrderList() {
				this.loading = true;
				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);

				this.axios.post(config.requestUrl + '/front/order/getWaitPayOrderList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = [];
						let newList = response.data.obj.list;
						for (let i in newList) {
							let createTime = newList[i].createTime.replace(/-/g, "/");
							newList[i].createTime = util.formatDate(createTime)

						}
						if (response.data.obj.isLastPage) {
							this.finished = true;
							this.more = false;
						}
						list = list.concat(newList);
						this.pageNum = this.pageNum + 1;
						this.list = list;
						if (this.list.length == 0) {
							this.empty = true;
						}
						this.$forceUpdate();
						this.timeout = setTimeout(() => {
							that.pageNum = 1;
							that.finished = false;
							that.getWaitPayOrderList();
						}, 10000)
						this.error = true;
						that.request = 1;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			pay(index) {
				const that = this;
				let order = that.list[index];
				if (order.unionQRPayInfo != '') {
					let payInfo = JSON.parse(order.unionQRPayInfo)
					this.$router.push({
						path: '/codesdpay',
						query: {
							orderId: order.id,
							token: this.$store.state.user.token,
							type: 3,
							name: order.isOpen == '是' ? order.productName : '盲盒',
							qrcode: payInfo.qrCode
						}
					})
				} else if (order.h5PayInfo != '') {
					this.form = '';
					this.$forceUpdate;
					setTimeout(() => {
						let payInfo = JSON.parse(order.h5PayInfo);
						if (payInfo.credential) {
							this.form = payInfo.credential;
							setTimeout(() => {
								document.forms[0].submit();
							}, 500)
						}
					}, 100)
					// if()
				} else if (order.aliPayInfo != '') {
					this.$router.push({
						path: '/sdpay',
						query: {
							orderId: order.id,
							token: this.$store.state.user.token,
							type: 3,
							name: order.isOpen == '是' ? order.productName : '盲盒',
							payPath: order.aliPayInfo
						}
					})
				} else if (order.fastPayInfo != '') {
					this.$router.push({
						path: '/open',
						query: {
							orderId: order.id,
							token: this.$store.state.user.token,
							type: 1,
							name: order.isOpen == '是' ? order.productName : '盲盒',
							payPath: order.fastPayInfo
						}
					})
				} else if (order.payType == 8) {
					window.location.href=order.ybOneKeyBankPayInfo
				}else if(order.h5FastPayInfo !=''){
					window.location.href=order.h5FastPayInfo
				}else if(order.h5QuickTopUpPayInfo !=''){
					window.location.href=order.h5QuickTopUpPayInfo
				} else {
					this.$router.push({
						path: '/open',
						query: {
							orderId: order.id,
							token: this.$store.state.user.token,
							type: 1,
							name: order.isOpen == '是' ? order.productName : '盲盒',
							payPath: order.fastPayInfo
						}
					})
				}
			}

		},
		mounted() {
			util.wxConfig();
			this.getWaitPayOrderList();
		},
		destroyed() {
			clearInterval(this.timeout)
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	.nav {
		position: relative;
	}


	.list {
		width: 750px;
	}

	.item {
		width: 690px;
		height: 406px;
		padding: 20px;
		box-sizing: border-box;
		margin: 30px auto auto;
	}

	.img-con {
		width: 200px;
		height: 200px;
	}

	.icon-time {
		width: 26px;
		height: 26px;
	}

	.stitle {
		font-size: 26px;
		font-weight: 400;
		color: #6D7278;
		margin-left: 12px;
	}

	.time {
		font-size: 26px;
		font-weight: 400;
		color: #6D7278;
	}

	.icon-time {
		width: 20px;
		height: 20px;
	}

	.item-con {
		margin-top: 40px;
	}

	.item-con-ri {
		width: 430px;
		min-height: 200px;
	}

	.goods-img {
		width: 200px;
		height: 200px;
		margin-right: 20px;
	}

	.goods-img2 {
		width: 178px;
		height: 200px;
		margin-right: 20px;
	}

	.goods-img3 {
		width: 163px;
		height: 200px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center -35px;
	}

	.goods-name {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.goods-position {
		font-size: 26px;
		font-weight: 400;
		color: #6D7278;
		margin-top: 20px;
		height: 100px;
	}

	.goods-price {
		text-align: right;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
	}

	.pay-btn {
		width: 180px;
		height: 60px;
		background: #FFFFFF;
		border-radius: 30px;
		text-align: center;
		line-height: 60px;
		color: #6236FF;
		border: 1px solid #6236FF;
		font-size: 30px;
		margin-top: 50px;
	}

	.pay-btn-false {
		width: 180px;
		height: 60px;
		background: grey;
		border-radius: 30px;
		text-align: center;
		line-height: 60px;
		color: #fff;
		border: 1px solid grey;
		font-size: 30px;
		margin-top: 50px;
		margin-right: 20px;
	}

	.empty-text {
		font-size: 30px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		text-align: center;
		margin-top: 621px;
	}
</style>
