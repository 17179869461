<template>
	<div class="container">
		<template v-if="step ==1">
			<div ref="qrCodeDiv" style="position: fixed;top: -9999px;left: -9999px;"></div>
			<div class="for-img" @click.stop="nav3D()">
				<div v-if="info.isOpen == '是' && !showQrcode && info.period !=8" class="goods-img-wrap"
					:style="'background-image: url('+info.pic+');'">
				</div>
				<img v-else-if="info.isOpen == '是' && !showQrcode && info.period == 8"
					src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
					class="good-img" />
				<img v-else-if="info.isOpen == '是' && showQrcode" :src="info.pic" class="good-img" />
				<img v-if="info.isOpen == '否'" src="../assets/manghe.png" class="good-img2" />
				<img v-if="tkData.id" src="../assets/icon-shop.png" class="icon-3d" @click="navTk()">
				<img src="../assets/3d.png" class="icon-3d" v-if="info.iconType == 3">
				<img src="../assets/fd.png" class="icon-3d" v-if="info.iconType == 1">
				<img src="../assets/goods/play2.png" class="icon-play" alt="" v-if="info.iconType == 2">
				<video id="myVideo" v-show="videoModal" :src="info.link3D" class="video" controls width="100%"
					height="100%"></video>
			</div>
		<!-- 	<img src="../assets/xian_img.png" class="xian-img flex-row" v-if="showQrcode" />
			<div class="qrcode-wrap flex-row align-center space-between" v-if="showQrcode">
				<div class="qrcode-wrap-left flex-column">
					<div class="qrcode-goods-name">{{info.name}}</div>
					<div class="qrcode-goods-fx">发行方:{{info.issuerName}}</div>
					<div class="qrcode-goods-bh" v-if="info.tokenId !='888888'">版号:{{info.productEntryNo}}</div>
					<div class="qrcode-goods-bh" v-else>编号:{{info.productEntryNo}}</div>
					<div class="qrcode-goods-tk" v-if="info.tokenId !='888888'">Token ID:{{info.tokenId}}</div>
				</div>
				<div class="qrcode-wrap-right flex-column align-center" v-if="info.tokenId !='888888'">
					<img :src="qrcodeImg" class="qrcode-img">
					<div class="qrcode-desc">扫码查主人</div>
				</div>
			</div> -->
			<div class="flex-row align-center good-name-sec">
				<img src="../assets/good_name_bg.png" class="good-name-bg" />
				<div class="flex-row align-center good-name-con space-between">
					<div class="good-name">{{info.isOpen == '是'?info.name:'盲盒'}}</div>
					<!-- <div class="good-status-sec">
						<img src="../assets/good_status_bg.png" class="good-status-bg" />
						<div class="flex-column align-center good-status-con">
							<img src="../assets/sale_icon.png" class="sale-icon" />
							<div class="good-status">售卖中</div>
						</div>
					</div> -->
					<div class="flex-column zc-wrap">
						<div class="num-wrap2 flex-shrink flex-row align-center" v-if="info.quantity>0">
							<div class="num-wrap-ti">发行量</div>
							<div class="num-wrap-con">{{info.quantity}}件</div>
						</div>
						<div class="num-wrap2 flex-shrink flex-row align-center" v-if="info.mergeRecordCount>0">
							<div class="points-wrap-ti">流通量</div>
							<div class="points-wrap-con">{{info.mergeRecordCount}}件</div>
						</div>
					</div>
				</div>
			</div>

			<div class="good-detail-1">
				<div class="label-item flex-wrap flex-row align-center">
					<!-- <div class="num-wrap flex-shrink flex-row align-center" v-if="info.mergeRecordCount>0">
						<div class="num-wrap-ti">实际数量</div>
						<div class="num-wrap-con">{{info.mergeRecordCount}}件</div>
					</div> -->
					<div class="ye-wrap flex-shrink flex-row align-center" v-if="info.income !=null">
						<div class="ye-wrap-ti">余额</div>
						<div class="ye-wrap-con">{{info.income}}元</div>
					</div>
					<div class="ye-wrap flex-row align-center flex-shrink"
						v-if="info.tkOperationsWithdrawAmount !=null && info.tkOperationsWithdrawAmount>0">
						<div class="ye-wrap-ti">余额</div>
						<div class="ye-wrap-con">{{info.tkOperationsWithdrawAmount }}元</div>
					</div>
					<div class="points-wrap flex-shrink flex-row align-center" v-if="info.havePoints!=null">
						<div class="points-wrap-ti">积分</div>
						<div class="points-wrap-con">{{info.havePoints}}</div>
					</div>
					<div class="upoints-wrap flex-shrink flex-row align-center" v-if="info.surplusPoints!=null">
						<div class="upoints-wrap-ti">未发放</div>
						<div class="upoints-wrap-con">{{info.surplusPoints}}</div>
					</div>
				</div>
				<div class="flex-row align-center detail-item" v-if="info.tokenId !='888888'">
					<div class="detail-item-title">版号</div>
					<div class="detail-item-con">
						{{info.consignmentType==1?info.periodView:info.productEntryNo}}
					</div>
				</div>
				<div class="flex-row align-center detail-item" v-else>
					<div class="detail-item-title">编号</div>
					<div class="detail-item-con">
						{{info.consignmentType==1?info.periodView:info.productEntryNo}}
					</div>
				</div>
				<div class="flex-row align-center detail-item" v-if="showQrcode">
					<div class="detail-item-title">Token ID</div>
					<div class="detail-item-con">
						{{info.tokenId}}
					</div>
				</div>
				<!-- <div class="flex-row align-center detail-item">
					<div class="detail-item-title">发行方</div>
					<div class="detail-item-con">{{info.issuerName}}</div>
				</div>
				<div class="flex-row align-center detail-item">
					<div class="detail-item-title">收藏者</div>
					<div class="detail-item-con">{{info.nickName}}</div>
				</div> -->
				<div class="flex-row align-center detail-item" v-if="info.tokenId !='888888'">
					<div class="detail-item-title">权益</div>
					<div class="detail-item-con">{{info.rightsDesc}}</div>
				</div>
				<div class="flex-row align-center detail-item" v-if="show">
					<div class="detail-item-title">作品介绍</div>
					<div class="detail-item-con">{{head.productDesc?head.productDesc:''}}</div>
				</div>
			</div>
			<div class="good-detail-2" v-if="show">
				<div class="deal-info">发行信息</div>
				<div class="flex-row align-center detail-item">
					<div class="detail-item-title">发行方</div>
					<div class="detail-item-con">{{transactionInfo[0].ownerName}}</div>
				</div>
				<div class="flex-row align-center detail-item">
					<div class="detail-item-title">藏品生成</div>
					<div class="detail-item-con">{{transactionInfo[0].time}}</div>
				</div>
				<div class="flex-row align-center detail-item">
					<div class="detail-item-title">交易哈希</div>
					<div class="detail-item-con">{{transactionInfo[0].hash}}</div>
				</div>
			</div>
			<div class="good-detail-2">
				<div class="deal-info">交易信息</div>
				<div class="flex-row align-center detail-item">
					<div class="detail-item-title">交易模式</div>
					<div class="detail-item-con">C2C个人交易</div>
				</div>
				<!-- <div class="flex-row align-center detail-item" v-if="info.tokenId !='888888'">
					<div class="detail-item-title">换手次数</div>
					<div class="detail-item-con">{{info.changeHands}}次</div>
				</div> -->
			</div>
			<div class="recommend-title">历史公告</div>
			<div class="bg-grey">
				<van-list v-model="loading" @load="getPushMessageList" :finished="finished2" finished-text="没有更多了"
					:error.sync="error" error-text="请求失败，点击重新加载">
					<div class="news-list">
						<div v-for="(item,index) in  message" v-bind:key="index" class=" flex-row align-center item"
							@click.stop="navDetail(index)">
							<div class="item-img-sec">
								<img :src="item.pic" class="item-img" />
							</div>
							<div class="flex-column justify-center">
								<div class="item-title">{{item.title}}</div>
								<div class="item-sen-title">{{item.subTitle}}</div>
							</div>
						</div>
					</div>
				</van-list>
			</div>
			<div class="zhanwei"></div>
			<div class="flex-row align-center space-between good-price-sec">
				<div class="flex-column goods-price-sec-left">
					<div class="flex-row align-center">
						<div class="good-price-title">寄售价格：</div>
						<div class="good-price">￥{{info.salePrice}}</div>
					</div>
					<div class="goods-buy-price">购入价格：{{info.buyAmt !=''?'￥':''}}{{info.buyAmt}}</div>
				</div>
				<div class="btn-wrap flex-row align-center" v-if="info.isSelf == 1">
					<div class="btn-left" @click.stop="openSaleModal()">修改价格</div>
					<div class="btn-right" @click.stop="cancelConsignment()">撤回寄售</div>
				</div>
			</div>
			<div class="hang-sec-model" v-if="saleModal" @click.stop="closeSaleModal">
			</div>
			<div class="hang-sec" v-if="saleModal">
				<div class="hang-title">寄售详情</div>
				<div class="hang-item-sec">
					<div class="flex-row align-center space-between price-sec">
						<div class="price-title">请输入寄售价格：</div>
						<input class="price-input" @input="inputSalePrice" v-model="slaeInfo.salePrice" type="number"
							placeholder="点击设置寄售价格"  />
					</div>
					<div class="flex-row align-center space-between hang-item">
						<div class="hang-item-title">支付通道费：</div>
						<div class="hang-item-price"
							:class="slaeInfo.channelFee?'hang-item-price-active1':'hang-item-price'">
							-￥{{slaeInfo.channelFee}}</div>
					</div>
					<div class="flex-row align-center space-between hang-item">
						<div class="hang-item-title">技术服务费：</div>
						<div class="hang-item-price"
							:class="slaeInfo.txServiceCharge?'hang-item-price-active1':'hang-item-price'">
							-￥{{slaeInfo.txServiceCharge}}</div>
					</div>
					<!-- 	<div class="flex-row align-center space-between hang-item">
						<div class="hang-item-title">提现至银行卡手续费：</div>
						<div class="hang-item-price"
							:class="slaeInfo.serviceCharge?'hang-item-price-active1':'hang-item-price'">
							-￥{{slaeInfo.serviceCharge}}</div>
					</div> -->
					<div class="flex-row align-center space-between hang-item">
						<div class="hang-item-title">赋能收益：</div>
						<div class="hang-item-price"
							:class="slaeInfo.rights?'hang-item-price-active1':'hang-item-price'">
							+￥{{slaeInfo.rights}}</div>
					</div>
					<div class="flex-row align-center space-between hang-item">
						<div class="hang-item-title color-red">红包抵扣：</div>
						<div class="hang-item-price color-red">
							+￥{{slaeInfo.discountAmount}}</div>
					</div>
					<div class="flex-row align-center space-between hang-item">
						<div class="hang-item-title">商品出售所得：</div>
						<div class="hang-item-price"
							:class="slaeInfo.price?'hang-item-price-active1':'hang-item-price'">
							￥{{slaeInfo.price}}</div>
					</div>
					<div class="hang-grey-btn" v-if="!slaeInfo.salePrice || slaeInfo.salePrice<=0">确认修改</div>
					<div class="hang-btn" v-else @click.stop="modifyPrice()">确认修改</div>
				</div>
			</div>
		</template>
		<template v-else>
			<iframe id="myiframe" name="myiframe" :src="info.link3D" width="" height=""></iframe>
		</template>
	</div>
</template>
<script>
	import {
		Toast,
		Dialog,
		ImagePreview
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import 'vant/lib/index.css'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import QRCode from 'qrcodejs2';
	export default {
		data() {
			return {
				step: 1,
				saleModal: false,
				slaeInfo: {
					salePrice: '',
					serviceCharge: 0, //平台手续费
					rights: 0, //赋能权益：
					price: 0, //商品出售所得
					txServiceCharge: 0,
					channelFee: 0,
					discountAmount: 0
				},
				info: {},
				productEntryId: '',
				qrcodeImg: '',
				showQrcode: 0,
				showImg: 0,
				videoModal: 0,
				tkData: {},
				pid: 0,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished2: false,
				error: false,
				message: [],
				head: {},
				transactionInfo: [],
				show:0,
			}
		},
		methods: {
			getCerify(){
				const that=this;
				let params = new FormData()
				params.append('productId', that.pid)
				
				this.axios.post(config.requestUrl + '/front/nftProduct/queryCertificate', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'Token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear()
						that.head = response.data.obj.head;
						let transactionInfo = response.data.obj.transactionInfo
						that.transactionInfo = transactionInfo;
						that.show=1;
						// Toast.success('查询成功')
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					this.getBox = 0;
					Toast.fail('获取失败，请稍后重试');
				})
			},
			navDetail(index){
				this.$router.push('/newsDetail?id='+ this.message[index].id)
			},
			getPushMessageList() {
				const that = this
				let params = new FormData()
				params.append('pageNum', that.pageNum);
				params.append('pageSize', that.pageSize);
				params.append('consignmentId',that.productEntryId)
				that.axios.post(config.requestUrl + '/search/getPushMessageList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let newList = response.data.obj.list;
						let list = that.message;
						let finished = false;
						if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
							true;
						list = list.concat(newList);
						that.message = list;
						that.finished2 = finished;
						that.pageNum=that.pageNum+1
						that.error = false;
					} else {
						let msg = response.data.msg;
						if(msg=='') msg='获取失败，请稍后重试'
						Toast(msg);
						that.error = true;
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					that.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			nav3D() {
				const that = this;
				if (this.info.link3D != '' && this.info.link3D != null) {
					if (this.info.isOpen == '是') {
						document.title = this.info.name;
					} else {
						document.title = '盲盒';
					}
					if (this.info.link3D.indexOf('.jpg') != '-1') {
						this.showImg = 1;
						ImagePreview({
							images: [
								this.info.link3D
							],
							closeable: true,
							showIndex: false,
							overlayStyle: {
								background: '#000000'
							},
							onClose() {
								that.showImg = 0;
							},
						})
					} else if (this.info.link3D.indexOf('.mp4') != '-1') {
						this.videoModal = 1;
						let myVideo = document.getElementById('myVideo');
						myVideo.play();
						myVideo.addEventListener('pause', function() { //暂停
							console.log("暂停");
							that.videoModal = 0;
						});
						myVideo.addEventListener('ended', function() { //结束
							that.videoModal = 0;
						}, false);
					} else if (this.info.link3D == 'https://shop.metacbc.cn/models') {
						this.$router.push('/models')
					} else if (this.info.link3D == 'https://shop.metacbc.cn/models2') {
						this.$router.push('/models2')
					} else {
						this.step = 2;
						this.$forceUpdate()
					}
				}
			},
			getOperationsInfoByEntryId(productEntryId) {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', productEntryId)
				this.axios.post(config.requestUrl + '/front/agencyOperations/getOperationsInfoByEntryId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj) {
							that.tkData = response.data.obj;
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navTk() {
				this.$router.push('/tkdata?productEntryId=' + this.pid + '&price=' + this.info.tkOperationsWithdrawAmount)
			},
			openSaleModal() {
				this.saleModal = true;
			},
			closeSaleModal() {
				this.saleModal = false
			},
			cancelConsignment() {
				const that = this;
				Dialog.confirm({
						message: '确认要撤回寄售吗？',
						// confirmButtonText:'完成支付',
						// cancelButtonText:'取消支付'
					})
					.then(() => {
						let params = new FormData()
						params.append('consignmentId', that.productEntryId)
						this.axios.post(config.requestUrl + '/front/consignment/cancelConsignment', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								setTimeout(() => {
									this.$router.replace({
										path: '/backsuccess',
										query: {
											name: that.info.name
										}
									})
								}, 1200)
							} else {
								Toast(response.data.msg);
							}
						}, response => {
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('关闭了')
					});
			},
			getProductEntryInfoDetail() {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', that.productEntryId)
				this.axios.post(config.requestUrl + '/front/consignment/getProductEntryInfoDetail', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.info = response.data.obj
						that.getOperationsInfoByEntryId();
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			async inputSalePrice(e) {
				if (e.target.value && e.target.value > 0) {
					await this.getConsignmentFee()
				}
			},
			async getConsignmentFee() {
				const that = this;
				let params = new FormData()
				params.append('salePrice', that.slaeInfo.salePrice)
				params.append('consignmentId', that.productEntryId)
				await this.axios.post(config.requestUrl + '/front/consignment/getConsignmentFee', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.slaeInfo.serviceCharge = response.data.obj.platformFee;
						that.slaeInfo.rights = response.data.obj.rightsFee;
						that.slaeInfo.price = response.data.obj.incomeAmount;
						that.slaeInfo.txServiceCharge = response.data.obj.txServiceCharge;
						that.slaeInfo.channelFee = response.data.obj.channelFee;
						that.slaeInfo.discountAmount = response.data.obj.discountAmount
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			modifyPrice() {
				const that = this;
				if (!that.slaeInfo.salePrice || that.slaeInfo.salePrice <= 0) {
					Toast('请输入寄售价格')
					return;
				}
				if (!(/(^[1-9]\d*$)/.test(that.slaeInfo.salePrice))) {
					Toast('寄售价格必须为整数')
					return;
				}
				let maxPrice = 99999;
				if(that.info.maxPrice){
					maxPrice=that.info.maxPrice
				}
				if(that.slaeInfo.salePrice > maxPrice){
					Toast('最大寄售价格为'+maxPrice+'元')
					return;
				}
				let minPrice=0;
				if(that.info.minPrice){
					minPrice=that.info.minPrice;
				}
				if (that.slaeInfo.salePrice < minPrice) {
					Toast('最小寄售价格为' + minPrice + '元')
					return;
				}
				Toast.loading({
					message: '保存中...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('consignmentId', that.productEntryId)
				params.append('price', that.slaeInfo.salePrice)
				this.axios.post(config.requestUrl + '/front/consignment/modifyPrice', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.success('修改成功')
						that.saleModal = false;
						this.getConsignmentDetail();
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getConsignmentDetail() {
				const that = this
				let params = new FormData()
				params.append('consignmentId', that.productEntryId)
				that.axios.post(config.requestUrl + '/front/consignment/getConsignmentDetail', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					that.request = 0;
					if (response.data.code == 0) {
						that.info = response.data.obj;
						let iconType = 0;
						if (that.info.link3D != '' && that.info.link3D != null) {
							if (that.info.link3D.indexOf('.jpg') != '-1') {
								iconType = 1;
							} else if (that.info.link3D.indexOf('.mp4') != '-1') {
								iconType = 2;
							} else {
								iconType = 3;
							}
						}
						that.info.iconType = iconType;
						if (response.data.obj.isSelf == 0) {
							that.$router.replace(`/detail?id=${that.productEntryId}`)
						} else {

							that.slaeInfo.salePrice = response.data.obj.salePrice;
							that.slaeInfo.serviceCharge = response.data.obj.platformFee;
							that.slaeInfo.rights = response.data.obj.rightsFee;
							that.slaeInfo.price = response.data.obj.incomeAmount;
							that.slaeInfo.txServiceCharge = response.data.obj.txServiceCharge;
							that.slaeInfo.channelFee = response.data.obj.channelFee;
							that.slaeInfo.discountAmount = response.data.obj.discountAmount
							let productId = response.data.obj.productEntryIdMap.replace(/{/, "")
							productId = productId.replace(/}/, "")
							productId = productId.split(':');
							that.pid = productId[1];
							if (this.info.isOpen == '否' || productId.length > 2) {
								that.showQrcode = 0;
							} else {
								that.getOperationsInfoByEntryId(productId[1]);
								that.getCerify();
								
								productId = productId[1];
								let qrcode = new QRCode(that.$refs.qrCodeDiv, {
									text: config.qrcodeHost + 'certify?productId=' + productId,
									width: 200,
									height: 200,
									colorDark: "#000000", //二维码颜色
									colorLight: "#00FA9A", //二维码背景色
									correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
								})
								let canvas = qrcode._el.querySelector(
									"canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
								that.qrcodeImg = canvas.toDataURL("image/png");
								that.showQrcode = 1;

							}

							util.detailWxConfig(that.productEntryId, that.info.name, that.info.pic, that.info
								.isOpen, that.info.nickName);
						}

					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					that.request = 0;
					Toast('获取失败，请稍后重试');
				})
			},
		},
		mounted() {
			if (!this.$route.query.id) {
				this.$route.replace('/')
			}
			this.type = this.$route.query.type
			this.productEntryId = this.$route.query.id;
			if (this.type == 1) {
				this.getProductEntryInfoDetail();
				util.wxConfig();
			} else {
				this.getConsignmentDetail();
			}

		},
		beforeRouteLeave(to, from, next) {
			if (to.path == '/collect') {
				if (this.step == 2) {
					this.step = 1;
					document.title = 'C2C电商平台'
					next(false);
				} else if (this.showImg == 1) {
					this.showImg = 0;
					next(false);
				} else {
					next();
				}
			} else {
				next();
			}
		},
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		background: rgba(203, 203, 203, 0.1);
		min-height: 100%;
	}

	.for-img {
		position: relative;
	}

	.icon-3d {
		width: 100px;
		height: 100px;
		right: 36px;
		bottom: 36px;
		position: absolute;

	}

	.good-img {
		width: 750px;
		height: 840px;
	}

	.good-img2 {
		width: 750px;
		height: 750px;
		padding: 45px 0;
	}

	.good-name-sec {
		width: 750px;
		height: 120px;
		background: rgba(203, 203, 203, 0.1);
		position: relative;
	}

	.good-name-bg {
		width: 750px;
		height: 120px;
	}

	.good-name-con {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.good-name {
		font-size: 32px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 32px;
		margin-left: 40px;
	}

	.good-status-sec {
		width: 248px;
		height: 120px;
		position: relative;
	}

	.good-status-bg {
		width: 248px;
		height: 120px;
	}

	.good-status-con {
		position: absolute;
		width: 226px;
		height: 120px;
		left: 22px;
		top: 0;
	}

	.sale-icon {
		width: 132px;
		height: 40px;
		margin-top: 18px;
	}

	.good-status {
		font-size: 34px;
		font-family: Helvetica;
		color: #FFFFFF;
		line-height: 34px;
		margin-top: 8px;
	}

	.good-detail-1 {
		width: 750px;
		background: #FFFFFF;
		padding: 0 0 40px 40px;
		box-sizing: border-box;
	}

	.detail-item {
		padding-top: 30px;
	}

	.detail-item-title {
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
		width: 105px;
		flex-shrink: 0;
	}

	.detail-item-con {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
		margin-left: 46px;
		word-break: break-all;
		word-wrap: break-word;
	}

	.good-detail-2 {
		width: 750px;
		background: #FFFFFF;
		padding: 30px 0 40px 40px;
		box-sizing: border-box;
		margin-top: 10px;
	}

	.deal-info {
		font-size: 26px;
		color: #000000;
		line-height: 30px;
	}

	.zhanwei {
		height: 120px;
	}

	/* 底部购买部分 */
	.good-price-sec {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 750px;
		height: 130px;
		background: #FFFFFF;
		box-shadow: 0px -8px 19px 0px rgba(0, 0, 0, 0.07);
	}

	.good-price-title {
		font-size: 30px;
		color: #000000;
		line-height: 30px;
		margin-left: 40px;
		font-weight: 400;
	}

	.good-price {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FF0000;
		line-height: 30px;
	}

	.good-by-sec {
		width: 302px;
		height: 98px;
		position: relative;
	}

	.good-by-bg {
		width: 302px;
		height: 98px;
	}

	.buy-btn {
		position: absolute;
		left: 0;
		top: 0;
		width: 302px;
		height: 98px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 98px;
		margin-left: 116px;
	}

	/* 挂出寄售部分 */
	.hang-sec {
		width: 750px;
		height: 900px;
		background: #FFFFFF;
		border-radius: 20px 20px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
	}

	.hang-title {
		width: 750px;
		height: 88px;
		background: #6236FF;
		border-radius: 20px 20px 0px 0px;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 88px;
		text-align: center;
	}

	.hang-item-sec {
		padding: 0 40px;
	}

	.price-sec {
		width: 670px;
		height: 120px;
		background: #FFFFFF;
		box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.price-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
	}

	.price-input {
		font-size: 30px;
		border: none;
		text-align: right;
		font-weight: 600;
		color: #FF0101;
		line-height: 30px;
	}

	.price-input:focus {
		border: none !important;
	}

	/* 挂出寄售部分 */
	.hang-sec-model {
		width: 100%;
		height: 100%;
		background: #F4F4F4;
		min-height: 100%;
		background: #000000;
		opacity: 0.7;
		position: absolute;
		top: 0;
		left: 0;
	}

	.hang-item {
		width: 670px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
	}

	.hang-item-title {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 28px;
	}

	.hang-item-price {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 30px;
	}

	.hang-item-price-active {
		font-size: 30px;
		font-weight: 600;
		color: #FF0101;
		line-height: 30px;
	}

	.hang-item-price-active1 {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
	}

	.hang-grey-btn {
		width: 590px;
		height: 80px;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		margin: 134px auto 0 auto;
	}

	.hang-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		margin: 134px auto 0 auto;
	}

	.goods-buy-price {
		margin-left: 40px;
		margin-top: 25px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
	}

	.btn-wrap {
		width: 360px;
		height: 98px;
		background: linear-gradient(138deg, #FFC755 0%, #FF9624 100%);
		border-radius: 49px;
		padding: 34px 0;
		box-sizing: border-box;
		margin-right: 30px;

	}

	.btn-left {
		width: 50%;
		border-right: 3px solid #f2901c;
		text-align: center;
		box-sizing: border-box;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.btn-right {
		width: 50%;
		text-align: center;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	/*下面码*/
	.xian-img {
		width: 750px;
		height: 8px;
		vertical-align: bottom;
	}

	.qrcode-wrap {
		width: 750px;
		height: 292px;
		background: #000000;
	}

	.qrcode-wrap-left {
		width: 350px;
		margin-left: 40px;
	}

	.qrcode-goods-name {
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-fx {
		margin-top: 25px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-bh {
		margin-top: 23px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-goods-tk {
		margin-top: 14px;
		font-size: 19px;
		font-weight: bold;
		color: #FFFFFF;
		word-break: break-all;
		word-wrap: break-word;
	}

	.qrcode-wrap-right {
		width: 158px;
		margin-right: 48px;
	}

	.qrcode-img {
		width: 156px;
		height: 156px;
	}

	.qrcode-img img {
		width: 156px;
		height: 156px;
	}

	.qrcode-img image {
		width: 156px;
		height: 156px;
	}

	.qrcode-desc {
		margin-top: 20px;
		font-size: 25px;
		font-weight: bold;
		color: #FFFFFF;
	}

	.goods-img-wrap {
		width: 750px;
		height: 1000px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center -100px;
	}

	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}

	.num-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.num-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(#3FBDFF, #1E5EFF);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.num-wrap-con {
		padding: 8px;
		background: #E3EDFF;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #2369CC;
	}

	.ye-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.ye-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(#AA8AF7, #5850EC);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.ye-wrap-con {
		padding: 8px;
		background: rgba(98, 54, 255, 0.14);
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #523094;
	}

	.icon-play {
		width: 100px;
		height: 100px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	video {
		position: absolute;
		z-index: 10;
		left: 0;
		top: 0;
		width: 750px;
		height: 841px;
		background: rgba(0, 0, 0, 1);
	}

	.points-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.points-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(180deg, #FFB684 0%, #FF7311 100%);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.points-wrap-con {
		padding: 8px;
		background: #FFF0DD;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #FA6400;
	}

	.color-red {
		color: #FF0101;
	}

	.upoints-wrap {
		margin-top: 37px;
		margin-right: 36px;
	}

	.upoints-wrap-ti {
		padding: 10px 14px;
		border-radius: 6px;
		background: linear-gradient(180deg, #CECECE 0%, #8E8E8E 100%);
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.upoints-wrap-con {
		padding: 8px;
		background: #E7E7E7;
		border-radius: 4px;
		font-size: 24px;
		font-weight: 400;
		color: #767676;
	}
	.recommend-title {
		width: 750px;
		height: 90px;
		background: #F4F4F4;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 90px;
		padding-left: 40px;
		box-sizing: border-box;
		margin-top: 52px;
	}
	
	.item {
		width: 710px;
		height: 120px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-top: 20px;
		padding: 0 27px;
		box-sizing: border-box;
	}
	
	.item-img-sec {
		position: relative;
		margin-right: 37px;
	}
	
	.item-dot {
		position: absolute;
		z-index: 10;
		top: -3px;
		right: -3px;
		width: 16px;
		height: 16px;
		background: #FF0000;
		border-radius: 50%;
	}
	
	.item-img {
		width: 86px;
		height: 86px;
		border-radius: 10px;
	}
	
	.item-title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}
	
	.item-sen-title {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-top: 10px;
	}

	.num-wrap2 {
		margin-top: 10px;
		margin-right: 15px;
	}

	.num-wrap2:first-child {
		margin-top: 0;
	}
</style>