<template>
	<div class="container" ref="getheight">
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="getGoods(1)">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<van-list v-model="loading" @load="getGoods" :finished="finished" finished-text=" " :error.sync="error"
			error-text="请求失败，点击重新加载">
			<div class="good-list flex-row align-center flex-wrap space-between">
				<div class="good-item flex-column" v-for="(item,index) in list" v-bind:key="index"
					@click.stop="navDetail(index,item.id,item.currentMinPrice,item.productId)">
					<div class="relative">
						<div v-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period !=8"
							class="goods-item-wrap" :style="'background-image: url('+item.pic+');'">
						</div>
						<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period == 8"
							src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
							class="good-item-img3" />
						<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 1" :src="item.pic"
							class="good-item-img3" />
						<img v-else src="../assets/manghe.png" class="good-item-img2" />
						<div class="goods-nums2">x {{item.quantity}}</div>
						<img src="../assets/icon-ts2.png" v-if="item.quantity<=0" class="icon-ts" alt="">
					</div>
					<div class="good-item-con">
						<div class="good-item-name">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
						<div class="good-item-num2" v-if="item.isOpen =='否'">
							{{item.periodView}}
						</div>
						<div class="good-item-price" v-if="item.currentMinPrice">￥{{item.currentMinPrice}}</div>
					</div>
				</div>
			</div>
		</van-list>

		<div class="empty flex-column align-center justify-center" v-if="empty">
			<div class="empty-tip">无搜索结果</div>
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import dataCenterBus from '../config/dataCenterBus.js';
	export default {
		data() {
			return {
				keywords: '',
				list: [],
				empty: 0,
				more: true,
				pageNum: 1,
				pageSize: 10,
				windowHeight: 0,
				offsetHeight: 0,
				loading: false,
				finished: false,
				error: false,
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
			}
		},
		methods: {
			onScroll(e) {
				let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body
					.scrollTop; //页面滚动高度
				if (this.offsetHeight < scroll + this.windowHeight + 50) {
					if (this.more && !this.loading) {
						this.getGoods();
					}
				}
			},
			getGoods(verify) {
				// if (!this.more) {
				// 	Toast('没有更多了')
				// 	return;
				// }
				// if (this.loading) return;
				// this.loading = true;
				const that = this;
				if (!this.$store.state.user.token) {
					this.$router.replace('/')
					return;
				}
				let params = {
					likeName: this.keywords,
					sortBy: 'sale_price',
					order: 'asc',
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}
				if (verify) {
					params.codeValue = that.verifyCode;
					params.key = that.verifyKey;
				}
				this.axios.get(config.requestUrl + '/front/consignment/newListConsignment', {
					params,
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let resList = response.data.obj.list
						let newList=[];
						for(let i in resList){
							if(resList[i].quantity >0){
								newList.push(resList[i])
							}
						}
						let list = this.list;
						let finished = false;
						if (response.data.obj.isLastPage) finished = true;
						list = list.concat(newList);
						if (list.length == 0) this.empty = true;
						this.list = list;
						this.pageNum = this.pageNum + 1;
						that.finished = finished;
						that.showVerify = false;
						that.error = false;
						console.log(response)
					} else if (response.data.code == -7) {
						that.error = true;
						that.getVerifyCode()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						that.error = true;
					}
					this.loading = false;
				}, response => {
					Toast('获取失败，请稍后重试');
					that.error = true;
					this.loading = false;
				})
			},
			navDetail(index, id,price,productId) {
				this.$router.push({
					path: '/detail',
					query: {
						id,
						price,
						productId
					}
				})
			},
			getVerifyCode() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},
			refreshData(verify) {
				const that = this;
				if (!this.$store.state.user.token) {
					this.$router.replace('/')
					return;
				}
				let pageNum = this.pageNum > 5 ? 5 : this.pageNum
				let params = {
					likeName: this.keywords,
					sortBy: 'sale_price',
					order: 'asc',
					pageNum: 1,
					pageSize: pageNum * this.pageSize
				}
				if (verify) {
					params.codeValue = that.verifyCode;
					params.key = that.verifyKey;
				}
				this.axios.get(config.requestUrl + '/front/consignment/newListConsignment', {
					params,
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let resList = response.data.obj.list
						let newList=[];
						for(let i in resList){
							if(resList[i].quantity >0){
								newList.push(resList[i])
							}
						}
						let finished = false;
						if (response.data.obj.isLastPage) finished = true;
						if (newList.length == 0) this.empty = true;
						this.list = newList;
						that.finished = finished;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
		},
		mounted() {
			this.keywords = this.$route.query.keywords;
			if (this.keywords != undefined && this.keywords != '') {
				let history = localStorage.getItem('history');
				if (history) {
					history = JSON.parse(history);
					var bool = history.indexOf(this.keywords);
					if (bool == -1) {
						history.unshift(this.keywords);
					} else {
						history.splice(bool, 1);
						history.unshift(this.keywords);
					}
				} else {
					history = [this.keywords]
				}
				localStorage.setItem('history', JSON.stringify(history))
			}
			util.wxConfig();
			dataCenterBus.$on('refreshData', () => {
				console.log('asd')
				this.refreshData();
			});
			console.log('mouted')
		}
	}
</script>

<style scoped="scoped">
	.container {
		/* width: 100%;
		min-height: 100%;
		background: #f2f2f2;
		width: 100%;
	
		min-height: 100%;
		overflow: visible; */
		width: 100%;
		background: #F4F4F4;
		min-height: 100%;
		padding-bottom: 120px;
	}

	.empty {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}

	.empty-tip {
		font-size: 40px;
		color: #000000;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}
</style>
