<template>
	<div class="container">
		<div class="flex-column align-center" v-show="show">
			<img src="../assets/pay_suc_icon.png" class="pay-suc-icon" />
			<div class="good-name">「{{name}}」</div>
			<div class="good-name">数字藏品已购买成功！</div>
			<div @click.stop="navDetail()" class="pay-detail">查看详情</div>
			<div v-if="payType ==9" @click.stop="navHome()" class="go-home">回到首页</div>
			<div v-else @click.stop="navBack()" class="go-home">继续购买</div>
			<!-- <div @click.stop="navHome()" class="go-home">回到首页</div> -->
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import dataCenterBus from '../config/dataCenterBus.js';
	export default {
		data() {
			return {
				name: '',
				show: 0,
				orderId: '',
				payType: 0
			}
		},
		methods: {
			navBack() {
				dataCenterBus.$emit('refreshData')
				if (this.payType == 1) {
					this.$router.go(-3);
				} else if (this.payType == 8) {
					this.$router.go(-4);
				}
			},
			navHome() {
				this.$router.replace('/')
			},
			navDetail() {
				this.$router.replace('/collect')
			},
			getPayRes() {
				const that = this;

				Toast.loading({
					message: '正在获取支付结果...',
					forbidClick: true,
					duration: 0,
				});
				that.count = that.count + 1;
				let orderid = that.orderId;
				let params = new FormData()
				params.append('orderId', orderid)
				that.axios.post(config.requestUrl + '/front/order/queryOrderStatus', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj.orderStatus == 3 || response.data.obj
							.orderStatus == 5) {
							Toast.clear();
							clearInterval(that.getPayResInteval)
							this.show = 1;
						} else {
							that.getPayResInteval = setTimeout(() => {
								that.getPayRes();
							}, 2000)
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
		},
		beforeRouteLeave(to, from, next) {
			if (to.name == 'codesdpay') {
				dataCenterBus.$emit('refreshData')
				this.$router.go(-3);
			} else if (to.name == 'pay') {
				dataCenterBus.$emit('refreshData')
				this.$router.go(-2);
			} else {
				next()
			}
		},
		mounted() {
			util.wxConfig();
			if (this.$route.query.token) {
				let token = this.$route.query.token;
				this.$store.commit('setToken', token);
				let user = {}
				if (localStorage.getItem("user")) {
					user = JSON.parse(localStorage.getItem("user"))
					user.token = token;
				} else {
					user = {
						token
					}
				}
				localStorage.setItem('user', JSON.stringify(user))
			}
			this.name = this.$route.query.name
			this.payType = this.$route.query.payType
			if (!this.$route.query.orderId) {
				this.show = 1;
			} else {
				this.orderId = this.$route.query.orderId
				this.getPayRes();
			}
			
		}
	}
</script>

<style scoped="scoped">
	.pay-suc-icon {
		width: 200px;
		height: 200px;
		margin-top: 100px;
		margin-bottom: 50px;
	}

	.good-name {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		line-height: 40px;
	}

	.pay-detail {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		position: absolute;
		bottom: 290px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}

	.go-home {
		width: 590px;
		height: 80px;
		border: 1px solid #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		position: absolute;
		bottom: 170px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}
</style>
