<template>
	<div class="container" ref="getheight">
		<div class="wxb-modal" v-if="wxbModal">
			<div class="wxb-title">风险提示</div>
			<div class="wxb-con">根据网信办管理要求：</div>
			<div class="wxb-content">
				注册使用人应具备完全的民事行为能力，不参与炒作，对自己的买卖行为负完全法律责任。请增强自我保护意识，基于真实消费和收藏需求购买数字藏品，自觉抵制利用数字藏品投机炒作的行为，远离非法金融活动，保障自身财产安全。
			</div>
			<div class="wxb-btn" @click.stop="closeWxbModal()">确认</div>
		</div>
		<div class="model" v-if="wxbModal"></div>
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="getGoods(1)">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<LoginWrap v-if="showLogin" :logintype="type" v-on:close-login="closeLogin()" />
		<div class="flex-row align-center justify-center support-sec" style="flex-shrink: 0;" v-if="!islogin">
			<img src="../assets/support_icon.png" class="support-icon" />
			<div class="support-dian"></div>
			<div class="support-con">Meta链提供技术支持</div>
			<div class="login-btn" @click="login()">登录/注册</div>
		</div>
		<van-notice-bar style="flex-shrink: 0;" :speed="speed" color="#ffffff" background="#000000" v-if="islogin" scrollable :text="text" />
		<div style="flex-shrink: 0;" class="search-sec" @click.stop="search()">搜索</div>
		<div style="flex-shrink: 0;" class="tab-sec flex-row align-center flex-wrap">

			<div class="tab-item flex-row align-center  space-between" v-for="(item,index) in areaList"
				v-bind:key="'c'+index" @click="changTab(index)" :class="tab==index?'tab-item-active':''">
				<div class="tab-item-name">{{item.name}}</div>
			</div>
			<div class="tab-item flex-row align-center  space-between" @click.stop="changeTab2(-1)"
				:class="tab==-1?'tab-item-active':''">
				<div class="tab-item-name">价格</div>
				<img src="../assets/icon-price.png" class="tab-item-icon2" v-show="tab == -1" />
			</div>
			<div class="tab-item flex-row align-center  space-between" v-for="(item,index) in catenaList"
				v-bind:key="index" @click="navPeriod(index)">
				<div class="tab-item-name">{{item.name}}</div>
			</div>
		</div>
		<div style="flex-shrink: 0;" class='good-list-wrap' v-if="tab>-1">
			<van-list v-model="loading" @load="getGoods" :finished="finished" finished-text="没有更多了"
				:immediate-check="check" :error.sync="error" error-text="请求失败，点击重新加载">
				<div class="good-list flex-row align-center flex-wrap space-between">
					<div class="good-item flex-column" v-for="(item,index) in list" v-bind:key="index"
						@click="navDetail(index,item.id,item.currentMinPrice,item.productId)">
						<div class="relative">
							<div v-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period !=8"
								class="goods-item-wrap" :style="'background-image: url('+item.pic+');'">
							</div>
							<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period == 8"
								src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
								class="good-item-img3" />
							<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 1" :src="item.pic"
								class="good-item-img3" />
							<img v-else src="../assets/manghe.png" class="good-item-img2" />
							<div class="goods-nums2">x {{item.quantity}}</div>
							<img src="../assets/icon-ts2.png" v-if="item.quantity<=0" class="icon-ts" alt="">
						</div>
						<div class="good-item-con">
							<div class="good-item-name">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
							<div class="good-item-num2" v-if="item.isOpen =='否'">
								{{item.periodView}}
							</div>
							<div class="good-item-price" v-if="item.currentMinPrice">￥{{item.currentMinPrice}}</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>

		<div style="flex-shrink: 0;" class='good-list-wrap' v-if="tab == -1">
			<van-list v-model="loading" @load="getGoods2" :finished="finished2" finished-text="没有更多了"
				:error.sync="error2" error-text="请求失败，点击重新加载">
				<div class="good-list flex-row align-center flex-wrap space-between">
					<div class="good-item-new flex-column" v-for="(item,index) in list2" v-bind:key="index"
						@click="navDetail2(index,item.id,item.currentMinPrice,item.productId)">
						<div v-if="item.isOpen == '是' &&  item.isAll == 1 && item.period !=8" class="goods-item-wrap"
							:style="'background-image: url('+item.pic+');'">
						</div>
						<img v-else-if="item.isOpen == '是' &&  item.isAll == 1 && item.period == 8"
							src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
							class="good-item-img3" />
						<img v-else-if="item.isOpen == '是' &&  item.isAll == 0" :src="item.pic"
							class="good-item-img3" />
						<img v-else src="../assets/manghe.png" class="good-item-img2" />
						<div class="good-item-con">
							<div class="goods-name-wrap flex-row align-center space-between"
								v-if="item.userCollectCount !=null">
								<div class="good-item-name2">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
								<div class="collect-wrap flex-row align-center">
									<img src="../assets/shop/icon-star.png" class="icon-collect" alt="">
									<div class="collect-num">{{item.userCollectCount}}</div>
								</div>
							</div>
							<div class="good-item-name" v-else>{{item.isOpen == '是'?item.name:'盲盒'}}</div>
							<!-- <div class="good-item-name">{{item.isOpen == '是'?item.name:'盲盒'}}</div> -->
							<div class="flex-row align-center space-between goods-mt" v-if="item.mergeRecordCount>0">
								<div class="good-item-num2">
									{{item.periodView?item.periodView:item.position}}
								</div>
								<div class="compound-num-wrap flex-row align-center">
									<div class="compound-num-wrap-ti">实际数量</div>
									<div class="compound-num-wrap-con">{{item.mergeRecordCount}}</div>
								</div>
							</div>
							<div class="good-item-num" v-else>
								{{item.periodView?item.periodView:item.position}}
							</div>
							<div class="flex-row align-center space-between goods-mt" v-if="item.income !=null">
								<div class="good-item-price2">￥{{item.salePrice}}</div>
								<div class="goods-balance-wrap flex-row align-center">
									<div class="goods-balance-wrap-ti">余额</div>
									<div class="goods-balance-wrap-con">{{item.income}}</div>
								</div>
							</div>
							<div class="good-item-price" v-else>￥{{item.salePrice}}</div>
							<div class="flex-row align-center space-between pt-wrap"
								v-if="item.havePoints !=null || item.surplusPoints !=null || (item.tkOperationsWithdrawAmount !=null &&  item.tkOperationsWithdrawAmount >0)">
								<div></div>
								<div class="for-goods-pt flex-row align-center">
									<div class="goods-balance-wrap flex-row align-center"
										v-if="item.tkOperationsWithdrawAmount !=null &&  item.tkOperationsWithdrawAmount >0">
										<div class="goods-balance-wrap-ti">余额</div>
										<div class="goods-balance-wrap-con">{{item.tkOperationsWithdrawAmount}}</div>
									</div>
									<div class="goods-upt-wrap flex-row align-center" v-if="item.surplusPoints !=null">
										<div class="goods-upt-wrap-ti">未发放</div>
										<div class="goods-upt-wrap-con">{{item.surplusPoints}}</div>
									</div>
									<div class="goods-pt-wrap flex-row align-center" v-if="item.havePoints !=null">
										<div class="goods-pt-wrap-ti">积分</div>
										<div class="goods-pt-wrap-con">{{item.havePoints}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>



		<div class="flex-row align-center justify-center support-sec" style="margin-top: auto;flex-shrink: 0;">
			<img src="../assets/support_icon.png" class="support-icon" />
			<div class="support-dian"></div>
			<div class="support-con">Meta链提供技术支持</div>
		</div>
		<div class="download-app-tips flex-row align-center justify-center" v-if="downloadModal">
			<img src="../assets/close-download.png" class="close-dl-img" alt="" @click.stop="closeDownloadModal()">
			<img src="../assets/icon-download.png" class="icon-download" alt="" @click.stop="navDownload()">
		</div>
		<div class="icp flex-row align-center space-around">
			<div @click.stop="navBa()">沪ICP备17003041号-4</div>
			<div class="flex-row align-center">
				<div @click.stop="navBa2()">沪公网安备 31011402009626号</div>
			</div>
		</div>
		<div class="bot-tab-sec flex-row align-center">
			<div class="bot-tab-item flex-column align-center bot-tab-item-active justify-center">
				<img src="../assets/home_selected.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">C2C</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navMsg()">
				<img src="../assets/tab/msg2.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">公告</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navCollect()">
				<img src="../assets/collect_tab.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的藏品</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navMember()">
				<img src="../assets/mine.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的</div>
			</div>
		</div>
	</div>
</template>
<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import LoginWrap from '@/components/Login.vue';
	import dataCenterBus from '../config/dataCenterBus.js';
	import {
		Toast,
		// Sticky,
		List,
		Dialog,
		NoticeBar
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import 'vant/lib/sticky/index.css'
	import 'vant/lib/list/index.css';
	import 'vant/lib/dialog/index.css';
	import 'vant/lib/notice-bar/index.css';
	import Vue from 'vue';
	Vue.use(List);
	Vue.use(NoticeBar);
	// Vue.use(Sticky);
	export default {
		data() {
			return {
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				tab: 0,
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 30,
				loading: false,
				windowHeight: 0,
				offsetHeight: 0,
				showLogin: false,
				sortBy: 'sale_price',
				order: 'asc',
				finished: false,
				error: false,
				catenaList: [],
				downloadModal: 0,
				wxbModal: 0,
				areaList: [],
				check: false,
				list2: [],
				more2: true,
				pageNum2: 1,
				pageSize2: 30,
				loading2: false,
				finished2: false,
				error2: false,
				type: '1',
				text: '',
				speed: 100
			}
		},
		components: {
			LoginWrap,
		},
		computed: {
			islogin() {
				return this.$store.state.user.token ? true : false
			},
			userPic() {
				return this.$store.state.user.userPic
			},
			nickname() {
				return this.$store.state.user.nickName ? this.$store.state.user.nickName : this.$store.state.user.name
			}
		},
		methods: {
			getConsignmentOrderDealList() {
				const that = this;
				let params = new FormData()
				params.append('pageSize', 7)
				this.axios.post(config.requestUrl + '/front/order/getConsignmentOrderDealList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					let list = response.data.obj;
					let str = '最新成交: ';
					for (let i in list) {
						let time = list[i].createTime
						str += list[i].productName + '　' + list[i].amount + '元　' + time + "　　"
					}
					that.text = str

					setTimeout(() => {
						that.getConsignmentOrderDealList();
					}, 15000)
					console.log(str)

				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			bindUserBySendCode(inviteCode) {
				const that = this;
				let params = new FormData()
				params.append('inviteCode', inviteCode)
				this.axios.post(config.requestUrl + '/front/bindUserBySendCode_new', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					console.log(response)
					if (response.data.code != 0) {
						Toast.fail(response.data.msg)
					} else {
						Toast('绑定成功')
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			changeTab(tab) {
				this.tab = tab;
				this.sortBy = 'create_time';
				this.order = 'desc';
				if (this.tab == 2) {
					this.sortBy = 'sale_price';
					this.order = 'asc';
				} else if (this.tab == 3) {
					this.sortBy = 'change_hands';
				}
				this.list = [];
				this.more = true;
				this.pageNum = 1
				this.finished = false;
				this.error = false;
				this.getGoods();
			},
			navMsg() {
				this.$router.push('/newsList')
			},
			closeWxbModal() {
				this.wxbModal = 0;
				sessionStorage.setItem('showWxb', 1)
				let today = util.getToday();
				localStorage.setItem('wxbDate', today)
			},
			navDownload() {
				this.$router.push('/download')
			},
			closeDownloadModal() {
				this.downloadModal = 0;
				sessionStorage.setItem('showDownload', 1)
			},
			getVerifyCode() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			changeTab2(tab) {
				this.tab = tab;
				this.list2 = [];
				this.more2 = true;
				this.pageNum2 = 1
				this.finished2 = false;
				this.error2 = false;
				this.getGoods2();
			},
			changTab(tab) {
				this.tab = tab;
				this.list = [];
				this.more = true;
				this.pageNum = 1
				this.finished = false;
				this.error = false;
				this.getGoods();
			},
			search() {
				const that = this;
				if (!that.islogin) {
					that.login();
					return;
				} else {
					this.$router.push('/search')
				}
			},
			async getGoods2(verify) {
				const that = this;
				that.loading2 = true;
				let params = {
					likeName: that.keywords,
					sortBy: that.sortBy,
					order: that.order,
					pageNum: that.pageNum2,
					pageSize: that.pageSize
				};
				that.pageNum2 = that.pageNum2 + 1;
				if (verify) {
					that.pageNum2 = that.pageNum2 - 1
					params.codeValue = that.verifyCode;
					params.key = that.verifyKey;
					params.pageNum = that.pageNum2;
				}
				await that.axios.get(config.requestUrl + '/front/consignment/oldListConsignment', {
					params,
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token ? that.$store.state.user.token : ''
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].isAll = 0;
							let productId = newList[i].productEntryIdMap;
							if (productId.length > 0) {
								productId = productId.replace(/{/, "")
								productId = productId.replace(/}/, "")
								productId = productId.split(':');
								if (productId.length > 2) {
									newList[i].isAll = 1;
								}
							}
						}
						let list = that.list2;
						let finished = false;
						if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
							true;
						list = list.concat(newList);
						that.list2 = list;

						that.finished2 = finished;
						that.error2 = false;
						that.showVerify = false;
					} else if (response.data.code == -7) {
						that.error2 = true;
						that.getVerifyCode()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							that.showLogin = true;
						}
						Toast(response.data.msg);
						that.error2 = true;
					}
					that.loading2 = false;
				}, response => {
					that.loading2 = false;
					that.error2 = true;
					Toast('获取失败，请稍后重试');
				})
			},
			async getGoods(verify) {
				const that = this;
				if (!that.areaList[this.tab]) return;
				that.loading = true;
				let tab = that.tab;
				let params = {
					areaId: that.areaList[this.tab].id,
					pageNum: that.pageNum,
					pageSize: that.pageSize
				};
				that.pageNum = that.pageNum + 1;
				if (verify) {
					that.pageNum = that.pageNum - 1
					params.codeValue = that.verifyCode;
					params.key = that.verifyKey;
					params.pageNum = that.pageNum;
				}
				await that.axios.get(config.requestUrl + '/front/consignment/getAreaProductListByAreaId', {
					params,
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token ? that.$store.state.user.token : ''
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (tab == that.tab) {
							Toast.clear();
							let newList = response.data.obj.list;
							for (let i in newList) {
								newList[i].isAll = 0;
								let productId = newList[i].productEntryIdMap;
								if (productId.length > 0) {
									productId = productId.replace(/{/, "")
									productId = productId.replace(/}/, "")
									productId = productId.split(':');
									if (productId.length > 2) {
										newList[i].isAll = 1;
									}
								}
							}
							let list = that.list;
							let finished = false;
							if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
								true;
							if (newList) {
								list = list.concat(newList);
							}
							that.list = list;

							that.finished = finished;
							that.error = false;
							that.showVerify = false;
						}
					} else if (response.data.code == -7) {
						that.error = true;
						that.getVerifyCode()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							that.showLogin = true;
						}
						Toast(response.data.msg);
						that.error = true;
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					that.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},
			navDetail(index, id, price, productId) {
				this.$router.push({
					path: '/detail',
					query: {
						id,
						price,
						productId
					}
				})
			},
			navDetail2(index, id, price, productId) {
				if (!this.$store.state.user.token) {
					this.login()
				} else {
					let item = this.list2[index]
					this.$router.push({
						path: '/pay',
						query: {
							id: id,
							name: item.name,
							productPic: item.isOpen == '是' ? item.pic : '../assets/manghe.png',
							productEntryNo: item.isOpen == '是' ? item.productEntryNo : item.periodView,
							salePrice: item.salePrice,
							isOpen: item.isOpen
						}
					})
				}
			},
			navCollect() {
				if (!this.$store.state.user.token) {
					this.login()
				} else {
					this.$router.replace('/collect')
				}
			},
			navMember() {
				if (!this.$store.state.user.token) {
					this.login()
				} else {
					this.$router.replace('/member')
				}
			},
			login(type = '1') {
				console.log(type)
				this.type = type
				console.log(this.type)
				this.showLogin = true;
				this.$forceUpdate()
			},
			closeLogin() {
				this.showLogin = false;
			},
			closeLogin2() {
				console.log(1)
			},
			navBa2() {
				window.location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009626"
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			},
			getProductCatenaList() {
				const that = this;
				that.axios.get(config.requestUrl + '/front/consignment/getProductCatenaList', {
					headers: {
						'Content-Type': 'multipart/form-data',
						// 'token': that.$store.state.user.token?that.$store.state.user.token:''
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.catenaList = response.data.obj.productCatenaList;
						that.areaList = response.data.obj.areaList;
						if (that.areaList[0]) {
							that.getGoods();
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							that.showLogin = true;
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navPeriod(index) {
				this.$router.push({
					path: '/catena',
					query: {
						name: this.catenaList[index].name,
						id: this.catenaList[index].id,
					}
				})
			},
			sayHi() {
				this.$emit('refreshData')
			},
			async refreshData() {
				// const that = this;

				// that.loading = true;
				// let tab = that.tab;
				// let pageNum = that.pageNum > 5 ? 5 : that.pageNum
				// let params = {
				// 	likeName: that.keywords,
				// 	sortBy: that.sortBy,
				// 	order: that.order,
				// 	pageNum: 1,
				// 	pageSize: that.pageSize * pageNum
				// };
				// await that.axios.get(config.requestUrl + '/front/consignment/listConsignment', {
				// 	params,
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data',
				// 		'token': that.$store.state.user.token ? that.$store.state.user.token : ''
				// 	}
				// }).then(response => {
				// 	if (response.data.code == 0) {
				// 		if (tab == that.tab) {
				// 			Toast.clear();
				// 			let newList = response.data.obj.list;
				// 			for (let i in newList) {
				// 				newList[i].isAll = 0;
				// 				let productId = newList[i].productEntryIdMap;
				// 				if (productId.length > 0) {
				// 					productId = productId.replace(/{/, "")
				// 					productId = productId.replace(/}/, "")
				// 					productId = productId.split(':');
				// 					if (productId.length > 2) {
				// 						newList[i].isAll = 1;
				// 					}
				// 				}
				// 			}
				// 			let list = newList;
				// 			let finished = false;
				// 			if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
				// 				true;
				// 			list = list.concat(newList);
				// 			that.list = list;

				// 			that.finished = finished;
				// 			that.error = false;
				// 			that.showVerify = false;
				// 		}
				// 	} else {
				// 		let msg = response.data.msg;
				// 		const str = "未获取到用户信息";
				// 		const str2 = "登录已失效"
				// 		if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
				// 			localStorage.removeItem("user");
				// 			that.$store.commit("clearUser");
				// 			that.$store.commit("clearCode");
				// 			that.showLogin = true;
				// 		}
				// 		Toast(response.data.msg);
				// 	}
				// }, response => {
				// 	Toast('获取失败，请稍后重试');
				// })
			}
		},
		mounted() {
			util.wxConfig();
			if (this.$route.query.pid) {
				this.$router.push({
					path: '/detail',
					query: {
						id: this.$route.query.pid
					}
				})
			}
			if (this.$route.query.inviteCode) {
				sessionStorage.setItem('inviteCode', this.$route.query.inviteCode)
				this.$store.commit('setInviteCode', this.$route.query.inviteCode);
				if (this.islogin) {
					// Dialog.confirm({
					// 	message: '确认加入团队?',
					// }).then(() => {
					// 	console.log(111)
					// 	this.bindUserBySendCode(this.$route.query.inviteCode)
					// }).catch(() => {
					// 	console.log('取消')
					// 	// on cancel
					// });
				} else {
					console.log('dasdas')
					this.login('2');
				}

				// Dialog.alert({
				// 	message: '本期活动已结束',
				// }).then(() => {
				// 	console.log(111)
				// 	// on close
				// });
			}
			this.getProductCatenaList();

			dataCenterBus.$on('refreshData', () => {
				this.refreshData();
			});
			var u = navigator.userAgent,
				app = navigator.appVersion;
			var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
			var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			if (isAndroid) {
				if (!sessionStorage.getItem('showDownload')) {
					this.downloadModal = 1;
				}
			}

			// if (!sessionStorage.getItem('showWxb')) {
			// 	this.wxbModal = 1;
			// }
			let today = util.getToday();
			if (localStorage.getItem('wxbDate') != today) {
				this.wxbModal = 1;
			}
			this.getConsignmentOrderDealList();
		},
		beforeRouteEnter(to, from, next) {
			if (from.name == 'detail' || from.name == 'paysuccess') {
				next();
			} else {
				next(that => {
					that.tab = 0;
					that.sortBy = 'sale_price';
					that.order = 'asc';
					that.list = [];
					that.more = true;
					that.pageNum = 1
					that.finished = false;
					that.loading = true;
					that.getGoods();
				});
			}
		},
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100vh;
		background: #F4F4F4;
		overflow: scroll;

		box-sizing: content-box;
		padding-bottom: 150px;
		display: flex;
		/* min-height: 100vh; */
		flex-direction: column;
	}

	.search-sec {
		width: 690px;
		height: 70px;
		background: #FFFFFF;
		border-radius: 10px;
		font-size: 30px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 70px;
		margin: 20px auto 0 auto;
		padding-left: 30px;
		box-sizing: border-box;
	}

	.tab-sec {
		margin-left: 30px;
		background: #F4F4F4;
		padding: 24px 0 10px;
	}

	.tab-item {
		margin-right: 30px;
		margin-bottom: 15px;
		padding: 7px 0;
	}

	.tab-item-name {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
	}

	.tab-item-active {
		height: 40px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 7px 13px 7px 10px;
		/* padding: 7px 13px 7px 10px; */
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 26px;
	}

	.tab-item-icon {
		width: 36px;
		height: 19px;
		margin-left: 8px;
	}

	.tab-item-icon-1 {
		width: 22px;
		height: 22px;
		margin-left: 8px;
	}

	.tab-item-icon2 {
		width: 22px;
		height: 22px;
		margin-left: 10px;
	}

	.tab-item-icon3 {
		width: 22px;
		height: 22px;
		margin-left: 10px;
	}

	.login-btn {
		width: 137px;
		height: 50px;
		background: #6236FF;
		border-radius: 34px;
		text-align: center;
		line-height: 50px;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
		position: absolute;
		top: 0;
		right: 30px;
		bottom: 0;
		margin: auto;
	}



	.bot-tab-sec {
		bottom: 50px !important;
	}

	.good-list-wrap {
		/* padding-bottom: 150px; */
		background: #F4F4F4;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}

	.download-app-tips {
		position: fixed;
		bottom: 145px;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 90px;
		background-image: url(../assets/download-btn-bg.png);
		background-size: 750px 90px;
	}

	.close-dl-img {
		width: 30px;
		height: 30px;
		margin-right: 30px;
	}

	.icon-download {
		width: 263px;
		height: 57px;
	}

	.wxb-modal {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		position: fixed;
		top: 0%;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1001
	}

	.wxb-title {
		width: 630px;
		height: 110px;
		line-height: 110px;
		text-align: center;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
		border-radius: 20px 20px 0px 0px;
	}

	.wxb-con {
		width: 630px;
		padding: 0 52px;
		box-sizing: border-box;
		margin-top: 26px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
		line-height: 50px;
	}

	.wxb-content {
		width: 630px;
		padding: 0 52px;
		box-sizing: border-box;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 50px;
	}

	.wxb-btn {
		width: 630px;
		height: 88px;
		margin-top: 50px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 20px 20px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 600;
		color: #6236FF;
	}

	.support-sec2 {
		position: fixed !important;
		top: 0%;
		left: 0;
	}

	/* @notice-bar-font-size{
		font-size:60px
	} */

	/* .van-notice-bar {
		font-size: 60px !important
	} */
</style>