<template>
	<div class="container">
		<div class="model" v-if="editNameModal" @click.stop="cancelModal"></div>
		<div class="edit-wrap flex-column align-center" v-if="editNameModal">
			<div class="edit-title">修改昵称</div>
			<div class="edit-line"></div>
			<input type="text" maxlength="10" oninput="if(value.length > 10)value = value.slice(0, 10)" v-model="name"
				class="edit-input" placeholder="请输入昵称">
			<div class="edit-line"></div>
			<div class="edit-btn" @click="doEdit()">确认修改</div>
		</div>
		<div class="member-wrap">
			<div class="page-title-wrap ">
				<div class="page-title">我的</div>
				<img src="../assets/page-bg.png" alt="" class="page-title-bg">
				<div class="flex-row align-center loginout-wrap" @click="loginout()">
					<img src="../assets/loginout2.png" class="loginout-img" />
					<div class="loginout-text">退出登录</div>
				</div>
			</div>
			<div class="user-wrap flex-row align-center">
				<img v-if="user.pic" :src="user.pic" class="headimg">
				<img v-else src="../assets/good1.png" class="headimg">

				<div class="user-right flex-column space-between">
					<div class="username-tip">昵称</div>
					<div class="flex-row align-center" @click.stop="showEditNameModal()">
						<div class="username">{{user.name ? user.name:user.randName}}</div>
						<img src="../assets/edit2.png" class="edit-img" alt="">
					</div>
				</div>
			</div>
			<div class="info-wrap">
				<div class="info-con flex-row align-center">
					<div class="info-left flex-column justify-center" @click.stop="navSr()">
						<div class="info-collect-num">{{srPrice}}元</div>
						<div class="info-collect-wrap flex-row align-center justify-center">
							<img src="../assets/sr.png" class="info-collect-img">
							<div class="info-collect-text">收入</div>
						</div>
					</div>
					<div class="info-right" @click.stop="navBalance()">
						<div class="info-collect-num">{{balance}}元</div>
						<div class="info-collect-wrap flex-row align-center justify-center">
							<img src="../assets/member-collect.png" class="info-collect-img">
							<div class="info-collect-text">余额</div>
						</div>
					</div>
				</div>
			</div>
			<div class="menu-wrap flex-row align-center  space-around">
				<div class="menu-item flex-column align-center" @click.stop="openModal()">
					<img src="../assets/icon-group.png" class="menu-img">
					<div class="menu-name">加入社群</div>
				</div>
				<div class="menu-item flex-column align-center" @click.stop="openModal()">
					<img src="../assets/icon-custom.png" class="menu-img">
					<div class="menu-name">客服</div>
				</div>
				<div class="menu-item flex-column align-center" @click.stop="openModal()">
					<img src="../assets/icon-public.png" class="menu-img">
					<div class="menu-name">关注公众号</div>
				</div>
			</div>
			<div class="menu-wrap2  ">
				<div class="menu-wrap2-top flex-row align-center  space-around">
					<div class="menu-item flex-column align-center" @click.stop="navOrderList()">
						<img src="../assets/dzf.png" class="menu-img">
						<div class="menu-name">待支付</div>
					</div>
					<div class="menu-item flex-column align-center" @click.stop="navInvite()">
						<img src="../assets/invite.png" class="menu-img">
						<div class="menu-name">邀请</div>
					</div>
					<div class="menu-item flex-column align-center" @click.stop="navCompose()">
						<img src="../assets/icon-compound.png" class="menu-img">
						<div class="menu-name">合成</div>
					</div>
				</div>
				<div class="menu-wrap2-bottom flex-row align-center  space-around">
					<div class="menu-item flex-column align-center" @click.stop="navDecompose()">
						<img src="../assets/icon-fj.png" class="menu-img">
						<div class="menu-name">分解</div>
					</div>
					<div class="menu-item flex-column align-center" @click.stop="navSubstitution()">
						<img src="../assets/icon-th.png" class="menu-img">
						<div class="menu-name">置换</div>
					</div>

					<div class="menu-item flex-column align-center" @click.stop="navPrior()">
						<img src="../assets/yxg.png" class="menu-img">
						<div class="menu-name">优先购</div>
					</div>
				</div>
				<div class="menu-wrap3-bottom flex-row align-center  space-around">
					<div class="menu-item flex-column align-center" @click.stop="navPoints()">
						<img src="../assets/points/points.png" class="menu-img">
						<div class="menu-name">消费积分</div>
					</div>
					<div class="menu-item flex-column align-center" @click.stop="navCard()">
						<img src="../assets/points/card.png" class="menu-img">
						<div class="menu-name">红包卡券</div>
					</div>
					<div class="menu-item flex-column align-center" @click.stop="navExtension()">
						<img src="../assets/destroy/icon.png" class="menu-img">
						<div class="menu-name">推广卡</div>
					</div>
				</div>
				<div class="menu-wrap3-bottom flex-row align-center  space-around">
					<!-- <div class="menu-item flex-column align-center" @click.stop="navYb()">
						<img src="../assets/icon-yb.png" class="menu-img">
						<div class="menu-name">易宝钱包</div>
					</div> -->
					<div class="menu-item flex-column align-center" @click.stop="navShop()">
						<img src="../assets/shop/icon-shop.png" class="menu-img">
						<div class="menu-name">古玩店</div>
					</div>
					<div class="menu-item flex-column align-center" @click.stop="navHistoryOrder()">
						<img src="../assets/icon-order.png" class="menu-img">
						<div class="menu-name">历史订单</div>
					</div>
					<!-- <div class="menu-item"></div> -->
					<div class="menu-item flex-column align-center" @click.stop="navEntry()">
						<img src="../assets/fnsy.png" class="menu-img">
						<div class="menu-name">赋能实业</div>
					</div>
				</div>
				<div class="menu-wrap3-bottom flex-row align-center  space-around">

					<div class="menu-item flex-column align-center" @click.stop="navDestroy()">
						<img src="../assets/xh.png" class="menu-img">
						<div class="menu-name">销毁</div>
					</div>
					<div class="menu-item flex-column align-center" @click.stop="navExchange()">
						<img src="../assets/icon-exchange.png" class="menu-img">
						<div class="menu-name">兑换券</div>
					</div>
					<!-- <div class="menu-item"></div> -->
					<div class="menu-item"></div>
				</div>
			</div>
		</div>
		<div class="public-modal-shaw" @click.stop="closeModal()" v-show="publicModal"></div>
		<div class="public-modal flex-column align-center" v-show="publicModal">
			<img src="../assets/qrcode.jpg" ref='qrcode' class="qrcode">
			<div class="public-text">扫码关注公众号</div>
			<div class="public-sbtn" @click.stop="saveQrocde()">保存二维码</div>
			<div class="public-close-btn" @click.stop="closeModal()">关闭</div>
		</div>
		<div class="icp flex-row align-center space-around">
			<div @click.stop="navBa()">沪ICP备17003041号-4</div>
			<div class="flex-row align-center">
				<!-- <img src="../assets/beiab.png" class="beiab-img"> -->
				<div @click.stop="navBa2()">沪公网安备 31011402009626号</div>
			</div>
		</div>
		<div class="bot-tab-sec flex-row align-center">
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navHome()">
				<img src="../assets/home.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">C2C</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navMsg()">
				<img src="../assets/tab/msg2.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">公告</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navCollect()">
				<img src="../assets/collect_tab.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的藏品</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center bot-tab-item-active">
				<img src="../assets/mine_select.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';

	export default {
		data() {
			return {
				user: {},
				balance: '',
				productSize: '',
				publicModal: false,
				srPrice: '',
				editNameModal: 0,
				name: ''
			}
		},
		methods: {
			navExchange() {
				this.$router.push('/box/card')
			},
			navMsg() {
				this.$router.push('/newsList')
			},
			navHistoryOrder() {
				this.$router.push('/historyorder')
			},
			navExtension() {
				this.$router.push('extension')
			},
			navYb() {
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/financial/createYiBaoUserWallet', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						window.location.href = response.data.obj
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			showEditNameModal() {
				this.name = ''
				this.editNameModal = 1;
			},
			cancelModal() {
				this.editNameModal = 0;
			},
			doEdit() {
				const that = this;
				if (that.name == '') {
					Toast('请输入昵称');
					return;
				}
				let name = that.name
				for (let i = 0; i < 10; i++) {
					name = name.replace(/[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/, '')
				}
				let params = new FormData()
				params.append('newNickName', name);
				this.axios.post(config.requestUrl + '/front/user/changeNickName', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast('修改成功');
						that.editNameModal = 0;
						this.getMember()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navPoints() {
				this.$router.push('/points/box')
			},
			navCard() {
				this.$router.push('/points/card')
			},
			navShop() {
				this.$router.push('/shop/list')
			},
			navDestroy() {
				this.$router.push('/destroy')
			},
			navPrior() {
				this.$router.push('/prior')
			},
			navCollect() {
				this.$router.replace('/collect')
			},
			navHome() {
				this.$router.replace('/')
			},
			navCompose() {
				this.$router.push('/compose')
			},
			navBalance() {
				this.$router.push('/balance')
			},
			navDecompose() {
				this.$router.push('/decompose')
			},
			navSubstitution() {
				this.$router.push('/substitution')
			},
			getMember() {
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.getBox = 0;
					if (response.data.code == 0) {
						this.user = response.data.obj.userInfo;
						console.log(this.user)
						this.user.randName = this.$store.state.user.randName;
						if (!this.$store.state.user.ranName) {
							let user = {
								token: this.$store.state.user.token,
								randName: util.randomString(),
								userPic: this.user.pic,
								nickName: this.user.name
							}
							console.log(user)
							this.$store.commit('setUser', user)
							localStorage.setItem('user', JSON.stringify(user))
						}

						this.productSize = response.data.obj.productSize
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getWalletByCurrencyId() {
				const that = this;
				let params = new FormData()
				params.append('currencyId', 1)
				that.axios.post(config.requestUrl + '/front/financial/getWalletByCurrencyId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.balance = response.data.obj.using;
						console.log(response)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						setTimeout(() => {
							this.$router.push('/')
						}, 1200)
					}
					Toast(response.data.msg);
				})
			},
			getUserTotalIncome() {
				const that = this;
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/financial/getUserTotalIncome', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.srPrice = response.data.obj.totalAmount;
						console.log(response)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						setTimeout(() => {
							this.$router.push('/')
						}, 1200)
					}
					Toast(response.data.msg);
				})
			},
			openModal() {
				this.publicModal = true;
			},
			closeModal() {
				this.publicModal = false;
			},
			saveQrocde() {
				if (util.isWx()) {
					Toast.success('请长按图片保存')
				} else {
					let canvas = document.createElement("canvas");
					let ctx = canvas.getContext("2d");
					let img = this.$refs.qrcode;
					console.log(img)
					canvas.height = 300;
					canvas.width = 300;
					ctx.drawImage(img, 0, 0, 300, 300);
					let base64 = canvas.toDataURL("image/png");
					canvas = null;
					console.log(base64)
					var aLink = document.createElement('a');
					var blob = this.base64ToBlob(base64); //new Blob([content]);
					var evt = document.createEvent("HTMLEvents");
					evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
					aLink.download = 'qrcode.jpg';
					aLink.href = URL.createObjectURL(blob);
					// aLink.dispatchEvent(evt);
					aLink.click()

				}
			},
			base64ToBlob(code) {
				var parts = code.split(';base64,');
				var contentType = parts[0].split(':')[1];
				var raw = window.atob(parts[1]);
				var rawLength = raw.length;

				var uInt8Array = new Uint8Array(rawLength);

				for (var i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			},
			loginout() {
				const that = this;
				Dialog.confirm({
						title: '退出登录',
						message: '确认要退出登录？',
					})
					.then(() => {
						console.log('退出登录')
						Toast.loading({
							message: '退出中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						that.axios.post(config.requestUrl + '/front/logout', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								localStorage.removeItem("user");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
								Toast.success('退出成功')
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			navSr() {
				this.$router.push('/withdraw')
			},
			navBa2() {
				window.location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009626"
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			},
			navInvite() {
				const that = this
				this.$router.push('/invite');
				/* let params = new FormData()
				params.append('inviteCode', 1);
				that.axios.post(config.requestUrl + '/front/user/checkIsShowCode', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.$router.push('/invite');
					} else {
						Dialog.alert({
							message: response.data.msg,
						}).then(() => {
							console.log(111)
						});
					}
					console.log(response)
				}, response => {
					Toast('获取失败，请稍后重试');
				}) */
			},
			navOrderList() {
				this.$router.push('orderlist');
			},
			navEntry() {
				this.$router.push('entry');
			}
		},
		mounted() {
			this.getMember();
			this.getWalletByCurrencyId();
			this.getUserTotalIncome();
			util.wxConfig();
		}
	}
</script>

<style scoped="scoped">
	.container {
		/* padding-bottom: 240px; */
		padding-bottom: 120px;
		background: #F4F4F4;
		min-height: 100%;
		box-sizing: content-box;
	}

	.member-wrap {
		background-image: url(../assets/mem-bg.png);
		width: 750px;
		/* height: 447px; */
		box-sizing: border-box;
		background-size: 750px 447px;
		background-repeat: no-repeat;
	}

	.user-wrap {
		width: 750px;
		height: 88px;
		padding: 0 40px;
		margin-top: 68px;
		box-sizing: border-box;
	}

	.headimg {
		width: 80px;
		height: 80px;
		margin-right: 20px;
		border: 4px solid #FFFFFF;
		border-radius: 100%;
	}

	.user-right {
		height: 80px;
	}

	.username-tip {
		font-size: 20px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.username {
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 30px;
	}

	.info-wrap {
		margin: auto;
		width: 690px;
		height: 372px;
		background-image: url(../assets/mem-info-bg.png);
		box-sizing: border-box;
		background-size: 690px 365px;
		background-repeat: no-repeat;
		position: relative;
	}

	.info-line {
		width: 2px;
		height: 180px;
		background: #FFFFFF;
	}

	.info-left {
		width: 374px;
		padding-left: 30px;
		box-sizing: border-box;
	}

	.info-collect-num {
		font-size: 36px;
		font-weight: 600;
		color: #FFFFFF;
		margin-top: 126px;
		text-align: center;
	}

	.info-collect-img {
		width: 40px;
		height: 40px;
	}

	.info-collect-wrap {
		margin-top: 61px;
	}

	.info-collect-text {
		margin-left: 10px;
		font-size: 24px;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.85);
	}

	.info-right {
		width: 374px;
		padding-right: 30px;
		box-sizing: border-box;
	}

	.menu-wrap {
		width: 690px;
		height: 250px;
		background: #FFFFFF;
		border-radius: 0px 0px 20px 20px;
		margin: -100px auto;
		padding-top: 40px;
		padding: 40px 40px 0;
		box-sizing: border-box;
	}

	.menu-wrap2 {
		width: 610px;
		padding: 0 40px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		margin: 110px auto;
		padding-bottom: 0px;
	}

	.menu-wrap2-top {
		width: 100%;
		height: 208px;
	}

	.menu-wrap2-bottom {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		width: 100%;
		height: 208px;
	}

	.menu-wrap3-bottom {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		width: 100%;
		height: 208px;
		/* margin-bottom: 120px; */
	}

	.menu-img {
		width: 60px;
		height: 60px;
	}

	.menu-name {
		font-size: 24px;
		font-weight: 400;
		color: #000000;
		margin-top: 20px;
	}

	/* .menu-wrap2 {
		width:500px;
		padding: 0 95px;
		height: 208px;
		background: #FFFFFF;
		border-radius: 20px;
		margin: 110px auto;
	}
 */
	.public-modal-shaw {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1000;
	}

	.public-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background: rgba(0, 0, 0, 0.7);
		width: 600px;
		height: 1062px;
		z-index: 1001;
		background-image: url(../assets/public-bg.png);
		background-size: 600px 1062px;
		background-repeat: no-repeat;
	}

	.qrcode {
		width: 410px;
		height: 410px;
		margin-top: 50px;
	}

	.public-text {
		margin-top: 142px;
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		line-height: 40px;
	}

	.public-sbtn {
		margin-top: 138px;
		width: 463px;
		height: 88px;
		background: rgba(255, 255, 255, 0);
		border-radius: 10px;
		border: 3px solid #FFFFFF;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 88px;
		text-align: center;
	}

	.public-close-btn {
		margin-top: 40px;
		width: 463px;
		height: 88px;
		background: rgba(255, 255, 255, 0);
		border-radius: 10px;
		border: 3px solid #FFFFFF;
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 88px;
		text-align: center;
	}


	.loginout-wrap {
		position: fixed;
		top: 29px;
		right: 10px;
		z-index: 100
	}

	.loginout-img {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	.loginout-text {
		font-size: 22px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 30px;
		letter-spacing: 1px;
	}

	.menu-item {
		width: 120px;
	}

	.edit-img {
		width: 30px;
		height: 30px;
		margin-left: 10px;
	}

	.edit-wrap {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		margin: auto;
	}

	.edit-title {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		margin: 30px 0;
	}

	.edit-line {
		width: 100%;
		height: 2px;
		background: rgba(0, 0, 0, 0.1);
	}

	.edit-input {
		width: 480px;
		height: 70px;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		padding: 17px 20px;
		box-sizing: border-box;
		margin: 35px 0 39px;
		border: none;
	}

	.edit-btn {
		margin-top: 20px;
		padding-bottom: 30px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
	}

	.edit-msg {
		width: 470px;
		margin: 40px 0;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}
</style>