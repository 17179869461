<template>
	<div class="container">
		<div class="flex-column align-center">
			<img src="../assets/pay_suc_icon.png" class="pay-suc-icon" />
			<div class="good-name">「{{name}}」</div>
			<div class="good-name">数字藏品已挂撤回寄售！</div>
			<div @click.stop="navHome" class="pay-detail">寄售广场</div>
			<div @click.stop="navHome" class="go-home">回到首页</div>
		</div>
	</div>
</template>


<script>
	import util from '../config/util.js';
	export default {
		data() {
			return {
				name:''
			}
		},
		methods: {
			navHome(){
				this.$router.replace('/')
			},
		},
		mounted() {
			util.wxConfig();
			this.name=this.$route.query.name
		}
	}
</script>

<style scoped="scoped">
	.pay-suc-icon {
		width: 200px;
		height: 200px;
		margin-top: 100px;
		margin-bottom: 50px;
	}

	.good-name {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		line-height: 40px;
	}
	.pay-detail {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		position: absolute;
		bottom: 290px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}

	.go-home {
		width: 590px;
		height: 80px;
		border: 1px solid #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		position: absolute;
		bottom: 170px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}
</style>
