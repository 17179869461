//演示环境
var requestUrl, imgUrl, authHost, qrcodeHost, balanceUrl, ticketUrl, pointsRequest, tdUrl, updateUrl;
let env =1;

if (env == 0) {
	requestUrl = 'http://www.allnfts.cn';
	// requestUrl = 'http://y8ua7g.natappfree.cc/';
	// requestUrl = 'http://sfytyg.natappfree.cc'
	authHost = 'http://test.shop.metacbc.cn';
	qrcodeHost = 'http://test.allnfts.cn/'
	balanceUrl = 'http://test.allnfts.cn/balance';
	ticketUrl = 'http://testticket.metacbc.cn';
	pointsRequest = "https://api.ossclub.cn/v2";
	tdUrl = "http://test3d.metacbc.cn"
	updateUrl = "http://app.metacbc.cn/api/update/devversion"
} else {
	//线上环境
	requestUrl = 'https://nft.allnfts.cn';
	imgUrl = 'https://ipfs.allnfts.cn/ipfs/';
	authHost = 'https://shop.metacbc.cn';
	qrcodeHost = 'https://wx.allnfts.cn/'
	balanceUrl = 'https://wx.allnfts.cn/balance'
	ticketUrl = 'https://market.metacbc.cn'
	pointsRequest = "https://nft.allnfts.cn/v2";
	tdUrl = "https://3d.metacbc.cn"
	updateUrl = "http://app.metacbc.cn/api/update/version"
}

//固定参数
const appId = 'wx23406d34d48c253c';
const host = authHost + '/';
const authUrl = 'https://auth.allnfts.cn'
export default {
	appId,
	requestUrl,
	imgUrl,
	authHost,
	host,
	authUrl,
	qrcodeHost,
	balanceUrl,
	ticketUrl,
	pointsRequest,
	tdUrl,
	updateUrl
}