<template>
	<div class="container">
		<div class="flex-row align-center justify-center support-sec">
			<div class="zd" @click.stop="navLog()">账单</div>
			<div class="tx" @click.stop="widthdraw()">提现</div>
		</div>
		<van-list v-model="loading" @load="getEndueListByProductId" :finished="finished" finished-text=""
			:error.sync="error" error-text="请求失败，点击重新加载">
			<div class="item flex-column" v-for="(item,index) in list" v-bind:key="index">
				<div class="top flex-row align-center">
					<div class="name-wrap flex-column" @click.stop="showDetail(index)">
						<div class="flex-row align-center justify-center">
							<div class="name">{{item.endueSettingName}}</div>
							<img src="../assets/entry/icon-search.png" alt="" class="detail-img">
						</div>
						<div class="flex-row align-center justify-center label-wrap">
							<div class="label org">{{item.endueSettingFareTypeStr}}</div>
							<div class="label blue">{{item.endueSettingTicketName}}</div>
						</div>
					</div>
					<div class="flex-column align-center price-item">
						<div class="price-ti">统一零售价</div>
						<div class="price">￥{{item.retailPrice}}</div>
					</div>
					<div class="flex-column align-center price-item2">
						<div class="price-ti">成本价</div>
						<div class="price">￥{{item.srcPrice}}</div>
					</div>
					<div class="flex-column align-center price-item3">
						<div class="price-ti">利润</div>
						<div class="price">￥{{item.lr}}</div>
						<!-- <div class="price">￥{{item.endueIncome}}</div> -->
					</div>
				</div>
				<div class="poster-btn" @click.stop="makeCanvas(index)">生成海报</div>
			</div>
		</van-list>
		<div class="model" v-if="detailModel" @click.stop="closeModal()"></div>
		<div class="detail-modal" v-if="detailModel">
			<div class="ti-con flex-row align-center">
				<div class="detail-ti">{{title}}</div>
				<div class="close-btn" @click.stop="closeModal()">关闭</div>
			</div>
			<div class="detail-con" v-html="detail"></div>
		</div>
		<div class="model" v-if="confirmModal" @click.stop="cancelConfirmModal()"></div>
		<div class="confirm-wrap flex-column align-center" v-if="confirmModal">
			<div class="confirm-title">提现提示</div>
			<div class="confirm-goods-name">
			</div>
			<div class="confirm-content" v-if="confirmPrice>0">
				截止上个月最后一天，卡内可提现余额{{confirmPrice}}元， 是否确认转收入？
			</div>
			<div class="confirm-content">
				截止上个月最后一天，卡内可提现余额{{confirmPrice}}元
			</div>
			<div class="confirm-tips flex-row align-center justify-center" v-if="confirmPrice>0">
				<img src="../assets/icon-tx.png" class="icon-tx" alt="">
				<div class="tx-tips">转收入后，可提现至银行卡。</div>
			</div>
			<div class="btn-wrap flex-row align-center">
				<div class="btn-left" @click.stop="confirmTx()">确认</div>
				<div class="btn-right" @click.stop="cancelConfirm()">取消</div>
			</div>
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				error: false,
				productId: '',
				detailModel: 0,
				detail: '',
				title: '',
				productEntryId: '',
				confirmModal: 0,
				confirmPrice: 0
			}
		},
		mounted() {
			this.productId = this.$route.query.productId;
			this.productEntryId = this.$route.query.productEntryId;
		},
		methods: {
			confirmTx() {
				const that = this;
				if(that.confirmPrice<=0){
					that.confirmModal = 0;
					return;
				}
				let params = new FormData()
				params.append('productEntryId', this.productEntryId);
				this.axios.post(config.requestUrl + '/front/endue/transferToIncome', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.confirmModal = 0;
						Toast('提现成功');

					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg); 
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			cancelConfirm() {
				this.confirmModal = 0;
			},
			widthdraw() {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', this.productEntryId);
				this.axios.post(config.requestUrl + '/front/endue/getCanTransferAmount', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj > 0) {
							that.confirmPrice = response.data.obj;
							that.confirmModal = 1;
						} else {
							that.confirmPrice = response.data.obj;
							that.confirmModal = 1;
						}
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			navLog() {
				this.$router.push('/entrylog?productEntryId=' + this.productEntryId)
			},
			closeModal() {
				this.detailModel = 0;
			},
			showDetail(index) {
				const that = this;
				let params = new FormData()
				params.append('endueSettingId', this.list[index].endueSettingId);
				this.axios.post(config.requestUrl + '/front/endue/getEndueDetailsById', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.detail = response.data.obj.details;
						this.title = response.data.obj.name
						console.log(this.detail)
						this.detailModel = 1;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getEndueListByProductId() {
				this.loading = true;
				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				params.append('productId', this.productId);
				this.axios.post(config.requestUrl + '/front/endue/getEndueListByProductId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = that.list;
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].lr = util.floatSub(newList[i].retailPrice, newList[i].srcPrice)
						}
						if (response.data.obj.isLastPage) {
							this.finished = true;
							this.more = false;
						}
						list = list.concat(newList);
						this.pageNum = this.pageNum + 1;
						this.list = list;
						if (newList.length == 0) {
							this.finished = true;
						}
						Toast.clear()
						this.$forceUpdate();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			makeCanvas(index) {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', this.productEntryId);
				params.append('productId', this.productId);
				params.append('id', this.list[index].id)
				this.axios.post(config.requestUrl + '/front/endue/generatePosterById', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.$router.push({
						path: '/entryshare',
						query: {
							endueSettingFareTypeStr: that.list[index].endueSettingFareTypeStr,
							endueSettingTicketName: that.list[index].endueSettingTicketName,
							retailPrice: that.list[index].retailPrice,
							endueSettingName: that.list[index].endueSettingName,
							id: response.data.obj
						}
					})
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			}
		},
	}
</script>

<style scoped="scoped">
	.container {
		background: #F4F4F4;
		min-height: 100%;
		width: 100%;
		height: 100%;
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	.item {
		width: 710px;
		height: 230px;
		background: #FFFFFF;
		border-radius: 10px;
		margin: 20px auto 0;
	}

	.top {
		width: 710px;
		height: 160px;
		background: #FFFFFF;
		box-shadow: 0px 5px 8px 0px rgba(212, 212, 212, 0.28);
		border-radius: 10px;
	}

	.name-wrap {
		width: 256px;
		height: 100px;
	}

	.name {
		font-size: 28px;
		font-weight: 600;
		color: #000000;
		border-bottom: 1px solid #000000;
	}

	.detail-img {
		width: 22px;
		height: 22px;
		margin-left: 5px;
	}

	.label-wrap {
		margin-top: 25px;
	}

	.org {
		background: #FFE396;

	}

	.blue {
		background: rgba(0, 169, 250, 0.27);

	}

	.label {
		padding: 8px 14px;
		border-radius: 20px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.85);
		margin-right: 10px;
		flex-shrink: 0;
	}

	.price-item {
		width: 180px;
		height: 100px;
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		flex-shrink: 1;
	}

	.price-item2 {
		width: 133px;
		height: 100px;
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		flex-shrink: 1;
	}

	.price-item3 {
		width: 137px;
		height: 100px;
		border-left: 1px solid rgba(0, 0, 0, 0.1);
		flex-shrink: 1;
	}

	.price-ti {
		font-size: 24px;
		font-weight: 400;
		color: #000000;
	}

	.price {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-top: 35px;
	}

	.poster-btn {
		width: 150px;
		height: 40px;
		background: rgba(98, 54, 255, 0.08);
		border-radius: 25px;
		font-size: 24px;
		font-weight: 400;
		color: #6236FF;
		line-height: 40px;
		text-align: center;
		margin-top: 14px;
		margin-right: 30px;
		align-self: flex-end;
	}

	.detail-modal {
		width: 600px;
		height: max-content;
		max-height: 90%;
		overflow: scroll;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1001;
		background: #FFFFFF;
		border-radius: 20px;
	}

	.ti-con {
		width: 600px;
		height: 90px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px #E7E7E7;
		border-radius: 20px 20px 0px 0px;
		position: relative;
	}

	.detail-ti {
		width: 600px;
		text-align: center;
		font-size: 32px;
		font-weight: 600;
		color: #000000;
	}

	.close-btn {
		font-size: 26px;
		font-weight: 400;
		color: #000000;
		position: absolute;
		top: 32px;
		right: 39px;
		bottom: 0;
		margin: auto;
	}

	.detail-con {
		width: 600px;
	}

	.detail-con>>>img {
		max-width: 100% !important;
		height: auto !important;
	}

	.zd {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 150px;
		width: 100px;
		height: 50px;
		background: #6236FF;
		border-radius: 25px;
		margin: auto;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
	}

	.tx {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 20px;
		width: 100px;
		height: 50px;
		background: #6236FF;
		border-radius: 25px;
		margin: auto;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
	}

	.confirm-title {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 38px;
	}

	.confirm-wrap {
		width: 630px;
		height: max-content;
		background: #FFFFFF;
		border-radius: 20px;
		padding: 35px 0 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		box-sizing: border-box;
		z-index: 1111;
	}

	.confirm-goods-name {
		font-size: 26px;
		font-weight: 400;
		margin-bottom: 5px;
	}

	.confirm-content {
		width: 520px;
		font-size: 26px;
		font-weight: 400;
		padding-bottom: 24px;
	}

	.confirm-tips {
		width: 520px;
		height: 60px;
		background: #FEF9EA;
		border-radius: 30px;
		margin-bottom: 20px;
	}

	.icon-tx {
		width: 20px;
		height: 20px;
	}

	.tx-tips {
		margin-left: 10px;
		font-size: 24px;
		font-weight: 400;
		color: #FFA800;
	}

	.btn-wrap {
		width: 100%;
		height: 88px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	.btn-left {
		width: 50%;
		height: 88px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.btn-right {
		width: 50%;
		height: 88px;
		text-align: center;
		line-height: 88px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}
</style>
