<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">tk</div>
		</div>
		<div class="wrap">
			<div class="flex-row align-center detail-item">
				<div class="detail-item-title">tiktok账户名称:</div>
				<div class="detail-item-con">
					{{tkData.tkAccountName}}
				</div>
				<!-- <div class="detail-item-btn" @click="getAgencyOperationsSetListByUser" v-if="qc==0">查看全称</div> -->
			</div>
			<div class="flex-row align-center detail-item">
				<div class="detail-item-title">所属国家:</div>
				<div class="detail-item-con">
					{{tkData.country}}
				</div>
			</div>
			<div class="flex-row align-center detail-item">
				<div class="detail-item-title">本月发布视频数量:</div>
				<div class="detail-item-con">
					{{tkData.pushVideosQuantity}}
				</div>
			</div>
			<div class="flex-row align-center detail-item">
				<div class="detail-item-title">本月带货订单数量:</div>
				<div class="detail-item-con">
					{{tkData.ordersQuantity}}
				</div>
			</div>
			<div class="flex-row align-center detail-item">
				<div class="detail-item-title">本月成交金额:</div>
				<div class="detail-item-con">
					{{tkData.dealAmount}}
				</div>
			</div>
			<div class="flex-row align-center detail-item">
				<div class="detail-item-title">数据更新时间:</div>
				<div class="detail-item-con">
					{{tkData.dataUpdateTime}}
				</div>
			</div>
			<div class="con-tips1">注：由于成交金额受退换货，以及平台结算时间影响，可能与最后结算金额不一致。 </div>
			<div class="flex-row align-center detail-item">
				<div class="detail-item-title">NTF状态:</div>
				<div class="detail-item-con" style="color: red;" v-if="tkData.entryStatus == 16">
					冻结
				</div>
				<div class="detail-item-con" style="color: green;" v-else>
					流通
				</div>
				
			</div>

			<div class="flex-row align-center detail-item">
				<div class="detail-item-title">可提金额:</div>
				<div class="detail-item-con">
					{{price}}元
				</div>
			</div>
			<div class="con-tips1">注：每月15号完成上月结算，节假日顺延 </div>
			<!-- <div class="flex-row align-center btn-sec">
				<div @click.stop="navHome" class="pay-detail" @click="showCModel">申请代运营</div>
				<div @click.stop="navHome" class="go-home" @click="jg">交割TK账户</div>
			</div> -->
		</div>
		<div class="model" v-if="cmodel" @click="cancelCModel"></div>
		<div class="c-wrap" v-if="cmodel">
			<div class="flex-row align-center detail-item">
				<div class="t">代运营时间：</div>
				<van-radio-group v-model="cureentIndex" direction="horizontal">
					<van-radio v-for="(item,index) in percent" :key="index" :name="index"
						icon-size="13px">{{item.monthNumber}}个月</van-radio>
				</van-radio-group>
			</div>
			<div class="flex-row align-center detail-item">
				<div class="t">代运营分成：</div>
				<div>{{percent[cureentIndex].shareRatio}}%</div>
			</div>
			<div class="flex-row align-center detail-item">
				<div class="t">账户名称：</div>
				<div>{{tkData.tkAccountName}}</div>
			</div>
			<div @click.stop="navHome" class="pay-detail2" @click="cancelCModel">确认</div>
			<div class="flex-row align-center agreement-sec">
				<div class="agreement-pre">线上签署</div>
				<div class="agreement-con" @click="navAgreement(3)">《合作协议》</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog,
		RadioGroup,
		Radio
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import 'vant/lib/radio/index.css';
	import 'vant/lib/radio-group/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	Vue.use(Radio);
	Vue.use(RadioGroup);
	export default {
		data() {
			return {
				num: '',
				cureentIndex: '',
				tkData: {

				},
				qc: 0,
				percent: [],
				cmodel: 0,
				price:''
			}
		},
		methods: {
			cancelCModel() {
				this.cmodel = 0;
			},
			showCModel() {
				this.cmodel = 1;
			},
			jg() {
				Dialog.confirm({
						title: '提示',
						message: '请跟平台管理员联系，走相关流程',
						showCancelButton: false
					})
					.then(() => {
						console.log('关闭了')
					})
					.catch(() => {
						console.log('关闭了')
					});
			},
			getAgencyOperationsSetListByUser() {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', that.productEntryId)
				this.axios.post(config.requestUrl + '/front/agencyOperations/getAgencyOperationsSetListByUser', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj) {
							console.log(11)
							that.tkData.tkAccountName = response.data.obj.tkAccountName
							that.qc = 1;
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getOperationsInfoByEntryId() {
				const that = this;
				let params = new FormData()
				params.append('productEntryId', that.productEntryId)
				this.axios.post(config.requestUrl + '/front/agencyOperations/getOperationsInfoByEntryId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj) {
							that.tkData = response.data.obj;
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getAgencyOperationsSetList() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/agencyOperations/getAgencyOperationsSetList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj) {
							that.percent = response.data.obj;
							that.cureentIndex = 0
						}
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			}
		},
		mounted() {
			this.productEntryId = this.$route.query.productEntryId;
			this.price = this.$route.query.price;
			this.getOperationsInfoByEntryId();
			// this.getAgencyOperationsSetList();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		min-height: 100%;
		background: #6236FF;
	}

	.wrap {
		width: 680px;
		height: max-content;
		margin: 46px auto 0;
		/* padding: 155px 0; */
		padding: 30px;
		box-sizing: border-box;
		background: #FFFFFF;
		box-shadow: inset 0px 0px 87px 0px #FFFFFF;
		border-radius: 20px;
	}

	.img-wrap {
		width: 680px;
		height: 746px;
		background-image: url(../assets/bg-yxg.png);
		background-size: 680px 746px;
	}



	.bg {
		width: 680px;
		height: 746px;
	}

	.num {
		font-size: 150px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 30px;
	}

	.unit {
		margin: 60px 0 30px 10px;
		font-size: 24px;
		font-weight: 400;
		color: #000000;
	}

	.tips {
		width: 330px;
		height: 80px;
		margin: 16px auto;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 40px;
		font-size: 30px;
		font-weight: 400;
		color: #000000;
		text-align: center;
		line-height: 80px;
	}

	.zd-btn {
		width: 105px;
		height: 49px;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 25px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 49px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 30px;
		bottom: 0;
		margin: auto;
	}

	.detail-item {
		padding-top: 30px;
	}

	.detail-item-title {
		font-size: 26px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 26px;
		width: 180px;
		flex-shrink: 0;
	}

	.detail-item-con {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
		margin-left: 46px;
		word-break: break-all;
		word-wrap: break-word;
	}

	.detail-item-btn {
		width: 180px;
		height: 50px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		margin-left: 50px;
	}

	.btn-sec {
		margin-top: 50px;
		justify-content: space-between;
	}

	.pay-detail {
		width: 280px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;

		text-align: center;
	}

	.go-home {
		width: 280px;
		height: 80px;
		border: 1px solid #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		text-align: center;
	}

	.c-wrap {
		position: fixed;
		z-index: 1001;
		width: 660px;
		height: max-content;
		background: #FFFFFF;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		padding: 40px 40px;
		border-radius: 10px;
		font-size: 26px;
		color: #000000;
		box-sizing: border-box;
		line-height: 26px;
	}

	. .t {
		margin-right: 5px;
	}


	.agreement-sec {
		/* margin-top: 30px; */
		margin-left: 44px;
	}

	.agreement-pre {
		font-size: 18px;
		color: #000000;
		line-height: 37px;
	}

	.agreement-con {
		font-size: 26px;
		color: #6236FF;
		line-height: 26px;
		border-bottom: 1px solid #6236FF;
	}

	.pay-detail2 {
		width: 600px;
		height: 60px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 18px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 60px;
		margin: 120px auto 10px;
		text-align: center;
	}

	.con-tips1 {
		margin-top: 21px;
		font-size: 24px;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5000);
	}
</style>