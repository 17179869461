<template>
	<div>
		<div class="flex-row align-center justify-end">
			<div class="bill" @click.stop="navBillList()">账单</div>
		</div>
		<div class="flex-column align-center">
			<img src="../assets/yue_img.png" class="yue-icon" />
			<div class="mine-yue-title">我的收入</div>
			<div class="mine-yue">￥{{sr.avlBalance}}</div>
		</div>
		<div class="echarge-btn" @click.stop="navWithdraw()">提现</div>
		<!-- <div class="more-btn" @click.stop="navWithdraw()">更多操作</div> -->
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				balance: 0,
				typeArr: [],
				type: 0,
				payModel: false,
				payType: 2,
				wxH5Pay: 0, //是否开始了其他浏览器h5支付
				sr: {}
			}
		},
		methods: {
			changeMoney(type) {
				this.type = type;
			},
			recharge() {
				this.payModel = true;
			},
			navBillList() {
				this.$router.push('/billlist')
			},
			navBill() {
				this.$router.push('/bill')
			},
			navWithdraw() {
				this.$router.push('/withdraw')
			},
			getWalletByCurrencyId() {
				const that = this;
				let params = new FormData()
				params.append('currencyId', 1)
				that.axios.post(config.requestUrl + '/front/financial/getWalletByCurrencyId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.balance = response.data.obj.using;
						console.log(response)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getUserTotalIncome() {
				const that = this;
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/financial/getUserTotalIncome', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.sr = response.data.obj;
						console.log(response)
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						setTimeout(() => {
							this.$router.push('/')
						}, 1200)
					}
					Toast(response.data.msg);
				})
			},
		},
		mounted() {
			// this.getWalletByCurrencyId();
			this.getUserTotalIncome();
			util.wxConfig();
		}
	}
</script>

<style scoped="scoped">
	.bill {
		width: 105px;
		height: 49px;
		background: rgba(98, 54, 255, 0.07);
		border-radius: 25px;
		margin-top: 20px;
		text-align: center;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 49px;
		margin-right: 30px;
	}

	.yue-icon {
		width: 200px;
		height: 200px;
	}

	.mine-yue-title {
		font-size: 30px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
		margin-top: 40px;
	}

	.mine-yue {
		font-size: 60px;
		font-weight: 600;
		color: #000000;
		line-height: 60px;
		margin-top: 101px;
	}

	.echarge-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		position: absolute;
		bottom: 290px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}

	.more-btn {
		width: 590px;
		height: 80px;
		border: 1px solid #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 80px;
		position: absolute;
		bottom: 170px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}
</style>
