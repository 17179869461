<template>
	<div class="container">
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="getCode()">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">交易密码设置</div>
		</div>
		<div class="content flex-column align-center" :style="'height:'+conHeight+'px'">
			<div class="title">密码设置/修改</div>
			<div class="in-wrap flex-column align-center">
				<div class="flex-row align-center t">
					<div class="phone">{{user.loginName}}</div>
					<div class="get-code" @click.stop="getVerifyCode()">{{msg}}</div>
				</div>
				<input type="text" class="input-item" placeholder="验证码" v-model="code"
					oninput="if(value.length > 6)value = value.slice(0, 6)" maxlength="6">
				<input type="text" class="input-item" oninput="if(value.length > 8)value = value.slice(0, 8)"
					maxlength="8" placeholder="请输入新密码" v-model="password">
				<input type="text" class="input-item" oninput="if(value.length > 8)value = value.slice(0, 8)"
					maxlength="8" v-model="confirmPassword" placeholder="请再次输入新密码">
				<div class="btn" :class="code && password &&confirmPassword ?'btn-true':''" @click="confrimEdit()">确认
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';

	export default {
		data() {
			return {
				conHeight: 0,
				user: {},
				time: 0,
				msg: '获取验证码',
				sendCode: 0,
				inteval: '',
				code: '',
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				password: '',
				confirmPassword: ''

			}
		},
		methods: {

			getVerifyCode() {
				const that = this;
				if (that.sendCode || that.time > 0) return;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			getCode() {
				const that = this;
				if (that.sendCode || that.time > 0) return;
				that.sendCode = 1;
				let params = new FormData()
				params.append('phoneEmail', that.user.loginName)
				params.append('smsType', 1)
				params.append('zone', 86)
				params.append('doType', 1)
				params.append('codeValue', that.verifyCode)
				params.append('key', that.verifyKey)

				Toast.loading({
					message: '发送中...',
					forbidClick: true,
					duration: 0,
				});
				that.axios.post(config.requestUrl + '/ajax/sendCode', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					that.sendCode = 0;
					if (response.data.code == 0) {
						that.showVerify = 0;
						Toast.success('发送成功');
						that.time = 60;
						that.msg = `${that.time}s`;
						that.inteval = setInterval(() => {
							if (that.time <= 0) {
								that.msg = '获取验证码'
								clearInterval(this.interval);
							} else {
								that.time = that.time - 1;
								that.msg = `${that.time}s`
							}
						}, 1000)
					} else {
						Toast(response.data.msg);
					}

				}, response => {
					console.log(response)
					this.sendCode = 0;
					Toast('获取失败，请稍后重试');
				})
			},
			confrimEdit() {
				const that = this;
				if(!that.code || !that.password || !that.confirmPassword){
					return;
				}
				if (!that.code) {
					Toast('请输入验证码');
					return;
				}
				if (!that.password) {
					Toast('请输入密码');
					return;
				}
				if (!that.confirmPassword) {
					Toast('请输入确认密码');
					return;
				}
				if (that.password.length < 6) {
					Toast('密码长度为6-8位');
					return;
				}

				if (that.password != that.confirmPassword) {
					Toast('密码与确认密码不一致');
					return;
				}
				let params = new FormData()
				params.append('code', that.code);
				params.append('newPwd', that.password);
				params.append('newPwdConfirm', that.confirmPassword);
				this.axios.post(config.requestUrl + '/front/user/changePassword', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast('修改成功');
						setTimeout(() => {
							this.$router.go(-1)
						}, 1200)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},
			getMember() {
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.getBox = 0;
					if (response.data.code == 0) {
						this.user = response.data.obj.userInfo;
						this.user.randName = this.$store.state.user.randName;
						if (!this.$store.state.user.ranName) {
							let user = {
								token: this.$store.state.user.token,
								randName: util.randomString(),
								userPic: this.user.pic,
								nickName: this.user.name
							}
							this.$store.commit('setUser', user)
							localStorage.setItem('user', JSON.stringify(user))
						}

						this.productSize = response.data.obj.productSize
						Toast.clear()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);

					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
		},
		mounted() {
			this.conHeight = window.screen.height - 375 / window.screen.width * 44
			this.getMember();
		},
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
	}

	.content {
		height: 100%;
		background-image: url(../assets/pw-bg.png);
		background-size: 100%;
		overflow: scroll;
	}

	.title {
		font-size: 38px;
		font-weight: 600;
		color: #FFFFFF;
		letter-spacing: 2px;
		margin-top: 35px;
	}

	.in-wrap {
		width: 706px;
		height: max-content;
		background-image: url(../assets/pw-bg2.png);
		background-size: 706px 1200px;
		padding-bottom: 100px;
	}

	.t {
		margin-top: 158px;
		width: 590px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		overflow: hidden;

	}

	.phone {
		width: 410px;
		margin-left: 30px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.get-code {
		width: 180px;
		height: 100px;
		background: #6236FF;
		font-size: 28px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 100px;
	}

	.input-item {
		margin-top: 40px;
		width: 590px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		padding: 30px;
		box-sizing: border-box;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.btn {
		margin-top: 200px;
		width: 550px;
		height: 80px;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		text-align: center;
		line-height: 80px;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
		letter-spacing: 2px;
	}
	.btn-true{
		background:#6236FF ;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}
</style>
