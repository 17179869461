<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">充值</div>
		</div>
		<div class="pay-container">
			<div class="bill-type-title">充值方式</div>
			<div class="pay-con">
				<div class="pay-list">
					<!--					<div class="flex-row align-center space-between pay-item" @click.stop="choosePayType(2)">-->
					<!--						<div class="flex-row align-center ">-->
					<!--							<img src='../assets/zfb_icon.png' class="pay-icon" />-->
					<!--							<div class="pay-item-name">支付宝支付</div>-->
					<!--						</div>-->
					<!--						<div class="no-select" v-if="payType != 2"></div>-->
					<!--						<img class="select_icon" v-if="payType == 2" src="../assets/select_icon.png" />-->
					<!--					</div>-->
					<!-- 	<div class="flex-row align-center space-between pay-item" @click.stop="choosePayType(4)">
						<div class="flex-row align-center ">
							<img src='../assets/ysf.png' class="pay-icon" />
							<div class="pay-item-name">银联云闪付</div>
						</div>
						<div class="no-select" v-if="payType != 4"></div>
						<img class="select_icon" v-if="payType == 4" src="../assets/select_icon.png" />
					</div> -->
					<!-- <div class="flex-row align-center space-between pay-item" @click.stop="choosePayType(6)">
						<div class="flex-row align-center ">
							<img src='../assets/zfb_icon.png' class="pay-icon" />
							<div class="pay-item-name">支付宝支付</div>
						</div>
						<div class="no-select" v-if="payType != 6"></div>
						<img class="select_icon" v-if="payType == 6" src="../assets/select_icon.png" />
					</div>
					<div class="flex-row align-center space-between pay-item" @click.stop="choosePayType(7)">
						<div class="flex-row align-center ">
							<img src='../assets/yl.png' class="pay-icon" />
							<div class="pay-item-name">银联快捷</div>
						</div>
						<div class="no-select" v-if="payType != 7"></div>
						<img class="select_icon" v-if="payType == 7" src="../assets/select_icon.png" />
					</div> -->
			<!-- 		<div class="flex-row align-center space-between pay-item" @click.stop="choosePayType(11)">
						<div class="flex-row align-center ">
							<img src='../assets/yl.png' class="pay-icon" />
							<div class="pay-item-name">快捷支付(只需一次绑卡)</div>
						</div>
						<div class="no-select" v-if="payType != 11"></div>
						<img class="select_icon" v-if="payType == 11" src="../assets/select_icon.png" />
					</div> -->
					<div class="flex-row align-center space-between pay-item" @click.stop="choosePayType(12)">
						<div class="flex-row align-center ">
							<img src='../assets/yl.png' class="pay-icon" />
							<div class="pay-item-name">快捷收银台</div>
						</div>
						<div class="no-select" v-if="payType != 12"></div>
						<img class="select_icon" v-if="payType == 12" src="../assets/select_icon.png" />
					</div>
					<div class="flex-row align-center space-between pay-item" @click.stop="choosePayType(8)">
						<div class="flex-row align-center ">
							<img src='../assets/ysf.png' class="pay-icon" />
							<div class="pay-item-name">银联云闪付</div>
						</div>
						<div class="no-select" v-if="payType != 8"></div>
						<img class="select_icon" v-if="payType == 8" src="../assets/select_icon.png" />
					</div>

				</div>
				<div class="flex-row align-center bill-price-sec">
					<div class="bill-price-title">充值金额</div>
					<div class="bill-price-tishi">点击下方数字输入充值金额</div>
				</div>
				<div class="flex-row align-center price-input-sec">
					<div class="fuhao">￥</div>
					<input placeholder="0" type="number" class="price-input" v-model="money" />
				</div>
				<div class="pay-grey-btn" v-if="!money || money == 0">充值</div>
				<div class="pay-btn" v-else @click.stop='confirmPay()'>充值</div>
			</div>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				balance: 0,
				typeArr: [],
				type: 0,
				payModel: false,
				payType: 9,
				wxH5Pay: 0, //是否开始了其他浏览器h5支付
				money: ''
			}
		},
		methods: {
			confirmPay() {
				const that = this
				Toast.loading({
					message: '正在获取支付参数...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('currencyId', 1)
				params.append('rechargeAmount', this.money)
				params.append('rechargeType', 4)
				// params.append('rechargeType', this.payType == 6 || this.payType == 7 || this.payType == 8 || this
				// 	.payType == 9 ? 4 : this.payType == 4 || this
				// 	.payType == 2 ? 3 : this.payType == 11 ? 4 : this.paytType == 12 ? 4 : this.payType)
				this.axios.post(config.requestUrl + '/front/financial/doRechargeOneCustomizeAmt', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.msg = this.msg + "提交订单:成功" + "\n";
					if (response.data.code == 0) {
						this.orderInfo = response.data.obj;
						if (this.payType == 1) {
							this.getPayInfo()
						} else if (this.payType == 4) {
							Toast.clear();
							this.$router.push({
								path: "/scanpay",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						} else if (this.payType == 6) {
							Toast.clear();
							this.$router.push({
								path: "/sdpay",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						} else if (this.payType == 7) {
							Toast.clear();
							this.$router.push({
								path: "/scansdpay",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						} else if (this.payType == 8) {
							Toast.clear();
							this.$router.push({
								path: "/codesdpay",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						} else if (this.payType == 9) {
							Toast.clear();
							this.$router.push({
								path: "/open",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						} else if (this.payType == 11 || this.payType == 12) {
							Toast.clear();
							const that = this;
							let params = new FormData()
							params.append('orderId', this.orderInfo.id)
							// params.append('rechargeType', 2)
							params.append('rechargeType', 4)
							params.append('adaPayType', 1)
							params.append('payType', 2)
							params.append('sandPayType', this.payType == 11 ? 6 : 7)
							params.append('returnUrl', config.host + 'billsuccess?payType=2&token=' + this
								.$store.state.user.token + '&money=' + this.money + '&orderId=' + this.orderInfo.id)
							params.append('callback_url', config.host + 'billsuccess?payType=2&token=' +
								this
								.$store.state.user.token + '&money=' + this.money + '&orderId=' + this.orderInfo.id)
							this.axios.post(config.requestUrl + '/front/financial/doRechargeTwo', params, {
								headers: {
									'Content-Type': 'multipart/form-data',
									'token': this.$store.state.user.token
								}
							}).then(response => {
								if (response.data.code == 0) {
									console.log(response)
									window.location.href = response.data.obj;
								} else {
									let msg = response.data.msg;
									const str = "未获取到用户信息";
									const str2 = "登录已失效"
									if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
										localStorage.removeItem("user");
										that.$store.commit("clearUser");
										that.$store.commit("clearCode");
										setTimeout(() => {
											this.$router.push('/')
										}, 1200)
									}
									Toast(response.data.msg);
								}
							}, response => {
								Toast('获取失败，请稍后重试');
							})
						} else {
							Toast.clear();
							this.$router.push({
								path: "/zfb",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getPayInfo() {
				const that = this;
				let params = new FormData()
				params.append('orderId', this.orderInfo.id);
				params.append('payType', util.isWx() ? 1 : 2)
				params.append('rechargeConfigId', this.typeArr[this.type].id);
				params.append('rechargeType', 1);
				this.axios.post(config.requestUrl + '/front/financial/doRechargeTwo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.msg = this.msg + "getPayInfo:结果成功" + "\n";
						Toast.clear();
						console.log(response)
						if (util.isWx()) {
							let payData = response.data.obj.jsapiResult;
							wx.ready(function() {
								wx.chooseWXPay({
									timestamp: payData.timeStamp,
									nonceStr: payData.nonceStr,
									package: payData.package,
									signType: payData.signType,
									paySign: payData.sign, // 支付签名
									success: function(res) {
										// 支付成功后的回调函数
										Toast.loading({
											message: '正在获取支付结果...',
											forbidClick: true,
											duration: 0,
										});
										that.getPayRes(0);
									},
									fail() {
										Toast('取消支付')
									}
								});
							});
						} else {
							console.log(response.data.obj.h5PayResult)
							this.wxH5Pay = 1;
							this.payModel = false;
							window.location.href = response.data.obj.h5PayResult.mweb_url
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
			getPayRes(redirect) {
				const that = this;
				that.getPayResInteval = setTimeout(() => {
					Toast.loading({
						message: '正在获取支付结果...' + that.count,
						forbidClick: true,
						duration: 0,
					});
					that.count = that.count + 1;
					let orderid = that.orderInfo.id;
					let params = new FormData()
					params.append('id', that.orderInfo.id);
					params.append('type', 1);
					that.axios.post(config.requestUrl + '/front/financial/detailInfo', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (response.data.obj.status == 2) {
								Toast.success('支付成功')
								this.getWalletByCurrencyId();
								clearInterval(that.getPayResInteval)
								if (redirect) {
									this.$router.replace('/balance')
								}
							} else {
								that.getPayRes(redirect);
							}
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						Toast('获取失败，请稍后重试');
					})

				}, 2000)

			},

			choosePayType(type) {
				this.payType = type
			},
			getGetG5PayRes() {
				const that = this;
				Dialog.confirm({
						title: '提示',
						message: '请确认微信支付是否已完成？',
					})
					.then(() => {
						that.getPayRes(0);
					})
					.catch(() => {
						console.log('关闭了')
					});
			},
		},
		mounted() {
			util.wxConfig();
		}
	}
</script>


<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #6236FF;
		min-height: 100%;
	}

	.pay-container {
		width: 750px;
		min-height: 100%;
		background: #6236FF;
		padding: 0 40px 40px 40px;
		box-sizing: border-box;
	}

	.bill-type-title {
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 42px;
		text-align: center;
		margin-top: 50px;
		margin-bottom: 40px;
	}

	.pay-con {
		width: 670px;
		min-height: 100%;
		background: #FFFFFF;
		border-radius: 10px;
		box-sizing: border-box;
		padding-bottom: 246px;
	}

	.pay-list {
		/* height: 233px; */
		padding: 60px 40px 0 40px;
		box-sizing: border-box;
		border-bottom: 1px solid #E3E3E3;
	}

	.pay-item {
		margin-bottom: 41px;
	}

	.pay-icon {
		width: 28px;
		height: 28px;
	}


	.pay-item-name {
		font-size: 28px;
		color: #000000;
		line-height: 26px;
		margin-left: 20px;
	}

	.pay-item-yue {
		font-size: 28px;
		color: #FF0000;
		line-height: 26px;
	}

	.select_icon {
		width: 22px;
		height: 22px;
		background: #6236FF;
		border-radius: 50%;
	}

	.no-select {
		width: 22px;
		height: 22px;
		background: #D8D8D8;
		border-radius: 50%;
	}

	.bill-price-title {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
	}

	.bill-price-sec {
		margin-top: 46px;
		margin-left: 40px;
	}

	.bill-price-tishi {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-left: 20px;
	}

	.price-input-sec {
		margin-top: 25px;
		margin-left: 40px;
	}

	.fuhao {
		font-size: 60px;
		font-weight: 600;
		color: #000000;
		line-height: 84px;
	}

	.price-input {
		font-size: 60px;
		font-weight: 600;
		color: #000000;
		line-height: 84px;
		border: none;
		margin-left: 6px;
		width: 400px;
	}

	.pay-grey-btn {
		width: 590px;
		height: 80px;
		background: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		margin: 552px auto 0 auto;
		text-align: center;
	}

	.pay-btn {
		width: 590px;
		height: 80px;
		background: #6236FF;
		border-radius: 10px;
		font-size: 26px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 80px;
		margin: 552px auto 0 auto;
		text-align: center;
	}
</style>