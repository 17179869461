<template>
	<div class="container" ref="getheight">
		<van-list v-model="loading" @load="getGoods" :finished="finished" finished-text="没有更多了" :error.sync="error"
			error-text="请求失败，点击重新加载">
			<div class="good-list flex-row align-center flex-wrap space-between ">
				<div class="good-item-new flex-column relative" v-for="(item,index) in list" v-bind:key="index"
					@click.stop="navDetail(index,item.id)">
					<!-- 	<div v-if="item.isOpen == '是'" class="goods-item-wrap" :style="'background-image: url('+item.pic+');'">
				</div> -->
					<img v-if="item.isOpen == '是'" :src="item.productPic" class="good-item-img" />
					<img v-else src="../assets/manghe.png" class="good-item-img2" />
					<div class="good-item-con">
						<div class="good-item-name">{{item.isOpen == '是'?item.productName:'盲盒'}}</div>
						<div class="good-item-num">{{item.isOpen == '是'?item.position:item.period+'期'}}</div>
						<div class="good-item-price">￥{{item.buyAmt}}</div>
					</div>
					<!-- <div class="label-freeze">冻结</div> -->
				</div>
			</div>
			<div class="empty flex-column align-center justify-center" v-if="list.length == 0">
				<!-- <div class="empty-tip">无搜索结果</div> -->
			</div>
		</van-list>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css'
	export default {
		data() {
			return {
				keywords: '',
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				windowHeight: 0,
				offsetHeight: 0,
				id: '',
				loading: false,
				finished: false,
				error: false
			}
		},
		methods: {
			onScroll(e) {
				let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body
					.scrollTop; //页面滚动高度
				if (this.offsetHeight < scroll + this.windowHeight + 50) {
					if (this.more && !this.loading) {
						this.getGoods();
					}
				}
			},
			getGoods() {
				// if (!this.more) {
				// 	Toast('没有更多了')
				// 	return;
				// }
				// if (this.loading) return;
				// this.loading = true;
				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				params.append('productId', this.id);
				this.axios.post(config.requestUrl + '/front/user/getProEntryListByProductId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let newList = response.data.obj.list
						let list = this.list;
						let finished = false;
						if (response.data.obj.isLastPage) finished = true;
						list = list.concat(newList);
						this.list = list;
						this.pageNum = this.pageNum + 1;
						that.finished = finished;
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						that.error = true;
					}
					this.loading = false;
				}, response => {
					Toast('获取失败，请稍后重试');
					this.loading = false;
					that.error = true;
				})
			},
			navDetail(index, id) {
				console.log('eee')
				this.$router.push({
					path: '/orderdetail',
					query: {
						id,
						type: 1
					}
				})
			},
		},
		mounted() {
			console.log(this.more)
			this.id = this.$route.query.id;

			// this.windowHeight = window.innerHeight; //窗口高度
			// this.offsetHeight = this.$refs.getheight.offsetHeight;
			// this.getGoods();
			util.wxConfig();
		},
		// destroyed() {
		// 	window.removeEventListener('scroll');
		// }
		// beforeRouteEnter(to, from, next){
		// 	window.addEventListener('scroll', this.onScroll, false);
		// 	next()
		// },
		// beforeRouteLeave(to, from, next) {
		// 	window.removeEventListener('scroll');
		// 	next()
		// }
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		min-height: 100%;
		background: #f2f2f2;
		overflow: scroll;
		padding-top: 20px;
	}

	.empty {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}

	.empty-tip {
		font-size: 40px;
		color: #000000;
	}

	.label-freeze {
		position: absolute;
		right: 0;
		top: 0;
		/* width: 100px;
		height: 20px; */
		font-size: 12px;
		
		border-radius: 10px;
		border-radius: 6px;
		background: linear-gradient(180deg, #CECECE 0%, #8E8E8E 100%);
		padding: 3px 5px;
	}

	.relative {

		position: relative;

	}
</style>